import { messageAction, medicalInfoAction, problemAction } from '.';
import { teleplanService } from '../_services';
import { isPatient, errorHandling, getPersonId } from '../_helpers';
import { teleplanConstant, patientConstant } from '../_constants';


export const teleplanAction = {
    eligibility,
    closeDialog,
};

function eligibility( hic, DateOfBirth, personId){
    return dispatch => {
        teleplanService.eligibility(hic, DateOfBirth, personId)
            .then(
                res => {
                    dispatch({
                        type: teleplanConstant.OPEN_CHECK,
                        result:res.data
                    });
                    dispatch({
                        type: patientConstant.UPDATE_ELIGIBILITY,
                        personId,
                        status: res.data.status
                    });
                },
                error => {
                    errorHandling('teleplan.action.eligibility', error, dispatch); 
                }
            )

    }
}

function closeDialog (){
    return {
        type:teleplanConstant.CLOSE_CHECK,
    }
}


