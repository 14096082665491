import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import config from '../config';

class CountrySelect extends Component {
    constructor(props) {
        super(props);

        // init
        this.state = {
            value: this.props.value,
            countries: []
        };
    }

    componentDidMount() {
        const url = `${config.apiHost}/country`;

        axios.get(url)
            .then(res => {
                this.setState({
                    countries: res.data
                });
            });
    }

    handleChange = (event) => {
        this.setState({
            value: event.target.value
        });

        this.props.onChange(event);
    }

    render() {
        const { countries, value } = this.state;

        const list = countries.length ? (
            countries.map(country => {
                return (
                    <option value={country._id} key={country._id}>{country.Name}</option>
                );
            })
        )
            : <option>No countries yet.</option>;

        return (
            <select className="custom-select d-block w-100" id={this.props.id} name={this.props.name}
                value={value} onChange={this.handleChange} required>
                <option value="">Choose...</option>
                {list}
            </select>
        );
    }
}


CountrySelect.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
};


const temp = CountrySelect;

export { temp as CountrySelect };