import { noteService } from '../_services';
import { errorHandling, formatDateTime, formatDateTimeDisplayInEmr } from '../_helpers';
import { noteConstant } from '../_constants';
import { loadingAction, messageAction } from '../_actions';
import { getUser } from '../_helpers';

export const noteAction = {
    getByPerson,
    edit,
    cancelEdit,
    change,
    save,
    sendNote,
    openRevision,
    closeRevision,
    acknowledgeReply,
    goToNote,
    sendNoteToEmr,
    sendNoteCcToEmr
};

function getByPerson(personId) {
    return dispatch => {
        noteService.getByPerson(personId)
            .then(
                res => {
                    const notes = res.data;

                    dispatch({
                        type: noteConstant.GET_BY_PERSON,
                        personId,
                        notes
                    });
                },
                error => {
                    errorHandling('note.action.getByPerson', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function edit(editNoteId) {
    return {
        type: noteConstant.EDIT,
        editNoteId
    };
}

function cancelEdit() {
    return {
        type: noteConstant.CANCEL_EDIT
    };
}

function change(text) {
    return {
        type: noteConstant.CHANGE,
        text
    };
}

function save(noteId, personId, from, text) {
    return dispatch => {
        text = markText(text, 'Signed', from);

        noteService.save(noteId, personId, from._id, text)
            .then(
                res => {
                    const notes = res.data;

                    dispatch({
                        type: noteConstant.SAVED,
                        notes
                    });

                    dispatch(cancelEdit());
                },
                error => {
                    errorHandling('note.action.save', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function sendNote(noteId, fromObj, to, subject, lastVersion, prescriptionId) {
    return dispatch => {
        dispatch(loadingOpen());
        lastVersion.text = markText(lastVersion.text, 'Sent to patient', fromObj);

        noteService.sendNote(noteId, fromObj._id, to, subject, lastVersion, prescriptionId)
            .then(
                res => {
                    const notes = res.data;

                    dispatch({
                        type: noteConstant.SEND_NOTE_SUCCESS,
                        notes
                    });
                },
                error => {
                    errorHandling('note.action.sendNote', error, dispatch);
                }
            )
            .finally(
                () => { dispatch(loadingClose()); }
            );
    };
}

function sendNoteCcToEmr(lastVersion,person,noteId, personId, fromObj) {
    return dispatch => {
        dispatch(loadingOpen());

        let text, textToEmr;

        text = markText(lastVersion.text, 'Sent to EMR', fromObj);
        textToEmr = markTextToEmr(lastVersion.text);
        noteService.sendNoteToEmr(textToEmr,person);
        dispatch(loadingClose());
    };
}

function sendNoteToEmr(lastVersion,person,noteId, personId, fromObj) {
    return dispatch => {
        dispatch(loadingOpen());

        let text, textToEmr;

        text = markText(lastVersion.text, 'Sent to EMR', fromObj);
        textToEmr = markTextToEmr(lastVersion.text);
        noteService.sendNoteToEmr(textToEmr,person)
        noteService.sentToEmrSave( noteId, personId, fromObj._id, text)  
        .then(
            res => {
                const notes = res.data;

                dispatch({
                    type: noteConstant.SEND_EMR_SUCCESS,
                    notes
                });

            },
            error => {
                errorHandling('note.action.sent to EMR and save', error, dispatch);
            }
        )
        .finally(
            () => { dispatch(loadingClose()); }
        );
    };
}

function openRevision(note) {
    return {
        type: noteConstant.OPEN_REVISION,
        note
    };
}

function closeRevision() {
    return {
        type: noteConstant.CLOSE_REVISION
    };
}

function markText(text, action, from) {
    text = text.replace('\n[Draft]', '');
    text = `${text}\n[${action} by ${from.FirstName} ${from.LastName} on ${formatDateTime(Date.now())}]`;

    return text;
}

function markTextToEmr(text) {
    text = text.replace('\n[Draft]', '');
    text = text.replace(/\n\[Signed.+/, `\n\n[Signed through AskMedication.com on ${formatDateTimeDisplayInEmr(Date.now())} by Balint Budai MD CM #63473]`);
    text = text.replace(/\n\[Sent.+/, "");
    return text;
}

function acknowledgeReply(note) {
    return dispatch => {
        const reply = note.message.replies[0];
        const replyText = markText(reply.message, 'Acknowledged', getUser().Person);

        noteService.acknowledgeReply(note.message._id, note.person._id, reply._id, replyText)
            .then(
                res => {                
                    const notes = res.data;

                    dispatch({
                        type: noteConstant.ACKNOWLEDGE_REPLY_SUCCESS,
                        notes
                    });
                    dispatch(messageAction.countUnread());
                },
                error => {
                    errorHandling('note.action.acknowledgeReply', error, dispatch);
                }
            );
    };
}

function goToNote(selectedNoteId) {
    return {
        type: noteConstant.GO_TO_NOTE,
        selectedNoteId
    };
}


function loadingOpen() { return loadingAction.open(); }
function loadingClose() { return loadingAction.close(); }