import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { yearsOld } from '../_helpers';
import { alertAction } from '../_actions';


class DateOfBirth extends Component {
    constructor(props) {
        super(props);

        let obj = {};

        if (this.props.value) {
            obj = this.transformToDayMonthYear(new Date(this.props.value));
        }

        // init value from props
        this.state = {
            ...obj,
            monthDays: 31
        };

        this.onDayChange = this.onDayChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.onYearChange = this.onYearChange.bind(this);
    }

    transformToDayMonthYear(value) {
        return {
            day: value.getDate(),
            month: value.getMonth() + 1,         // for Feb it will return 1
            year: value.getFullYear()
        };
    }

    onDayChange(event) {
        const { month, year } = this.state;
        const day = parseInt(event.target.value);

        this.setState({
            monthDays: this.getMonthDays(month, year),
            day
        }, () => {
            this.setDateObj();      // run this function after setState finished
        });
    }

    onMonthChange(event) {
        const month = parseInt(event.target.value);

        this.setState({
            monthDays: this.getMonthDays(month, this.state.year),
            month
        }, () => {
            this.setDateObj();      // run this function after setState finished
        });
    }

    onYearChange(event) {
        const year = parseInt(event.target.value);

        this.setState({
            monthDays: this.getMonthDays(this.state.month, year),
            year
        }, () => {
            this.setDateObj();      // run this function after setState finished
        });
    }

    getMonthDays(month, year) {
        const largeMonth = [1, 3, 5, 7, 8, 10, 12];
        const smallMonth = [2, 4, 6, 9, 11];
        let monthDays;

        if (month === 2) {
            // check leap year
            if (year % 4 === 0) {
                monthDays = 29;
            } else {
                monthDays = 28;
            }
        } else if (largeMonth.indexOf(month) > -1) {
            monthDays = 31;
        } else if (smallMonth.indexOf(month) > -1) {
            monthDays = 30;
        } else {
            monthDays = 31;
        }

        return monthDays;
    }

    setDateObj() {
        let { year, month, day, monthDays } = this.state;

        // check if day within monthDays
        if (day > monthDays) {
            day = NaN;
        }

        // check if year month day are valid
        if (year && month && day) {
            const dob = new Date(year, month - 1, day);

            if (yearsOld(dob) >= 19) {
                // set the date of birth date
                this.props.onChange(dob);
            } else {
                // less than 19 years old
                this.props.onChange(null);

                const { dispatch } = this.props;
                dispatch(alertAction.error('You must be 19 years or older to access AskMedication.com'));
            }
        } else {
            // set back to undefined
            this.props.onChange(undefined);
        }
    }

    render() {
        const startYear = 1900;
        const currentYear = new Date().getFullYear();
        const monthDays = [...Array(this.state.monthDays).keys()].map(i => i + 1);
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const years = [...Array(currentYear - startYear + 1).keys()].map(i => (i + startYear).toString());

        return (
            <div className="d-flex">
                <select className="custom-select d-block w-100"
                    value={this.state.day} onChange={this.onDayChange} required
                    data-test-id="date-of-birth__day"
                >
                    <option value="">Day</option>
                    {monthDays.map((i) => {
                        return <option value={i} key={i}>{i}</option>;
                    })}
                </select>
                <select className="custom-select d-block w-100"
                    value={this.state.month} onChange={this.onMonthChange} required
                    data-test-id="date-of-birth__month"
                >
                    <option value="">Month</option>
                    {months.map((obj, i) => {
                        return <option value={i + 1} key={i}>{obj}</option>;
                    })}
                </select>
                <select className="custom-select d-block w-100"
                    value={this.state.year} onChange={this.onYearChange} required
                    data-test-id="date-of-birth__year"    
                >
                    <option value="">Year</option>
                    {years.reverse().map((i) => {
                        return <option value={i} key={i}>{i}</option>;
                    })}
                </select>
            </div>
        );
    }
}


DateOfBirth.propTypes = {
    dispatch: PropTypes.func,
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func
};


const temp = connect()(DateOfBirth);

export { temp as DateOfBirth };