import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const pharmanetService = {
    encryptCredential,
    decryptCredential
};


function encryptCredential(credential) {
    const url = `${config.apiHost}/pharmanet/encrypt`;    
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, credential);
}

function decryptCredential(encryptedStr) {
    const url = `${config.apiHost}/pharmanet/decrypt`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, {
        encryptedStr
    });
}
