import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registrationAction } from '../../_actions';
import { AgreementDialog } from './AgreementDialog';
import { TermsOfUseDialog } from '.';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { profileConstant } from '../../_constants';

import config from '../../config'


class PrivacyPolicyDialog extends React.Component {
    state = { showFeesModal: false };
    constructor(props) {
        super(props);
    }

    handleFeesModalOpen = () => {
        this.setState({ showFeesModal: true });
    };

    handleFeesModalClose = () => {
        this.setState({ showFeesModal: false });
    };

    handleTermsOfUse = () => {
        const { dispatch } = this.props;
        dispatch(registrationAction.closePrivacyPolicy());
        dispatch(registrationAction.closeFeeSchedule())
        dispatch(registrationAction.openTermsOfUse());
    }
    

    handleFeeSchedule = () => {
        const { dispatch } = this.props;
        dispatch(registrationAction.closePrivacyPolicy())
        dispatch(registrationAction.closeTermsOfUse())
        dispatch(registrationAction.openFeeSchedule())
    }


    handleClose = (checked) => {
        const { dispatch } = this.props;
        dispatch(registrationAction.closePrivacyPolicy(checked));
    };


    // <a className="italic bold  button-link important-info-link font-1 m-0" name="terms" href='#' onClick={e=>{this.handleTermsOfUse()}}>Terms of Use.</a>

/* <a className="italic bold button-link important-info-link font-1 m-0" name="fee-schedule" href="#" onClick={e=>this.handleFeeSchedule()}>Fee Schedule.</a> */

    

    buildContent() {
        return (
            <>
                <div className="title">
                <div>{profileConstant.DOMAIN_NAME}</div>
                    <div>PRIVACY POLICY</div>
                </div>

                <>
                <p className="centered">Please read this document completely.</p>
                <p className="centered">Continuing onto {profileConstant.DOMAIN_NAME} means that you agree with this Privacy Policy.
</p><br />
                <p>Last updated: March 3, 2022 - Version 2.0</p>
                <p>Welcome to the South Vancouver Medical Clinic portal (the "Website"). This Website includes {profileConstant.DOMAIN_NAME} and is operated by the South Vancouver Medical Clinic (South Vancouver Medical Clinic, "we," "us," "our") and our directly-affiliated or subsidiary websites. Our Website allows, among other things, patients to book medical appointments with our physicians and receive other patient care services (the "Services"). Specific Services are described in greater detail below. Please read this document completely.</p>

                <p>This Privacy Policy is designed to be read in connection with our Terms of Use, which is available at <a className="italic bold  button-link important-info-link font-1 m-0" name="terms" href='#' onClick={e=>{this.handleTermsOfUse()}}>Terms of Use.</a> By accessing the Website and/or using the Services, you agree to be bound by both our Terms of Use and Privacy Policy. If you do not agree with this Privacy Policy or our Terms of Use, your only recourse is to cease using the Website and/or Services.</p>

                <p>The South Vancouver Medical Clinic is committed to protecting the privacy of the users of this Website (the "User", "you", "yours") and seeks to provide you with a safe and secure user experience. This Privacy Policy describes: our privacy practices; how we collect, use, disclose and treat your personally identifiable information ("PII") during your use of or access to this Website and the Services; and, the part that you play in safeguarding your PII while using this Website and/or accessing the Services.</p>

                <p>This Privacy Policy and our practices regarding the collection, use and disclosure of PII have been designed to comply with the Personal Information Privacy Act, SBC 2003, c. 63 as amended from time to time (“PIPA”) and to meet or exceed the relevant guidelines and standards published by the Doctors of B.C., the College of Physicians and Surgeons of B.C., and the Office of the Information and Privacy Commissioner of British Columbia.</p>

                <p>Unless otherwise defined in this Privacy Policy, all capitalized defined terms have the meanings ascribed to them in the Terms of Use.</p>
                <hr/>
                <h3>1. HOW WE COLLECT, USE AND DISCLOSE YOUR PERSONAL INFORMATION</h3>
                <p>The Website provides Users access to a secure online portal and platform (the “Portal”) allowing them to receive Services from one of our physicians licensed to practice medicine in the Province of British Columbia and our support staff. The Services are only accessible to Users who have provided certain PII and other identification information we require to create a patient user account with us (the “User Account”), and who have agreed to this Privacy Policy and Terms of Use.</p>
                <p>When you create a User Account and/or use our Services, we collect certain information about your device, your interaction with the Website and/or Portal, and information necessary to provide our Service.</p>
                <p>When you create a User Account, we collect certain information from you to verify your identity as you use the Portal. Your User Account must not be accessed by anyone other than the person who created the User Account. Notwithstanding the foregoing, a parent or guardian of a User who is under the age of majority may access a User Account on behalf of that User if necessary.</p>
                <p>In order to optimize our Website, Portal, and/or Services and provide you with a better user experience, we may collect certain User Data about you:</p>
                <ul>
                    <li><span className="bold">Personal Data: </span>voluntarily provided information which may include your name, address, email address, billing and/or credit card information, other PII, etc.; and</li>
                    <li><span className="bold">Aggregate Information: </span>non-personal anonymous demographic information, or other information automatically collected when visiting our Services, which may include cookies, third party tracking technologies and server logs, IP addresses, and device information such as the operating system or browser type of the device you use to access the Services.</li>
                </ul>

                <p>The South Vancouver Medical Clinic uses this Website and/or the Portal to collect, and may use and disclose, your Personal Data, including personal health information, in order to deliver our Services. Our Services include, but are not limited to: allowing Users with Users Accounts to book medical appointments; communicating with Users to attend to their patient care needs; communicating with you about our patient care related inquiries; communicating recommendations; and, sharing test results with Users. </p>
                <p>We will only collect Personal Data that you knowingly and willingly provide to us, for example, during onboarding when creating a User Account, or when you make a purchase through our Services.</p>
                <p>We utilize the third-party payment processor, Paypal, to collect payments for some of our Services in accordance with our <a className="italic bold button-link important-info-link font-1 m-0" name="fee-schedule" href="#" onClick={e=>this.handleFeeSchedule()}>Fee Schedule.</a></p>
                <p>We communicate with you through the mediums available from time to time on or in this Website and/or the Portal, including instant messaging, videoconferencing, text messaging and email. South Vancouver Medical Clinic may automatically collect certain Aggregate Information. This Aggregate Information is used to analyze the use of resources, prevent fraud, troubleshoot, and also to improve our Website, the Portal, and/or the Services.</p>
                <p>We may disclose your Personal Data you. We may also disclose your Personal Data to other health professionals if, and only to the extent that, such disclosure is required to provide the Services. For example, we may disclose your Personal Data when answering inquiries by health care professionals pertaining to any requests for laboratory services, imaging, testing, specialist referrals and treatment referrals. In any event, the communication of Personal Data or other medical information to you or to health professionals may be done via email. While we will do all we reasonably can to secure and protect any and all information transmitted via email, you understand that email transmission of information carries certain risks, and you agree to solely assume any such risks.</p>
                <p>We will not otherwise disclose your Personal Data unless we have your express consent or are required or permitted by law to do so. PIPA permits our disclosure of your Personal Data without express consent where: such disclosure is clearly in your personal interest and your consent cannot be obtained in a timely way, or where the disclosure is necessary for your medical treatment and you do not have the legal capacity to give consent. We may also be permitted or required to disclose your Personal Data without your expressed consent in other exceptional circumstances, such as when required by law or legal process (such as a Court order or subpoena), or to address actual or threatened illegal or harmful conduct.
In general, we will limit the amount and type of Personal Data that we collect from you to that which is necessary for providing our Services and attending to your patient care needs.</p>
                <p>We will not sell your Personal Data to third parties.</p>
                <p className="bold">Links to Other Websites</p>
                <p>Our Website, Portal, and/or Services may contain links to other websites that are controlled by third parties. We are not responsible for any of the content, user terms, or privacy policies for websites that we do not own or control. You are strongly advised to read the terms and policies of all websites you visit or mobile apps you use.</p>
                <p className="bold">Cookies</p>

                <p>Cookies are small pieces of data stored in text files that are saved on your computer or devices when websites are loaded in a browser. They are widely used to remember you and your preferences, either for a single visit (through a temporary “session cookie”) or for multiple repeat visits (using a “persistent cookie”).</p>
                <p>Any use of cookies – or of other tracking tools – by the Website, Portal, and/or Services or by the owners of third-party services used by the Website is solely for the purpose of better providing you with the Services, in addition to any other purposes described in this Privacy Policy or any other policy we may publish regarding our Website, the Portal, and/or the Services from time to time.</p>
                <p>You can change your browser's settings to delete cookies that have already been set and to not accept new cookies; however, please note that if you delete cookies or do not accept them, you may not be able to use all of the features the Website, Portal and/or Services offer. To learn more about how to do this or to learn more about cookies, please contact us, or visit <a href="https://www.internetcookies.com">www.internetcookies.com</a>.</p>
                <hr/>
                <h3>2. HOW WE WORK WITH YOU TO SAFEGUARD YOUR PERSONAL INFORMATION</h3>
                <p>Security of your Personal Data is of the utmost importance, so when you submit sensitive information through the Website or Portal, your information is protected both online and offline. The South Vancouver Medical Clinic has imposed stringent safeguards to protect your Personal Data, including your personal health information, accessible via the Website and/or through the Portal. Security measures have been integrated into the design, implementation, and operation of the Website and the Portal. We use a combination of firewall barriers, encryption techniques (https) and authentication procedures, among others, to maintain the security of your online communications and to protect User Accounts and systems from unauthorized access. Communications are encrypted using the Secure Socket Layer (SSL) system. Our physicians and staff will only log into the Website through a secure 2-step authenticated network. Any payment transactions will be encrypted using SSL technology. We utilize up-to-date anti-malware software as well as audit trails to record user access. Our cloud services are secure and store your Personal Data within Canada. Our employees are required to maintain the confidentiality of your Personal Data and may not use such Personal Data for any unauthorized purpose.</p>

                <p>While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to Personal Data. The computers and servers in which we store personally identifiable information are kept in a secure environment. This is all done to prevent any loss, misuse, unauthorized access, disclosure or modification of the user’s personal information under our control.</p>
                <p>The contact information for our Privacy Officer is below and you may contact our Privacy Officer with any questions or concerns about how the South Vancouver Medical Clinic is handling your Personal Data.
You must also do your part to protect and safeguard your Personal Data when using this Website and/or the Portal. As required by our Terms of Use, we suggest[Some of this language spoke to "requiring" Users to do these things to protect their data - that probably isn't necessary. If they do (or don't do) something that results in their privacy rights being infringed by a third party, that isn't SVMC/RISMC's concern.] that you:</p>
                <ul>
                    <li>Use only secure, adequately encrypted internet access on each and every occasion that you log in to, create and/or modify your Account. You should not use unsecured public networks, such as public wi-fi from a hotel or coffee shop, when creating or accessing your User Account;</li>
                    <li>Do not save your password for your User Account to your phone, computer or other electronic device;</li>
                    <li>Do not share your password with others;</li>
                    <li>Notify us immediately of any unauthorized access to or use of your User Account, including your username or password, or if you believe there is a risk of unauthorized use of your User Account;</li>
                    <li>When communicating with us via text message, instant message, or email, keep any Personal Data you share to a minimum (only as much as required);</li>
                    <li>Ensure that you logout from your User Account at the end of each session. </li>
                </ul>
                <p>In any event, South Vancouver Medical Clinic is not responsible for any breaches of your privacy rights by third parties resulting from your failure to adhere to the above guidelines.</p>
                <hr/>
                <h3>3. YOUR CONSENT</h3>
                <p>By accessing and by continuing to access and use the Website, Portal and/or Services, you consent and agree to our collection, use and disclosure of your Personal Data in accordance with this Privacy Policy. Please carefully read this Privacy Policy in its entirety, and in particular, <span className="bold">1. HOW WE COLLECT, USE AND DISCLOSE YOUR PERSONAL INFORMATION, </span>and <span className="bold">2. HOW WE WORK WITH YOU TO SAFEGUARD YOUR PERSONAL INFORMATION</span> <span className="text-underline">before</span> using the Website, Portal and/or Services so as to ensure that you are providing informed and meaningful consent. If you do not agree to the Privacy Policy, your only recourse is to cease using and/or accessing the Website, Portal and/or Services.</p>

                <p>You may change or withdraw your consent at any time. All communications with respect to such withdrawal or variation of consent should be in writing and addressed to our Privacy Officer. If you withdraw or do not provide us with your consent to our collection, use, and disclosure of your Personal Data, you may limit or prevent us from being able to provide you with our Services.</p>
                <hr/>
                <h3>4. HOW TO ACCESS AND MODIFY YOUR PERSONAL INFORMATION</h3>
                <p>South Vancouver Medical Clinic believes in the right of individuals to access their health information and manage their Personal Data. You have the right, upon submitting a request to us, to be informed of the existence, use and disclosure of your Personal Data under our control, subject to certain exceptions set out in applicable law. Please direct your requests for access to your Personal Data to our Privacy Officer. We may request that you provide us with sufficient detail and information to verify your identity and enable us to adequately respond to your request. We will attempt to respond to requests promptly and, in any event, within such timelines as may be stipulated by applicable law. We may charge you a fee to receive copies of your Personal Data kept on record, however, we will advise you of any such fees in advance. In the event we cannot provide you with access to your Personal Data, we will inform you of the reasons why, subject to any legal or regulatory restrictions.</p>

                <p>It is important that the Personal Data we collect is accurate and complete. Having accurate information about you is critical to our provision of the Services to you. You may update, correct, and otherwise revise Personal Data provided to us on your own by accessing your User Account, or by contacting our Privacy Officer</p>
                <p>Despite our best efforts, errors may occur. If a User demonstrates to us that their Personal Data in our records is inaccurate or incomplete, we will revise our records accordingly and, where relevant, make reasonable efforts to advise relevant third parties to whom that information has been disclosed. Please direct all requests for revision of Personal Data to our Privacy Officer.</p>
                <hr/>

                <h3>5. OUR RECORD RETENTION AND DESTRUCTION PRACTICES</h3>
                <p>We retain your personal health information for a minimum period of sixteen (16) years from either of (1) the date of the last entry of such information, or (2) the date on which a patient about which such information has been entered reaches age of majority (whichever is later), in accordance with our obligations under PIPA and the regulatory requirements and by-laws established by the Doctors of B.C. and the College of Physicians and Surgeons of B.C pursuant to the Health Professions Act, RSBC 1996, c. 183 (the “Regulatory Requirements”. The South Vancouver Medical Clinic has appropriate procedures in place with respect to the secure destruction, deletion, and disposition of your Personal Data when we are no longer legally obliged to retain your Personal Data.</p>
                <p>Any Personal Data not subject to the Regulatory Requirements shall only be kept for as long as is reasonably necessary to fulfill the purposes for which such Personal Data was collected, or as required by applicable laws or regulations</p>
                <p>We may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, there may be circumstances in which we may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.</p>
                <p>Once the retention period expires, Personal Data shall be destroyed. Therefore, your any of your rights to access, rights to erasure, rights to rectification and rights to data portability cannot be enforced after expiration of the retention period and we no longer possess such data.</p>
                <hr/>

                <h3>6. PRIVACY BREACH RESPONSE</h3>

                <p>We shall use our best efforts to ensure that unauthorized parties will not access or obtain collected Personal Data in contravention of this Privacy Policy. However, due to the potential for interception, loss, or alteration of information transmitted over the internet, we cannot and do not guarantee complete confidentiality and security of your Personal Data. You acknowledge and agree that any information you communicate to us over the internet is at your own risk.</p>
                <p>The security of your Personal Data is important to us. Please advise our Privacy Officer immediately of any incident involving the loss of, unauthorized access to, or disclosure of Personal Data that is in our custody or control.</p>
                <p>Employees of the South Vancouver Medical Clinic will immediately report any breach of privacy, or suspected breach of privacy, to the South Vancouver Medical Clinic's Privacy Officer on discovery of same. A breach of privacy includes: unauthorized external or internal access to our physical records or electronic records; misdirected communications, including mail, fax, and electronic communications; and, the loss or theft of physical records or electronic records stored on data storage devices.</p>

                <p>The South Vancouver Medical Clinic Privacy Officer will coordinate a review of the matter with our management team and investigate all reported privacy breaches or suspected privacy breaches. If we become aware of a privacy breach concerning your Personal Data, we will notify you in accordance with our obligations under PIPA, and may report the breach to the Office of the Information and Privacy Commissioner of British Columbia (“OIPC”) or to the appropriate authorities, including the police, as the case may be.</p>
                <hr/>
                <h3>7. CONCERNS OR COMPLAINTS RELATED TO YOUR PERSONAL INFORMATION</h3>
                <p>The South Vancouver Medical Clinic has designated a Privacy Officer who is responsible for coordinating the our compliance with this Privacy Policy and with the South Vancouver Medical Clinic’s obligations generally under PIPA and the Regulatory Requirements. The contact information for our Privacy Officer is as follows:</p>

                <p>Attention: Ying Pang, Privacy Officer</p>
                <p>Email: info@{profileConstant.DOMAIN_NAME}</p>
                <p>Address: 350 SE Marine Drive, Vancouver, B.C. V5X 2S5</p>
                <p>We are committed to having an accessible and responsible complaint handling process in place to ensure you can express concerns about compliance with PIPA and the Regulatory Requirements. Any complaints, disputes, or other feedback concerning our privacy practices, procedures, and this Privacy Policy can be submitted directly to our Privacy Officer. Where appropriate, we will provide a written response to you and may amend our privacy practices and procedures or this Privacy Policy in response to your or other’s concerns.</p>
                <p>If you have raised a concern with our Privacy Officer and are not satisfied in how performed its duties under PIPA or the Regulatory Requirements, or wish to seek a review of the South Vancouver Medical Clinic’s response to your privacy concern, or wish to make an access or correction request, you can contact the OIPC.</p>
                <p>OIPC Website: <a href="https://www.oipc.bc.ca">www.oipc.bc.ca</a></p>
                <p>OIPC Phone number: (250) 387-5629</p>
                <p>OIPC Email: <span>info@oipc.bc.ca</span></p>
                <hr/>

                <h3>8. UPDATES TO OUR PRIVACY POLICY</h3>
                <p>We reserve the right to make changes to this Privacy Policy from time to time. In order to ensure you are up to date with the latest changes, we advise you to visit this page frequently. We reserve the right to change or modify any of the terms and conditions contained in this Privacy Policy, at any time and in our sole discretion. Any and all such modifications are effective immediately upon posting and apply to all access to and continued use of the Website, the Portal and the Services. Your continued use of the Website constitutes your acceptance of such modifications.</p>
                <p>You may obtain this Privacy Policy and any related information from our Website or upon request by contacting us at info@{profileConstant.DOMAIN_NAME}. </p>
                <p>This Privacy Policy does not create or confer upon any individual any rights, or impose upon South Vancouver Medical Clinic any rights or obligations outside of, or in addition to, any rights or obligations imposed by PIPA, any other applicable federal or provincial privacy laws, or under the Regulatory Requirements. Should there be, in a specific case, any inconsistency between this Privacy Policy and any such laws, this Privacy Policy shall be interpreted in respect of that case to give effect to, and comply with, such privacy laws.</p>

                </>   
                   
                <TermsOfUseDialog />
                <Dialog
                    open={this.state.showFeesModal}
                    onClose={this.handleFeesModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Fees"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        Our services are free if you have a valid B.C. Services Card (CareCard). <br/>
                        A $100.00 private charge for up to 3 prescriptions applies for patients who do not have a valid B.C. Services Card (CareCard).
                        </DialogContentText>
                        {/* <hr />
                        <DialogContentText>
                            <span className="m-3">1st prescription: <strong>$20.00</strong></span>
                        </DialogContentText>
                        <DialogContentText>
                            <span className="m-3">Subsequent prescription(s): <strong>$10.00</strong></span>
                        </DialogContentText> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleFeesModalClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    render() {
        const { openPrivacyPolicy } = this.props;

        return (
            <AgreementDialog
                open={openPrivacyPolicy}
                title="Privacy Policy"
                content={this.buildContent()}
                onClose={(checked) => this.handleClose(checked)}
            />
        );
    }
}

PrivacyPolicyDialog.propTypes = {
    dispatch: PropTypes.func,
    openPrivacyPolicy: PropTypes.bool
};


function mapStateToProps(state) {
    let { openPrivacyPolicy } = state.registration;

    if (openPrivacyPolicy === undefined) {
        openPrivacyPolicy = false;
    }

    return {
        openPrivacyPolicy
    };
}

const temp = connect(mapStateToProps)(PrivacyPolicyDialog);

export { temp as PrivacyPolicyDialog };