import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { uploadBucket, setStringCapital } from '../_helpers';
import config from '../config';


class PersonIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showImg: true
        };
    }

    handleError = () => {
        this.setState({
            showImg: false
        });
    }

    render() {
        const { person = {}, theme } = this.props;
        let avatar = '';

        // set Avatar
        if (this.state.showImg && person && person.images && person.images.length > 0) {
            const image = person.images[person.images.length - 1];
            const imageKey = `${uploadBucket.profileImage}/${image._id}_${image.name}`;
            const url = `${config.uploadBucket}/${imageKey}`;

            avatar = <Avatar src={url} />;
        } else {
            let initial = '';
            if (person && person.FirstName && person.LastName) {
                initial = `${person.FirstName[0].toUpperCase()}${person.LastName[0].toUpperCase()}`;
            }
            avatar = <Avatar>{initial}</Avatar>;
        }

        let classes = 'person-icon';

        if (theme === 'light') {
            classes += ' light-theme';
        }

        return (
            <div className={classes}>            
                <Chip
                    avatar={avatar}
                    // label={`${(person || {}).FirstName || ''} ${(person || {}).LastName || ''}`}
                    label={`${setStringCapital((person || {}).FirstName)} ${setStringCapital((person || {}).LastName)}`}
                    variant="outlined"
                    onError={this.handleError}
                />
            </div>
        );
    }
}


PersonIcon.propTypes = {
    theme: PropTypes.string,
    person: PropTypes.object
};


const temp = (PersonIcon);

export { temp as PersonIcon };