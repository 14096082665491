import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { problemAction, alertAction } from '../../_actions';
import { getPersonId } from '../../_helpers';


class ReportProblemDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isErrorMessageOpen:false,
        };

    }

    handleChange = (event) => {
        const { dispatch } = this.props;
        const message = event.target.value;

        dispatch(problemAction.change(message));
    }
    validateForm = () => {
        const { message } = this.props;
        const newMessage = message.trim();
        if (newMessage.length == 0) {

              this.setState({isErrorMessageOpen: true});
              return false;
            }
        return true;
    }

    displayErrorMessage = () => {
        const { isErrorMessageOpen } = this.state;
        return ( 
            <Dialog
            maxWidth="md"
            open={isErrorMessageOpen}
        >
            <DialogContent>
                        <div>
                         <p>Please enter text in the text box below to explain the suggestion you have for us. Then click the Submit button. Thanks.</p>
                        </div>
                        <div className="row float-right">
                        <button
                            className="btn btn-outline-primary btn-sm mt-2 mr-2"
                            onClick={() =>this.setState({isErrorMessageOpen:false})}
                        >
                            Close
                        </button>
                        </div>

                    </DialogContent>
        </Dialog>
        )
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const { dispatch, dataURL, message, loggedIn } = this.props;
        let personId;

        if (loggedIn) {
            personId = getPersonId();
        }
        if (this.validateForm()){
            dispatch(problemAction.submit(dataURL, message, personId));
        }
        
    }

    handleClose = () => {
        const { dispatch } = this.props;

        dispatch(problemAction.closeDialog());
    }

    displayContent = () => {
        const { dataURL, message } = this.props;

        return (
            <form noValidate onSubmit={this.handleSubmit}>
                <div className="text-center mb-2">
                    <img className="screenshot" src={dataURL} />
                </div>
                <div className="form-group">
                    <textarea className="form-control" id="message" name="message" rows="4"
                        value={message} placeholder="Please explain the improvement you suggest. A screenshot of this page will be included for our records. Unfortunately, we cannot respond to suggestions." onChange={this.handleChange}></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        );
    }

    renderReportProblem = () => {

        const { isOpen } = this.props;

        return (
            isOpen ? <Dialog
                maxWidth="md"
                open={isOpen}
            >
                <DialogTitle className="no-print">
                    <span>Suggest an improvement</span>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                        <i className="fa fa-close mx-2"></i>
                    </button>
                </DialogTitle>
                <DialogContent>
                    {this.displayContent()}
                </DialogContent>
            </Dialog>
                :
                ''
        );
    }

    render() {

        return (
            <>
            {this.displayErrorMessage()}
            {this.renderReportProblem()}
            </> 
        );
    }
}


ReportProblemDialog.propTypes = {
    dispatch: PropTypes.func.isRequired,
    dataURL: PropTypes.string,
    message: PropTypes.string,
    loggedIn: PropTypes.bool,
    isOpen: PropTypes.bool
};


function mapStateToProps(state) {
    const { dataURL, message } = state.problem;
    let { isOpen } = state.problem;
    const { loggedIn } = state.authentication;

    if (isOpen === undefined) {
        isOpen = false;
    }

    return {
        dataURL,
        message,
        isOpen,
        loggedIn
    };
}

const temp = connect(mapStateToProps)(ReportProblemDialog);

export { temp as ReportProblemDialog };
