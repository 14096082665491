import { popupConstant } from '../_constants';

export function popup(state = {}, action) {
    switch (action.type) {
        case popupConstant.OPEN_DIALOG:
            return {
                isOpen: true,
                title: action.title,
                message: action.message,
                action: action.action
            };
        case popupConstant.CONFIRM_SUCCESS:
        case popupConstant.CANCEL:
            return {
                isOpen: false
            };
        default:
            return state;
    }
}