import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const medicationFilterService = {
    get,
    create,
    importData,
    remove
};

function get() {
    const url = `${config.apiHost}/medication-filter`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function create(tc_ahfs_number, status, activeList) {
    const url = `${config.apiHost}/medication-filter`;
    const body = {
        tc_ahfs_number,
        status,
        activeList,
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, body);
}

function importData(formData) {
    const url = `${config.apiHost}/medication-filter/import-data`;
    const axiosConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data'
        }
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, formData, axiosConfig);
}

function remove(id) {
    const url = `${config.apiHost}/medication-filter/${id}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.delete(url);
}