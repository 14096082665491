import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from "../../config";
import { getToken } from '../../_helpers';

const RemittancePage = () => {

    const [remittances, setRemittances] = useState([]);
    const [limit, setLimit] = useState(1);
    const [skip, setSkip] = useState(0);
    const [loader, setLoader] = useState(true);

    const nextPage = () => {
        setSkip(skip + limit)
    }

    const previousPage = () => {
        setSkip(skip - limit)
    }

    useEffect(() => {
        const fetchRemittances = async () => {


            try{
                axios.defaults.headers.common['x-access-token'] = getToken();
                let res = await axios.get(`${config.apiHost}/tele-plan/remittance?limit=${limit}&skip=${skip}`);
                setRemittances(res.data.succeessRemittance);
                setLoader(false);
            } catch (err) {
                throw err;
            }
        };

        fetchRemittances();
        console.log(remittances);
    }, [skip, limit])

       const compare = (a1, b1) => {
           const a = parseInt(a1.seqNumber);
           const b = parseInt(b1.seqNumber);
           
           let comparison = 0;
           if (a > b){
               comparison = 1;
           } else if (a < b) {
               comparison = -1
           }

           return comparison;

       }

    return (
        
        
        <div className="remittance">
            <div className="remittance">
            <h3> Remittance Lists</h3>
            <table className="table highlight center">
           
                            <thead>
                                <tr>
                                    <th scope="col">Sequence#</th>
                                    <th scope="col"> payment Date</th>
                                    <th scope="col"> Amount Bill</th>
                                    <th scope="col">Amount Paid</th>
                                    <th scope="col">Service Date</th>
                                    <th scope="col">Fee Code</th>
                                    <th scope="col">Health Number</th>
                                    <th scope="col">Remittance Type</th>
                                </tr>
                            </thead>
                     <tbody>
                        { loader  ? <h3> Loading the remittance...</h3>  :
                            
                       remittances.sort(compare).map((item, i) => {
                           let colorStyle = item.amountPaid == "$00.00" ? {color:"red"}:{}
                           return(
                                                   <tr>
                                                   
                                                   <td>{item.seqNumber}</td>
                                                   <td>{item.paymentDate}</td>
                                                   <td>{item.amountBill}</td>
                                                   <td style={colorStyle}>{item.amountPaid}</td>
                                                   <td>{item.serviceDate}</td>
                                                   <td>{item.feeCode}</td>
                                                   <td>{item.healthNumber}</td>
                                                   <td>{item.remittanceType}</td>
                                                   
                                                   </tr>
                            )
                          })
                          
                         }
                    </tbody>      
            </table>
            </div>
        
        <div> 
            <button className = "btn btn-primary" style= {{marginRight:"15px"}} onClick={previousPage}> Previous Page </button>
            <button className = "btn btn-primary" onClick={nextPage}> Next Page </button> 
        </div>
        
            
            
    </div>)
}

export { RemittancePage };