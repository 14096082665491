import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const registrationService = {
    register,
    isActivated,
    activate,
    confirmOfficeToken
};

function register(formData) {
    const url = `${config.apiHost}/user`;
    const axiosConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data'
        }
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, formData, axiosConfig);
}

function isActivated(email) {
    const url = `${config.apiHost}/user/isActivated/${email}`;

    return axios.get(url);
}

function activate(email, verificationCode) {
    const url = `${config.apiHost}/user/activate`;
    const body = {
        email,
        verificationCode
    };

    return axios.post(url, body);
}

function confirmOfficeToken(token) {
    const url = `${config.apiHost}/office/confirmToken?token=${token}`;
    return axios.get(url);
}