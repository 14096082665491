import React from 'react';
import PropTypes from 'prop-types';
import { calculatePrescriptionCharge } from '../../_helpers';


const PrescriptionCost = (props) => {
    return (
    <div className={"prescription-cost " + props.style}>
        <span className="badge badge-pill badge-warning">
            {`Fee: $${calculatePrescriptionCharge(props.medications, props.isEligible)}`}
        </span>
    </div>
)};


PrescriptionCost.propTypes = {
    medications: PropTypes.array
};


const temp = PrescriptionCost;

export { temp as PrescriptionCost };