import React from 'react';

const ErrorMsgField = (props) => {
    if (props.isShow) {
        return (
            <div style={{ color: 'red' }}>
                {props.children}
            </div>
        );
    }

    return '';
};


const temp = ErrorMsgField;

export { temp as ErrorMsgField };