import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { profileAction, alertAction, userAction } from '../../_actions';
import { RequiredLabel, ErrorMsgField } from '../../components';
import { profileService } from '../../_services';
import { ProfileValidator } from '../../_validator';
import { profileConstant } from '../../_constants';
import { setToken } from '../../_helpers/session'; 

class UserEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            isPasswordValid: false,
            phoneCode: '',
            emailCode: '',
            isPhoneCodeValid: false,
            isEmailCodeValid: false,
            newEmail: '',
            confirmNewEmail: '',
            isEmailCodeVisible: false
        };

        this.changeValue = this.changeValue.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSubmitPhoneCode = this.handleSubmitPhoneCode.bind(this);
        this.handleSubmitEmailCode = this.handleSubmitEmailCode.bind(this);
        this.handleSubmitEmail = this.handleSubmitEmail.bind(this);
        this.onNewEmailChange = this.onNewEmailChange.bind(this);
        this.onConfirmNewEmailChange = this.onConfirmNewEmailChange.bind(this);
    }

    section = profileConstant.SECTION_CREDENTIAL;

    /**
     * Get the value from the inputs form
     */
    getPasswordValid() {
        return this.state.isPasswordValid;
    }

    setPasswordValid(isValid) {
        this.setState({
            isPasswordValid: isValid
        });
    }

    changeValue = (event) => {
        const { dispatch, user } = this.props;

        user[event.target.name] = event.target.value;
        dispatch(profileAction.change(user));
    }

    onPhoneCodeChange = (e) => {
        this.setPhoneCode(e.target.value);
    }

    setPhoneCode = (phoneCode = '') => {
        this.setState({
            phoneCode
        });
    }

    getPhoneCode = () => {
        return this.state.phoneCode;
    }

    isPhoneCodeValid = () => {
        return this.state.isPhoneCodeValid;
    }

    setPhoneCodeIsValid = (isPhoneCodeValid = false) => {
        this.setState({
            isPhoneCodeValid
        });
    }

    isEmailCodeValid = () => {
        return this.state.isEmailCodeValid;
    } 

    setEmailCodeIsValid = (isEmailCodeValid = false) => {
        this.setState({ isEmailCodeValid });
    }

    onEmailCodeChange = (e) => {
        this.setEmailCode(e.target.value);
    }

    setEmailCode = (emailCode = '') => {
        this.setState({
            emailCode
        });
    }

    getEmailCode = () => {
        return this.state.emailCode;
    }

    getNewEmail() {
        return this.props.user.Email; // this.state.newEmail;
    }

    setNewEmail(newEmail = '') {
        this.setState({ newEmail });
    }

    getConfirmNewEmail() {
        return this.props.user.confirmEmail; // this.state.confirmNewEmail;
    }

    setConfirmNewEmail(confirmNewEmail = '') {
        this.setState({ confirmNewEmail });
    }

    onNewEmailChange(e) {
        e.preventDefault();
        this.setNewEmail(e.target.value);
    }

    onConfirmNewEmailChange(e) {
        e.preventDefault();
        this.setConfirmNewEmail(e.target.value);
    }

    /**
     * validate the confirm field
     */
    validateConfirmField(field) {
        const { dispatch, user } = this.props;

        if (user[field] && user[`confirm${field}`] &&
            user[field] !== user[`confirm${field}`]) {
            user[`confirm${field}Error`] = true;
        } else {
            user[`confirm${field}Error`] = false;
        }

        dispatch(profileAction.change(user));
    }

    renderConfirmFieldError(field) {
        if (this.props.user[`confirm${field}Error`]) {
            return (
                <div className="confirm-error-div">
                    <label>{`Confirm ${field} doesn't match ${field}`}</label>
                </div>
            );
        }

        return '';
    }

    /**
     * Submit the form sending a post request to the API
     */
    handleSave = async (event) => {
        try {
            event.preventDefault();
            const { user } = this.props;

            /*
            const validator = new ProfileValidator();
            validator.updateEmail(user);

            // check if it is the same as original email

            if (!validator.validate()) 
                throw ({ message: 'Form validation errors. ' + validator.errorMsgs });*/

            await profileService.changeEmailGetPhoneCode(user.Password, user.Role);
            this.setPasswordValid(true);
            // this.props.saveUser(user); // finally save the user

        } catch (error) {
            this.props.dispatch(alertAction.error(error.message));
        }
    }

    async handleSubmitPhoneCode(e) {
        try {
            e.preventDefault();
            const phoneCode = this.getPhoneCode();
            const res = await profileService.changeEmailGetEmailCode(phoneCode);
            if (res.data.isVerified)
                this.setPhoneCodeIsValid(true);
        } catch (error) {
            this.props.dispatch(alertAction.error(error.message));
            this.setPhoneCodeIsValid(false);
        }
    }

    // getNewEmail() {
    //     return this.props.user.Email;
    // }

    isEmailCodeVisible() {
        return this.state.isEmailCodeVisible;
    }

    setIsEmailCodeVisible(isVisible = false) {
        this.setState({ isEmailCodeVisible: isVisible })
    }

    async handleSubmitEmail(e) {
        try {
            e.preventDefault();
            const newEmail = this.getNewEmail();
            const confirmEmail = this.getConfirmNewEmail();
            if (confirmEmail != newEmail)
                throw ({ message: `Confirm Email doesn't match Email` });
            const res = await profileService.changeEmailsetNewEmail(newEmail);
            if (res.data.isVerified)
                this.setIsEmailCodeVisible(true);
        } catch (error) {
            this.setIsEmailCodeVisible(false);
            this.props.dispatch(alertAction.error(error.message));
        }
    }

    async handleSubmitEmailCode(e) {
        try {
            e.preventDefault();
            const emailCode = this.getEmailCode();
            const res = await profileService.changeEmailValidateEmailCode(emailCode);
            if (res.data.isVerified) {
                this.setEmailCodeIsValid(true);
                setToken(res.data.token);
                const user = {...this.props.user, Email: this.getNewEmail()}
                this.props.saveUser(user);
                this.reset();
            }
        } catch (error) {
            this.setEmailCodeIsValid(false);
            this.props.dispatch(alertAction.error(error.message));
        }
    }

    handleEdit() {
        const { dispatch, user } = this.props;
        dispatch(profileAction.editSection(this.section));

        user.Email = '';
        user.confirmEmail = '';
        dispatch(profileAction.change(user));
    }

    handleCancel() {
        const { dispatch } = this.props;
        dispatch(profileAction.editSection(undefined));
        this.reset();
    }

    renderEdit() {
        const { user, edit } = this.props;
        const emailProps = {
            type: "email",
            className: "form-control",
            id: "Email",
            name: "Email",
            value: user.Email || '',
            onChange: this.changeValue,
            onBlur: () => this.validateConfirmField('Email'),
            required: true
        };

        const emailConfirmProps = {
            type: "email",
            className: "form-control",
            id: "confirmEmail",
            name: "confirmEmail",
            value: user.confirmEmail || '',
            onChange: this.changeValue,
            onBlur: () => this.validateConfirmField('Email'),
            required: true
        };

        const passwordProps = {
            type: "password",
            className: "form-control",
            id: "Password",
            name: "Password",
            value: user.Password || '',
            onChange: this.changeValue,
            onBlur: () => this.validateConfirmField('Password'),
            required: true
        };

        const phoneCodeProps = {
            type: "text",
            className: "form-control",
            value: this.getPhoneCode(),
            onChange: this.onPhoneCodeChange,
        };

        const emailCodeProps = {
            type: "text",
            className: "form-control",
            value: this.getEmailCode(),
            onChange: this.onEmailCodeChange,
        };

        let isPasswordValid = false;
        if (this.getPasswordValid()) {
            isPasswordValid = true;
            passwordProps.disabled = true;
        }

        let isPhoneCodeValid = false;
        if (this.isPhoneCodeValid()) {
            phoneCodeProps.disabled = true;
            isPhoneCodeValid = true;
        }

        let isEmailCodeVisible = false;
        if (this.isEmailCodeVisible()) {
            emailProps.disabled = true;
            emailConfirmProps.disabled = true;
            isEmailCodeVisible = true;
        }

        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="my-3">
                        User Email
                    </h4>
                    {!edit &&
                        <div>
                            <button className="btn btn-outline-secondary btn-sm mr-2" type="button" onClick={this.handleCancel}>Cancel</button>
                            {/*<button className="btn btn-outline-success btn-sm" type="submit" onClick={this.handleSave}>Save</button>*/}
                        </div>
                    }
                </div>
                <div className="row">
                    <div className="col-md-3 mb-3">
                        <RequiredLabel htmlFor="Password">Step 1: Please enter your password</RequiredLabel>
                        <input {...passwordProps} />
                        {!isPasswordValid && <button
                                className="btn btn-outline-secondary btn-sm mr-2"
                                type="button"
                                onClick={this.handleSave}
                        >
                            Submit
                        </button>}
                        <div className="invalid-feedback">
                            Password is required.
                        </div>
                    </div>
                

                    {isPasswordValid 
                        && <div className="col-md-3 mb-3">
                            <RequiredLabel htmlFor="phone-code">Step 2: Please enter code sent to your phone</RequiredLabel>
                            <input {...phoneCodeProps} />
                            {!isPhoneCodeValid && <button
                                className="btn btn-outline-secondary btn-sm mr-2"
                                type="button"
                                onClick={this.handleSubmitPhoneCode}
                            >
                                Submit
                            </button>}
                        </div>
                    }

                    {isPhoneCodeValid
                        && <div className="col-md-3 mb-3">
                            <RequiredLabel htmlFor="Email">
                                Step 3: Please input your new Email address
                            </RequiredLabel>
                            {<input {...emailProps} />}
                            <div className="invalid-feedback">
                                Email is required.
                            </div>
                            <RequiredLabel htmlFor="confirmEmail">
                                    Confirm Email
                            </RequiredLabel>
                                <input {...emailConfirmProps} />
                                <ErrorMsgField isShow={user['confirmEmailError']}>
                                    Confirm Email doesn&apos;t match Email
                            </ErrorMsgField>
                                <div className="invalid-feedback">
                                    Confirm Email is required.
                            </div>
                            {!isEmailCodeVisible && <button
                                className="btn btn-outline-secondary btn-sm mr-2"
                                type="button"
                                onClick={this.handleSubmitEmail}
                            >
                                Submit
                            </button>}
                        </div>
                    }

                    {isEmailCodeVisible
                        && <div className="col-md-3 mb-3">
                            <RequiredLabel htmlFor="email-code">Step 4: Please enter code sent to new email address</RequiredLabel>
                            <input {...emailCodeProps} />
                            <button
                                className="btn btn-outline-secondary btn-sm mr-2"
                                type="button"
                                onClick={this.handleSubmitEmailCode}
                            >
                                Submit
                            </button>
                        </div>
                    }
                </div>
            </div>
        );
    }

    renderDisplay() {
        const { user } = this.props;

        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="my-3">
                        User Email
                    </h4>
                    <div>
                        <button className="btn btn-outline-primary btn-sm" type="button" onClick={this.handleEdit}>Edit</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 mb-3">
                        <label className="title">Email</label>
                        <div>{user.Email}</div>
                    </div>
                </div>
            </div>
        );
    }

    reset() {
        this.setIsEmailCodeVisible(false);
        this.setEmailCodeIsValid(false);
        this.setPhoneCodeIsValid(false);
        this.setPasswordValid(false);

        this.setPhoneCode('');
        this.setNewEmail('');
        this.setConfirmNewEmail('');
    }

    render() {
        const { user, edit, editSection } = this.props;

        if (user == undefined) return '';

        if (edit) {
            return this.renderEdit();
        } else {
            if (editSection === this.section) {
                return (
                    <form className="w-100" onSubmit={this.handleSave} noValidate>
                        {this.renderEdit()}
                    </form>
                );
            } else {
                return this.renderDisplay();
            }
        }
    }
}


function mapStateToProps(state) {
    const { editSection, user, status } = state.profile;

    // pre-filled
    if (status !== 'changed' && user && user.confirmEmail === undefined) {
        user.confirmEmail = user.Email;
    }

    // pre-filled, set the password to empty because the database password is a hash value
    if (status !== 'changed' && user && user.Password !== '') {
        user.Password = '';
    }

    return {
        editSection,
        user: { ...user }
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        // dispatching plain actions
        saveUser: (user) => {
            dispatch(profileAction.updateCredentialAction(user));
            dispatch(userAction.updateEmail(user.Email));
        }
    }
}

UserEmail.propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object,
    edit: PropTypes.bool,
    editSection: PropTypes.string
};

const temp = connect(mapStateToProps, mapDispatchToProps)(UserEmail);

export { temp as UserEmail };
