import React from 'react';
import PropTypes from 'prop-types';


const BadgeItem = ({ item, onClick }) => {
    return (
        <span className="badge badge-light badge-item" onClick={onClick}>
            <span>{item}</span>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" >
                <span aria-hidden="true">&times;</span>
            </button>
        </span>
    );
};


BadgeItem.propTypes = {
    item: PropTypes.string,
    onClick: PropTypes.func
};


const temp = (BadgeItem);

export { temp as BadgeItem };