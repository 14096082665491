import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { medicationFilterAction } from '../../_actions';
import { teleplanService } from '../../_services';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { formatDate } from '../../_helpers';
import TimePicker from 'react-time-picker';
import moment from 'moment';

import { PdfDocument } from './PdfDocument';


class ManageTelePage extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            
        }
    }

    async componentDidMount() {
        const { dispatch } = this.props;
        dispatch(medicationFilterAction.get());
        const retrivedList = await teleplanService.getRetrieveList();
        this.setState({
            retrivedList
        })

    }

    render() {
        const { retrivedList = [ ] } = this.state;
        return (
            <div className="manage-tele-page">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Manage teleplan page</h1> 
                    <button 
                        className="btn-outline-secondary"
                        onClick={async () => {
                            const latestRemittance = await teleplanService.pullLatestRemittance();
                            this.setState({
                                retrivedList: [latestRemittance, ...retrivedList]
                            })
                        }}
                    > Pull latest remittance </button>
                </div>
                {/* <div>
                    {
                        retrivedList.slice(0, 15).map(item =>{
                              
                            return(
                                <div className="item">
                                    <span className="created-at">{moment(item.createdAt).format('YYYY-MM-DD @ HH:mm a')}  </span>

                                    <PDFDownloadLink 
                                      document={<PdfDocument data={item.text} />}
                                      fileName="teleplanstatus.pdf"> 
                                    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download pdf')}
                                    </PDFDownloadLink>
                                </div>
                            )

                        })

                    }
                   
                </div> */}
            </div>
        );
    }
}


ManageTelePage.propTypes = {
    dispatch: PropTypes.func.isRequired
};


const temp = connect()(ManageTelePage);

export { temp as ManageTelePage };

