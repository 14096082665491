import { medicationBillService } from '../_services';
import { errorHandling } from '../_helpers';
import { medicationBillConstant } from '../_constants';
import { alertAction } from '.';

export const medicationBillAction = {
    getDiagnosisCode,
    getQuestion,
    createOrUpdate,
    get,
    remove,
    add
     
};

function get() {
    return dispatch => {
        medicationBillService.get()
               .then(
                   res => {
                          dispatch({
                              type: medicationBillConstant.GET_LISTS_SUCCESS_ALL,
                              medicationBillListAll: res.data
                          })
                   },

                   error => {
                    errorHandling('medication-bill.action.get', error, dispatch);
                   }
               )
    }
}

function getDiagnosisCode(tc_ahfs_number) {
    return dispatch => {
        medicationBillService.getDiagnosisCode(tc_ahfs_number)
            .then(
                res => {
                    if (res.data.isDiagnosisCodeExists == true){
                        dispatch({
                            type: medicationBillConstant.DIAGNOSIS_CODE_EXIST,
                            isDiagnosisCodeExists: true,
                            diagnosisCode: res.data.diagnosisCode,
                        })
                    }  else {

                    dispatch({
                        type: medicationBillConstant.GET_LISTS_SUCCESS_DIAGNOSIS,
                        medicationBillList: res.data.medicationLists,
                        isDiagnosisCodeExists: false,
                    });
                }
                },
                error => {
                    errorHandling('medication-bill.action.get', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function getQuestion(tc_ahfs_number) {
    return dispatch => {
        medicationBillService.getQuestion(tc_ahfs_number)
            .then(
                res => {
                    if (res.data.isQuestionExists == true){
                        dispatch({
                            type: medicationBillConstant.QUESTION_EXIST,
                            isQuestionExists: true,
                            question: res.data.question
                        })
                    }  else {
                    dispatch({
                        type: medicationBillConstant.GET_LISTS_SUCCESS_QUESTION,
                        medicationBillList: res.data.medicationLists,
                        isQuestionExists:false
                    });
                    }
                },
                error => {
                    errorHandling('medication-bill.action.get', error, dispatch);
                }
            )
            .finally(
            );
    };
}

// function change(medicationFilter) {
//     return {
//         type: medicationFilterConstant.CHANGE,
//         medicationFilter
//     };
// }

function add(diagnosisCode, question,tc_ahfs_number ) {
    return dispatch => {
        return medicationBillService.add(diagnosisCode, question,tc_ahfs_number )
            .then(
                res => {
                    dispatch({
                        type: medicationBillConstant.ADDED,
                        medicationBillListAll: res.data
                    });
                },
                error => {
                    errorHandling('medication-bill.action.create', error, dispatch);
                    throw(error);
                }
            )
 
    };
}

  function createOrUpdate(diagnosisCode, question,tc_ahfs_number){
    return  async dispatch => {
        try {
            if (diagnosisCode != ''){
                const res = await medicationBillService.createOrUpdate(diagnosisCode, question,tc_ahfs_number);
                if (res.data.diagnosisCode != undefined){
                    dispatch(alertAction.success('Save diagnosis code successfully'));
                }
            }

            if(question != ''){
                const res = await medicationBillService.createOrUpdate(diagnosisCode, question,tc_ahfs_number);
                if ( res.data.question != undefined){
                    dispatch(alertAction.success('Save question successfully'));
                }
            }

        } catch (error) {
            errorHandling('bill.action.create bill', error, dispatch);
        }
    };
}



function remove(id) {
    return dispatch => {
        medicationBillService.remove(id)
            .then(
                res => {
                    dispatch({
                        type: medicationBillConstant.DELETED,
                        medicationBillListAll: res.data
                    });
                },
                error => {
                    errorHandling('medication-filter.action.remove', error, dispatch);
                }
            )
            .finally(
            );
    };
}