import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sleepClinicAction } from '../../_actions';
import { history } from '../../_helpers';
import { SleepClinicTable } from '.';



class SleepClinicBranchPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const { dispatch, clinicList } = this.props;
       

        if (clinicList === undefined) {
            dispatch(sleepClinicAction.get());
        }
    }

    handleBack = () => {
        history.goBack();
    }

    render() {
        const branchName = this.props.match.params.name;
        return (
            <div id="sleep-diagnostic-clinic">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Please choose one of the sleep diagnostic clinic branch locations below:</h1>
                </div>
                 <SleepClinicTable branchName={branchName} /> 
                <hr className="mb-4" />
                <div className="mb-3 d-flex justify-content-between">
                    <button className="btn btn-outline-secondary btn-lg" onClick={this.handleBack}>Back</button>
                </div>
            </div>
        );
    }
}


SleepClinicBranchPage.propTypes = {
    dispatch: PropTypes.func,
    clinicList: PropTypes.array
};


function mapStateToProps(state) {
    const { clinicList } = state.sleepClinic;

    return {
        clinicList
    };
}

const temp = connect(mapStateToProps)(SleepClinicBranchPage);

export { temp as SleepClinicBranchPage };

