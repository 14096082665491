import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { messageAction } from '../../_actions';
import { getUser, getPersonId, isPatient } from '../../_helpers/authorized';
import IconButton from '@material-ui/core/IconButton';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import Tooltip from '@material-ui/core/Tooltip';
import { messageConstant } from '../../_constants';
import { formatDateTime, uploadBucket, getS3ImageUrl, formatDateTimeDisplayInEmr } from '../../_helpers';
import { FileUploader } from '../../components';
import { multilineText } from '../../_helpers';
import { CustomImage } from '../../components';
import { compose } from 'recompose';
import { noteService } from '../../_services';


const styles = () => ({
    progress: {
        marginTop: 20,
        color: blue[500],
    }
});


class Message extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleReply = () => {
        const { dispatch } = this.props;
        dispatch(messageAction.reply());
    }

    handleDiscard = () => {
        const { dispatch } = this.props;
        dispatch(messageAction.discard());
    }

    handleChange = event => {
        const { dispatch, sendMessageObj } = this.props;

        sendMessageObj.message = event.target.value;

        // dispatch messageAction change
        dispatch(messageAction.change(sendMessageObj));
    };

    /**
     * Get the file on the input file
     */
    changeFile = (files) => {
        const { dispatch, sendMessageObj } = this.props;
        sendMessageObj.files = files;
        dispatch(messageAction.change(sendMessageObj));
    }

    handleSendReply = () => {
        const { dispatch, messageObj, sendMessageObj } = this.props;
        const user = getUser();
        const from = user.Person._id;
        const to = messageObj.from._id === from ? messageObj.to._id : messageObj.from._id;
        
        const textToEmr = sendMessageObj.message + `\n\n[Patient message received using AskMedication.com on ${formatDateTimeDisplayInEmr(Date.now())}]`

        // dispatch messageAction send
        dispatch(messageAction.sendReply(messageObj._id, from, to, sendMessageObj.message, sendMessageObj.files));
        noteService.sendNoteToEmr(textToEmr, user.Person);
    }

    handleClose = () => {
        this.handleDiscard();
    }

    renderMessage(from, date, message, files) {
        return (
            <>
                <div className="title">
                    <span className="person">
                        {(from || {}).FirstName} {(from || {}).LastName}
                    </span>
                    <span className="date">
                        {formatDateTime(date)}
                    </span>
                </div>
                <div className="content">
                    {multilineText(message)}
                </div>
                <br />
                <div className="d-flex">
                    {files && files.map((o, index) => {
                        const fileKey = `${uploadBucket.messaging}/${o._id}_${o.name}`;
                        if ((/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(o.name)) {
                            return <CustomImage key={index} imageKey={fileKey} />;
                        }
                        return <a href={getS3ImageUrl(fileKey)} download>
                            {o.name}
                        </a>
                    })}
                </div>
            </>
        );
    }

    // patient can only reply once in the messaging system 
    isRenderReply() {
        const { messageObj } = this.props;

        if (isPatient()) {
            const personId = getPersonId();

            // patient send the message
            if (((messageObj || {}).from || {})._id === personId) {                

                // no one reply, then patient can't reply
                if (messageObj.replies.length === 0) {
                    return false;
                }
            }

            // this applied to all message no matter send to/from patient
            // if patient replied already, then can't reply again
            if (messageObj.replies.length > 0) {
                const from = messageObj.replies.find(o => o.from._id === personId);
                
                if (from !== undefined) {
                    return false;
                }
            }
        }

        return true;
    }

    renderReply() {
        const { status, sendMessageObj, classes } = this.props;

        if (status === messageConstant.STATUS_REPLYING) {
            return (
                <form noValidate onSubmit={this.handleSendReply}>
                    <div className="form-group">
                        <textarea className="form-control" id="message" name="message" rows="4" placeholder="message ..."
                            onChange={this.handleChange}
                            value={sendMessageObj.message}
                            autoFocus
                        >
                        </textarea>
                        <br />
                        <FileUploader multiple={true} onChange={this.changeFile} accept="application/pdf, image/*" />
                    </div>
                    <div className="send-action">
                        <button type="submit" className="btn btn-primary">Send</button>
                        <Tooltip title="Discard message">
                            <IconButton color="inherit" onClick={this.handleDiscard} className="float-right">
                                <DeleteRoundedIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </form>
            );
        }
        else if (status === messageConstant.STATUS_SENDING) {
            return (
                <CircularProgress className={classes.progress} />
            );
        }
        else {
            return (
                <button className="btn btn-outline-secondary" onClick={this.handleReply}>
                    <i className="fa fa-mail-reply mx-1"></i>
                    <span className="mx-1">Reply</span>
                </button>
            );
        }
    }

    render() {
        const { messageObj } = this.props;

        return (
            messageObj !== undefined &&
            <div id="message">
                <h2 className="subject">
                    {messageObj.subject}
                </h2>
                {this.renderMessage(messageObj.from, messageObj.createdAt, messageObj.message, messageObj.files)}
                <div className="replies">
                    {messageObj.replies.map((reply, i) => {
                        return (
                            <div key={i}>
                                <hr />
                                {this.renderMessage(reply.from, reply.repliedAt, reply.message, reply.files)}
                            </div>
                        );
                    })}
                </div>
                <div className="reply">
                    {this.isRenderReply() && this.renderReply()}
                </div>
            </div>
        );
    }
}


Message.propTypes = {
    dispatch: PropTypes.func.isRequired,
    sendMessageObj: PropTypes.object,
    messageObj: PropTypes.object,
    classes: PropTypes.object,
    status: PropTypes.string
};


function mapStateToProps(state) {
    const { status, messageObj, customerServiceListIds } = state.message;
    let { sendMessageObj } = state.message;

    if (sendMessageObj === undefined) {
        sendMessageObj = {
            message: ''
        };
    }

    return {
        status,
        messageObj,
        sendMessageObj,
        customerServiceListIds,
    };
}


const temp = compose(
    connect(mapStateToProps),
    withStyles(styles)
)(Message);

export { temp as Message };

