import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const paymentService = {
    getCardByCharge,
    charge,
    refund
};

function getCardByCharge(chargeId) {
    const url = `${config.apiHost}/payment/card-by-charge/${chargeId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function charge(orderID, amount, description) {
    const url = `${config.apiHost}/payment/charge`;
    const body = {
        orderID,        // "tok_visa"
        amount,
        description
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, body);
}

function refund(chargeId, amount) {
    const url = `${config.apiHost}/payment/refund`;
    const body = {
        chargeId,
        amount
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}