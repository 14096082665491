import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { alertAction } from '../_actions';


const Alert = ({ dispatch, type, title, messages, isOpen }) => {
    return (
        <div id="alert">
            <div className={`alert alert-${type} ${isOpen ? 'alert-shown' : 'alert-hidden'}`}>
                <div>
                    <div className="title">{title}</div>
                    <div className="messages">
                        {messages && messages.map((message, i) => {
                            return <div key={i}>{message}</div>;
                        })}
                    </div>
                </div>
                <button type="button" className="close" aria-label="Close"
                    onClick={() => dispatch(alertAction.clear())}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    );
};


Alert.propTypes = {
    dispatch: PropTypes.func,
    type: PropTypes.string,
    title: PropTypes.string,
    messages: PropTypes.array,
    isOpen: PropTypes.bool
};


function mapStateToProps(state) {
    const { type, title, messages, isOpen } = state.alert;

    return {
        type,
        title,
        messages,
        isOpen
    };
}


const temp = connect(mapStateToProps)(Alert);

export { temp as Alert };