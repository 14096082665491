import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BloodPressure } from '.';


class SleepInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    renderRadioField(question, name) {
        const { sleepInfoObj } = this.props;

        return (
            <div className="col-md-12 my-2">
                <strong className="mr-3">{question}</strong>
                <span>{sleepInfoObj[name] ? 'Yes' : 'No'}</span>
            </div>
        );
    }

    renderHeightWeight(question, name, unit) {
        const { sleepInfoObj } = this.props;

        return (
            <div className="col-md-12 my-2">
                <strong className="mr-3">{question}</strong>
                <span className="mr-1">{sleepInfoObj[name]}</span>
                <span>{unit}</span>
            </div>
        );
    }

    renderBloodPressure() {
        const { sleepInfoObj } = this.props;

        return (
            sleepInfoObj.bloodPressures.map((o) => {
                return <BloodPressure key={o._id} bloodPressureObj={o} />;
            })
        );
    }

    render() {
        const { sleepInfoObj } = this.props;

        if (sleepInfoObj) {
            return (
                <div className="row">
                    {this.renderHeightWeight('What is your height?', 'height', 'cm')}
                    {this.renderHeightWeight('What is your weight?', 'weight', 'kg')}
                    {this.renderRadioField('Did you gain weight recently?', 'gainWeight')}
                    {this.renderRadioField('Do you suffer from depression? ', 'depression')}
                    {this.renderRadioField('Have you been diagnosed with diabetes?', 'diabetes')}
                    {this.renderRadioField('Do you have any heart disease?', 'heartDisease')}
                    {this.renderRadioField('Have you ever been told that you have a heart condition called atrial fibrillation?', 'atrialFibrillation')}
                    {this.renderRadioField('Is your cognition (ability to think and remember) deteriorating?', 'cognitionDeteriorating')}
                    {this.renderRadioField('Do you snore?', 'snore')}
                    {this.renderRadioField('Does you breathing pause while you are asleep?', 'asleepBreathPause')}
                    {this.renderRadioField('Do you choke while you are asleep?', 'asleepChoke')}
                    {this.renderRadioField('Are you taking any medication for blood pressure?', 'bloodPressureMedication')}
                    {this.renderBloodPressure()}                    
                </div>
            );
        } else {
            return '';
        }
    }
}


SleepInfo.propTypes = {
    dispatch: PropTypes.func,
    sleepInfoObj: PropTypes.object
};

const temp = SleepInfo;

export { temp as SleepInfo };

