import axios from 'axios';
import config from '../config';
import { getToken, getPersonId } from '../_helpers';


export const sleepAssessmentService = {
    getPendingByPerson,
    create,
    updateStatus,
    paymentCompleted,
    refundSuccess
};


function getPendingByPerson(personId) {
    const url = `${config.apiHost}/sleep-assessment/pending-by-person/${personId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function create(sleepInfoObj, sleepClinicObj) {
    const url = `${config.apiHost}/sleep-assessment`;
    const body = {
        sleepInfoObj,
        sleepClinicObj
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, body);
}

function updateStatus(id, status) {
    const url = `${config.apiHost}/sleep-assessment/update-status`;
    const body = {
        id,
        status
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function paymentCompleted(id, personId, paymentId, fax = {}) {
    const url = `${config.apiHost}/sleep-assessment/payment-completed`;
    const body = {
        id,
        personId,
        paymentId,
        fax
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function refundSuccess(id, personId) {
    const url = `${config.apiHost}/sleep-assessment/refund-success`;
    const body = {
        id,
        personId,
        refundedBy: getPersonId()
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}
