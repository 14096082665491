import { prescriptionConstant, faxConstant } from '../_constants';

export function prescription(state = {}, action) {
    switch (action.type) {
        case prescriptionConstant.GET_BY_ID:
            return {
                ...state,
                prescription: { ...action.prescription }
            };
        case prescriptionConstant.CHANGE:
            return {
                status: 'changed',
                prescriptionObj: { ...action.prescriptionObj }
            };
        case prescriptionConstant.REQUEST:
            return {};
        case prescriptionConstant.CREATE:
            return {
                status: 'created',
                prescriptionObj: { 
                    ...action.prescriptionObj,
                    Medications:action.prescriptionObj.medications
                }
            };
        case prescriptionConstant.GET_NON_CREATED:
        case prescriptionConstant.GET_FAXED:
        case prescriptionConstant.GET_BY_PERSON:
        case prescriptionConstant.GET_PENDING_BY_PERSON:
        case prescriptionConstant.MEDICATION_STATUS_UPDATED:
        case prescriptionConstant.UPDATE_MEDICATION_USAGE_SUCCESS:
        case prescriptionConstant.REFUND_SUCCESS:
        case prescriptionConstant.PRINT_SUCCESS:
            return {
                ...state,
                prescriptions: [...action.prescriptions]
            };
        case prescriptionConstant.CANCEL_SUCCESS:
            return{
                ...state,
                prescription: {...action.prescription}
            };
            
        case faxConstant.QUEUE_PRESCRIPTION_FAX_SUCCESS:

            if ( 
                (state.prescriptions || []).length > 0 
                && state.prescriptions[0].person._id == action.personId
            ) {
                const prescriptions = action.prescriptions
                return {
                    ...state,
                    prescriptions,
                };
            }
            return {...state};
                
        case prescriptionConstant.TABLE_CHANGE:
            return {
                ...state,
                tableObj: { ...action.tableObj }
            };
        case prescriptionConstant.OPEN_PRESCRIPTION_DIALOG:
            return {
                ...state,
                openPrescription: true,
                prescription: { ...action.prescription },
                isText: action.isText
            };
        case prescriptionConstant.CLOSE_PRESCRIPTION_DIALOG:
            return {
                ...state,
                openPrescription: false
            };
        case prescriptionConstant.OPEN_INVOICE_DIALOG:
            return {
                ...state,
                openInvoice: true,
                prescription: { ...action.prescription }
            };
        case prescriptionConstant.CLOSE_INVOICE_DIALOG:
            return {
                ...state,
                openInvoice: false
            };
        case prescriptionConstant.GO_TO_PRESCRIPTION:
            return {
                ...state,
                selectPrescriptionId: action.selectPrescriptionId
            };
        case prescriptionConstant.SET_TEXT:
            const prescriptions  = state.prescriptions.map((p) => {
                if (p._id == action.id)
                    return {...p, text: action.text}
                return p;
            })
            return {
                ...state,
                prescriptions
            }
        case prescriptionConstant.SET_DR_PHARMACY:{
            const prescriptions = state.prescriptions.map((prescription) => {
                if (prescription._id == action.id) 
                    return {...prescription, drPharmacy: action.drPharmacy}
                return prescription;
            })
            return {
                ...state,
                prescriptions
            }
        }
        default:
            return state;
    }
}