import React from 'react';
import { connect } from 'react-redux';
import { scroller } from 'react-scroll';
import { Link } from 'react-router-dom';

import { history } from '../../_helpers';
import blogImage from '../../assets/images/mbr-2-1920x1280.jpg';
import priceImage from '../../assets/images/mbr-1-1920x1280.jpg';
import registrationImage from '../../assets/images/mbr-1920x1238.jpg';
import aboutusImage from '../../assets/images/mbr-1920x1280.jpg';
import faqImage from '../../assets/images/mbr-1920x1281.jpg';
import defaultImage from '../../assets/images/mbr-1868x1920.png';
import loginImage from '../../assets/images/login.jpg';
import config from '../../config';

class Banner extends React.Component {
    scrollToMain() {
        scroller.scrollTo("main", {
            duration: 800,
            smooth: true,
            offset: -50
        });
    }

    render() {
        let url = "";
        switch (this.props.location.pathname) {
            case "/blog":
                url = blogImage;//"mbr-2-1920x1280.jpg";
                break;
            case "/price":
                url = priceImage;
                break;
            case "/registration":
                url = registrationImage;
                break;
            case "/about-us":
                url = aboutusImage;// "mbr-1920x1280.jpg";
                break;
            case "/faq":
                url = faqImage//+= "mbr-1920x1281.jpg";
                break;
            case "/login":
            case "/office-login":
                url = loginImage;
                break;
            default:
                url = defaultImage; // "mbr-1868x1920.png";
        }
        return <section class="header1 cid-s8n53c4pbp mbr-fullscreen main-banner" id="header1-e"
            style={{backgroundImage: `url(${url})`}}
        >
        
        <div class="mbr-overlay" style={{
            opacity: "0.6", backgroundColor: "rgb(255, 255, 255)",
        }}>
        </div>
    
        <div class="container align-center">
            <div class="row">
                <div class="col-lg-12">
                    <p class="mbr-section-subtitle mbr-bold align-left pb-1 mbr-fonts-style display-4">
                        ONLINE SOLUTION</p>
                    <h1 class="mbr-section-title align-left mbr-fonts-style display-1">
                        We are the <span class="accent-word">most</span> <br/> convenient.
                    </h1>
                    
                    
                    <div class="mbr-section-btn align-left  justify-content-center main-banner__buttons">
                        <a
                            class="btn btn-lg btn-primary display-4 main-banner__buttons__learn-more"
                            onClick={this.scrollToMain}
                        >LEARN MORE</a>

                        <a
                            href="https://search.google.com/local/writereview?placeid=ChIJ5Vq2L1R1hlQR6w5jP52CVQg"
                            className="m-3 main-banner__buttons__heart"
                            target="_blank"
                            style={{order: window.innerWidth>768?'':'1'}}
                        >
                            <span className="mdi-action-favorite-outline mbr-iconfont" style={{
                                    marginRight: "15px",
                                    marginLeft: "20px",
                                    padding: "10px",
                                    borderRadius: "100%",
                                    color:"#4f87fb",
                                    backgroundColor: "#fff",
                                    fontSize: "2.475rem"
                            }}>
                                <i class="far fa-heart"></i>
                            </span>
                        </a>
                        <a className="content-link link mbr-bold display-4 main-banner__buttons__get-refill"
                            onClick={() => window.location.href = config.southVanHost + "/login"}
                            style={{order: window.innerWidth>768?'':'1'}}
                        >
                        GET REFILL
                        </a>
                        <div className="w-100 d-flex justify-content-center mt-3">
                            <a
                                className="btn btn-lg btn-primary display-4 main-banner__buttons__learn-more"
                                style={{
                                    width: window.innerWidth>768?'378px':'',
                                    height: window.innerWidth>768?'80px':'',
                                    fontSize: window.innerWidth>768?'18px':'',
                                    justifyContent: 'center',
                                }}
                                href={config.southVanHost +"/login"}
                                >SIGN UP / SIGN IN</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </section>
    }

}

const temp = connect()(Banner);
export {temp as Banner}