import { noteDraftService } from '../_services';
import { errorHandling, formatDateTime } from '../_helpers';
import { noteConstant, noteDraftConstant } from '../_constants';

export const noteDraftAction = {
    getByPerson,
    edit,
    close,
    change,
    update,
    sign,
    updateV2,
    updateV3
};

function getByPerson(personId) {
    return dispatch => {
        noteDraftService.getByPerson(personId)
            .then(
                res => {
                    const draft = res.data;

                    dispatch({
                        type: noteDraftConstant.GET_BY_PERSON,
                        draft
                    });
                },
                error => {
                    errorHandling('noteDraft.action.getByPerson', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function edit() {
    return {
        type: noteDraftConstant.EDIT,
    };
}

function close() {
    return {
        type: noteDraftConstant.CLOSE,
    };
}


function change(text) {
    return {
        type: noteDraftConstant.CHANGE,
        text
    };
}

function update(id, text) {
    return dispatch => {
        text = draftText(text);

        noteDraftService.update(id, text)
            .then(
                () => {
                    dispatch({
                        type: noteDraftConstant.SAVED,
                        text
                    });
                },
                error => {
                    errorHandling('noteDraft.action.save', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function updateV3(id, text) {
    return dispatch => {
        text = draftText(text);

        dispatch({
            type: noteDraftConstant.SAVED,
            text
        });
        noteDraftService.updateOrSignDebounce(true, id, text)
            .then(
                () => {
                    
                },
                error => {
                    errorHandling('noteDraft.action.save', error, dispatch);
                }
            )
            .finally(
            );
    };
}


function updateV2(id, text) {
    return async dispatch => {
        try {
            text = draftText(text);
            await noteDraftService.update(id, text);
            dispatch({
                type: noteDraftConstant.SAVED,
                text
            });
        } catch (error) {
            errorHandling('noteDraft.action.save', error, dispatch);
            throw(error);
        }       
    };
}

function sign(id, personId, from, text) {
    return dispatch => {
        text = signedText(text, from);

        // noteDraftService.sign(id, personId, from, text)
        noteDraftService.updateOrSignDebounce(false, id, personId, from, text)
            .then(
                res => {
                    const notes = res.data;

                    dispatch({
                        type: noteDraftConstant.SIGNED
                    });

                    dispatch({
                        type: noteConstant.CREATED,
                        notes
                    });
                },
                error => {
                    errorHandling('noteDraft.action.sign', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function signedText(text, from) {
    text = text.replace('\n[Draft]', '');
    text = `${text}\n[Signed by ${from.FirstName} ${from.LastName} on ${formatDateTime(Date.now())}]`;

    return text;
}

function draftText(text = '') {
    const draftText = '\n[Draft]';
    if (text.indexOf(draftText) < 0) {
        text = `${text}${draftText}`;
    }
    
    return text;
}