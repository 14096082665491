import ReactDomServer from 'react-dom/server';

import getUrlFromEle from './getUrlFromEle';


export default async function getCompUrl(comp) {
    let elem = null;
    try {
        elem = document.createElement('div');
        const html = ReactDomServer.renderToStaticMarkup(comp);
        elem.innerHTML = html;
        elem.style.position = 'relative';
        elem.style.top = window.innerHeight + 'px';
        elem.style.width = 'fit-content';

        document.body.appendChild(elem);
        const url = await getUrlFromEle(elem);
        document.body.removeChild(elem);

        return url;
    } catch (error) {
        if (elem)
            document.body.removeChild(elem);
        throw (error);
    }
}

