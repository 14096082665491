import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Element, scroller } from 'react-scroll';

import { sleepAssessmentAction } from '../../_actions';
import { SleepAssessmentDialog } from './';
import { formatDateTime } from '../../_helpers';


const CONTAINER_ID = 'pending-sleep-assessment';

class PendingSleepAssessment extends Component {
    constructor(props) {
        super(props);
        const state = {};
        if (props.selectSleepAssessmentId)
            state.selectSleepAssessmentId = props.selectSleepAssessmentId;
        this.state = state;
    }

    openSleepAssessmentDialog = (obj) => {
        const { dispatch } = this.props;
        dispatch(sleepAssessmentAction.openDialog(obj));
    }

    render() {
        const { pendingList } = this.props;
        const { selectSleepAssessmentId } = this.state;

        if (pendingList && pendingList.length > 0) {
            return (
                <div id={CONTAINER_ID}>
                    {pendingList.map(obj => {
                        let classes = 'sleep-assessment';
                        const selected = obj._id === selectSleepAssessmentId;
                        if (selected) classes += ' sleep-assessment--selected';
                        return (
                            <Element name={obj._id} key={obj._id} className={classes}>
                                <span>                                    
                                    <span className="created-date">
                                        {formatDateTime(obj.createdDate)}
                                    </span>
                                    <span className="id">{obj._id}</span>
                                </span>                                                                                             
                                <button type="button" className="btn btn-outline-primary"
                                    onClick={() => this.openSleepAssessmentDialog(obj)}
                                >
                                    Sleep Assessment
                                </button>
                            </Element>
                        );
                    })}
                    <SleepAssessmentDialog />
                </div>
            );
        } else {
            return (
                <div className="mb-4">
                    No pending sleep assessment for this patient.
                </div>
            );
        }
    }

    componentDidMount() {
        const { selectSleepAssessmentId } = this.state;

        if (selectSleepAssessmentId) {
            // scroll to specific note
            scroller.scrollTo(selectSleepAssessmentId, {
                containerId: CONTAINER_ID,
                duration: 1,
                smooth: true,
                offset: -300
            });
        }
    }
}


PendingSleepAssessment.propTypes = {
    dispatch: PropTypes.func.isRequired,
    pendingList: PropTypes.array
};


function mapStateToProps(state) {
    const { pendingList, selectSleepAssessmentId } = state.sleepAssessment;
    return {
        pendingList,
        selectSleepAssessmentId
    };
}

const temp = connect(mapStateToProps)(PendingSleepAssessment);

export { temp as PendingSleepAssessment };

