import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from './components/CustomRoute';
import { Confirm, Home, Login, Dashboard, Recovery, RecoveryPassword, Fees,FAQ, AboutUs, Blog, Price, Post, FaxResend,CrossDomainAuth } from './pages/Public';
import { Prescriptions, Prescription } from './pages/Prescription';
import { Messaging, ReplyMessage, ReplyFaxResend } from './pages/Messaging';
import { MedicalInfoPage, EditMedicalInfoPage } from './pages/MedicalInfo';
import { PrescriptionMedicalInfo, PrescriptionForm, PrescriptionPayment } from './pages/RequestPrescription';
import { RegistrationPage, ProfilePage } from './pages/Profile';
import { ProblemPage } from './pages/Problem';
import { OfficeInvitePage } from './pages/OfficeInvite';
import { PatientsPage, PatientPage, PatientsLists } from './pages/Patient';
import { AuditPage } from './pages/Audit';
import { isAuth, isDoctor, isPatient, isAdmin, isCustomerService, env } from './_helpers';
import { ManageDataPage } from './pages/ManageData';
import { MedicationFilterPage } from './pages/MedicationFilter';
import { MedicationBillPage } from './pages/MedicationBill';
import { TeleFilePage } from './pages/Bill/TeleFilePage';
import { ManageTelePage } from './pages/Bill/ManageTelePage';
import { EditInvoicePage } from './pages/Bill/edit-invoice-page';
import { InvoicePage } from './pages/Bill/invoice-page';
import { InvoicePageEdit } from './pages/Bill/invoice-page-edit';
import { RemittancePage } from './pages/Bill/RemittancePage';
import { DrugSearchPage } from './pages/Patient/DrugSearchPage';
import { ManageDrugPage } from './pages/ManageDrug/ManageDrugPage';
import { SleepInfoPage, EditSleepInfoPage, SleepClinicPage, SleepClinicBranchPage } from './pages/SleepReferral';
import { PersonalInfo } from "./pages/personal-info";
import { featureToggle } from './_helpers/featureToggle';

const Routes = () => (
    <Switch>
        <PublicRoute exact path='/' component={Home} />
        <PublicRoute exact path='/auth/:token' component={CrossDomainAuth} />
        <PublicRoute exact path='/registration' component={Home} />
        <PublicRoute exact path='/faq' component={FAQ} />
        <PublicRoute exact path='/blog' component={Blog} />
        {<PublicRoute exact path='/blog/:name' component={Post} />}
        <PublicRoute exact path='/about-us' component={AboutUs} />
        <PublicRoute exact path='/price' component={Price} />
        <PublicRoute exact path='/login' key="login" component={Login} />
        {(env() === 'dev' || env() === 'auto') && <PublicRoute exact path='/office-login' key="office-login" component={Login} />}
        {(env() === 'dev' || env() === 'auto') && <PublicRoute exact path='/office-registration' key="office-registration" component={RegistrationPage} />}
        <PublicRoute exact path='/confirm' component={Confirm} />
        <PublicRoute exact path='/recovery' component={Recovery} />
        <PublicRoute exact path='/recovery/password' component={RecoveryPassword} />
        <PublicRoute exact path='/fees' component={Fees} />
        <PublicRoute exact path='/fax/:sfaxDetailsID/resend' component={FaxResend} />
        <PublicRoute path='/reply/:personId/:msgId' component={ReplyMessage} />
        <PublicRoute path='/reply/:prescriptionId' component={ReplyFaxResend}/>
        <PrivateRoute exact path='/dashboard' component={Dashboard} />
        {isAuth() && !isCustomerService() && <PrivateRoute exact path='/prescriptions' component={Prescriptions} />}
        {isAuth() && !isCustomerService() && <PrivateRoute exact path='/prescription/:id' component={Prescription} />}
        <PrivateRoute exact path='/messaging' key={Date.now()} component={Messaging} />
        <PrivateRoute exact path='/profile' component={ProfilePage} />

        {isAuth() && isPatient() && <PrivateRoute exact path='/request-prescription/medicalinfo' component={PrescriptionMedicalInfo} />}
        {isAuth() && isPatient() && <PrivateRoute exact path='/request-prescription/form' component={PrescriptionForm} />}
        {isAuth() && isPatient() && <PrivateRoute exact path='/request-prescription/payment/' component={PrescriptionPayment} />}
        {isAuth() && isPatient() && <PrivateRoute exact path='/medicalInfo' component={MedicalInfoPage} />}
        {isAuth() && isPatient() && <PrivateRoute exact path='/medicalInfo/edit' component={EditMedicalInfoPage} />}
        {isAuth() && isPatient() && <PrivateRoute exact path='/medication-search' component={DrugSearchPage} />}
        {isAuth() && isPatient() && <PrivateRoute exact path='/sleep-info' component={SleepInfoPage} />}
        {isAuth() && isPatient() && <PrivateRoute exact path='/sleep-info/edit' component={EditSleepInfoPage} />}
        {isAuth() && isPatient() && <PrivateRoute exact path='/sleep-clinic' component={SleepClinicPage} />}
        {isAuth() && isPatient() && <PrivateRoute exact path='/sleep-clinic/:name' component={SleepClinicBranchPage} />}

        {isAuth() && isAdmin() && <PrivateRoute exact path='/audit' component={AuditPage} />}
        {isAuth() && isAdmin() && <PrivateRoute exact path='/problems' component={ProblemPage} />}
        {isAuth() && isAdmin() && <PrivateRoute exact path='/office-invite' component={OfficeInvitePage} />}
        {isAuth() && ( isDoctor() || isCustomerService() ) && <PrivateRoute exact path='/patients' component={PatientsLists} />}
        {isAuth() && ( isDoctor() || isCustomerService() ) && <PrivateRoute exact path='/patient/:id' component={PatientPage} />}
        {isAuth() && ( isDoctor() || isCustomerService() ) && <PrivateRoute exact path='/medicalInfo/edit/:id' component={EditMedicalInfoPage} />}
        {isAuth() && isDoctor() && <PrivateRoute exact path='/personal-info/:id/edit' component={PersonalInfo} />}
        {isAuth() && isAdmin() && <PrivateRoute exact path='/manage-data' component={ManageDataPage} />}
        {isAuth() && isAdmin() && <PrivateRoute exact path='/manage-drug' component={ManageDrugPage} />}
        {isAuth() && isAdmin() && <PrivateRoute exact path='/medication-filter' component={MedicationFilterPage} />}
        {isAuth() && isAdmin() && <PrivateRoute exact path='/medication-icd' component={MedicationBillPage} />}
        {isAuth() && isAdmin() && <PrivateRoute exact path='/remittance' component={RemittancePage} />}
        {featureToggle.bill && isAuth() && isAdmin() && <PrivateRoute exact path='/tele-file' component={TeleFilePage} />}
        {featureToggle.bill && isAuth() && isAdmin() && <PrivateRoute exact path='/manage-tele' component={ManageTelePage} />}
        {featureToggle.bill && isAuth() && isAdmin() && <PrivateRoute exact path='/edit-invoice' component={EditInvoicePage} />}
        {featureToggle.bill && isAuth() && isAdmin() && <PrivateRoute exact path='/edit-invoice/:id' component={InvoicePage} />}
        {featureToggle.bill && isAuth() && isAdmin() && <PrivateRoute exact path='/edit-invoice/:id/edit' component={InvoicePageEdit} />}
        <Redirect to="/dashboard" />
    </Switch>
);


export default Routes;