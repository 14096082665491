import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registrationAction } from '../../_actions';
import { PrivacyPolicyDialog, TermsOfUseDialog, FeeScheduleDialog } from '../Profile';


class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handlePrivacyPolicy = () => {
        const { dispatch } = this.props;
        dispatch(registrationAction.openPrivacyPolicy());
    }

    handleTermsOfUse = () => {
        const { dispatch } = this.props;
        dispatch(registrationAction.openTermsOfUse());
    }

    handleFeeSchedule = () => {
        const { dispatch } = this.props;
        dispatch(registrationAction.openFeeSchedule());
    }

    render() {
        return (
            <footer id="footer" className="no-print">
                <div className="empty-container"></div>
                <div className="container">
                    <a href="#" onClick={this.handlePrivacyPolicy}>Privacy Policy</a>
                    <a href="#" onClick={this.handleTermsOfUse}>Terms of Use</a>
                    <a href="#" onClick={this.handleFeeSchedule}>Fee Schedule</a>
                </div>
                <PrivacyPolicyDialog />
                <TermsOfUseDialog />
                <FeeScheduleDialog />
            </footer>
        );
    }
}


Footer.propTypes = {
    dispatch: PropTypes.func,
    openPrivacyPolicy: PropTypes.bool,
    openTermsOfUse: PropTypes.bool
};


function mapStateToProps(state) {
    const { openPrivacyPolicy, openTermsOfUse,openFeeSchedule } = state.registration;    

    return {
        openPrivacyPolicy,
        openTermsOfUse,
        openFeeSchedule
    };
}


const temp = connect(mapStateToProps)(Footer);

export { temp as Footer };