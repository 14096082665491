import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


export default async function getUrlFromEle(div) {
    const divWidth = div.clientWidth;
    const divHeight = div.clientHeight;
    const ratio = divHeight / divWidth;
    div.style.whiteSpace = "pre-wrap";

    const noteNode = div.getElementsByTagName("textarea")[0];

    if (noteNode) {
        const div = document.createElement("div");
        div.style.margin = "10px";
        div.style.fontSize = "10px";
        div.innerHTML = noteNode.value;
        noteNode.replaceWith(div);
    }

    const canvas = await html2canvas(div, {
        scale: 5,
        allowTaint : true,
        useCORS: true,
        y: window.scrollY + div.getBoundingClientRect().top,
        x: window.scrollX + div.getBoundingClientRect().left
    });

    // document.body.appendChild(canvas);
    // throw ('error')
    
    // create pdf by jsPDF
    const doc = new jsPDF();
    const width = doc.internal.pageSize.getWidth() - 40;
    const height = ratio * width;
    doc.addImage(canvas, 'JPEG', 20, 20, width, height);

    // convert back to dataURL
    const dataURL = doc.output('datauristring');

    return dataURL;
}
