import { loadingConstant } from '../_constants';


export const loadingAction = {
    open,
    close,
    openWithStyle
};

function open(message) {
    return { 
        type: loadingConstant.OPEN,
        message
    };
}

function openWithStyle(style) {
    console.log(style);
    return {
        type: loadingConstant.OPEN_WITH_STYLE,
        style
    }
}

function close() {
    return { 
        type: loadingConstant.CLOSE,
    };
}