import { auditService } from '../_services';
import { errorHandling, getPersonId } from '../_helpers';
import { auditConstant } from '../_constants';

export const auditAction = {
    getPatients,
    getAccessedBy,
    get,
    create,
    change
};

function getPatients(search) {
    return dispatch => {
        auditService.getPatients(search)
            .then(
                res => {
                    dispatch({
                        type: auditConstant.GET_PATIENTS_SUCCESS,
                        patientList: res.data
                    });
                },
                error => {
                    errorHandling('audit.action.getPatients', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function getAccessedBy(search) {
    return dispatch => {
        auditService.getAccessedBy(search)
            .then(
                res => {
                    dispatch({
                        type: auditConstant.GET_ACCESSED_BY_SUCCESS,
                        accessedByList: res.data
                    });
                },
                error => {
                    errorHandling('audit.action.getAccessedBy', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function get(search) {
    return dispatch => {
        auditService.get(search)
            .then(
                res => {
                    dispatch({
                        type: auditConstant.GET_SUCCESS,
                        auditList: res.data
                    });
                },
                error => {
                    errorHandling('audit.action.get', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function create(patientId) {
    return dispatch => {
        const accessedBy = getPersonId();

        auditService.create(patientId, accessedBy)
            .then(
                () => {
                    dispatch({
                        type: auditConstant.CREATED,
                    });
                },
                error => {
                    errorHandling('audit.action.create', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function change(search) {
    return {
        type: auditConstant.CHANGE,
        search
    };
}