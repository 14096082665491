import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const sleepInfoService = {
    getByPerson,
    getById,
    save
};

function getByPerson(personId) {
    const url = `${config.apiHost}/sleep-info/person/${personId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function getById(id) {
    const url = `${config.apiHost}/sleep-info/${id}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function save(sleepInfoObj) {
    const url = `${config.apiHost}/sleep-info`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, sleepInfoObj);
}