
import React from 'react';
import PropTypes from 'prop-types';
import { Medication } from './';


function Medications(props) {
    return (
        props.items.map((item, index) => {
            if (props.type === 'print') {
                // print
                // only render the Approved medications
                if (item.status === 'Approved') {
                    return <Medication key={index} item={item} {...props} />;
                } else {
                    return '';
                }
            } else {
                return <Medication key={index} item={item} {...props} />;
            }
        })
    );
}


Medications.propTypes = {
    items: PropTypes.array,
    type: PropTypes.string,
    hideDuration: PropTypes.bool
};


export {
    Medications
};