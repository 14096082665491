import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PrescriptionTable } from './';
import { isDoctor, getPersonId, isCustomerService } from '../../_helpers';
import { prescriptionAction } from '../../_actions';

class Prescriptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.getPrescriptions();
    }

    getPrescriptions = () => {
        const { dispatch } = this.props;

        if (isDoctor() || isCustomerService()) {            
            // doctor            
            dispatch(prescriptionAction.getFaxedOrRefunded());
        } else {
            // patient
            dispatch(prescriptionAction.getByPerson(getPersonId()));
        }
    }

    displayPrescriptions() {
        const { prescriptions } = this.props;

        if (prescriptions === undefined) {
            return <span>Loading...</span>;
        } else if (prescriptions === null) {
            return <span>Server side Error!</span>;
        } else if (prescriptions.length == 0) {
            return <div>No prescription records</div>;           
        } else {
            return <PrescriptionTable />;
        }
    }

    renderTitle() {
        return <h1 className="h2">Prescription history</h1>;
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    {this.renderTitle()}
                </div>
                <div>
                    { this.displayPrescriptions() }
                </div>
            </div>
        );
    }
}


Prescriptions.propTypes = {
    dispatch: PropTypes.func.isRequired,
    prescriptions: PropTypes.array
};


function mapStateToProps(state) {
    const { prescriptions } = state.prescription;

    return {
        prescriptions
    };
}

const temp = connect(mapStateToProps)(Prescriptions);

export { temp as Prescriptions };