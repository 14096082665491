import { sleepClinicService } from '../_services';
import { sleepClinicConstant } from '../_constants';
import { errorHandling } from '../_helpers';

export const sleepClinicAction = {
    get
};


function get() {
    return dispatch => {        
        sleepClinicService.get()
            .then(
                res => {
                    dispatch({
                        type: sleepClinicConstant.GET,
                        clinicList: res.data
                    });
                },
                error => {
                    errorHandling('sleep-clinic.action.get', error, dispatch);
                }
            );
    };
}