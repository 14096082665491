import { userConstant } from '../_constants';

//let user = JSON.parse(sessionStorage.getItem('user'));
//const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = {}, action) {
    switch (action.type) {
        case userConstant.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };
        case userConstant.LOGIN_VERIFY:
            return {
                loggedIn: false,
                verifymfa: true
            };
        case userConstant.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user,
                isTried: true,
            };
        case userConstant.REFRESH:
            return {
                ...state,
                loggedIn: true,
                user: action.user,
                isTried: true
            }
        case userConstant.LOGIN_FAILURE:
            return {
                loggedIn: false,
            };
        case userConstant.LOGOUT:
        case userConstant.BACK_TO_DASHBOARD:
            return {};
        case userConstant.IS_LOGGING:
            return {
                isloggingIn: action.isLogging
            };
        case userConstant.IS_TRIED:
            return {
                isTried: true
            };
        case userConstant.UPDATE_EMAIL:
            return {
                ...state,
                user: {...state.user, Email:action.email},
            }
        case userConstant.SOUTH_VAN:
            return {
                ...state,
                user: action.user
            }
        case userConstant.UPDATE_FIRST_POPUP:
            return {
                ...state,
                user: {...state.user, Person:action.person}
            }
        case userConstant.UPDATE_PREFER_PHARMACY:
            return {
                ...state,
                user: { ...state.user, Person: action.person }
            }
        
        default:
            return state;
    }
}