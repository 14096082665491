import config from '../config';


export const isEmptyObj = (obj) => {
    if (Object.keys(obj).length === 0) {
        return true;
    } else {
        return false;
    }
};

export const calculatePrescriptionChargeV2 = (medications) => {
    // return 0.01;

    let cost = 0;

    if (medications) {
        const baseCost = 20;
        let extraCount = 0;

        if (medications.length > 0) {
            if (medications.length > 2) {
                extraCount = medications.length - 2;
            }

            cost = baseCost + extraCount * 10;
        }
    }

    return cost;
};

export const calculatePrescriptionChargeV3 = ( medications) => {
    let cost = 100;

    return cost;
}

export const calculatePrescriptionCharge = ( medications, isEligible) => {
    let cost = 0;
    let number = medications.length;
    if(isEligible){
        cost = 10 + 10 * number;
    } else {
        cost = 110 + 10 * number;
    }
    return cost;
}



export const calculatePrescriptionRefundV2 = (medications) => {
    const totalCharge = calculatePrescriptionCharge(medications);
    const approvedCharge = calculatePrescriptionCharge(medications.filter(o => o.status === 'Approved'));
    const refundAmount = totalCharge - approvedCharge;

    return refundAmount;
};

export const calculatePrescriptionRefund = (medications) => {
    const refundAmount = 100;

    return refundAmount;
}

export const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

// (604) 953-1700 => 16049531700
export const getFaxNumber = (number) => {
    number = number.replace('(', '');
    number = number.replace(')', '');
    number = number.replace(' ', '');
    number = number.replace(/-/g, '');
    number = `1${number}`;

    return number;
};

export const getLastObj = (array) => {
    let lastObj = null;

    // get the last object from the array
    if (array !== undefined && array.length > 0) {
        lastObj = [...array].pop();
    }

    return lastObj;
};

export const getS3ImageUrl = (key) => {
    const url = `${config.uploadBucket}/${key}`;
    return url;
};

export const setStringCapital = (str) => {

    if (typeof (str) !== 'undefined') {
        if(str.length === 0) return '';
        const newStr = str[0].toUpperCase() + str.slice(1);
        return newStr;
    } else {
        return;
    }

};

export const getFormattedPhoneNumber = (PhoneNumber) => {
    if (PhoneNumber) {
        PhoneNumber = PhoneNumber.replace(/\D/g, "");
        const len = PhoneNumber.length;
        if (len > 6)
            return '(' + PhoneNumber.slice(0, 3) + ') ' + PhoneNumber.slice(3, 6) + '-' + PhoneNumber.slice(6);

        if (len > 3)
            return '(' + PhoneNumber.slice(0, 3) + ') ' + PhoneNumber.slice(3);

        return PhoneNumber;
    } else {
        return '';
    }
}
