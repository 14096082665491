const PHNCheck = (phn) => {
    if(phn === undefined) {
        return false;
    }
    // let errMsg = '';
    if (phn.length != 10 && phn.length != 13) {
        return false;
    }
    let weightedArr = [2, 4, 8, 5, 10, 9, 7, 3];
    let i=1;
    let sum = 0;

    while (i < 9) {
        let reminder =  Math.floor((phn[phn.length-i-1] * weightedArr[weightedArr.length-i])%11);
        sum += reminder;
        i++;
    }
    
    let result = 11 - sum%11;

    
    if ( result == 10 || result == 11) {
        return false;
    }

    if (result != phn[9])
        return false;

    return true;
};

export {
    PHNCheck
};