import { BaseValidator } from '.';

class SleepInfoValidator extends BaseValidator {
    constructor(props) {
        super(props);
    }

    form = (form) => {
        const radioCheck = form.snore == null 
            || form.asleepBreathPause == null 
            || form.asleepChoke == null 
            || form.bloodPressureMedication == null 
            || form.cognitionDeteriorating == null 
            || form.gainWeight == null 
            || form.depression == null 
            || form.diabetes == null 
            || form.heartDisease == null 
            || form.atrialFibrillation == null ;

        this.validateField(radioCheck, 'Please select the answers from radio buttons.');
        this.validateField(form.height <= 0, 'Height should greater than 0.');
        this.validateField(form.weight <= 0, 'Weight should greater than 0.');
        this.validateField(form.systolic < 50 || form.systolic > 400, 'Systolic should between 50 - 400.');
        this.validateField(form.diastolic < 20 || form.diastolic > 300, 'Diastolic should between 20 - 300.');

        // validate blood pressure readings
        const invalidSystolic = form.bloodPressures.filter(o => o.systolic < 50 || o.systolic > 400).length > 0;
        const invalidDiastolic = form.bloodPressures.filter(o => o.diastolic < 20 || o.diastolic > 300).length > 0;
        const missingMeasureDate = form.bloodPressures.filter(o => o.measureDate === null).length > 0;

        this.validateField(invalidSystolic, 'Systolic should between 50 - 400.');
        this.validateField(invalidDiastolic, 'Diastolic should between 20 - 300.');
        this.validateField(missingMeasureDate, 'Blood pressure measure date is required.');
    }
}

export { SleepInfoValidator };