import { medicationFilterService } from '../_services';
import { errorHandling } from '../_helpers';
import { medicationFilterConstant } from '../_constants';
import { alertAction } from '../_actions';

export const medicationFilterAction = {
    get,
    change,
    create,
    importData,
    remove
};

function get() {
    return dispatch => {
        medicationFilterService.get()
            .then(
                res => {
                    dispatch({
                        type: medicationFilterConstant.GET_SUCCESS,
                        medicationFilterList: res.data
                    });
                },
                error => {
                    errorHandling('medication-filter.action.get', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function change(medicationFilter) {
    return {
        type: medicationFilterConstant.CHANGE,
        medicationFilter
    };
}

function create(tc_ahfs_number, status, activeList) {
    return dispatch => {
        return medicationFilterService.create(tc_ahfs_number, status, activeList)
            .then(
                res => {
                    dispatch({
                        type: medicationFilterConstant.CREATED,
                        medicationFilterList: res.data
                    });
                },
                error => {
                    errorHandling('medication-filter.action.create', error, dispatch);
                    throw(error);
                }
            )
            .finally(
            );
    };
}

function importData(formData) {
    return dispatch => {
        dispatch(alertAction.info('Start importing medication filter...'));

        medicationFilterService.importData(formData)
            .then(
                () => {
                    dispatch({
                        type: medicationFilterConstant.IMPORT_SUCCESS
                    });

                    dispatch(alertAction.success('Import medication filter successfully!'));
                },
                error => {
                    errorHandling('medication-filter.action.importData', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function remove(id) {
    return dispatch => {
        medicationFilterService.remove(id)
            .then(
                res => {
                    dispatch({
                        type: medicationFilterConstant.DELETED,
                        medicationFilterList: res.data
                    });
                },
                error => {
                    errorHandling('medication-filter.action.remove', error, dispatch);
                }
            )
            .finally(
            );
    };
}