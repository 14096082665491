import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { noteAction } from '../../_actions';
import { formatDateTime } from '../../_helpers';
import { PersonIcon } from '../../components';
import { multilineText } from '../../_helpers';


class NoteRevisionDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    handleClose = () => {
        const { dispatch } = this.props;

        dispatch(noteAction.closeRevision());
    }

    renderNoteRevision(obj, version) {
        return (
            <div key={obj._id} className="note-revision">
                <div className="text">
                    {multilineText(obj.text)}
                </div>
                <div className="footer text-primary">
                    <span>
                        <span>Signed by </span>
                        <PersonIcon person={obj.from} />
                        <span>, </span>
                        <span className="created-at">{`${formatDateTime(obj.createdAt)}`}</span>
                    </span>
                    <span>Rev {version}</span>
                </div>
            </div>
        );
    }

    renderContent() {
        const { note } = this.props;
        const versions = [...note.versions].reverse();

        if (versions) {
            return (
                versions.map((obj, index) => {
                    return this.renderNoteRevision(obj, versions.length - index);
                })
            );
        } else {
            return '';
        }
    }

    render() {
        const { note } = this.props;

        return (
            note ?
                <Dialog
                    className="note-revision-dialog"
                    fullWidth={true}
                    maxWidth="md"
                    open={true}
                    onClose={this.handleClose}
                >
                    <DialogTitle className="no-print">
                        <span>Note Revision History</span>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                            <i className="fa fa-close mx-2"></i>
                        </button>
                    </DialogTitle>
                    <DialogContent>
                        {this.renderContent()}
                    </DialogContent>
                </Dialog>
                :
                ''
        );
    }
}


NoteRevisionDialog.propTypes = {
    dispatch: PropTypes.func.isRequired,
    note: PropTypes.object
};


function mapStateToProps(state) {
    const { note } = state.note;

    return {
        note
    };
}

const temp = connect(mapStateToProps)(NoteRevisionDialog);

export { temp as NoteRevisionDialog };
