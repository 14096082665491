import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";
import { medicationFilterAction, medicationBillAction } from '../../_actions';
import { IconButton } from '../../components';
import { drugService } from '../../_services/drug.service';


class AddMedicationBill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            question:'',
            diagnosisCode:'',
            tc_ahfs_number:'',
        };
    }

    handleChange = (event) => {
       
        this.setState({
            [event.target.name]:event.target.value,
        })

    }


    handleAdd = (event) => {
        event.preventDefault();

        const { dispatch } = this.props;
        const { tc_ahfs_number, question, diagnosisCode} = this.state;

        dispatch(medicationBillAction.add(diagnosisCode, question,tc_ahfs_number ));
        this.setState({
            question:'',
            diagnosisCode:'',
            tc_ahfs_number:'',
        })
    }

    render() {
        return (
            <div>
                <div className="add-medication-filter row">
                    <form className="add-medication-filter__add-form mb-4" onSubmit={this.handleAdd}>
                        <div className="row">
                            <div className="col-3">
                                <label htmlFor="tc_ahfs_number">tc_ahfs_number</label>
                                <input className="form-control" type="text" id="tc_ahfs_number" name="tc_ahfs_number"
                                    value={this.state.tc_ahfs_number} onChange={this.handleChange} autoFocus required />
                            </div>
                            <div className="col-3">
                                <label htmlFor="diagnosis_code">Diagnosis code :</label>
                                <input className="form-control" type="text" id="diagnosis_code" name="diagnosisCode"
                                    value={this.state.diagnosisCode} onChange={this.handleChange} />
                            </div>
                            {/* <div className="col-3">
                                <label htmlFor="question">Question :</label>
                                <textarea className="form-control " name="question"
                                    id="question" value={this.state.question}
                                    onChange={this.handleChange}
                                >
                                </textarea>
                            </div> */}
                            {/* <div className="col-3 d-flex align-self-end"> */}
                            <div className="col-3 my-auto">
                                <IconButton type="submit" icon="fas fa-plus" className="btn btn-outline-secondary ">Add Or Update</IconButton>
                            </div>
                        </div>

                    </form>

                </div>
              
            </div>
        );
    }
}


AddMedicationBill.propTypes = {
    dispatch: PropTypes.func.isRequired,
};


function mapStateToProps(state) {
    let { medicationFilter, medicationFilterList = [] } = state.medicationFilter;

    if (medicationFilter === undefined) {
        medicationFilter = {
            tc_ahfs_number: '',
            status: '',
            activeList:[],
        };
    }

    if (medicationFilterList.length > 0) {
        medicationFilterList = medicationFilterList.map((medicationFilter) => {
            return {
                tc_ahfs_number: medicationFilter.tc_ahfs_number,
                status: medicationFilter.status,
                activeList: medicationFilter.activeIngredients,
            };
        });
    }

    return {
        medicationFilter,
        medicationFilterList
    };
}


const temp = connect()(AddMedicationBill);

export { temp as AddMedicationBill };