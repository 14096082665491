import { alertAction } from '.';
import { sleepInfoConstant } from '../_constants';
import { sleepInfoService } from '../_services';
import { errorHandling, history, getLastObj, getPersonId } from '../_helpers';


export const sleepInfoAction = {
    getByPerson,
    change,
    save,
    addBloodPressure,
    removeBloodPressure
};


const emptyReading = {
    systolic: '',
    diastolic: '',
    measure_date: null
};


function init(sleepInfoObj) {
    const noSleepInfo = sleepInfoObj === null;

    if (noSleepInfo) {
        sleepInfoObj = {
            snore: null,
            asleepBreathPause: null,
            asleepChoke: null,
            height: 0,
            weight: 0,           
            cognitionDeteriorating: null,
            gainWeight: null,
            depression: null,
            diabetes: null,
            heartDisease: null,
            atrialFibrillation: null,            
            bloodPressureMedication: null,
            bloodPressures: [{...emptyReading}],
            person: getPersonId()
        };
    }
    return {
        noSleepInfo,
        sleepInfoObj        
    };
}

function getByPerson(personId) {
    return dispatch => {
        sleepInfoService.getByPerson(personId)
            .then(
                res => {
                    const array = res.data;
                    const sleepInfoObj = getLastObj(array);
                    const actionObj = init(sleepInfoObj);

                    dispatch({
                        type: sleepInfoConstant.GET_BY_PERSON,
                        ...actionObj
                    });
                },
                error => {
                    errorHandling('sleep-info.action.getByPerson', error, dispatch);
                }
            );
    };
}

function change(sleepInfoObj) {
    return {
        type: sleepInfoConstant.CHANGE,
        sleepInfoObj
    };
}

function save(sleepInfoObj) {
    return dispatch => {
        sleepInfoService.save(sleepInfoObj)
            .then(
                res => {                   
                    dispatch({
                        type: sleepInfoConstant.SAVE_SUCCESS,
                        sleepInfoObj: res.data
                    });

                    dispatch(alertAction.success('Sleep Referral updated successfully.'));
                    history.goBack();
                },
                error => {
                    errorHandling('sleep-info.action.save', error, dispatch);
                }
            );
    };
}

function addBloodPressure(sleepInfoObj) {
    sleepInfoObj.bloodPressures.push({...emptyReading});

    return {
        type: sleepInfoConstant.CHANGE,
        sleepInfoObj
    };
}

function removeBloodPressure(sleepInfoObj) {
    sleepInfoObj.bloodPressures.pop();

    return {
        type: sleepInfoConstant.CHANGE,
        sleepInfoObj
    };
}
