import React from 'react';
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';
import config from '../config';


class PaypalButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showButton: false,
        };
    }

    renderButton() {
        window.paypal.Buttons({
            enableStandardCardFields: true,
            createOrder:  (data, actions) => {
                return actions.order.create({
                    intent: 'CAPTURE',
                    application_context: { 
                        shipping_preference: 'NO_SHIPPING' 
                    },
                    ...this.props.order //https://developer.paypal.com/docs/checkout/integration-features/standard-card-fields/
                });
            },
            onApprove: (data, actions) => {
                return actions.order.capture().then( (details) => {
                    // alert('Transaction completed by ' + details.payer.name.given_name);
                    this.props.onApprove(data, details);
                });
            },
            onError: (err) => {
                this.props.onError(err);
            }
        }).render('#paypal-button-container');
    }

    componentDidMount() {
        const {
            isScriptLoaded,
            isScriptLoadSucceed
        } = this.props;
        if (isScriptLoaded && isScriptLoadSucceed) {
            this.setState({ showButton: true }, () => {
                this.renderButton();
            });
        }
    }
    componentWillReceiveProps(nextProps) {
        const {
            isScriptLoaded,
            isScriptLoadSucceed,
        } = nextProps;
        const isLoadedButWasntLoadedBefore =
            !this.state.showButton &&
            !this.props.isScriptLoaded &&
            isScriptLoaded;
        if (isLoadedButWasntLoadedBefore) {
            if (isScriptLoadSucceed) {
                this.setState({ showButton: true }, () => {
                    this.renderButton();
                });
            }
        }
    }
    render() {
        return (
            <div id="paypal-button-container"></div>
        );
    }
}
export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${config.payPalClientId}&currency=CAD`)(PaypalButton);