import { teleplanConstant } from '../_constants';



export function teleplan(state = {}, action) {
    switch (action.type) {
        case teleplanConstant.OPEN_CHECK:
            return {
                ...state,
                isOpen:true,
                result:action.result
            };
        case teleplanConstant.CLOSE_CHECK:
            return {
                ...state,
                isOpen:false,
            }
        default:
            return state;
    }
}