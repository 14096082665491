import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';


class AgreementDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAgreenment: false,
            checked: false
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    // Pharmanet Consent is not scrollable
    static getDerivedStateFromProps(props) {
        if (props.title === 'PharmaNet Consent') {
            return { showAgreenment: true };
        }
        return null;
    }

    handleScroll(event) {
        const target = event.target;

        if (target.scrollHeight - Math.ceil(target.scrollTop) <= target.clientHeight/*target.scrollHeight - Math.floor(target.scrollTop) === target.clientHeight*/) {
            this.setState({
                showAgreenment: true
            });
        }
    }

    handleClose = () => {
        this.props.onClose(this.state.checked);
    };

    changeCheckbox = (event) => {
        this.setState({
            checked: event.target.checked
        });
    }

    renderContent = () => {
        return (
            <div className="content" onScroll={this.handleScroll}>
                {this.props.content}
            </div>
        );
    }

    renderAgreement() {
        const { checked, showAgreenment } = this.state;
        const { title, loggedIn } = this.props;
        const text = `I have read and accept the AskMedication.com ${title}`;

        if (loggedIn) {
            return (
                <div className="agreement custom-control custom-checkbox disabled">
                    <input type="checkbox" className="custom-control-input" checked={true} disabled />
                    <label className="custom-control-label">
                        {text}
                    </label>
                </div>
            );
        } else if (showAgreenment) {
            return (
                <div className="agreement custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="ckbPrivacyPolicy"
                        checked={checked} onChange={this.changeCheckbox} />
                    <label className="custom-control-label" htmlFor="ckbPrivacyPolicy">
                        {text}
                    </label>
                </div>
            );
        } else {
            return '';
        }
    }

    render() {
        const { title, loggedIn, open } = this.props;

        return (
            <Dialog id="agreement-dialog"
                fullWidth={true}
                maxWidth="md"
                open={open}
            >
                <DialogTitle>
                    {title}
                    {!loggedIn &&
                        <span className="text-info">
                            {`(Please read this document completely prior to agreeing with the ${title})`}
                        </span>
                    }
                </DialogTitle>
                <DialogContent>
                    {this.renderContent()}
                </DialogContent>
                <DialogActions className="action">
                    {this.renderAgreement()}
                    <span></span>
                    {loggedIn ?
                        <button className="btn btn-outline-secondary" onClick={this.handleClose}>Close</button>
                        :
                        <button className="btn btn-outline-secondary" onClick={this.handleClose}>Confirm</button>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

AgreementDialog.propTypes = {
    dispatch: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    loggedIn: PropTypes.bool,
    title: PropTypes.string,
    content: PropTypes.object
};


function mapStateToProps(state) {
    const { loggedIn } = state.authentication;

    return {
        loggedIn
    };
}

const temp = connect(mapStateToProps)(AgreementDialog);

export { temp as AgreementDialog };