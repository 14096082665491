import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'

import { teleplanService } from '../../_services/tele-plan.service';


class TeleFilePage extends Component {
    constructor() {
        super();
        this.state = { claimNumbers: []}
    }

    async componentDidMount() {
        const claimNumbers = await teleplanService.getClaimNumbers();
        this.setState({
            claimNumbers
        })
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Teleplan File</h1>
                </div>
                <div style={{fontSize: "20px"}}>
                    <div className="row">
                        <div className="col-md-4 text-center">Claims</div> 
                        <div className="col-md-4 text-center">Status</div>
                        <div className="col-md-4 text-center">Time</div>
                    </div>
                    <hr/>
                    {this.state.claimNumbers.map(claimNumber => {
                        return <div className="row">
                            <div className="col-md-4 text-center">{claimNumber.number}</div> 
                            <div className="col-md-4 text-center">
                                {claimNumber.createdAt 
                                    ? "Sent"
                                    : <button 
                                        className="btn btn-outline-secondary"
                                        onClick={async () => {
                                            const added = await teleplanService.sendUnsentBills();
                                            this.setState({
                                                claimNumbers: [added, ...this.state.claimNumbers.slice(1)]
                                            })
                                        }}
                                    >Send</button>
                                }
                            </div>
                            <div className="col-md-4 text-center">
                                {claimNumber.createdAt 
                                    ? moment(claimNumber.createdAt).format("YYYY-MMM-DD @ HH:mm")
                                    : ''
                                }
                            </div>
                        </div>
                    })}
                </div>
            </div>
        );
    }
}


TeleFilePage.propTypes = {
    dispatch: PropTypes.func.isRequired
};


const temp = connect()(TeleFilePage);

export { temp as TeleFilePage };

