import { drugService } from '../_services';
import { alertAction } from '../_actions';
import { drugSearchConstant } from '../_constants';
import { errorHandling } from '../_helpers';


export const drugSearchAction = {
    init,
    preload,
    change,
    search,
    selectDrug,
    showUsage,
    clearSearch,
    clearSearchKey,
    addDrug,
    removeDrug,
    updateDrugUsage,
    selectDrugAction
};

function init() {
    return {
        type: drugSearchConstant.INIT,
    };
}

function preload(drugs) {
    return {
        type: drugSearchConstant.PRELOAD,
        drugs
    };
}

function change(searchKey) {
    return {
        type: drugSearchConstant.CHANGE,
        searchKey
    };
}

/**
 * search the drug by name or ingredient
 */
function search(searchKey) {
    return dispatch => {
        dispatch(alertAction.info('Please wait or keep typing. Loading the medications...'));

        drugService.getByNameOrIngredient(searchKey)
            .then(
                res => {
                    const searchList = res.data;
                    dispatch(alertAction.clear());

                    if (searchList.length > 0) {
                        dispatch({
                            type: drugSearchConstant.SEARCH_SUCCESS,
                            searchList: res.data
                        });
                    } else {
                        dispatch(alertAction.warning('Medication was not found. Please check the name and try again.'));
                    }
                },
                error => {
                    if (!drugService.isCancel(error)) {
                        errorHandling('drug-search.action.search', error, dispatch);
                    }
                }
            );
    };
}

function selectDrug(drug) {
    return async dispatch => {
        try {
            const res = await drugService.getByName(drug.name);
            const multipleDrugs = res.data;
    
            if (multipleDrugs.length > 1) {
                dispatch({
                    type: drugSearchConstant.MULTIPLE_DRUG,
                    multipleDrugs,
                    drug
                });
            } else {
                dispatch(showUsage(drug));
            }
        } catch (error) {
            errorHandling('drug-search.action.selectDrug', error, this.props.dispatch);
        }
    };
}

function selectDrugAction(multipleDrugs, drug) {
    return {
        type: drugSearchConstant.MULTIPLE_DRUG,
        multipleDrugs,
        drug
    };
}

function showUsage(drug) {
    return {
        type: drugSearchConstant.SHOW_USAGE,
        drug
    };
}

function clearSearch() {
    return {
        type: drugSearchConstant.CLEAR_SEARCH
    };
}

function clearSearchKey() {
    return {
        type: drugSearchConstant.CLEAR_SEARCH_KEY
    };
}

function addDrug(drugs) {
    return dispatch => {
        dispatch({
            type: drugSearchConstant.ADD_DRUG,
            drugs
        });

        dispatch(clearSearch());
        dispatch(clearSearchKey());
    };
}

function removeDrug(drugs) {
    return {
        type: drugSearchConstant.REMOVE_DRUG,
        drugs
    };
}

function updateDrugUsage(drugs) {
    return {
        type: drugSearchConstant.UPDATE_DRUG_USAGE,
        drugs
    };
}