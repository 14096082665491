import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const medicationBillService = {
    getDiagnosisCode,
    getQuestion,
    createOrUpdate,
    get,
    remove, 
    add
};

function get() {
    const url = `${config.apiHost}/medication-bill`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function getDiagnosisCode(tc_ahfs_number) {
    const url = `${config.apiHost}/medication-bill/diagnosis-code/${tc_ahfs_number}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}
function getQuestion(tc_ahfs_number) {
    const url = `${config.apiHost}/medication-bill/question/${tc_ahfs_number}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}


function createOrUpdate(diagnosisCode, question, tc_ahfs_number) {
    const url = `${config.apiHost}/medication-bill`;
    const body = {
        diagnosisCode,
        question,
        tc_ahfs_number,
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, body);
}

function add(diagnosisCode, question, tc_ahfs_number) {
    const url = `${config.apiHost}/medication-bill/add`;
    const body = {
        diagnosisCode,
        question,
        tc_ahfs_number,
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, body);
}

function remove(id) {
    const url = `${config.apiHost}/medication-bill/${id}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.delete(url);
}