import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'

import { teleplanService } from '../../_services/tele-plan.service';
import { history } from '../../_helpers';


class InvoicePage extends Component {
    constructor() {
        super();
        this.state = {
            bill: null,
            isEdit: false
        }
    }

    async componentDidMount() {
        const { match } = this.props;
        const bill = await teleplanService.getBillById(match.params.id);
        this.setState({ bill });
    }

    render() {
        const bill = this.state.bill;
        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Invoice</h1>
                </div>
                {bill && <div>
                    <div className="row mb-2">
                        <div className="col-4">
                            <strong>First Name: </strong>
                            <div>{bill.person.FirstName}</div>
                        </div>
                        <div className="col-4">
                            <strong>Last Name: </strong>
                            <div>{bill.person.LastName}</div>
                        </div>
                        <div className="col-4">
                            <strong> BC Card: </strong>
                            <div>{bill.person.HealthDocuments[0].DocumentNumber}</div>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-4">
                            <strong>Diagnosis Code: </strong>
                            <div>{bill.diagnosisCode}</div>
                        </div>
                        <div className="col-4">
                            <strong>Service Code: </strong>
                            <div>{bill.serviceCode}</div>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-4">
                            <strong>Service Start: </strong>
                            <div>{bill.serviceStartTime}</div>
                        </div>
                        <div className="col-4">
                            <strong>Service End:</strong> 
                            <div>{bill.serviceEndTime}</div>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-4">
                            <strong>Bill Date: </strong> 
                            <div>{moment(bill.createdAt).format('YYYY-MM-DD')}</div>
                        </div>
                    </div>
                    
                    
                    <button
                        className="btn btn-outline-primary" 
                        onClick={() => {
                            history.push(this.props.match.url + '/edit')
                        }}
                    > Edit </button>
                    <button
                        className="btn btn-outline-secondary ml-2" 
                        onClick={() => {
                            history.goBack()
                        }}
                    > Back </button>
                </div>}
            </div>
        );
    }
}


InvoicePage.propTypes = {
    dispatch: PropTypes.func.isRequired
};


const temp = connect()(InvoicePage);

export { temp as InvoicePage };

