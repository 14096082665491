import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { validateFileUploader } from '../_helpers';
import { alertAction } from '../_actions';

class FileUploader extends Component {
    constructor(props) {
        super(props);

        // init
        this.state = {
            fileName: props.placeHolder || 'Choose file'
        };
    }

    handleChange = (event) => {
        const { dispatch, onChange, accept } = this.props;
        let files = event.target.files;       

        if (validateFileUploader(files, accept)) {
            const fileName = files.length === 1 ? files[0].name : `${files.length} files`;

            this.setState({
                fileName: fileName
            });
        } else {
            let errorMsg = 'Only allow uploading following file type: ';

            if (accept.indexOf('application/pdf') > -1) {
                errorMsg += 'pdf, ';
            }

            if (accept.indexOf('application/json') > -1) {
                errorMsg += 'json, ';
            }

            if (accept.indexOf('image/') > -1) {
                errorMsg += 'image, ';
            }

            if (accept.indexOf('.csv') > -1) {
                errorMsg += 'csv, ';
            }

            errorMsg = errorMsg.trim().slice(0, -1);

            dispatch(alertAction.error(errorMsg));
            
            event.target.value = '';            // reset the file
            files = undefined;
            this.setState({
                fileName: 'Choose file'
            });
        }

        onChange(files);
    }

    render() {
        const { id, multiple, accept } = this.props;
        return (
            <div className="custom-file">
                <input type="file" className="custom-file-input" id={id} name="file" multiple={multiple}
                    onChange={this.handleChange} accept={accept} />
                <label className="custom-file-label" htmlFor={id}>{this.state.fileName}</label>
            </div>
        );
    }
}


FileUploader.propTypes = {
    dispatch: PropTypes.func,
    onChange: PropTypes.func,
    id: PropTypes.string,
    multiple: PropTypes.bool,
    accept: PropTypes.string
};


const temp = connect()(FileUploader);

export { temp as FileUploader };