import { profileService } from '../_services';
import { profileConstant } from '../_constants';
import { loadingAction, alertAction } from './';
import { history, errorHandling } from '../_helpers';


export const profileAction = {
    get,
    change,
    editSection,
    updatePersonalInfo,
    updateHealthDoc,
    updateCredential,
    updateCredentialAction,
    updatePersonalInfoAndHealthDoc
};

function get() {
    return dispatch => {
        profileService.get()
            .then(
                res => {
                    dispatch ({
                        type: profileConstant.GET, 
                        user: res.data
                    });
                },
                error => {
                    errorHandling('profile.action.get', error, dispatch);
                }
            );
    };
}

function change(user) {
    return {
        type: profileConstant.CHANGE,
        user: user
    };
}

function editSection(section) {
    return {
        type: profileConstant.EDIT_SECTION,
        editSection: section
    };
}

function editSectionV2(section) {
    return {
        type: profileConstant.EDIT_SECTION_V2,
        editSection: section
    };
}

function updateCredential(user) {
    return dispatch => {
        dispatch(loadingOpen());

        profileService.updateCredential(user)
            .then(
                res => {
                    dispatch(success(res.data.user));                    
                    dispatch(editSection(undefined));
                    dispatch(alertAction.success('Update user credential successful'));
                },
                error => {
                    errorHandling('profile.action.updateCredential', error, dispatch);
                }
            )
            .finally(
                () => { dispatch(loadingClose()); }
            );
    };

    function success(user) { return { type: profileConstant.UPDATE_SUCCESS, user }; }
}

function updateCredentialAction(user) {
    return dispatch => {
        dispatch(loadingOpen());
        dispatch(success(user));                    
        dispatch(editSectionV2(undefined));
        dispatch(alertAction.success('Update user credential successful'));
        dispatch(loadingClose());
    };

    function success(user) { return { type: profileConstant.UPDATE_SUCCESS, user }; }
}



function updatePersonalInfo(formData) {
    return dispatch => {
        dispatch(loadingOpen());

        profileService.updatePersonalInfo(formData)
            .then(
                res => {
                    dispatch(success(res.data.user));                    
                    dispatch(editSection(undefined));
                    dispatch({type:profileConstant.GET, user:res.data.user});
                    dispatch(alertAction.success('Update personal information successful'));
                    dispatch(loadingClose());
                },
                error => {
                    errorHandling('profile.action.updatePersonalInfo', error, dispatch);
                    dispatch(loadingClose()); 
                }
            )
            // .finally(
            //     () => { dispatch(loadingClose()); }
            // );
    };

    function success(user) { return { type: profileConstant.UPDATE_SUCCESS, user }; }
}

function updateHealthDoc(formData) {
    return dispatch => {
        dispatch(loadingOpen());

        profileService.updateHealthDoc(formData)
            .then(
                res => {
                    dispatch(success(res.data.user));                    
                    dispatch(editSection(undefined));
                    dispatch(alertAction.success('Update health document successful'));
                },
                error => {
                    errorHandling('profile.action.updateHealthDoc', error, dispatch);
                }
            )
            .finally(
                () => { dispatch(loadingClose()); }
            );
    };

    function success(user) { return { type: profileConstant.UPDATE_SUCCESS, user }; }
}

function updatePersonalInfoAndHealthDoc (
    id, 
    firstName, lastName, dateOfBirth, 
    address, phoneNumber, bestNumber,
    docType, docNumber, docProvince,
    city, province, postalCode, email
) {
    return async (dispatch) => {
        try {
            const { res, southvanRes }= await profileService.updatePersonalInfoAndHealthDoc(
                id, 
                firstName, lastName, dateOfBirth,
                address, phoneNumber, bestNumber,
                docType, docNumber, docProvince,
                city, province, postalCode, email
            );

            // res; // call reducer to update person
            if (southvanRes.data.success == true){
                dispatch(alertAction.success('Update patient info successful'));
            } else {
                dispatch(alertAction.error('Update patient info in southvanmed failed'));
            }
           
            history.goBack();
            return;
        } catch (error) {
            errorHandling('profile.action.updatePersonalInfo', error, dispatch);
        }
    }
} 


function loadingOpen() { return loadingAction.open(); }
function loadingClose() { return loadingAction.close(); }