import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const sleepClinicService = {
    get
};


function get() {
    const url = `${config.apiHost}/sleep-clinic`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}
