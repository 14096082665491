import { messageAction, medicalInfoAction, problemAction } from './';
import { appService } from '../_services';
import { isPatient, errorHandling, getPersonId } from '../_helpers';
import { appConstant } from '../_constants';


export const appAction = {
    init,
    setLastLogin,
    closeLastLoginDialog
};

/**
 * Initiate the application with these actions
 */
function init() {
    return dispatch => {
        appService.access()
            .then(
                res => {
                    if (res) {
                        dispatch(messageAction.countUnread());
                        dispatch(problemAction.countUnread());

                        // patient
                        if (isPatient()) {
                            dispatch(medicalInfoAction.getByPerson(getPersonId()));
                        }
                    }
                },
                error => {
                    errorHandling('app.action.init', error, dispatch);
                }
            );
    };
}

function setLastLogin(lastLogin, user) {
    return {
        type: appConstant.SET_LAST_LOGIN, 
        lastLogin,
        user,
    };
}

function closeLastLoginDialog() { 
    return {
        type: appConstant.SET_LAST_LOGIN
    };
}