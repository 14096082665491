import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import CircularProgress from '@material-ui/core/CircularProgress';
import { officeInviteAction } from '../../_actions';
import { alertAction } from '../../_actions';
import { officeInviteService } from '../../_services';

const styles = () => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: 300
    },
    progress: {
        marginTop: 20,
        color: blue[500],
    }
});

class OfficeInvitePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Email: ''
        };

        this.submitForm = this.submitForm.bind(this);
    }

    renderInviteForm = () => {
        const { classes } = this.props;
        return (
            <TextField
                id="Email"
                label="Office Invite Email"
                className={classes.textField}
                type="email"
                name="Email"
                autoComplete="Email"
                margin="normal"
                variant="outlined"
                value={this.state.Email}
                onChange={this.handleChange}
                InputLabelProps={{ shrink: true }}
                required
            />
        );
    }

    renderButton() {
        const { loggingIn, classes } = this.props;

        return loggingIn ? <CircularProgress className={classes.progress} /> : 
            <button className="btn btn-lg btn-primary btn-block" type="submit"
                data-test-id="office-invite-page__submit-button"
            >Invite</button>
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    submitForm = async (event) => {
        event.preventDefault();

        const { dispatch } = this.props;

        try {
            const response = await officeInviteService.sendOfficeInvitation(
                this.state.Email
            );
            dispatch(alertAction.success(`${response.data.message}`, []));
            this.setState({ Email: '' });

        } catch (error) {
            this.props.alertDisplay(`An Office Invite could not be sent.`);
        }
    }

    render() {
        return (
            <div className="office-invite-page"
                data-test-id="office-invite-page"
            >
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Office Invite</h1>
                </div>
                <div>
                    <form className="form-signin" onSubmit={this.submitForm} noValidate>
                        {this.renderInviteForm()}
                        {this.renderButton()}
                    </form>
                </div>
            </div>
        );
    }
}


OfficeInvitePage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    alert: PropTypes.object,
    classes: PropTypes.object,
    sendingInvited: PropTypes.bool,
};


function mapStateToProps(state) {
    const { sendingInvited, message } = state.office;
    const { alert } = state;

    return {
        sendingInvited,
        message,
        alert
    };
}

const mapDispatchToProps = dispatch => {
    return {
        alertDisplay: (title = '') => dispatch(alertAction.error(title, [])),
        dispatch
    }
}

const temp = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OfficeInvitePage));

export { temp as OfficeInvitePage };
