import { manageDataService } from '../_services';
import { errorHandling } from '../_helpers';
import { manageDataConstant } from '../_constants';
import { alertAction } from './alert.action';

export const manageDataAction = {
    importData,
    displayData,
    buildDrugs
};

function importData(resource, formData) {
    return dispatch => {
        dispatch(alertAction.info(`Start importing ${resource}...`));

        manageDataService.importData(formData)
            .then(
                () => {
                    dispatch({
                        type: manageDataConstant.IMPORT_DATA_SUCCESS
                    });

                    dispatch(alertAction.success(`Import ${resource} successfully!`));
                },
                error => {
                    errorHandling('manage-data.action.import', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function displayData(resource, collection) {
    return dispatch => {
        dispatch(alertAction.info(`Start displaying ${resource}...`));

        manageDataService.displayData(collection)
            .then(
                res => {
                    dispatch({
                        type: manageDataConstant.DISPLAY_DATA_SUCCESS,
                        json: res.data
                    });

                    dispatch(alertAction.success(`Display ${resource}!`));
                },
                error => {
                    errorHandling('manage-data.action.displayData', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function buildDrugs() {
    return dispatch => {
        dispatch(alertAction.info('Start building Drugs...'));

        manageDataService.buildDrugs()
            .then(
                () => {
                    dispatch({
                        type: manageDataConstant.BUILD_DRUGS_SUCCESS
                    });

                    dispatch(alertAction.success('Build Drugs successfully!'));
                },
                error => {
                    errorHandling('manage-data.action.buildDrugs', error, dispatch);
                }
            )
            .finally(
            );
    };
}
