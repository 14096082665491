import { sleepClinicConstant } from '../_constants';


export function sleepClinic(state = {}, action) {
    switch (action.type) {
        case sleepClinicConstant.GET:
            return {
                clinicList: action.clinicList
            };
        default:
            return state;
    }
}