import React from 'react';

export const multilineText = (text) => {
    return text.split('\n').map((i, key) => {
        if (i === '') {
            return <br key={key} />;
        } else {
            return <div key={key}>{i}</div>;
        }
    });
};

// export const capitalizeFirstLetter = (text) => {
//     return text.charAt(0).toUpperCase() + text.slice(1);
// };