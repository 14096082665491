import React, { Component } from 'react';
import { connect } from 'react-redux';

import { history } from '../../_helpers';
import ToggleInput from './toggleInput';
import { DateOfBirth, ProvinceSelect } from '../../components'; 
import { ToggleDate } from './toggleDate';
import { ToggleProvince } from './toggleProvince';
import { profileAction } from '../../_actions/profile.action';


class PersonalInfo extends Component {
    onClickSave() {
        this.props.updatePersonalInfoAndHealthDoc(  
            this.props.id,
            this.refs.firstName.getValue(),
            this.refs.lastName.getValue(), 
            this.refs.dateOfBirth.getValue(),
            this.refs.address.getValue(),
            this.refs.phoneNumber.getValue(),
            this.refs.bestNumber.getValue(),
            this.props.docType,
            this.refs.docNumber.getValue(),
            this.props.docProvince,
            this.refs.city.getValue(),
            this.refs.province.getValue(),
            this.refs.postalCode.getValue(),
            this.refs.email.getValue(),
        );
    }
    onClickCancel() {
        history.goBack();
    }
    render() {
        const { 
            firstName = "", lastName = "", dateOfBirth = "", 
            address = "", phoneNumber = "", bestNumber = "",
            docType = "", docNumber = "", docProvince = "",
            city = "", province = "", postalCode = "",
            email = "",
        } = this.props;

        return <div>
            <div className="row">
                <div className="col-md-4 col-sm-6 mb-3">
                    <label className="title">First name</label>
                    <ToggleInput 
                        ref={"firstName"}
                        value={firstName}
                    />
                </div>
                <div className="col-md-4 col-sm-6 mb-3">
                    <label className="title">Last name</label>
                    <ToggleInput 
                        ref={"lastName"}
                        value={lastName}
                    />
                </div>
                <div className="col-md-4 col-sm-6 mb-3">
                    <label className="title">Date of Birth</label>
                    <ToggleDate 
                        ref={"dateOfBirth"}
                        value={dateOfBirth}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-6 mb-3">
                    <label className="title">{docType || "BC Card"}</label>
                    <ToggleInput 
                        ref={"docNumber"}
                        value={docNumber}
                    />
                </div>
                {/* {docType && <div className="col-md-4 col-sm-6 mb-3">
                    <label className="title">Card Province</label>
                    <ToggleProvince 
                        ref={"docProvince"}
                        value={docProvince}
                    />
                </div>} */}
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-6 mb-3">
                    <label className="title">Cell Phone Number</label>
                    <ToggleInput 
                        ref={"phoneNumber"}
                        value={phoneNumber}
                    />
                </div>
                <div className="col-md-4 col-sm-6 mb-3">
                    <label className="title">Best Phone Number</label>
                    <ToggleInput 
                        ref={"bestNumber"}
                        value={bestNumber}
                    />
                </div>
                <div className="col-md-4 col-sm-6 mb-3">
                    <label className="title"> Street Address</label>
                    <ToggleInput 
                        ref={"address"}
                        value={address}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-6 mb-3">
                    <label className="title">City</label>
                    <ToggleInput 
                        ref={"city"}
                        value={city}
                    />
                </div>
                <div className="col-md-4 col-sm-6 mb-3">
                    <label className="title">Province</label>
                    <ToggleInput 
                        ref={"province"}
                        value={province}
                    />
                </div>
                <div className="col-md-4 col-sm-6 mb-3">
                    <label className="title"> Postal Code</label>
                    <ToggleInput 
                        ref={"postalCode"}
                        value={postalCode}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-6 mb-3">
                    <label className="title">Email</label>
                    <ToggleInput 
                        ref={"email"}
                        value={email}
                    />
                </div>
            </div>
            <button 
                className="btn btn-outline-success mr-3" 
                onClick={() => this.onClickSave()}
            >Save</button>
            <button 
                className="btn btn-outline-success" 
                onClick={() => this.onClickCancel()}
            >Cancel</button>
                
            
        </div>
    }
}

function mapStateToProps(state) {
    const { person = {} } = state.patient;
    const { 
        FirstName       : firstName,
        LastName        : lastName,
        DateOfBirth     : dateOfBirth,
        HealthDocuments : documents,
        PhoneNumber     : phoneNumber,
        BestPhoneNumber : bestNumber,
        Address         : address,
        _id             : id,
        City            : city,
        Province        : province,
        PostalCode      : postalCode,
        email           : email,
    } = person;
    const {
        Type: docType = '', 
        DocumentNumber: docNumber = '',
        Province: docProvince = '',
    } = (documents || [])[0] || {};

    return {
        firstName, lastName, dateOfBirth, 
        phoneNumber, bestNumber, address,
        docType, docNumber, docProvince,
        id, city, province, postalCode, email,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updatePersonalInfoAndHealthDoc
            : async (id,
                    firstName, lastName, dateOfBirth,
                    address, phoneNumber, bestNumber,
                    docType, docNumber, docProvince,
                    city, province, postalCode, email
                ) => {
                    return await dispatch(profileAction.updatePersonalInfoAndHealthDoc(
                        id,
                        firstName, lastName, dateOfBirth,
                        address, phoneNumber, bestNumber,
                        docType, docNumber, docProvince,
                        city, province, postalCode, email
                    ))
                }
    }
}



PersonalInfo = connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
export { PersonalInfo }