import React, { Component } from 'react';


export default class ToggleInput extends Component {
    constructor(props) {
        super();
        this.state = {
            value: props.value,
            disabled: props.disabled
        };
        this.onChange = this.onChange.bind(this);
    }
    getValue() {
        return this.state.value;
    }
    onChange(e) {
        this.setState({
            value: e.target.value
        });
    }
    render() {
        return (
            <div className={this.props.className}>
                {
                    this.state.disabled ? 
                    <input 
                        disabled
                        type="text" className="form-control" 
                        value={this.state.value} onChange={this.onChange}
                    /> :
                    <input 
                        type="text" className="form-control" 
                        value={this.state.value} onChange={this.onChange}
                    />
                }
            </div>
        );
    }
}
