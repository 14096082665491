import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';

import { sleepAssessmentAction, faxAction, popupAction } from '../../../_actions';
import { paymentConstant } from '../../../_constants/payment.constant';
import Content from './content';


class SleepAssessmentDialogFirst extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleFax = () => {
        const { dispatch, sleepAssessmentObj } = this.props;
        dispatch(faxAction.queueSleepAssessmentFax(sleepAssessmentObj));
        this.handleClose();
    }

    handleRefund = () => {
        const { dispatch, sleepAssessmentObj: obj } = this.props;
        const action = sleepAssessmentAction.refund(obj.payment.chargeId, obj._id, obj.person._id);
        dispatch(popupAction.openDialog('Refund confirmation', 'You are about to refund the sleep assessment, are you sure?', action));
    }

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(sleepAssessmentAction.closeDialog());
    };

    displayContent = () => {
        const { sleepAssessmentObj, medicalInfoObj } = this.props;

        return <Content
            sleepAssessmentObj={sleepAssessmentObj}
            medicalInfoObj={medicalInfoObj}
        ></Content>
    }

    render() {
        const { openDialog } = this.props;

        return (
            openDialog ?
                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={true}
                    onClose={this.handleClose}
                >
                    <DialogTitle className="no-print">
                        Sleep Assessment
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                            <i className="fa fa-close mx-2"></i>
                        </button>
                    </DialogTitle>
                    <DialogContent>
                        {this.displayContent()}
                    </DialogContent>
                    <DialogActions className="no-print">
                        <button className="btn btn-outline-primary" onClick={this.handleFax}>Fax</button>
                        {!paymentConstant.IS_FREE && <button className="btn btn-outline-danger" onClick={this.handleRefund}>Refund</button>}
                    </DialogActions>
                </Dialog>
                :
                ''
        );
    }
}

SleepAssessmentDialogFirst.propTypes = {
    dispatch: PropTypes.func,
    onClose: PropTypes.func,
    sleepAssessmentObj: PropTypes.object,
    medicalInfoObj: PropTypes.object,
    openDialog: PropTypes.bool
};


function mapStateToProps(state) {
    const { medicalInfoObj } = state.medicalInfo;
    const { sleepAssessmentObj } = state.sleepAssessment;
    let { openDialog } = state.sleepAssessment;

    if (openDialog === undefined) {
        openDialog = false;
    }

    return {
        sleepAssessmentObj,
        medicalInfoObj,
        openDialog
    };
}

const temp = connect(mapStateToProps)(SleepAssessmentDialogFirst);

export { temp as SleepAssessmentDialogFirst };