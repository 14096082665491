import { BaseValidator } from './';


class DrugValidator extends BaseValidator {
    constructor(props) {
        super(props);
    }

    validateUsage = (usage, hideDuration = false) => {
        this.validateField(!usage.frequency, 'Please choose the frequency.');
        
        if (!hideDuration) {
            this.validateField(!usage.duration, 'Please choose the duration.');
        }

        const repeat = Number(usage.repeat);
        this.validateField(repeat > 100 || repeat < 0, 'Please repeat between 0 and 100');
    }
}


export { DrugValidator };