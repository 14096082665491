import * as Sentry from "@sentry/react";

import { userService, appService,southVanService } from '../_services';
import { userConstant } from '../_constants';
import { history } from '../_helpers';
import { loadingAction, appAction,alertAction } from './';
import { errorHandling } from '../_helpers';
import { locStore } from '../_helpers/locStore';
import config from '../config';
import { removeToken } from '../_helpers/session'; 


export const userAction = {
    login,
    logout,
    refreshPage,
    updateEmail,
    createSouthVanPerson,
    authenticateWithToken,
    goBackToDashboard,
    updateIsFirstLogInShowUp,
    updateFirstPopUp,
    openEchartByHin,
    verifyPhoneNumber,
    submitPhoneCode,
    updatePreferPharmacy
};

function login(username, password, role, code) {
    return async dispatch => {
        dispatch(request({ username }));
        dispatch(loadingOpen());

        let ipObj = null;
        if (config.isIp) {
            try {
                ipObj = await appService.getIpObj();
            } catch (error) {
                ipObj = {};
            }
        }
            
        let storedCode = null;
        if (role != "patient" && !code) {
            storedCode = locStore.getItem("code");
        }
        await userService.login(username, password, role, ipObj, code, storedCode)
            .then(
                res => {
                    if (res.data.verifymfa) {
                        dispatch(verifymfa());
                    } else {
                        const token = res.data.token;
                        const user = res.data.user;
                        const lastLogin = res.data.lastLogin;

                        // saving the user information on local storage
                        sessionStorage.setItem('token', token);
                        if (code)
                            locStore.setItem("code", code);
                        //sessionStorage.setItem('user', JSON.stringify(user));

                        dispatch(success(user));
                        dispatch(appAction.init());
                        dispatch(appAction.setLastLogin(lastLogin,user));
                        history.push('/dashboard');             // redirect to dashboard
                    }
                },
                error => {
                    errorHandling('user.action.login', error, dispatch);
                    dispatch(failure());
                    throw(error)
                }
            )
            .finally(
                () => { dispatch(loadingClose()); }
            );
    };

    function request(user) { return { type: userConstant.LOGIN_REQUEST, user }; }
    function success(user) { return { type: userConstant.LOGIN_SUCCESS, user }; }
    function failure() { return { type: userConstant.LOGIN_FAILURE }; }
    function verifymfa() { return { type: userConstant.LOGIN_VERIFY }; }
}

function logout() {
    sessionStorage.clear();
    
    return { type: userConstant.LOGOUT };
}

function loadingOpen() { return loadingAction.open(); }
function loadingClose() { return loadingAction.close(); }

function refreshPage() {
    return async dispatch => {
        try {
            dispatch(setIsLogging(true));
            const Email = await userService.getUserEmailByToken();
            const user = await userService.getUserByEmail(Email);
            dispatch(setIsLogging(false));
            dispatch(refresh(user));
        } catch (error) {
            if (((error || {}).code) === "INVALID_TOKEN")
                removeToken();

            dispatch(setIsLogging(false));
            dispatch(setIsTried())
            // throw (error)
        }
        
    }

    function refresh(user) { return { type: userConstant.REFRESH, user }; }

}

function setIsLogging(isLogging) {
    return { 
        type: userConstant.IS_LOGGING,
        isLogging
    }
}

function setIsTried() {
    return { 
        type: userConstant.IS_TRIED,
    }
}

function updateEmail(email) {
    return {
        type: userConstant.UPDATE_EMAIL,
        email
    }
}

function updateFirstPopUp(person){
    return {
        type: userConstant.UPDATE_FIRST_POPUP,
        person
    }
}

function updatePreferPharmacy(person){
    return {
        type: userConstant.UPDATE_PREFER_PHARMACY,
        person
    }
}





function goBackToDashboard() {
    return async dispatch => {
        dispatch(loadingAction.openWithStyle('loading-whiteoverlay'));
        sessionStorage.clear();
        dispatch({type: userConstant.BACK_TO_DASHBOARD});
        
    }
}

function authenticateWithToken(token) {

    return async dispatch => {
        let user;
        try{
            const obj = await southVanService.getUserInfo(token);
            // valid token
            // otherwise throw err
            sessionStorage.setItem('svm_dashboard_token',token);
            user = obj.data;           
            if (user) {
                user.isSouthVan = true;
                await dispatch(userAction.createSouthVanPerson(user)); 
            }
            dispatch(loadingClose())    

        }catch(error){
            Sentry.captureException(new Error(
                "Err: ERROR_CREATE_SOUTHVAN_PERSON"
                + ". error is " 
                + error.message 
                // + ". token is " 
                // + JSON.stringify(token) 
                + ". user is " 
                + JSON.stringify(user)
            ));
            logout();
            dispatch(loadingClose())
            window.location.href = config.southVanHost + "/login";
        }
    }

}

function createSouthVanPerson(southVanUser) {
    return   async dispatch => {
            await userService.createSouthVanPerson(southVanUser)
                  .then (
                      res => {
                        console.log('response from createSouthVanPerson',res)
                        const token = res.data.token;
                        const user = res.data.user;
                        sessionStorage.setItem('token', token);
                        sessionStorage.setItem('isSouthVan','true');
                        dispatch ({
                            type: userConstant.SOUTH_VAN,
                            user
                        });
                        dispatch(success(user));
                        dispatch(appAction.init());
                        dispatch(loadingClose());
                        history.push('/dashboard');  
                      },
                      error => {
                        errorHandling('user.action.create southvan person', error, dispatch);
                        console.log('error from createSouthVanPerson',error)
                        dispatch(loadingClose());
                        throw(error);
                      }
                  )
                  function request(user) { return { type: userConstant.LOGIN_REQUEST, user }; }
                  function success(user) { return { type: userConstant.LOGIN_SUCCESS, user }; }
                  function failure() { return { type: userConstant.LOGIN_FAILURE }; }
                  function verifymfa() { return { type: userConstant.LOGIN_VERIFY }; }
    }
}

 function updateIsFirstLogInShowUp(personId){

    return async dispatch => {
    try {
         const res = await userService.updateIsFirstLogInShowUp(personId)
         const { person } = res.data;
         dispatch(userAction.updateFirstPopUp(person))

    } catch (error){
        console.log (error);
    }
  }
}

function openEchartByHin(hin) {
    return async dispatch => {
        try {

            let link = `https://southvan.kai-oscar.com:8443/oscar/demographic/demographiccontrol.jsp?search_mode=search_hin&keyword=${hin}&orderby=last_name%2C+first_name&dboperation=search_titlename&limit1=0&limit2=10&displaymode=Search&ptstatus=active`;

            window.open(link, '_blank');
            /*
            dispatch(loadingAction.open());
            let res = await southVanService.getDemographicByHin(hin);
            dispatch(loadingAction.close());
            // console.log(res);
            if(res.success){
                let providerNo = '1001';
                let demographicNo = res.payload;
                // let demographicNo = '69001';
                let link = `https://southvan.kai-oscar.com:8443/oscar/casemgmt/forward.jsp?action=view&demographicNo=${demographicNo}&providerNo=${providerNo}&providerName=Balint%20MDCM%2063473%20Budai&appointmentNo=&reason=Tel-Progress+Note&appointmentDate=&start_time=&apptProvider=null&providerview=null&msgType=null&OscarMsgTypeLink=null&noteId=null&forceNote=true`
                
                // console.log(link)
                window.open(link, '_blank');

                dispatch(alertAction.success('Echart open on another tab'))
                return;
            }

            dispatch(alertAction.error(res.payload.message || 'Server error'));
            */

        }catch (error) {
            // handling error;
            dispatch(loadingAction.close());
            dispatch(alertAction.error('Network error'))
            // throw error;
        }
        
    }
}

async function verifyPhoneNumber(personId){
    try {
         const res = await userService.verifyPhoneNumber(personId);
         return res;

    } catch (error){
        console.log(error);
    }
}


function submitPhoneCode(personId, verificationCode) {
    return async dispatch => {
        try {
            const res = await userService.submitPhoneCode(personId, verificationCode)
            if (res.data.activate == true) { 
                dispatch(alertAction.success('cell phone number is confirmed'));
                return res;
            } else {
                dispatch(alertAction.error('verification code is not correct, please check your phone and try again'));
                return res;
            }
        } catch (error) {
            errorHandling('user.action.verification code', error, dispatch);

        }
    }

}