import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { medicationFilterAction } from '../../_actions';
import { MedicationFilterTable, AddDrug } from './';
import { DrugSearchDumb } from '../../components/DrugSearchDumb';

class ManageDrugPage extends Component {
    constructor(props) {
        super(props);
       this.state = {
            time: '10:00',
          }
        this.setModalClose = this.setModalClose.bind(this)
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(medicationFilterAction.get());
    }
    setModalClose() {
        this.setState({
            isModal: false
        })
    }
    setModalOpen() {
        this.setState({
            isModal: true
        })
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Manage Drugs</h1>
                </div>
                <div>
                    <p>Please search a medication to edit. Or click on Add to create a medication directly</p>
                    <DrugSearchDumb 
                        onSelect={(drug) => this.setState({ searchDrug: drug })}
                        onClear={() => { 
                            this.setState({ searchDrug: null})
                        }}
                        isHideDrug={true}
                    ></DrugSearchDumb>
                    <div className="d-flex align-items-center">
                        {/* {this.state.drug && 
                            <del className='card'>
                                <div className='card-header'>
                                    {this.state.drug.name}
                                    <div>({(this.state.drug.administrations || [])[0]}/{(this.state.drug.formulations || [])[0]})</div> 
                                    <div>{
                                        this.state.drug.ingredients.map(
                                            (ing) => <span>{ing.ingredient_name + ' ' + ing.strength + ' ' + ing.strength_unit + '; '}</span>
                                        )
                                    }</div>
                                </div>
                            </del>
                        }

                        {this.state.drug && this.state.searchDrug && <div className='m-3'>{"=>"}</div>} */}
                        
                        {this.state.searchDrug 
                            && <div className='card'>
                                <div className='card-header'>
                                    {this.state.searchDrug.name}
                                    <div>({(this.state.searchDrug.administrations || [])[0]}/{(this.state.searchDrug.formulations || [])[0]})</div> 
                                    <div>{
                                        this.state.searchDrug.ingredients.map(
                                            (ing) => <span>{ing.ingredient_name + ' ' + ing.strength + ' ' + ing.strength_unit + '; '}</span>
                                        )
                                    }</div>
                                </div>
                            </div>
                        } 
                    </div>
                    {this.state.searchDrug && <button 
                        onClick={() => {
                            this.setModalOpen();
                            this.setState({ drug: this.state.searchDrug });
                        }}
                        color="primary"
                        className="btn btn-outline-primary mt-3"
                    >
                        Edit
                    </button>}
                    <hr/>
                    <button
                        className="btn btn-outline-primary"
                        onClick={() => {
                            this.setModalOpen();
                            this.setState({ drug: null });
                        }} 
                        color="primary"
                    >
                        + Add
                    </button>


                    <Dialog
                        open={this.state.isModal}
                        onClose={this.setModalClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {(this.state.drug ? "Edit" : "Add") + " Drug"}
                        </DialogTitle>
                        <DialogContent>
                            <AddDrug 
                                drug={this.state.drug}
                                onSuccess={(drug) => {
                                    if (this.state.drug)
                                        this.setState({ searchDrug: drug })
                                    this.setModalClose();
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <button onClick={
                                () => {
                                    this.setModalClose()
                                    this.setState({
                                        drug: null
                                    })
                                }
                            } color="primary" className="btn btn-outline-primary">
                                Close
                            </button>
                        </DialogActions>
                    </Dialog>
                    
                    
                   
                </div>
            </div>
        );
    }
}


ManageDrugPage.propTypes = {
    dispatch: PropTypes.func.isRequired
};


const temp = connect()(ManageDrugPage);

export { temp as ManageDrugPage };

