import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";
import { medicationFilterAction } from '../../_actions';
import { IconButton } from '../../components';
import { drugService } from '../../_services/drug.service';


class AddDrug extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            administration_1:'',
            administration_2:'',
            administration_3:'',
            formulation_1:'' ,
            formulation_2:'', 
            formulation_3:'', 
            tc_ahfs_number_1:'', 
            tc_ahfs_number_2:'', 
            tc_ahfs_number_3:'',
            ingredient_name_1:'', 
            strength_1:'',
            strength_unit_1:'',
            ingredient_name_2:'', 
            strength_2:'', 
            strength_unit_2:'',
            ingredient_name_3:'',
            strength_3:'',
            strength_unit_3:'', 
            ingredient_name_4:'',
            strength_4:'',
            strength_unit_4:'',
            administrations: [],
            formulations: [],
            tc_ahfs_numbers: [],
            ingredients: [],
            drug_code: '',
            status: '',
            history_date: '',
            class_name: '',
            drug_identification_number: '',
            name: '',
            descriptor: '',
            number_of_ais: '',
            ai_group_no: '',
            company_name: '',
            administrations_index: 1,
            formulations_index: 1,
            tc_ahfs_numbers_index: 1,
            ingredients_index: 1,
            ...props.drug,
        };
        this.state.numOfAdministrations = 3;
        this.state.numOfFormulations = 3;
        this.state.numOfTcAhfsNumbers = 3;
        this.state.numOfIngredients = 5;

        if (props.drug && props.drug.administrations) {
            this.state.numOfAdministrations = props.drug.administrations.length;
            props.drug.administrations.forEach((a, ind) => {
                this.state[`administration_${ind + 1}`] = a;
            })
        }

        if (props.drug && props.drug.formulations) {
            this.state.numOfFormulations = props.drug.formulations.length;
            props.drug.formulations.forEach((a, ind) => {
                this.state[`formulation_${ind + 1}`] = a;
            })
        }

        if (props.drug && props.drug.administrations) {
            this.state.numOfAdministrations = props.drug.administrations.length;
            props.drug.administrations.forEach((a, ind) => {
                this.state[`administration_${ind + 1}`] = a;
            })
        }

        if (props.drug && props.drug.tc_ahfs_numbers) {
            this.state.numOfTcAhfsNumbers = props.drug.tc_ahfs_numbers.length;
            props.drug.tc_ahfs_numbers.forEach((a, ind) => {
                this.state[`tc_ahfs_number_${ind + 1}`] = a;
            })
        }

        if (props.drug && props.drug.ingredients) {
            this.state.numOfIngredients = props.drug.ingredients.length;
            props.drug.ingredients.forEach((a, ind) => {
                this.state[`ingredient_name_${ind + 1}`] = a.ingredient_name;
                this.state[`strength_${ind + 1}`] = a.strength;
                this.state[`strength_unit_${ind + 1}`] = a.strength_unit;
            })
        }
    }


    handleChange = (event) => {
        const { dispatch} = this.props;

        this.setState({
            [event.target.name] : event.target.value,
        })
        
        
    }

    handleAdd = (event) => {
        event.preventDefault();
    
        const { dispatch } = this.props;
        

        
    }

    onAdd = async (event) => {
        try {
            event.preventDefault();
            const {
                drug_identification_number,
                administration_1,
                administration_2,
                administration_3,
                ai_group_no,
                class_name,
                company_name,
                descriptor,
                drug_code,
                formulation_1,
                formulation_2,
                formulation_3,
                history_date,
                ingredient_name_1,
                strength_1,
                strength_unit_1,
                ingredient_name_2,
                strength_2,
                strength_unit_2,
                ingredient_name_3,
                strength_3,
                strength_unit_3,
                ingredient_name_4,
                strength_4,
                strength_unit_4,
                status,
                name,
                number_of_ais,
                id
            } = this.state;

            const drug = {};

            drug.administrations = [];
            for (let index = 0; index < this.state.numOfAdministrations; index++) {
                const administration = this.state[`administration_${index + 1}`];
                if (administration) drug.administrations.push(administration);
            }

            drug.drug_identification_number = drug_identification_number;
            drug.ai_group_no = ai_group_no;
            drug.class_name = class_name;
            drug.company_name = company_name;
            drug.descriptor = descriptor;
            drug.drug_code = drug_code;
            drug.name = name;
            drug.number_of_ais = number_of_ais;

            drug.formulations = [];
            for (let index = 0; index < this.state.numOfFormulations; index++) {
                const formulation = this.state[`formulation_${index + 1}`];
                if (formulation) drug.formulations.push(formulation);
            }

            drug.tc_ahfs_numbers = []
            for (let index = 0; index < this.state.numOfTcAhfsNumbers; index++) {
                const tc_ahfs_number = this.state[`tc_ahfs_number_${index + 1}`];
                if (tc_ahfs_number) drug.tc_ahfs_numbers.push(tc_ahfs_number);
            }

            drug.history_date = history_date;

            drug.ingredients = [];
            for (let index = 0; index < this.state.numOfIngredients; index++) {
                const ingredient = {
                    ingredient_name: this.state[`ingredient_name_${index + 1}`],
                    strength: this.state[`strength_${index + 1}`],
                    strength_unit: this.state[`strength_unit_${index + 1}`]
                }
                if (ingredient.ingredient_name && ingredient.strength && ingredient.strength_unit) 
                    drug.ingredients.push(ingredient);
            }

            drug.status = status;
            drug.id = id

            await drugService.addOneOrUpdate(drug);
            this.props.onSuccess(drug);
            alert("Succeed!");
        } catch (error) {
            alert("Error!");
        }
    }

    getActiveList() {
        return this.state.ingredients.filter((ingredient) => this.refs[ingredient].checked);
    }

    onConfirm = async () => {
        try {
         
            this.setState({ ingredients: [] });
        } catch (error) {
            console.log(error);
        }
    }

    renderIngredientCheck(name) {
        return <div className="custom-control custom-checkbox mt-1 ml-3">
            <input
                type="checkbox" name={name} id={name}
                className="custom-control-input"
                ref={name}
            />
            <label className="custom-control-label" htmlFor={name}>{name}</label>
        </div>
    }

    isRenderCheck() {
        return this.state.ingredients.length > 0;
    }

    renderCheck() {
        return <div>
            <strong>Check-marking an ingredient below makes it an exception to this filter:</strong>
            <div className= "row mb-3">
                {this.state.ingredients.map((name) => this.renderIngredientCheck(name))}
            </div>
            <div>
                <button
                    className="btn btn-outline-secondary mb-3 mt-2"
                    onClick={this.onConfirm}
                >
                    Confirm
                </button>
            </div>
        </div>
    }

    renderAdministrationList() {
        const list = [];
        for (let index = 0; index < this.state.numOfAdministrations; index++) {
            const element = <div className="col-4">
                <input 
                    className="form-control" 
                    type="text" 
                    name={`administration_${index + 1}`}
                    value={this.state[`administration_${index + 1}`]} 
                    onChange={this.handleChange}
                      
                />
            </div>
            list.push(element);
        }
        return list;
    }

    renderFormulationList() {
        const list = [];
        for (let index = 0; index < this.state.numOfFormulations; index++) {
            const element = <div className="col-4">
                <input 
                    className="form-control" 
                    type="text" 
                    name={`formulation_${index + 1}`}
                    value={this.state[`formulation_${index + 1}`]} 
                    onChange={this.handleChange}  
                />
            </div>
            list.push(element);
        }
        return list;
    }

    renderTcAhfsNumberList() {
        const list = [];
        for (let index = 0; index < this.state.numOfTcAhfsNumbers; index++) {
            const element = <div className="col-4">
                <input 
                    className="form-control" 
                    type="text" 
                    name={`tc_ahfs_number_${index + 1}`}
                    value={this.state[`tc_ahfs_number_${index + 1}`]} 
                    onChange={this.handleChange}
                      
                />
            </div>
            list.push(element);
        }
        return list;
    }

    renderIngredientList() {
        const list = [];
        for (let index = 0; index < this.state.numOfIngredients; index++) {
            const element = <div className= "row mb-3">
                <div className="col-4">
                    <label htmlFor="tc_ahfs_number">ingredient_name</label>
                    <input className="form-control" type="text" id="tc_ahfs_number" name={`ingredient_name_${index + 1}`}
                        value={this.state[`ingredient_name_${index + 1}`]} onChange={this.handleChange}   />
                </div>
                <div className="col-4">
                    <label htmlFor="tc_ahfs_number">strength</label>
                    <input className="form-control" type="text" id="tc_ahfs_number" name={`strength_${index + 1}`}
                        value={this.state[`strength_${index + 1}`]} onChange={this.handleChange}   />
                </div>
                <div className="col-4">
                    <label htmlFor="tc_ahfs_number">strength unit</label>
                    <input className="form-control" type="text" id="tc_ahfs_number" name={`strength_unit_${index + 1}`}
                        value={this.state[`strength_unit_${index + 1}`]} onChange={this.handleChange}   />
                </div>
            </div>
            list.push(element);
        }
        return list;
    }

    render() {
        const { administration_1, administration_2, administration_3, formulation_1, formulation_2, formulation_3, 
                tc_ahfs_number_1, tc_ahfs_number_2, tc_ahfs_number_3, ingredient_name_1, strength_1, strength_unit_1, 
                ingredient_name_2, strength_2, strength_unit_2, ingredient_name_3, strength_3, strength_unit_3, 
                ingredient_name_4, strength_4, strength_unit_4, drug_code, status, history_date,
                class_name, drug_identification_number, name, descriptor, number_of_ais, 
                ai_group_no, company_name } = this.state;
        return (
            <div>
                <div className="add-medication-filter row">
                    <form className="add-medication-filter__add-form mb-4" onSubmit={this.onAdd}>
                        <div>
                            <div className="row mb-3">
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">name</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="name"
                                        value={name} onChange={this.handleChange}   />
                                </div>
                            </div>
                            

                            <label>ingredients:</label>
                            <button 
                                className="btn btn-outline-secondary rounded-circle"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        numOfIngredients: this.state.numOfIngredients + 1
                                    })
                                }
                            }>+</button>
                            <button 
                                className="btn btn-outline-secondary rounded-circle"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        numOfIngredients: this.state.numOfIngredients - 1
                                    })
                                }
                            }>-</button>
                            {this.renderIngredientList()}


                            <label>administrations: </label>
                            <button 
                                className="btn btn-outline-secondary rounded-circle"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        numOfAdministrations: this.state.numOfAdministrations + 1
                                    })
                                }
                            }>+</button>
                            <button 
                                className="btn btn-outline-secondary rounded-circle"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        numOfAdministrations: this.state.numOfAdministrations - 1
                                    })
                                }
                            }>-</button>
                            <div className="row mb-3">
                                {this.renderAdministrationList()}
                                {/* <div className="col-4">
                                    <input className="form-control" type="text"  name="administration_1"
                                        value={administration_1} onChange={this.handleChange}   />
                                </div>
                                <div className="col-4">

                                    <input className="form-control" type="text" id="tc_ahfs_number" name="administration_2"
                                        value={administration_2} onChange={this.handleChange}  />
                                </div>
                                <div className="col-4">

                                    <input className="form-control" type="text" id="tc_ahfs_number" name="administration_3"
                                        value={administration_3} onChange={this.handleChange}  />
                                </div> */}
                            
                            </div>
                        </div>
                        <div>
                            <label>tc_ahfs_numbers: </label>
                            <button 
                                className="btn btn-outline-secondary rounded-circle"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        numOfTcAhfsNumbers: this.state.numOfTcAhfsNumbers + 1
                                    })
                                }
                            }>+</button>
                            <button 
                                className="btn btn-outline-secondary rounded-circle"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        numOfTcAhfsNumbers: this.state.numOfTcAhfsNumbers - 1
                                    })
                                }
                            }>-</button>
                            <div className="row mb-3">
                                {this.renderTcAhfsNumberList()}
                                {/* <div className="col-4">
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="tc_ahfs_number_1"
                                        value={tc_ahfs_number_1} onChange={this.handleChange}   />
                                </div>
                                <div className="col-4">

                                    <input className="form-control" type="text" id="tc_ahfs_number" name="tc_ahfs_number_2"
                                        value={tc_ahfs_number_2} onChange={this.handleChange}  />
                                </div>
                                <div className="col-4">

                                    <input className="form-control" type="text" id="tc_ahfs_number" name="tc_ahfs_number_3"
                                        value={tc_ahfs_number_3} onChange={this.handleChange}  />
                                </div> */}
                            </div>
                        </div>
                        <div>
                            <label>formulations: </label>
                            <button 
                                className="btn btn-outline-secondary rounded-circle"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        numOfFormulations: this.state.numOfFormulations + 1
                                    })
                                }
                            }>+</button>
                            <button 
                                className="btn btn-outline-secondary rounded-circle"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        numOfFormulations: this.state.numOfFormulations - 1
                                    })
                                }
                            }>-</button>
                            <div className= "row mb-3">
                                {this.renderFormulationList()}
                                {/* <div className="col-4">
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="formulation_1"
                                        value={formulation_1} onChange={this.handleChange}   />
                                </div>
                                <div className="col-4">

                                    <input className="form-control" type="text" id="tc_ahfs_number" name="formulation_2"
                                        value={formulation_2} onChange={this.handleChange}  />
                                </div>
                                <div className="col-4">

                                    <input className="form-control" type="text" id="tc_ahfs_number" name="formulation_3"
                                        value={formulation_3} onChange={this.handleChange}  />
                                </div> */}
                            </div>
                        </div>
                        <div>
                            <div className= "row mb-3">
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">drug_identification_number</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="drug_identification_number"
                                        value={drug_identification_number} onChange={this.handleChange}  />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">drug code</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="drug_code"
                                        value={drug_code} onChange={this.handleChange}   />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">status</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="status"
                                        value={status} onChange={this.handleChange}   />
                                </div>
                            </div>
                            <div className= "row mb-3">
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number"> history_date</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="history_date"
                                        value={history_date} onChange={this.handleChange}   />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">class_name</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="class_name"
                                        value={class_name} onChange={this.handleChange}   />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">number_of_ais</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="number_of_ais"
                                        value={number_of_ais} onChange={this.handleChange}   />
                                </div>
                            </div>
                            <div className= "row mb-3">
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">ai_group_no: </label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="ai_group_no"
                                        value={ai_group_no} onChange={this.handleChange}   />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">company_name</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="company_name"
                                        value={company_name} onChange={this.handleChange}   />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">descriptor</label>
                                    <input className="form-control" type="text" id="descriptor" name="descriptor"
                                        value={descriptor} onChange={this.handleChange}   />
                                </div>

                            </div>

                            {/* <div className= "row mb-3">
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">ingredient_name</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="ingredient_name_1"
                                        value={ingredient_name_1} onChange={this.handleChange}   />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">strength</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="strength_1"
                                        value={strength_1} onChange={this.handleChange}   />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">strength unit</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="strength_unit_1"
                                        value={strength_unit_1} onChange={this.handleChange}   />
                                </div>
                            </div>
                            <div className= "row mb-3">
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">ingredient_name</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="ingredient_name_2"
                                        value={ingredient_name_2} onChange={this.handleChange}   />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">strength</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="strength_2"
                                        value={strength_2} onChange={this.handleChange}   />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">strength unit</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="strength_unit_2"
                                        value={strength_unit_2} onChange={this.handleChange}   />
                                </div>
                            </div>
                            <div className= "row mb-3">
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">ingredient_name</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="ingredient_name_3"
                                        value={ingredient_name_3} onChange={this.handleChange}   />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">strength</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="strength_3"
                                        value={strength_3} onChange={this.handleChange}   />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">strength unit</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="strength_unit_3"
                                        value={strength_unit_3} onChange={this.handleChange}   />
                                </div>
                            </div>
                            <div className= "row mb-3">
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">ingredient_name</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="ingredient_name_4"
                                        value={ingredient_name_4} onChange={this.handleChange}   />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">strength</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="strength_4"
                                        value={strength_4} onChange={this.handleChange}   />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="tc_ahfs_number">strength unit</label>
                                    <input className="form-control" type="text" id="tc_ahfs_number" name="strength_unit_4"
                                        value={strength_unit_4} onChange={this.handleChange}   />
                                </div>
                            </div> */}
                        </div>



                        <div className="col-4 d-flex align-self-end">
                            <button type="submit" className="btn btn-outline-secondary">Submit</button>
                        </div>
                    </form>

                </div>
            </div>
        );
    }
}


AddDrug.propTypes = {
    dispatch: PropTypes.func.isRequired,
    medicationFilter: PropTypes.object
};


function mapStateToProps(state) {
    let { medicationFilter, medicationFilterList = [] } = state.medicationFilter;

    if (medicationFilter === undefined) {
        medicationFilter = {
            tc_ahfs_number: '',
            status: '',
            activeList: [],
        };
    }

    if (medicationFilterList.length > 0) {
        medicationFilterList = medicationFilterList.map((medicationFilter) => {
            return {
                tc_ahfs_number: medicationFilter.tc_ahfs_number,
                status: medicationFilter.status,
                activeList: medicationFilter.activeIngredients,
            };
        });
    }

    return {
        medicationFilter,
        medicationFilterList
    };
}
function mapDispatchToProps(dispatch) {
    return {
        create: (tc_ahfs_number, status, activeList) => dispatch(medicationFilterAction.create(tc_ahfs_number, status, activeList)),
        dispatch
    };
}

const temp = connect(mapStateToProps, mapDispatchToProps)(AddDrug);

export { temp as AddDrug };