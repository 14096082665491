import { medicalInfoService } from '../_services';
import { medicalInfoConstant } from '../_constants';
import { alertAction } from './alert.action';
import { getPersonId, errorHandling, isEmptyObj } from '../_helpers';


export const medicalInfoAction = {
    getByPerson,
    getById,
    change,
    save,
    updateCurrentTaken
};

function getByPerson(personId) {
    return dispatch => {
        medicalInfoService.getByPerson(personId)
            .then(
                res => {
                    const array = res.data;
                    const medicalInfoObj = getLatestMedicalInfo(array);
                    const pastMedicalInfoObjs = getPastMedicalInfo(array);
                    const actionObj = init(medicalInfoObj);

                    dispatch({
                        type: medicalInfoConstant.GET_BY_PERSON,
                        ...actionObj,
                        pastMedicalInfoObjs
                    });
                },
                error => {
                    errorHandling('medicalInfo.action.getByPerson', error, dispatch);
                }
            );
    };
}

function getById(id) {
    return dispatch => {
        medicalInfoService.getById(id)
            .then(
                res => {
                    const actionObj = init(res.data);

                    dispatch({
                        type: medicalInfoConstant.GET_BY_ID,
                        ...actionObj
                    });
                },
                error => {
                    errorHandling('medicalInfo.action.getById', error, dispatch);
                }
            );
    };
}

function init(medicalInfoObj) {
    const noMedicalInfo = isEmptyObj(medicalInfoObj);

    if (noMedicalInfo) {
        medicalInfoObj = {
            Person: getPersonId(),
            Sex: '',
            MedicalConditions: [],
            Surgeries: [],
            AllergyToMedication: [],
            NoMedicalCondition: false,
            NoSurgery: false,
            NoAllergy: false,
            MedicationsCurrentlyTaken: [],
            supplements: '',
            otherItems: '',
            AlcoholHistory: '',
            SmokingHistory: '',
            defaultPharmacy: null,
            otherMedicalConditions: null,
            otherSurgery: null,
            otherAllergy: null,
          
        };
    } else {
        medicalInfoObj.NoMedicalCondition = medicalInfoObj.MedicalConditions.length === 0 && medicalInfoObj.otherMedicalConditions === null;
        medicalInfoObj.NoSurgery = medicalInfoObj.Surgeries.length === 0 && medicalInfoObj.otherSurgery === null;
        medicalInfoObj.NoAllergy = medicalInfoObj.AllergyToMedication.length === 0 && medicalInfoObj.otherAllergy === null;
    }

    return {
        medicalInfoObj,
        noMedicalInfo
    };
}

function getLatestMedicalInfo(medicalInfoList) {
    let medicalInfoObj = {};

    // get the latest medicalInfo object
    if (medicalInfoList !== undefined && medicalInfoList.length > 0) {
        // medicalInfoObj = [...medicalInfoList].pop();
        medicalInfoObj = [...medicalInfoList][0];
    }

    return medicalInfoObj;
}

function getPastMedicalInfo(medicalInfoList) {
    let pastMedicalInfo = [];

    if (medicalInfoList !== undefined && medicalInfoList.length > 1) {
        pastMedicalInfo = [...medicalInfoList].slice(1);   
    }
    return pastMedicalInfo;
}

function change(medicalInfoObj) {
    return {
        type: medicalInfoConstant.CHANGE,
        medicalInfoObj: medicalInfoObj
    };
}

async function updateCurrentTaken(medicalInfoObj) {
    try {
        const res = await medicalInfoService.updateCurrentTaken(medicalInfoObj);

    } catch(error){
        console.log('update current taken:', error);
    }

}

function save(medicalInfoObj, callback = undefined) {
    return dispatch => {
        medicalInfoService.save(medicalInfoObj)
            .then(
                res => {
                    const actionObj = init(res.data);
                    
                    dispatch({
                        type: medicalInfoConstant.SAVE,
                        ...actionObj
                    });

                    if (callback) {
                        callback();
                    }

                    dispatch(alertAction.success('Medical information updated successfully.'));
                },
                error => {
                    errorHandling('manage-data.action.buildDrugs', error, dispatch);
                }
            );
    };
}