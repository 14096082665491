
import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const medicalInfoService = {
    getByPerson,
    getById,
    save,
    updateCurrentTaken
};

function getByPerson(personId) {
    const url = `${config.apiHost}/medicalinfo/person/${personId}`;

    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function getById(id) {
    const url = `${config.apiHost}/medicalinfo/${id}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function save(medicalInfoObj) {
    const url = `${config.apiHost}/medicalinfo/`;

    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, medicalInfoObj);
}

function updateCurrentTaken(medicalInfoObj) {
    const url = `${config.apiHost}/medicalinfo/update`;

    axios.defaults.headers.common['x-access-token'] = getToken();

    const body = {
        medicalInfoObj
    }

    return axios.post(url, body);

}