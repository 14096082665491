import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'
import DatePicker from 'react-datepicker';

import { teleplanService } from '../../_services/tele-plan.service';
import { billAction } from '../../_actions/bill.action';
import { history } from '../../_helpers';



class InvoicePageEdit extends Component {
    constructor() {
        super();
        this.state = {
            serviceCode: '',
            createdAt: '',
            isEdit: false
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    async componentDidMount() {
        const { match } = this.props;
        const bill = await teleplanService.getBillById(match.params.id);
        this.setState({ 
            createdAt: bill.createdAt,
            diagnosisCode: bill.diagnosisCode,
            serviceCode: bill.serviceCode,
            firstName: bill.person.FirstName,
            lastName: bill.person.LastName,
            bcCardNumber: bill.person.HealthDocuments[0].DocumentNumber,
            serviceStartTime: bill.serviceStartTime ? new Date(bill.serviceStartTime) : new Date(),
            serviceEndTime: bill.serviceEndTime? new Date(bill.serviceEndTime): new Date(),
            personId: bill.person._id,
            prescription: bill.prescription
        });
    }

    handleStartChange = value => {
        this.setState({
            serviceStartTime: value,
        })
    }

    handleEndChange = value => {
        this.setState({
            serviceEndTime: value,
        })
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Invoice</h1>
                </div>
                {this.state.createdAt && <div>
                    <div className="row mb-2">
                        <div className="col-4">
                            <strong>First Name: </strong>
                            <div>{this.state.firstName}</div>
                        </div>
                        <div className="col-4">
                            <strong>Last Name: </strong>
                            <div>{this.state.lastName}</div>
                        </div>
                        <div className="col-4">
                            <strong>BC Card: </strong>
                            <div>{this.state.bcCardNumber}</div>
                        </div>
                    </div>


                    <div className="row mb-2">
                        <div className="col-4">
                            <strong>Diagnosis Code:</strong>
                            <input 
                                className="form-control" type="text"
                                name="diagnosisCode"
                                value={this.state.diagnosisCode}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-4">
                            <strong>Service Code:</strong>
                            <select 
                                className="custom-select d-block w-100"
                                id="service_code" 
                                name="serviceCode" 
                                onChange={this.handleChange} 
                                value={this.state.serviceCode} 
                                required
                            >
                                <option value="">Choose...</option>
                                <option value="13237">T13237-Telehealth GP Visit (age 0-1)</option>
                                <option value="13437">T13437-Telehealth GP Visit (age 2-49)</option>
                                <option value="13537">T13537-Telehealth GP Visit (age 50-59)</option>
                                <option value="13637">T13637-Telehealth GP Visit (age 60-69)</option>
                                <option value="13737">T13737-Telehealth GP Visit (age 70-79)</option>
                                <option value="13837">T13837-Telehealth GP Visit (age 80+)</option> 
                            </select>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-4">
                            <strong>Service Start Time:</strong>
                            <DatePicker
                                selected={this.state.serviceStartTime}
                                onChange={this.handleStartChange}
                                showTimeSelect
                                dateFormat="MM/dd/yyyy h:mm aa"
                            />
                        </div>

                        <div className="col-4">
                            <strong>Service End Time:</strong>
                            <DatePicker
                                selected={this.state.serviceEndTime}
                                onChange={this.handleEndChange}
                                showTimeSelect
                                dateFormat="MM/dd/yyyy h:mm aa"
                            />
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-4">
                            <strong>Bill Date: </strong>
                            <div>{moment(this.state.createdAt).format('YYYY-MM-DD @ hh:mm a')}</div>
                        </div>
                    </div>

                    <button
                        className="btn btn-outline-primary" 
                        onClick={async () => {
                            const {
                                personId, 
                                prescriptionId, 
                                diagnosisCode, 
                                serviceCode, 
                                serviceStartTime, 
                                serviceEndTime
                            } = this.state;

                            await this.props.createBill({
                                personId, 
                                prescriptionId, 
                                diagnosisCode, 
                                serviceCode, 
                                serviceStartTime, 
                                serviceEndTime
                            });
                            history.push('/edit-invoice');
                        }}
                    > Save </button>
                    <button
                        className="btn btn-outline-secondary ml-2" 
                        onClick={() => {
                            history.goBack()
                        }}
                    > Cancel </button>
                </div>}
            </div>
        );
    }
}


InvoicePageEdit.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapDispatchToProps (dispatch) {
    return {
        createBill: (obj) => dispatch(billAction.createBill(obj))
    }
}

const temp = connect(null, mapDispatchToProps)(InvoicePageEdit);

export { temp as InvoicePageEdit };

