import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { badgeColor, statusText, formatDateTime, isPrivatePay } from '../_helpers';
import { DialogActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import { prescriptionAction } from '../_actions';
import { featureToggle } from '../_helpers/featureToggle';


    class StatusBadges  extends React.Component{
        constructor(props){
            super(props);
        }

        cancelPrescription = () => {
            const { dispatch, prescription } = this.props;
            dispatch(prescriptionAction.cancel(prescription));
        }

        render(){
            const { statusList } = this.props;
            return(
                <div className="status-badges">
            {
                statusList.map((o, index) => {
                    return <StatusBadge key={index} statusObj={o} cancelPrescription = {this.cancelPrescription} />;
                })
            }
        </div>

            )

        }
    }


StatusBadges.propTypes = {
    dispatch: PropTypes.func.isRequired,
    statusList: PropTypes.array
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

const temp = connect(mapDispatchToProps)(StatusBadges);


export {temp as StatusBadges};



class StatusBadge extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showCancelModal:false,
        };
    }

    cancelPrescription = () => {
        this.props.cancelPrescription(); 
        this.setState({
            showCancelModal:false,
        })       
   }

   handelCancelModalClose = () => {
       this.setState({
        showCancelModal:false,
       })
   }
   openCancelModal = (event) => {
       this.setState({
        showCancelModal:true,
       })
       event.stopPropagation();
   }
    CancelPrescriptionDialog = () => {
        return(
            <>
                <Dialog
                    fullWidth={true}
                    maxWidth="sm"
                    open={this.state.showCancelModal}
                >
                    <DialogContent>
                        <DialogContentText id="calcel-dialog-description">
                        Are you sure you want to cancel this prescription?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={this.cancelPrescription} 
                            color="primary"
                            style={{textTransform: "none"}}
                            data-test-id="status-badge__cancel-dialog__confirm-button"
                        >
                            Yes, I am sure
                        </Button>
                        <Button 
                            onClick={this.handelCancelModalClose} 
                            color="primary"
                            style={{textTransform: "none"}}
                        >
                              Don't Cancel Prescription
                        </Button>
                    </DialogActions>
                </Dialog>

            </>
        )
    }

     render() {
         const { statusObj } = this.props;
         if (statusObj == undefined || statusObj.text == undefined) return '';     // status either null or undefined

         const obj = {
             text: statusText[statusObj.text.toUpperCase()],
             color: badgeColor[statusObj.text.toUpperCase()],
             name: statusObj.createdBy ? `${statusObj.createdBy.FirstName} ${statusObj.createdBy.LastName}` : null,
             createdAt: statusObj.createdAt
         };
         return (
            //  <div className="status-badge">
            //      <span className={`badge badge-pill ${obj.color}`}>{obj.text === 'Pending' ? 'Pending doctor callback': obj.text}</span>
            //      {(featureToggle.cancelPrescription && obj.text === 'Pending' && !isPrivatePay()) &&
            //          <button 
            //             className="btn btn-danger ml-4" 
            //             onClick={this.openCancelModal}
            //             data-test-id="status-badge__cancel-button"
            //         >Cancel</button>
            //      }
            //      {this.CancelPrescriptionDialog()}
            //      {obj.name &&
            //          <span className="extra-info">
            //              by
            //         <span className="person">{obj.name}</span>
            //         at
            //         <span className="date">{formatDateTime(obj.createdAt)}</span>
            //          </span>
            //      }
            //  </div>
             <div></div>

         )
     }

}




StatusBadge.propTypes = {
    statusObj: PropTypes.object
};


export { StatusBadge };