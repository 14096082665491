import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { MedicalInfo } from './';
import { history } from '../../_helpers';

const styles = () => ({
    root: {
        marginTop: 20,
    }
});


class MedicalInfoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.handleEdit = this.handleEdit.bind(this);
    }

    handleEdit() {
        history.push('/medicalInfo/edit');
    }

    showMedicalInfo() {
        const { noMedicalInfo } = this.props;

        if (noMedicalInfo) {
            return (
                <div className="my-4">
                    No medical information, please add your Medical Information.
                </div>
            );
        } else {
            return <MedicalInfo />;
        }
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Medical Information</h1>
                </div>
                {this.showMedicalInfo()}
                <hr className="mb-4" />
                <div className="mb-3">
                    <button className="btn btn-primary btn-lg" onClick={this.handleEdit}>
                        {this.props.noMedicalInfo? 'Add' : 'Edit'}
                    </button>
                </div>
            </div>
        );
    }
}


MedicalInfoPage.propTypes = {
    noMedicalInfo: PropTypes.bool
};


function mapStateToProps(state) {
    const { noMedicalInfo, medicalInfoObj } = state.medicalInfo;

    return {
        noMedicalInfo,
        medicalInfoObj
    };
}

const temp = connect(mapStateToProps)(withStyles(styles)(MedicalInfoPage));

export { temp as MedicalInfoPage };

