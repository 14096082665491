import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AuditTable, AuditSearch } from './';


class AuditPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    renderAuditTable() {
        const { auditList } = this.props;

        if (auditList === undefined) {
            return 'Loading...';
        } else if (auditList.length > 0) {
            return <AuditTable />;
        } else {
            return (
                <div className="alert alert-warning" role="alert">
                    No audit records for this search, please update the filter and search again!
                </div>
            );
        }
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Audit</h1>
                </div>
                <div>
                    <AuditSearch />
                    {this.renderAuditTable()}
                </div>
            </div>
        );
    }
}


AuditPage.propTypes = {
    auditList: PropTypes.array
};


function mapStateToProps(state) {
    const { auditList } = state.audit;

    return {
        auditList
    };
}

const temp = connect(mapStateToProps)(AuditPage);

export { temp as AuditPage };

