import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Element, scroller } from 'react-scroll';
import ReactDOMServer from 'react-dom/server';
import { Provider } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { store } from '../../_helpers';
import { formatDate, calculatePrescriptionRefund, isDoctor } from '../../_helpers';
import { prescriptionAction, popupAction, billAction } from '../../_actions';
import { PrescriptionDialog } from './';
import { Medications, PharmacySelect, BillDialog } from '../../components';
import { paymentConstant } from '../../_constants/payment.constant';
import { featureToggle } from '../../_helpers/featureToggle';


class PendingElement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: "card",
            isEdit: false
        };
    }

    openPrescriptionDialog = (prescription) => {
        const { dispatch } = this.props;
        dispatch(prescriptionAction.openPrescriptionDialog(prescription, this.state.mode == "text"));
    }

    openBillDialog = (prescription, billWay) => {
        const { dispatch } = this.props;
        dispatch(billAction.openBillDialog(prescription, billWay));
        
    }

    renderAcknowledgeBtn(prescription) {
        if (prescription.statuses[0].text === 'Pending' && isDoctor()) {
            return (
                <button className="btn btn-warning btn-xs"  onClick={() => this.acknowledge(prescription)}
                    data-test-id="pending-element__acknowledge-button"
                >
                    Acknowledge
                </button>
            );
        } else {
            return '';
        }
    }

    renderBillBtn(prescription) {
        return <div className = "row float-right">
            <button 
                className="btn btn-sm btn-outline-primary ml-4 mr-2 mt-2" 
                onClick={() => this.openBillDialog(prescription, 'phone')}
            >
                Bill phone
            </button>
            <button 
                className="btn btn-sm btn-outline-primary ml-2 mr-2 mt-2" 
                onClick={() => this.openBillDialog(prescription, 'msg')}
            >
                Bill msg
            </button>
        </div>
    }

    acknowledge = (prescription) => {
        const { dispatch } = this.props;
        dispatch(prescriptionAction.acknowledge(prescription));
    }

    renderPrescriptionBtn(prescription) {
        const anyApproved = prescription.medications.find(o => o.status === 'Approved');
        const anyNonAction = prescription.medications.find(o => o.status == undefined);     // status either null or undefined
        const isFaxed = prescription.statuses.find(o => o.text === 'Faxed');

        if (anyApproved && !anyNonAction /*&& !isFaxed*/) {
            return (
               isDoctor() && <button className="btn btn-sm btn-outline-primary ml-2 mt-2" onClick={() => this.openPrescriptionDialog(prescription)}>
                    Prescription
                </button>
            );
        } else {
            return '';
        }
    }

    handleChange = (e) => {
        this.setState({mode: e.target.value})
    }

    getMedicationsString() {
        const { prescription } = this.props; 
        if (((prescription || {}).medications || []).length == 0)
            return "";

        const element = <Provider store={store}><Medications items={prescription.medications} allowEdit={true} prescription={prescription}></Medications></Provider>;
        const string = ReactDOMServer.renderToString(element);
        let stringNoTag = string.replace(/<[^>\/]+>/g, '');
        stringNoTag = stringNoTag.replace(/(<[^>]+>){1,3}/g, '\r\n');
        stringNoTag = stringNoTag.replace(/(<[^>]+>){3,}/g, '\r\n\r\n');
        return stringNoTag;
    }

    getText() {
        const { prescription } = this.props; 
        if (prescription.text)
            return prescription.text;

        return this.getMedicationsString();
    }
    handleRefund = (prescription) => {
        const { dispatch } = this.props;
        const action = prescriptionAction.refund(prescription);
        dispatch(popupAction.openDialog('Refund confirmation', 'You are about to refund one or more prescriptions, are you sure?', action));
    }
    renderRefundBtn(prescription) {
        const anyRejected = prescription.medications.find(o => o.status === 'Rejected');
        const anyNonAction = prescription.medications.find(o => o.status == undefined);     // status either null or undefined
        const isRefunded = prescription.statuses.find(o => o.text === 'Refunded');
        const refundAmount = calculatePrescriptionRefund(prescription.medications);

        if (anyRejected && !anyNonAction && !isRefunded && refundAmount > 0) {
            return (
                <button className="btn btn-sm btn-outline-danger mt-2" onClick={() => this.handleRefund(prescription)}>
                    {`Refund ($${refundAmount})`} 
                </button>
            );
        } else {
            return '';
        }
    }
      

    handlePharmacyChange = (event) => {
        if (event.target.value == null)
            return;

        const { dispatch, prescription } = this.props;
        prescription[event.target.name] = event.target.value;
        dispatch (prescriptionAction.updateDrPharmacy(prescription));
    }

    renderLeft() {
        const { prescription } = this.props;
        const pharmacy = prescription.pharmacy;
        const drPharmacy = prescription.drPharmacy;
        
        // let displayPharmacy = {...pharmacy};
        // if (this.state.mode == "card" && drPharmacy) 
        //     displayPharmacy = {...drPharmacy};

        if (["card", "patient"].indexOf(this.state.mode) != -1) {

            // if (this.state.mode == "patient")
            //     for (let index = 0; index < medications.length; index++) {
            //         const medication = medications[index];
            //         medication.changes = [];
            //         medication.usages = [medication.usages[0]];
            //     }
            return <div className="left-panel">
                <Medications 
                    items={prescription.medications}
                    allowEdit={this.state.mode != "patient"} 
                    action={this.state.mode != "patient"} 
                    prescription={prescription}
                    isOriginal={this.state.mode == "patient"}
                    isStatus={this.state.mode != "patient"}
                ></Medications>
                {/* <PharmacySelect id="drPharmacy" name="drPharmacy" 
                    value={{name: displayPharmacy.name + ' - ' + displayPharmacy.address}} 
                    onChange={this.handlePharmacyChange} 
                    isDeletable={this.state.mode != "patient"}
                /> */}
            </div>
        }
        if (this.state.isEdit)
            return <div className="left-panel">
                <TextareaAutosize 
                    inputRef={tag => (this.textarea = tag)}
                    style={{width: "100%"}}
                >
                    {this.getText()}
                </TextareaAutosize>
            </div>
                
        return <div className="left-panel">
            <div style={{whiteSpace: "pre-wrap"}}> {this.getText()} </div>
        </div>
    }

    renderEditSave() {
        return this.state.isEdit 
        ? <React.Fragment>
            <button 
                className="btn btn-sm btn-outline-primary ml-2 mt-2" 
                onClick={async () => {
                    await this.props.saveText(this.props.prescription._id, this.textarea.value);
                    this.setState({
                        isEdit: false
                    });
                }}
            >
                Save
            </button>
            {this.state.isEdit && <button 
                className="btn btn-sm btn-outline-primary ml-2 mt-2" 
                onClick={() => {
                    this.setState({
                        isEdit: false
                    })
                }}
            >
                {"Cancel"}
            </button>}
        </React.Fragment>
        : <button 
            className="btn btn-sm btn-outline-primary ml-2 mt-2" 
            onClick={() => {
                this.setState({
                    isEdit: true
                })
            }}
        >
            Edit
        </button>
    }

    render() {
        const { prescription } = this.props;

        return <React.Fragment>
            {this.renderLeft()}
            <div className="right-panel">
                <div className="created-at">
                    {formatDate(prescription.createdAt)}
                </div>
                <div className="prescription-id">
                    {prescription._id}
                </div>
                <FormControl component="fieldset" style={{textAlign: "-webkit-right"}}>
                    {/*<FormLabel component="legend">Mode</FormLabel>*/}
                    <RadioGroup aria-label="mode" name="mode" value={this.state.mode} onChange={this.handleChange}>
                        <FormControlLabel value="card" control={<Radio />} label="Update" />
                        {/*<FormControlLabel value="text" control={<Radio />} label="Text" />*/}
                        <FormControlLabel value="patient" control={<Radio />} label="Requested" />
                    </RadioGroup>
                </FormControl>
                <div className="action">
                    {this.state.mode == "text" && this.renderEditSave()}
                    {/* {!this.state.isEdit && this.renderAcknowledgeBtn(prescription)} */}
                    {prescription.payment && this.renderRefundBtn(prescription)}
                    {!this.state.isEdit && this.renderPrescriptionBtn(prescription)}
                    {/* {featureToggle.bill && this.renderBillBtn(prescription)} */}
                </div>
            </div>
        </React.Fragment>
    }
}


PendingElement.propTypes = {
    dispatch: PropTypes.func.isRequired,
    selectPrescriptionId: PropTypes.string,
    prescriptions: PropTypes.array
};


function mapStateToProps(state) {
    const { prescriptions, selectPrescriptionId, text } = state.prescription;
    return {
        prescriptions,
        selectPrescriptionId,
        text
    };
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        saveText: (id, text = '') => dispatch(prescriptionAction.saveText(id, text)),
    }
}


const temp = connect(mapStateToProps, mapDispatchToProps)(PendingElement);

export { temp as PendingElement };

