export const medicationBillConstant = {
    GET_LISTS_SUCCESS_DIAGNOSIS: 'MEDICATION_BILL_GET_LISTS_SUCCESS_DIAGNOSIS',
    GET_LISTS_SUCCESS_QUESTION: 'MEDICATION_BILL_GET_LISTS_SUCCESS_QUESTION',
    GET_LISTS_SUCCESS_ALL: "MEDICATION_BILL_GET_LISTS_SUCCESS_ALL",
    CREATED: 'MEDICATION_BILL_CREATED',
    DELETED: 'MEDICATION_BILL_DELETED',
    ADDED:'MEDICATION_BILL_ADDED',
    DIAGNOSIS_CODE_EXIST:"MEDICATION_BILL_DIAGNOSIS_CODE_EXIST",
    QUESTION_EXIST:"MEDICATION_BILL_QUESTION_EXIST",
};
