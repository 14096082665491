const validateFileUploader = (files, accept) => {
    // only pdf and image is allowed
    for (const file of files) {
        // check image
        if (file.type.indexOf('image/') > -1) {            
            if (accept.indexOf('image/') < 0) {
                return false;
            } else {
                continue;
            }
        }
        
        // check csv
        if (file.type === 'application/vnd.ms-excel' || file.type == 'text/csv') {            
            if (accept.indexOf('.csv') < 0) {
                return false;
            } else {
                continue;
            }
        }
        
        // check other type like pdf and json
        if (accept.indexOf(file.type) < 0) {            
            return false;
        }
    }

    return true;
};

export {
    validateFileUploader
};
