import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Element, scroller } from 'react-scroll';

import { sleepAssessmentAction, labtestAction } from '../../_actions';
import { formatDateTime } from '../../_helpers';


const CONTAINER_ID = 'pending-labtest';

class PendingLabtest extends Component {
    constructor(props) {
        super(props);
        const state = {};
        if (props.selectLabtestId)
            state.selectLabtestId = props.selectLabtestId;
        this.state = state;
    }

    componentDidMount() {
        const { selectLabtestId } = this.state;

        if (selectLabtestId) {
            // scroll to specific note
            scroller.scrollTo(selectLabtestId, {
                containerId: CONTAINER_ID,
                duration: 1,
                smooth: true,
                offset: -300
            });
        }
    }


    openSleepAssessmentDialog = (obj) => {
        const { dispatch } = this.props;
        dispatch(sleepAssessmentAction.openDialog(obj));
    }

    render() {
        const { pendingList } = this.props;
        const { selectLabtestId } = this.state;

        if (pendingList && pendingList.length > 0) {
            return (
                <div id={CONTAINER_ID}>
                    {pendingList.map(obj => {
                        let classes = 'labtest';
                        const selected = obj._id === selectLabtestId;
                        if (selected) classes += '--selected';
                        return (
                            <Element name={obj._id} key={obj._id} className={classes}>
                                <div>                                    
                                    <span className="created-date">
                                        {formatDateTime(obj.createdAt)}
                                    </span>
                                    {obj.items.map(it =>{
                                        return (
                                            <p>
                                            <span className="id">{`${it.group}, `}</span> 
                                            <span className="id">{it.name}</span>
                                            </p>
                                        )
                                    })}
                                </div>                                                                                             

                            </Element>
                        );
                    })}
                    {/* <SleepAssessmentDialog /> */}
                </div>
            );
        } else {
            return (
                <div className="mb-4">
                    No Lab test for this patient.
                </div>
            );
        }
    }

    // componentDidMount() {
    //     const { selectSleepAssessmentId } = this.state;

    //     if (selectSleepAssessmentId) {
    //         // scroll to specific note
    //         scroller.scrollTo(selectSleepAssessmentId, {
    //             containerId: CONTAINER_ID,
    //             duration: 1,
    //             smooth: true,
    //             offset: -300
    //         });
    //     }
    // }
}


PendingLabtest.propTypes = {
    dispatch: PropTypes.func.isRequired,
    pendingList: PropTypes.array
};


function mapStateToProps(state) {
    
    const { pendingList, selectLabtestId } = state.labtest;

    return {
        pendingList,
        selectLabtestId
    };
}

const temp = connect(mapStateToProps)(PendingLabtest);

export { temp as PendingLabtest };

