import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { teleplanAction } from '../_actions';
import { DialogActions } from '@material-ui/core';


const TeleplanCheckDialog = ({ dispatch, isOpen, result }) => {
   
    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={isOpen || false}
        >
            {/* <DialogTitle>
                <span>{title}</span>
            </DialogTitle> */}
            <DialogContent>
                <div style={{fontSize: 16}}>
                    {/* {(result || {}).isEligibile ? "Eligible" : "Not Eligible"} */}
                    {/* {result && result.reason} */}
                    {((result || {}).status == "valid") && "Eligible"}
                    {((result || {}).status == "invalid") && "Not Eligible"}
                    {((result || {}).status == "pending") && "Pending Eligibility"}
                    {((result || {}).other == "mismatch") && ". Name Mismatch"}
                    {((result || {}).other == "Invalid Birthdate") && ". Invalid Birthdate"}
                </div>
            </DialogContent>
            <DialogActions>
                <button className="btn btn-primary mt-4" onClick={() => dispatch(teleplanAction.closeDialog())}>Close</button>
            </DialogActions>
        </Dialog>
    );
};


TeleplanCheckDialog.propTypes = {
    dispatch: PropTypes.func,
    isOpen: PropTypes.bool,
    result: PropTypes.object,
};


function mapStateToProps(state) {
    const { isOpen, result  } = state.teleplan;

    return {
        isOpen,
        result
        
    };
}


const temp = connect(mapStateToProps)(TeleplanCheckDialog);

export { temp as TeleplanCheckDialog };