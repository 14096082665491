import debounce from 'debounce-promise';

import { prescriptionService, paymentService, profileService, labtestService } from '../_services';
import { prescriptionConstant, labtestConstant } from '../_constants';
import { alertAction, paymentAction, messageAction } from '.';
import { history, errorHandling, calculatePrescriptionCharge, calculatePrescriptionRefund } from '../_helpers';


export const labtestAction = {
    goToLabtest,
    getLabtestByPerson,
    setOpenLabtest,
    acknowledgeMessage,
  
};

function goToLabtest(selectLabtestId) {
    if (selectLabtestId){
        return {
            type: labtestConstant.GO_TO_LABTEST,
            selectLabtestId
        };
    } else {
        return {
            type: labtestConstant.SET_OPEN_LAB_TEST,
            isOpen: false
        }
    }  
}

function getLabtestByPerson(personId) {
    return dispatch => {
        labtestService.getLabtestByPerson(personId)
            .then(
                res => {
                    dispatch({
                        type: labtestConstant.GET_LAB_TEST_SUCCESS,
                        pendingList: res.data
                    });
                },
                error => {
                    errorHandling('labtest.action.getLabtestByPerson', error, dispatch);
                }
            );
    };
}

function setOpenLabtest(){
    return {
        type: labtestConstant.SET_OPEN_LAB_TEST,
        isOpen: false
    }
}

function acknowledgeMessage(personId, messageId, labtestId){
    return dispatch => {
        labtestService.acknowledge(personId, messageId, labtestId)
            .then(
                res => {
                    dispatch(messageAction.countUnread());
                    dispatch(getLabtestByPerson(personId));
                },
                error => {
                    errorHandling('labtest.action.acknowledge', error, dispatch);
                }
            );
    };
}