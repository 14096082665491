import axios from 'axios';
import config from '../config';


export const officeInviteService = {
    confirmOfficeToken,
    sendOfficeInvitation
};

function confirmOfficeToken(token) {
    const url = `${config.apiHost}/office/confirmToken?token=${token}`;
    return axios.get(url);
}

function sendOfficeInvitation(email) {
    const url = `${config.apiHost}/office/sendInvitation`;
    const body = {
        email: email,
    };
    return axios.post(url, body);
}