import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const manageDataService = {
    importData,
    displayData,
    buildDrugs
};

function importData(formData) {
    const url = `${config.apiHost}/manage-data/import-data`;
    const axiosConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data'
        }
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, formData, axiosConfig);
}

function displayData(collection) {
    const url = `${config.apiHost}/manage-data/display-data/${collection}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function buildDrugs() {
    const url = `${config.apiHost}/manage-data/build-drugs`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}