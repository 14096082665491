import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const auditService = {
    getPatients,
    getAccessedBy,
    get,
    create
};

function getPatients() {
    const url = `${config.apiHost}/audit/patients`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}
function getAccessedBy() {
    const url = `${config.apiHost}/audit/accessedBy`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function get(search) {
    const url = `${config.apiHost}/audit`;
    const body = {
        ...search,
        patient: search.patient ? search.patient.value : search.patient,
        accessedBy: search.accessedBy ? search.accessedBy.value : search.accessedBy,
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function create(patientId, accessedBy) {
    const url = `${config.apiHost}/audit`;
    const body = {
        patientId,
        accessedBy
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, body);
}
