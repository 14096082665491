import { sleepInfoConstant } from '../_constants';

export function sleepInfo(state = {}, action) {
    switch (action.type) {
        case sleepInfoConstant.GET_BY_PERSON:
        case sleepInfoConstant.GET_BY_ID:
            return {
                sleepInfoObj: { ...action.sleepInfoObj },
                noSleepInfo: action.noSleepInfo
            };
        case sleepInfoConstant.CHANGE:
            return {
                ...state,
                changed: true,
                sleepInfoObj: { ...action.sleepInfoObj }
            };
        case sleepInfoConstant.SELECT_CLINIC:
            return {
                ...state,
                selectedClinic: { ...action.selectedClinic }
            };
        default:
            return state;
    }
}