import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg';

import {  problemAction } from '../../_actions';
import { ReportProblemDialog } from './';


class Screenshot extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    handleClick = () => {
        const { dispatch } = this.props;

        dispatch(problemAction.capture(document));
    }

    hideIcon = () => {
        const { capturing, openPrescription } = this.props;

        // hide screen shot icon condition
        if (capturing || openPrescription ) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        return (
            <>
                <div id="screenshot-icon" className={`${this.hideIcon() ? 'hidden' : 'shown'}`} onClick={this.handleClick}>
                    {/* <i className="fas fa-bug" /> */}
                    {/* <ReactSVG 
                        src="svg.svg" 
                        afterInjection={(error, svg) => {
                            debugger
                            if (error) {
                              console.error(error)
                              return
                            }
                            console.log(svg)
                          }}
                          beforeInjection={(svg) => {
                            debugger
                            console.log(svg)
                          }}
                          loading={() => <span>Loading</span>}
                          renumerateIRIElements={false}
                          useRequestCache={false}
                    /> */}
                    <img 
                        style={{
                            width: "25px",
                            height: "25px"
                        }} 
                        src={require('../../assets/img/bug.png')}
                        alt="B"
                        
                    />
                    {/* <object data="svg.svg" type="image/svg+xml"></object> */}
                    <div className="description">
                        Suggest an improvement
                    </div>
                </div>
                <ReportProblemDialog />
            </>
        );
    }
}


Screenshot.propTypes = {
    dispatch: PropTypes.func.isRequired,
    personId: PropTypes.string,
    capturing: PropTypes.bool,
    openPrescription: PropTypes.bool
};


function mapStateToProps(state) {
    const { capturing } = state.problem;
    const { openPrescription } = state.prescription;

    return {
        capturing,
        openPrescription
    };
}

const temp = connect(mapStateToProps)(Screenshot);

export { temp as Screenshot };