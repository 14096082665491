import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';



import { DrugSearch } from '../../components';


class DrugSearchPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }

    // onDeleteMeClick = async () => {
    //     await userService.deleteUserById(this.props.userId);
    //     this.props.logOut();
    // }

    /**
     * Render the HTML template
     */
    render() {
        return (
            <div className="profile-page">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                    <h1 className="h2">Medication Search</h1>
                </div>
                <DrugSearch isCrossOut={true} isUsageHide={true}/>
                

            </div>
        );
    }
}


// ProfilePage.propTypes = {
//     dispatch: PropTypes.func.isRequired,
//     user: PropTypes.object
// };


function mapStateToProps(state) {
    let { user, status } = state.profile;
    const userId = state.authentication.user._id;

    if (status === 'changed') {
        user = undefined;
    }

    return {
        user,
        userId
    };
}

// function mapDispatchToProps(dispatch) {
//     return {
//         logOut: () => dispatch(userAction.logout()),
//         dispatch
//     };
// }

const temp = connect(mapStateToProps)(DrugSearchPage);

export { temp as DrugSearchPage };
