import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { connect } from 'react-redux';

import { BaseTable } from '../../components';
import { getPersonId, capitalizeFirstLetter } from '../../_helpers';
import { sleepInfoAction, sleepAssessmentAction } from '../../_actions';
import { PayButton } from '../Payment';
import { default as SleepContent } from '../Patient/SleepAssessmentDialogFirst/content';
import getCompUrl from '../../_helpers/getCompUrl';
import { paymentConstant } from '../../_constants/payment.constant';


class SleepClinicTable extends BaseTable {
    constructor(props) {
        super(props);
        this.state = {
            order: 'asc',
            // orderBy: 'address',
            orderBy:'name',
            page: 0,
            rowsPerPage: 10,
            open: false
        };
    }

    componentDidMount() {
        const { dispatch, sleepInfoObj } = this.props;

        if (sleepInfoObj === undefined) {
            dispatch(sleepInfoAction.getByPerson(getPersonId()));
        }
    }

    handlePay = async (token, clinicObj) => {
        const { dispatch, sleepInfoObj, person, medicalInfoObj } = this.props;
        const sleepAssessmentObj = {
            sleepInfo: sleepInfoObj,
            sleepClinic:clinicObj,
            person
        }
        const url = await getCompUrl(<SleepContent
            sleepAssessmentObj={sleepAssessmentObj}
            medicalInfoObj={medicalInfoObj}
            options={{
                isPreliminary:true,
                isAnonymous:true
            }}
        />);
        dispatch(sleepAssessmentAction.create(sleepInfoObj, clinicObj, token, url));
    }

    render() {
        const { clinicList, branchName } = this.props;
        const { order, orderBy, rowsPerPage, page } = this.state;

        if (clinicList) {
           const branchClinicList = clinicList.filter (clinic => clinic.name.toLowerCase().indexOf(branchName)> -1) ;
            return (
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.stableSort(branchClinicList, this.getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(obj => {
                                    const buttonText = "Select" + (paymentConstant.IS_FREE ? "" : " and Pay");
                                    global.paymentConstant = paymentConstant;
                                    return (
                                        <TableRow key={obj._id} className="row">
                                            <TableCell>
                                                {capitalizeFirstLetter(obj.name)}
                                            </TableCell>
                                            <TableCell>
                                                {obj.address}
                                            </TableCell>
                                            <TableCell >
                                                Tel: {obj.phoneNumber}
                                            </TableCell>
                                            <TableCell>
                                                <PayButton className="btn btn-sm btn-outline-primary" 
                                                    onPay={(token) => this.handlePay(token, obj)} 
                                                    clinicObj = {obj}
                                                >
                                                    { buttonText }
                                                </PayButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 20]}
                        component="div"
                        count={branchClinicList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </Paper>
            );
        } else {
            return '';
        }
    }
}


SleepClinicTable.propTypes = {
    dispatch: PropTypes.func,
    sleepInfoObj: PropTypes.object,
    clinicList: PropTypes.array
};


function mapStateToProps(state) {
    const { sleepInfoObj } = state.sleepInfo;
    const { clinicList } = state.sleepClinic;
    const { Person: person } = state.authentication.user
    const { medicalInfoObj } = state.medicalInfo;

    return {
        sleepInfoObj,
        clinicList,
        person,
        medicalInfoObj
    };
}

const temp = connect(mapStateToProps)(SleepClinicTable);

export { temp as SleepClinicTable };

