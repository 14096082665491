import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { icdService } from '../../_services';
import { errorHandling } from '../../_helpers';
import { alertAction } from '../../_actions';
import { BadgeItem } from '../../components';


class ICDSelect extends Component {
    constructor(props) {
        super(props);

        // init
        this.state = {
            searchList: [],
            searchKey: '',
            list: props.value
        };
    }

    handleChange = (event) => {
        this.setState({
            searchKey: event.target.value,
            isLoading: true
        });
    }

    handleSearch = (event) => {
        const searchKey = event.target.value;

        this.setState({
            searchList: []
        });

        if (searchKey !== '' && searchKey.length > 3) {
            icdService.getByName(searchKey)
                .then(
                    res => {
                        if (res.data.length > 0) {
                            this.setState({
                                searchList: res.data
                            });
                        }
                        this.setState({
                            isLoading: false
                        })
                    },
                    error => {
                        if (!icdService.isCancel(error)) {
                            errorHandling('ICDSelect.handleSearch', error, this.props.dispatch);
                        }
                        this.setState({
                            isLoading: false
                        })
                    }
                );
        }
    }

    handleSelect = (item) => {
        const list = this.state.list;

        // don't add duplicated item to the list
        const exist = list.find(o => o._id === item._id);

        if (exist === undefined) {
            const newList = [...list, item];             // es6 spread operator

            this.setState({
                searchList: [],
                searchKey: '',
                list: newList
            });
    
            // match the event.target structure
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: newList
                }
            });
        } else {
            this.props.dispatch(alertAction.warning('Medical condition is already existed!'));
        }
    }

    removeItem = (index) => {
        const list = this.state.list;

        list.splice(index, 1);

        this.setState({
            list
        });

        // match the event.target structure
        this.props.onChange({
            target: {
                name: this.props.name,
                value: list
            }
        });
    }

    renderInput() {
        return (
            <div className='row align-items-baseline search-input'>
                 <i className="fas fa-search ml-2"></i>
            <input type="text" className="form-control" placeholder="Search for a medical condition"
                value={this.state.searchKey} onKeyUp={this.handleSearch} onChange={this.handleChange} />
            </div>
        );
    }

    renderSearchList() {
        if (this.state.searchList.length > 0) {
            return (
                <ul>
                    {
                        this.state.searchList.map((item) => {
                            return (
                                <li key={item._id} onClick={() => this.handleSelect(item)}>
                                    {item.name}
                                </li>
                            );
                        })
                    }
                </ul>
            );
        } else if (
            this.state.searchKey 
            && this.state.searchKey.length > 3
            && !this.state.isLoading
        ) {
            return <ul><li className='text-danger'>Medical condition not found</li></ul>;
        } else {
            return '';
        }
    }

    renderList() {
        const { list } = this.state;
        
        if (list.length > 0) {
            return (
                <div className="list">
                    {list.map((item, index) => {
                        return (
                            <BadgeItem key={index} item={item.name} onClick={() => this.removeItem(index)}/>
                        );
                    })}
                </div>
            );
        }

        return '';
    }

    render() {
        return (
            <div id="icd-select" className='col-md-12'>
                {this.renderInput()}
                {this.renderSearchList()}
                {this.renderList()}
            </div>
        );
    }
}


ICDSelect.propTypes = {
    dispatch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    value: PropTypes.array
};


const temp = connect()(ICDSelect);

export { temp as ICDSelect };