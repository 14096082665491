import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { prescriptionAction } from '../../_actions';
import { formatDate, capitalizeFirstLetter } from '../../_helpers';

class InvoiceDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    print = () => {
        window.print();
    }

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(prescriptionAction.closeInvoiceDialog());
    };


    renderContent = () => {
        const { prescription, card } = this.props;

        if (prescription === undefined || card === undefined) return '';

        const { person, payment } = prescription;
        return (
            <div id="invoice-dialog">
                <div className="header">
                    <div className="banner">
                        <div className="logo">Invoice</div>
                        <div className="clinic">
                            <div className="clinic-name">
                                AskMedication Medical Clinic
                            </div>
                            <div className="clinic-address">
                                350 SE Marine Dr, Vancouver BC V5X 2S5, Canada
                            </div>
                            <div>
                                Tel: (604) 323-0077
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="title">
                        <div className="text">Official Prescription Receipt</div>
                        <div className="prescriptionId">({prescription._id})</div>
                    </div>
                    <div className="person">
                        <div className="name">{`${card.name.given_name} ${card.name.surname}`}</div>
                        <div>{person.Address}</div>
                        <div>{`${person.City}, ${person.Province}, ${person.PostalCode}`}</div>
                    </div>
                    <div className="price">
                        <div className="title">Pricing:</div>
                        <div className="charge">
                            <div>{`Charge: $${parseFloat(payment.chargeAmount).toFixed(2)}`}</div>
                        </div>
                        <div className="charge">
                            <div>{`Payment: $${parseFloat(payment.chargeAmount).toFixed(2)}`}</div>
                            <div>{formatDate(payment.chargedAt)}</div>
                        </div>
                        <div>
                            {`Payment type ${capitalizeFirstLetter(card.brand || "Card")} ending in ${card.last4 || "XXXX"}`}
                        </div>
                        {payment.refundAmount &&
                            <div className="refund">
                                <div>{`Refund: $${parseFloat(payment.refundAmount).toFixed(2)}`}</div>
                                <div>{formatDate(payment.refundedAt)}</div>
                            </div>
                        }
                        <div>
                            Balance: $0.00
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { openInvoice, prescription, card } = this.props;
        const isOpen = openInvoice && prescription !== undefined && card !== undefined;

        return (
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={isOpen}
                onClose={this.handleClose}
            >
                <DialogTitle className="no-print">
                    Invoice
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                        <i className="fa fa-close mx-2"></i>
                    </button>
                </DialogTitle>
                <DialogContent>
                    {this.renderContent()}
                </DialogContent>
                <DialogActions className="no-print">
                    <button className="btn btn-outline-secondary" onClick={this.print}>Print</button>
                </DialogActions>
            </Dialog>
        );
    }
}

InvoiceDialog.propTypes = {
    dispatch: PropTypes.func,
    prescription: PropTypes.object,
    openInvoice: PropTypes.bool,
    card: PropTypes.object,
};


function mapStateToProps(state) {
    const { prescription } = state.prescription;
    let { openInvoice } = state.prescription;
    const { card } = state.payment;

    if (openInvoice === undefined) {
        openInvoice = false;
    }

    return {
        prescription,
        openInvoice,
        card
    };
}

const temp = connect(mapStateToProps)(InvoiceDialog);

export { temp as InvoiceDialog };