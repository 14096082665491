import { billConstant } from '../_constants';

export function bill (state = {}, action) {
    switch (action.type) {
        case billConstant.OPEN_BILL_DIALOG:
            return {
                ...state,
                isOpen: true,
                prescription: { ...action.prescription },
                billWay: action.billWay,
            }
        case billConstant.CLOSE_BILL_DIALOG:
            return {
                ...state,
                isOpen: false,
            }
        default:
            return state;

    }
}