

const localStorage = window.localStorage;

export const locStore = {
    // Syntax for SAVING data to localStorage:
    setItem: (...args) => localStorage.setItem(...args),

    // Syntax for READING data from localStorage:
    getItem: (...args) => localStorage.getItem(...args),

    // Syntax for REMOVING data from localStorage:
    removeItem: (...args) => localStorage.removeItem(...args)
}