import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const appService = {
    access,
    getIpObj
};

function access() {
    const url = `${config.apiHost}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

async function getIpObj() {
    try {
        // throw ("something is wrong")
        const url = `${config.ipApi}`;
        delete axios.defaults.headers.common['x-access-token'];     // remove x-access-token property
        const res = await axios.get(url);
        const ipObj = res.data;
        return ipObj;
    } catch (error) {
        throw (error);
    }
}
