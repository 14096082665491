import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DrugValidator } from '../_validator';
import { alertAction } from '../_actions';
import { msgConstant } from '../_constants';

class DrugUsage extends Component {
    constructor(props) {
        super(props);

        this.state = this.getStateFromProps(props);
        this.addOrUpdate = this.addOrUpdate.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        // update prop usage value to state if change to the edit mode
        if (this.props.isEdit === false && nextProps.isEdit === true) {
            this.setState(this.getStateFromProps(nextProps));
        }
    }

    getStateFromProps(props) {
        if (props.usage) {
            return {
                quantity: props.usage.quantity || 'One',
                frequency: props.usage.frequency || '',
                asNeeded: props.usage.asNeeded || false,
                duration: props.usage.duration || '',
                frequencyOther: props.usage.frequencyOther || '',
                durationOther: props.usage.durationOther || '',
                quantityOther: props.usage.quantityOther || '',
                repeat: props.usage.repeat || '0',
                textLine: props.usage.textLine || '',
            };
        } else {
            return {
                quantity: 'One',
                frequency: '',
                asNeeded: false,
                duration: '',
                frequencyOther: '',
                durationOther: '',
                quantityOther: '',
                repeat:'0',
                textLine:'',
            };
        }
    }

    handleChange = (event) => {
        const { name, value, checked } = event.target;

        this.setState({
            [name]: name === 'asNeeded' ? checked : value
        });
    }

    addOrUpdate = (event) => {
        event.preventDefault();

        const { onAdd, onUpdate, hideDuration } = this.props;

        // validation
        const validator = new DrugValidator();
        validator.validateUsage(this.state, hideDuration);            // this.state is usage object

        if (validator.validate()) {
            if (onUpdate) {
                onUpdate(this.state);
            }

            if (onAdd) {
                onAdd(this.state);
            }
        } else {
            this.props.dispatch(alertAction.warning(msgConstant.PLEASE_CORRECT_ERRORS_BELOW, validator.errorMsgs));
        }
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    renderQuantity() {
        const { isEdit, isOther } = this.props;

        if (isEdit) {
            return (
                <>
                    <div className="title py-2">
                        Select how many units of medication you take at a time:
                    </div>
                    <select className="form-control" name="quantity" onChange={this.handleChange} value={this.state.quantity}>
                        {isOther && <option value="Other">See below</option>}
                        <option value="One">One</option>
                        <option value="One quarter">One quarter</option>
                        <option value="One half">One half</option>
                        <option value="One and a half">One and a half</option>
                        <option value="Two">Two</option>
                        <option value="Two and a half">Two and a half</option>
                        <option value="Three">Three</option>
                        <option value="Four">Four</option>
                        <option value="Five">Five</option>
                    </select>
                    {
                        this.state.quantity == "Other" 
                        && <input 
                            className="form-control" 
                            name="quantityOther" 
                            value={this.state.quantityOther}
                            placeholder="Input quantity..."
                            onChange={this.handleChange}
                        ></input>
                    }
                </>
            );
        } else {
            const { quantity, quantityOther } = this.props.usage;
            return <div>Use: {quantity == "Other" ? quantityOther : quantity}</div>;
        }
    }

    renderFrequency() {
        const { isEdit, isOther } = this.props;

        if (isEdit) {
            return (
                <>
                    <div className="title py-2">
                        Select how often you take your medication:
                    </div>
                    <select 
                        className="form-control" 
                        name="frequency" 
                        onChange={this.handleChange} 
                        value={this.state.frequency}
                        data-test-id="drug-usage__frequency"
                    >
                        <option value="">Choose the frequency</option>
                        {isOther && <option value="Other">See below</option>}
                        <option value="Once a day">Once a day</option>
                        <option value="Once in the morning">Once in the morning</option>
                        <option value="Once at night">Once at night</option>
                        <option value="Twice a day">Twice a day</option>
                        <option value="Three times a day">Three times a day</option>
                        <option value="Four times a day">Four times a day</option>
                        <option value="Every other day">Every other day</option>
                        <option value="Once a week">Once a week</option>
                        <option value="Once a month">Once a month</option>
                        <option value="Once every three months">Once every three months</option>
                        <option value="Once every six months">Once every six months</option>
                    </select>
                    {
                        this.state.frequency == "Other" 
                        && <input 
                            className="form-control" 
                            name="frequencyOther" 
                            value={this.state.frequencyOther}
                            placeholder="Input frequency..."
                            onChange={this.handleChange}
                        ></input>
                    }
                </>
            );
        } else {
            const { frequency, frequencyOther } = this.props.usage;
            return <div>Frequency: {frequency == "Other" ? frequencyOther : frequency}</div>;
        }
    }

    renderAsNeeded() {
        const { isEdit } = this.props;

        if (isEdit) {
            return (
                <div className="custom-control custom-checkbox mt-3">
                    <input type="checkbox" name='asNeeded' id='asNeeded' className="custom-control-input"
                        onChange={this.handleChange} checked={this.state.asNeeded} />
                    <label className="custom-control-label" htmlFor='asNeeded'>As Needed</label>
                </div>
            );
        } else {
            const { asNeeded } = this.props.usage;
            return asNeeded ? <div>As needed</div> : '';
        }
    }

    renderDuration() {
        const { isEdit, hideDuration, isOther } = this.props;

        if (hideDuration) return '';

        if (isEdit || this.state.isEditDuration) {
            return (
                <>
                    <div className="title py-2">
                        Select the duration of the prescription:
                    </div>
                    <select 
                        className="form-control" 
                        name="duration" 
                        onChange={this.handleChange} 
                        value={this.state.duration}
                        data-test-id="drug-usage__duration"
                    >
                        <option value="">Choose the duration</option>
                        {isOther && <option value="Other">See below</option>}
                        <option value="1 day">1 day</option>
                        <option value="2 days">2 days</option>
                        <option value="3 days">3 days</option>
                        <option value="4 days">4 days</option>
                        <option value="5 days">5 days</option>
                        <option value="6 days">6 days</option>
                        <option value="7 days">7 days</option>
                        <option value="1 week">1 week</option>
                        <option value="2 weeks">2 weeks</option>
                        <option value="3 weeks">3 weeks</option>
                        <option value="4 weeks">4 weeks</option>
                        <option value="5 weeks">5 weeks</option>
                        <option value="6 weeks">6 weeks</option>
                        <option value="7 weeks">7 weeks</option>
                        <option value="1 month">1 month</option>
                        <option value="2 months">2 months</option>
                        <option value="3 months">3 months</option>
                    </select>
                    {
                        this.state.duration == "Other" 
                        && <input 
                            className="form-control" 
                            name="durationOther" 
                            value={this.state.durationOther}
                            placeholder="Input duration..."
                            onChange={this.handleChange}
                        ></input>
                    }
                </>
            );
        } else {
            const { duration, durationOther } = this.props.usage;

            if (duration === '') {
                return <a 
                    className="text-danger"
                    ref={(el) => {
                        if (el) {
                            el.style.setProperty('color', 'red', 'important');
                            el.style.setProperty('font-size', 'large');
                            el.addEventListener("mouseover", (e) => {
                                el.style.setProperty('cursor','pointer');
                              });
                            
                        }
                    }}
                    onClick={() => { this.setState({ isEditDuration: true })}}
                >Add Duration</a>;
            } else {
                return(
                    <>
                 <div>Quantity/Duration: {duration == "Other" ? durationOther : duration}</div>
                 {/* <div>No Repeats</div> */}
                 </>
                )}
        }
    }

    renderRepeat() {
        const { isEdit, isOther } = this.props;

        if (isOther) {
            if (isEdit) {
                return (
                    <>
                        <div className="title py-2">
                            Number of repeats of this prescription:
                    </div>
                        <div>
                            <input
                                className="form-control"
                                type="number"
                                name="repeat"
                                value={this.state.repeat}
                                placeholder="Input repeat..."
                                onChange={this.handleChange}
                                min="0"
                                max="100"
                            />

                        </div>

                    </>
                )
            } else {
                const { repeat } = this.props.usage;

                return (
                    <div>{(repeat == '0' || typeof (repeat) == "undefined") ? 'No repeat' : repeat == "1" ? `${repeat} repeat` : `${repeat} repeats`}</div>
                )

            }

        }
    }

    renderFreeTextLine() {
        const { isEdit, isOther } = this.props;
        const { frequency, duration, quantity, textLine } = this.state;
        if (isOther) {
            if (isEdit) {
                if (frequency == 'Other' || duration == 'Other' || quantity == 'Other') {
                    return (
                        <div>
                            <div class="title"> Other notes </div>
                            <textarea 
                                style={{fontSize: "12px"}}
                                className="form-control"
                                name="textLine"
                                value={textLine}
                                onChange={this.handleChange}
                                maxlength="200" />
                        </div>
                    )
                } else {
                    return '';
                }

            } else {
                const { textLine } = this.props.usage;
                return (
                    <div> {textLine} </div>
                )
            }
        } else {
            return '';
        }
    }

    renderAction() {
        const { isEdit, onAdd, onUpdate, onCancel } = this.props;

        if (isEdit || this.state.isEditDuration) {
            return (
                <>
                    {onAdd &&
                        <button 
                            className="btn btn-outline-primary btn-xs mt-2 mr-2" 
                            onClick={this.addOrUpdate}
                            data-test-id="drug-usage__add"
                        >
                            Add
                        </button>
                    }
                    {(onUpdate || this.state.isEditDuration) &&
                        <button 
                            className="btn btn-outline-primary btn-xs mt-2 mr-2" 
                            onClick={(e) => {
                                this.addOrUpdate(e)
                                this.setState({ isEditDuration: false })
                            }
                        }>
                            Update
                        </button>
                    }
                    {onCancel &&
                        <button 
                            className="btn btn-outline-secondary btn-xs mt-2" 
                            onClick={(e) => {
                                this.handleCancel(e);
                                this.setState({ isEditDuration: false })
                            }}>
                            Cancel
                        </button>
                    }
                </>
            );
        } else {
            return '';
        }
    }

    render() {
        return (
            <div id="drug-usage">
                {this.renderQuantity()}
                {this.renderFrequency()}
                {this.renderAsNeeded()}
                {this.renderDuration()}
                {this.renderRepeat()}
                {this.renderFreeTextLine()}
                {this.renderAction()}
            </div>
        );
    }
}


DrugUsage.propTypes = {
    dispatch: PropTypes.func,
    isEdit: PropTypes.bool,
    onAdd: PropTypes.func,
    onUpdate: PropTypes.func,
    onCancel: PropTypes.func,
    usage: PropTypes.object,
    hideDuration: PropTypes.bool
};


const temp = connect()(DrugUsage);

export { temp as DrugUsage };