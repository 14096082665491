import * as Sentry from "@sentry/react";
import { 
    pharmacyConstant
 } from '../_constants';
import axios from 'axios';
import config from '../config';
import { userService, southVanService } from '../_services';
import { userAction  } from '../_actions';
import { getSouthVanToken } from '../_helpers';


const configAxios = {
    headers:{
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store, must-revalidate"
    }
};

export const getAllPharmacy = () => async dispatch => {

    try{
        // dispatch(setToLoading())
        let res = await axios.get(`${config.southVanApi}/pharmacy/all`);
        dispatch({   
            type:pharmacyConstant.GET_ALL_PHARMACY, 
            payload:res.data
        })
        // dispatch(setToLoaded())
        
    }catch(e){
        // dispatch(setToLoaded())
        console.log(e);
    }

}

export const addPreferredPharmacy = (data) => async dispatch => {
    try{
        // dispatch(setToLoading())
        let res = await axios.post(`${config.southVanApi}/pharmacy/addPreferredPharmacy`,data,configAxios);
        
        if(res.data.success){
            dispatch({
                type:pharmacyConstant.USER_LOADED,
                payload:res.data.extras.payload
            })
        }

        // dispatch(setToLoaded())
    }catch(err){
        // dispatch(setToLoaded())
        console.log(err);
    }
}


export const updatePreferredPharmacy = (preferredPharmacy, Person) => async dispatch => {
    try{
        const southVanMedToken = getSouthVanToken();
         let resAskmed = await userService.updatePreferredPharmacy( Person._id, preferredPharmacy, southVanMedToken );
         if (resAskmed.data.person){
             dispatch(userAction.updatePreferPharmacy(resAskmed.data.person))
         }

      

    } catch (err){
        console.log(err);
        Sentry.captureException(new Error(
            "Err: ERROR_UPDATE_PHARMACY"
            + ". error is " 
            + err
       
        ));
    }
}