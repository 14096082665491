import React,{useEffect} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {userAction,loadingAction} from '../../_actions';


import {isAuth} from '../../_helpers/authorized';
const Auth = props => {

    useEffect(() => {
        // console.log('use effect')
        const { match: { params },dispatch } = props;
        dispatch(loadingAction.openWithStyle('loading-whiteoverlay'))
        
        dispatch(userAction.authenticateWithToken(params.token))
    },[])

    // const {isAuthenticated} = props;
    if(isAuth()) return <Redirect to='/dashboard' />
    return (null)
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const mapStateToProps = state => ({
    // isAuthenticated: state.auth.isAuthenticated
})
const temp =  connect(mapStateToProps, mapDispatchToProps)(Auth);

export  {temp as CrossDomainAuth}