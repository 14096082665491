import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ReactImageMagnify from 'react-image-magnify';
import { isMobile } from "react-device-detect";

import { FileUploader } from './';
import { getS3ImageUrl } from '../_helpers';


class CustomImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDialog: false,
            isEdit: false
        };
        
        this.placeHolder = "Choose file";
        if (isMobile)
            this.placeHolder += " or camera";
    }

    handleOpenDialog = () => {
        this.setState({
            openDialog: true
        });
    }

    handleCloseDialog = () => {
        this.setState({
            openDialog: false
        });
    }

    handleEdit = () => {
        this.setState({
            isEdit: true
        });
        this.props.onEdit();
    }

    renderContent() {
        const { edit, onEdit, onChange, imageKey } = this.props;
        const { isEdit } = this.state;
        const url = getS3ImageUrl(imageKey);
        const fileUploader = <FileUploader id="PassportPicture" onChange={onChange} accept="application/pdf, image/*" placeHolder={this.placeHolder}/>;

        if (edit) {
            // edit mode
            return fileUploader;
        } else {
            // non edit mode
            if (onEdit) {
                // allow edit
                if (isEdit) {
                    return fileUploader;
                } else {
                    return (
                        <div>
                            <img src={url} crossOrigin="anonymous" />
                            <i className="fa fa-edit pointer" onClick={this.handleEdit}></i>
                        </div>
                    );
                }
            } else {
                // not allow edit
                return <img src={url} className="pointer" onClick={this.handleOpenDialog} crossOrigin="anonymous" />;
            }
        }
    }

    render() {
        const url = getS3ImageUrl(this.props.imageKey);

        return (
            <div id="custom-image">                
                {this.renderContent()}
                <ImageDialog url={url} title={this.props.title} isOpen={this.state.openDialog} onClose={this.handleCloseDialog} />
            </div>
        );
    }
}


CustomImage.propTypes = {
    title: PropTypes.string,
    imageKey: PropTypes.string,
    onEdit: PropTypes.func,
    onChange: PropTypes.func,
    edit: PropTypes.bool
};


export { CustomImage };


class ImageDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zoomIn: false
        };

        this.onImgLoad = this.onImgLoad.bind(this);
    }

    handleClose = () => {
        this.props.onClose();
        this.setState({
            zoomIn: false
        });
    }

    zoomIn = () => {
        this.setState({
            zoomIn: !this.state.zoomIn
        });
    }

    // get image dimension
    onImgLoad = ({ target: img }) => {
        this.setState({
            dimension: {
                height: img.offsetHeight,
                width: img.offsetWidth
            }
        });
    }

    renderZoomInContainer(url) {
        const { height, width } = this.state.dimension;

        return (
            <div className="zoom-in-container">
                <ReactImageMagnify {...{
                    smallImage: {
                        isFluidWidth: true,
                        src: url
                    },
                    largeImage: {
                        src: url,
                        width: width * 2.5,
                        height: height * 2.5
                    }
                }} />
            </div>
        );
    }

    displayContent = () => {
        const { url } = this.props;
        const { zoomIn } = this.state;

        return (
            <div id="image-dialog">
                <div className="text-center mb-3">
                    {zoomIn ?
                        this.renderZoomInContainer(url)
                        :
                        <img src={url} onLoad={this.onImgLoad} crossOrigin="anonymous" />
                    }
                </div>
                <div className="buttons">
                    <button className="btn btn-outline-secondary mr-3" onClick={this.handleClose}>Close</button>
                    <button className="btn btn-outline-secondary mr-3" onClick={this.zoomIn}>
                        {zoomIn ?
                            <i className="fas fa-search-minus"></i>
                            :
                            <i className="fas fa-search-plus"></i>
                        }
                    </button>
                    {/* <a href={url}>
                        <button className="btn btn-primary">Download</button>
                    </a> */}
                </div>
            </div>
        );
    }

    render() {
        const { isOpen } = this.props;

        return (
            isOpen ?
                <Dialog
                    maxWidth="xl"
                    open={isOpen}
                    onClose={this.handleClose}
                >
                    <DialogTitle className="no-print">
                        <span>{this.props.title || 'Image'}</span>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                            <i className="fa fa-close mx-2"></i>
                        </button>
                    </DialogTitle>
                    <DialogContent>
                        {this.displayContent()}
                    </DialogContent>
                </Dialog>
                :
                ''
        );
    }
}


ImageDialog.propTypes = {
    onClose: PropTypes.func,
    url: PropTypes.string,
    isOpen: PropTypes.bool,
    title: PropTypes.string
};