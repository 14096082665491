import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MessageTable, Message } from './';
import { messageConstant } from '../../_constants';


class Messages extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    displayMessages() {
        let { tabValue, messageList } = this.props;

        if (messageList === undefined) {
            return (
                <div>Loading...</div>
            );
        } else if (messageList === null) {
            return (
                <div>Server side Error!</div>
            );
        } else {
            messageList = messageList.filter((message) => message.from); 
            if (messageList.length == 0) {
                switch (tabValue) {
                    case messageConstant.TAB_INBOX:
                        return <div className="my-4">No messages sent to this user</div>;
                    case messageConstant.TAB_SENT:
                        return <div className="my-4">No messages sent from this user</div>;
                    case messageConstant.TAB_ARCHIVE:
                        return <div className="my-4">No archive messages</div>;
                }
            }
            else {
                return <MessageTable />;
            }
        }
    }

    render() {
        const { messageObj } = this.props;

        if (messageObj) {
            return <Message />;
        } else {
            return this.displayMessages();
        }
    }
}


Messages.propTypes = {
    tabValue: PropTypes.string,
    messageList: PropTypes.array,
    messageObj: PropTypes.object
};


function mapStateToProps(state) {
    const { tabValue, messageList, messageObj } = state.message;

    return {
        tabValue,
        messageList,
        messageObj
    };
}


const temp = connect(mapStateToProps)(Messages);

export { temp as Messages };

