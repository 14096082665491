import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { history, getPersonId } from '../../_helpers';
import { sleepInfoAction } from '../../_actions';
import { SleepInfo } from './';


class SleepInfoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const { dispatch, sleepInfoObj, changed } = this.props;

        // the sleepInfoObj is modified but didn't save
        if (changed || sleepInfoObj === undefined) {
            dispatch(sleepInfoAction.getByPerson(getPersonId()));
        }
    }

    handleEdit = () => {
        history.push('/sleep-info/edit');
    }

    handleAssessment = () => {
        history.push('/sleep-clinic');
    }

    renderSleepInfo() {
        const { sleepInfoObj, noSleepInfo } = this.props;

        if (sleepInfoObj === undefined) {
            return (
                <div className="my-4">
                    Please wait, loading the sleep info...
                </div>
            );
        } else if (noSleepInfo) {
            return (
                <div className="my-4">
                    No sleep info, please add your Sleep Info.
                </div>
            );
        } else {
            return <SleepInfo sleepInfoObj={sleepInfoObj} />;
        }
    }

    render() {
        const { noSleepInfo } = this.props;

        return (
            <div id="sleep-info-page">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Sleep Info</h1>
                </div>
                {this.renderSleepInfo()}
                <hr className="mb-4" />
                <div className="mb-3 d-flex justify-content-between">
                    <button className="btn btn-outline-secondary btn-lg" onClick={this.handleEdit}>
                        {noSleepInfo ? 'Add' : 'Edit'}
                    </button>
                    {!noSleepInfo &&
                        <button className="btn btn-outline-primary btn-lg" onClick={this.handleAssessment}>
                            Submit Assessment
                        </button>
                    }                    
                </div>
            </div>
        );
    }
}


SleepInfoPage.propTypes = {
    dispatch: PropTypes.func,
    sleepInfoObj: PropTypes.object,
    noSleepInfo: PropTypes.bool,
    changed: PropTypes.bool
};


function mapStateToProps(state) {
    const { sleepInfoObj, noSleepInfo, changed } = state.sleepInfo;

    return {
        sleepInfoObj,
        noSleepInfo,
        changed
    };
}

const temp = connect(mapStateToProps)(SleepInfoPage);

export { temp as SleepInfoPage };

