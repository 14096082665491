import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registrationAction } from '../../_actions';
import { AgreementDialog } from './AgreementDialog';
import Table from '@material-ui/core/Table';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import { profileConstant } from '../../_constants';



class FeeScheduleDialog extends React.Component {
    constructor(props) {
        super(props);
        
    }

    handleFeesModalOpen = () => {
        this.setState({ showFeesModal: true });
    };

    handleFeesModalClose = () => {
        this.setState({ showFeesModal: false });
    };


    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(registrationAction.closeFeeSchedule());
    };

    buildContent() {
        return (
            <>
                <div className="title">
                <div>{profileConstant.DOMAIN_NAME}</div>
                    <div>FEE SCHEDULE</div>
                </div>
                {/* <Table style={{fontSize:"200pt"}}>
                <TableBody >
                    <TableRow style={{fontSize:"200pt",fontWeight:"bold"}}>
                        <TableCell className="fee-box">Valid British Columbia Services Card</TableCell>
                        <TableCell className="fee-box">Free</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell className="fee-box">Not Canadian resident, Invalid B.C. Services Card, Quebec Health Insurance Card</TableCell>
                        <TableCell className="fee-box">$100</TableCell>
                    </TableRow>
                    
                </TableBody>
            </Table> */}
            <div>
                <p className="mbr-text pt-4 mbr-fonts-style display-7 price__content__text">

                    If you have a valid B.C. Services Card (CareCard): <br />
                    1 Prescription: $20  <br />
                    2 Prescriptions: $30 <br />
                    3 Prescriptions: $40 <br />
                    4 Prescriptions: $50 <br />
                    5 Prescriptions: $60 <br />

                    If you do not have a valid B.C. Services Card (CareCard):<br />
                    1 Prescription: $120 <br />
                    2 Prescriptions: $130 <br />
                    3 Prescriptions: $140 <br />
                    4 Prescriptions: $150 <br />
                    5 Prescriptions: $160 <br />
                </p>
            </div>
            <br/>
            </>
        );
    }

    render() {
        const { openFeeSchedule } = this.props;

        return (
            <AgreementDialog
                open={openFeeSchedule}
                title="Fee Schedule"
                content={this.buildContent()}
                onClose={() => this.handleClose()}
            />
        );
    }
}

FeeScheduleDialog.propTypes = {
    dispatch: PropTypes.func,
    openFeeSchedule: PropTypes.bool
};


function mapStateToProps(state) {
    let { openFeeSchedule } = state.registration;

    if (openFeeSchedule === undefined) {
        openFeeSchedule = false;
    }

    return {
        openFeeSchedule
    };
}

const temp = connect(mapStateToProps)(FeeScheduleDialog);

export { temp as FeeScheduleDialog };