import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { medicationFilterAction, medicationBillAction } from '../../_actions';
import { MedicationBillTable, AddMedicationBill} from '.';


class MedicationBillPage extends Component {
    constructor(props) {
        super(props);
       this.state = {
            time: '10:00',
          }
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(medicationBillAction.get());
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Medication ICD</h1>
                </div>
                <div>
                    <AddMedicationBill/>
                    <MedicationBillTable />
                </div>
            </div>
        );
    }
}


MedicationBillPage.propTypes = {
    dispatch: PropTypes.func.isRequired
};


const temp = connect()(MedicationBillPage);

export { temp as MedicationBillPage };

