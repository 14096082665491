import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogActions } from '@material-ui/core';
import moment from "moment";
import { Link } from 'react-router-dom';
import * as Sentry from "@sentry/react";

import { PharmacySelect, DrugSearch, PharmacySelectOscar } from '../../components';
import { history, getPersonId, isPrivatePay, env } from '../../_helpers';
import { prescriptionAction, alertAction, drugSearchAction, userAction } from '../../_actions';
import { updatePreferredPharmacy } from '../../_actions/pharmacyOscar.action';
import { PrescriptionFormValidator } from '../../_validator';
import { PrescriptionCost } from './';
import { drugService, prescriptionService, userService, southVanService } from '../../_services';
import { teleplanService, } from "../../_services/tele-plan.service";
import { prescriptionConstant } from '../../_constants/prescription.constant';
import { paymentConstant } from '../../_constants/payment.constant';
import RequestedPopup from "./requested-popup";
import config from '../../config';
import { msgConstant } from '../../_constants';
import { timers } from 'jquery';


class PrescriptionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenMessage: false,
            isSubmitting: false,
            isOpenVerifyPhoneNumber: false,
            phoneCode: '',
            isOpenBeforeVerify: false,
            isOpenAgreement: false,
            pharmacyList: this.props.list,
        };
        this.prescriptionId = null;
        this.submitForm = this.submitForm.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.list !== this.props.list) {
            this.setState({ pharmacyList: this.props.list })
        }


    }

    /**
     * Get the value from the inputs form
     */
    handleChange = (event) => {
        const { dispatch, prescriptionObj } = this.props;
        prescriptionObj[event.target.name] = event.target.value;
        dispatch(prescriptionAction.change(prescriptionObj));
    }

    changeCheckbox = (event) => {
        this.setState({
            [event.target.name]: event.target.checked,
        })
    }

    /**
     *  Get the code from the inputs form
     */
    handlePhoneCodeChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handlePhoneCodeClose = async (event) => {
        event.preventDefault();
        const { phoneCode } = this.state;
        const { dispatch, prescriptionObj } = this.props;
        const personId = getPersonId();
        const res = await dispatch(userAction.submitPhoneCode(personId, phoneCode));
        if (res && res.data.activate == true) {
            this.setState({
                isOpenVerifyPhoneNumber: false
            })
            this.checkOscarDemographic();
            // let prescription = await this.props.createAndPending(prescriptionObj);
            const isEligible = await this.isEligible();
            dispatch(prescriptionAction.create(prescriptionObj, isEligible));
            // if (prescription){
            //     this.setState({
            //         isOpenMessage: true
            //     });
            //     this.prescriptionId = prescription._id;
            // }

        }


    }

    /**
     * Get the drugs items from the component
     */
    handleMedicationsChanged = (medications) => {
        const { dispatch, prescriptionObj } = this.props;
        prescriptionObj.Medications = [...medications];
        dispatch(prescriptionAction.change(prescriptionObj));
    }

    loadMedication = async (event) => {
        const { dispatch, prescriptionObj, medicalInfoObj } = this.props;
        let newMedications;

        if (event.target.checked) {
            // remove the same medications in currentlyTaken, so the add won't be duplicated same drug
            const temp = medicalInfoObj.MedicationsCurrentlyTaken.filter(o => {
                if (prescriptionObj.Medications.find(m => m.name === o.name)) {
                    return false;
                } else {
                    return true;
                }
            });
            newMedications = [...prescriptionObj.Medications, ...temp];
        } else {
            // remove currentlyTaken from medications
            newMedications = prescriptionObj.Medications.filter(o => {
                if (medicalInfoObj.MedicationsCurrentlyTaken.find(m => m.name === o.name)) {
                    return false;
                } else {
                    return true;
                }
            });
        }

        const names = newMedications.map(({ name = '' }) => name);
        if (names.length > 0) {
            const withFilters = await drugService.getByNames(names);
            newMedications = newMedications.filter((med) => {
                const withFilter = withFilters.find((filter) => filter.name == med.name);
                return withFilter.filter[0].status != 'CROSS_OUT';
            })
        }

        dispatch(drugSearchAction.preload(newMedications));

        this.handleMedicationsChanged(newMedications);
    }

    validation() {
        const { prescriptionObj } = this.props;

        if (prescriptionObj.pharmacy === undefined) return false;
        if (prescriptionObj.pharmacy === null) return false;
        if (prescriptionObj.Medications === undefined) return false;
        if (prescriptionObj.Medications.length === 0) return false;
        if (prescriptionObj.Person === undefined) return false;

        const nonDurationDrug = prescriptionObj.Medications.filter(o => {
            const usage = [...o.usages].pop();

            if (usage.duration === '') {
                return true;
            } else {
                return false;
            }
        });

        if (nonDurationDrug.length > 0) return false;

        return true;
    }

    async isEligible() {
        if (isPrivatePay()) return false;
        // if (env() != "prod") return true;

        const { person } = this.props;
        if (person.HealthDocuments.length == 0) return false;

        const eligibility = (await teleplanService.eligibility(person.HealthDocuments[0].DocumentNumber, person.DateOfBirth, person._id)).data;
        return eligibility.status == "valid" || eligibility.status == "pending";
    }

    checkOscarDemographic() {
        const { user: { Email }, person, medicalInfoObj: { Sex } } = this.props;
        prescriptionAction.createOrFindOscarDemographic(person, Email, Sex);
    }

    updatePrvLastName() {
        const { person } = this.props;
        if (person.eligibility == "R" || person.eligibility == "NE") {
            prescriptionAction.updatePrvLastName(person);
        }

    }

    updateEmail() {
        const { person } = this.props;

        prescriptionAction.updateEmail(person);

    }



    displaybeforeVerify() {
        this.setState({
            isOpenBeforeVerify: true
        })
    }

    handleDisplayClose = async () => {

        this.setState({
            isOpenBeforeVerify: false,
            isOpenVerifyPhoneNumber: true
        })


    }

    verifyPhoneNumber() {
        return userService.verifyPhoneNumber(getPersonId());
    }

    renderPreferedPharmacy() {

        const { prescriptionObj } = this.props;
        if (this.state.pharmacyList.length == 0 || true) {
            return (
                prescriptionObj.pharmacy !== undefined && <div>
                    <p>Name: {prescriptionObj.pharmacy.name}</p>
                    <p>Address: {prescriptionObj.pharmacy.address.address}</p>
                    <p>Fax: {prescriptionObj.pharmacy.fax}</p>
                    <p>Phone: {prescriptionObj.pharmacy.phone1}</p>

                </div>
            )
        }

        return (
            prescriptionObj.pharmacy !== undefined && <PharmacySelectOscar
                id="pharmacy"
                pharmacyList={this.state.pharmacyList}
                onChange={this.handleChange}
                name="pharmacy"
                selectedPharmacy={prescriptionObj.pharmacy}
                className="form-control"

            />
        )
    }

    /**
     * Submit the form sending a post request to the API
     */
    submitForm = async () => {
        // event.preventDefault();
        this.setState({ isSubmitting: true });
        const { dispatch, prescriptionObj, person } = this.props;

        const validator = new PrescriptionFormValidator();
        validator.form(prescriptionObj);

        if (validator.validate()) {
            let prescription;

            const isEligible = await this.isEligible();

            if (!person.oscarDemographicId) {
                try {

                    this.checkOscarDemographic();
                    await dispatch(prescriptionAction.create(prescriptionObj, isEligible));

                } catch (error) {
                    Sentry.captureException(error);
                    this.checkOscarDemographic();
                    await dispatch(prescriptionAction.create(prescriptionObj, isEligible));
                }
            } else {
                await dispatch(prescriptionAction.create(prescriptionObj, isEligible));
                dispatch(updatePreferredPharmacy(prescriptionObj.pharmacy, person));

            }

            if (isPrivatePay()) {
                this.updatePrvLastName();
            } else {
                this.updateEmail();
            }
        }
        else {
            dispatch(alertAction.warning(msgConstant.PLEASE_CORRECT_ERRORS_BELOW, validator.errorMsgs));
        }
        this.setState({ isSubmitting: false });
    }

    handlePrescriptionSubmit = () => {
        this.setState({ isOpenAgreement: false });
        this.submitForm();

    }
    handleMessageClose = () => {
        this.setState({ isOpenMessage: false });

        history.push(`/prescription/${this.prescriptionId}`);
    }



    renderMessage = () => {
        const { isOpenMessage } = this.state;
        return (
            <>
                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={isOpenMessage}
                // onClose={this.handleMessageClose}

                >
                    <RequestedPopup />
                    <DialogActions>
                        <button
                            className="btn btn-outline-primary btn-sm mt-2 mr-2"
                            onClick={this.handleMessageClose}
                            data-test-id="prescription-form__message__close-button"
                        >
                            Close
                        </button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    renderVerifyPhoneNumber = () => {
        const { isOpenVerifyPhoneNumber, phoneCode } = this.state;
        return (
            <>
                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={isOpenVerifyPhoneNumber}
                // onClose={this.handleMessageClose}

                >
                    <DialogTitle>
                        <span>Please input the code sent to your cell phone </span>
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <label htmlFor="phone_code">Code :</label>
                            <input className="form-control" type="text" id="phone_code" name="phoneCode"
                                value={phoneCode} onChange={this.handlePhoneCodeChange} />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className="row">
                            <button
                                className="btn btn-outline-primary btn-sm mt-2 mr-2"
                                onClick={this.handlePhoneCodeClose}
                                data-test-id="prescription-form__message__close-button"
                            >
                                Submit
                            </button>
                            <button
                                className="btn btn-outline-primary btn-sm mt-2 mr-2"
                                onClick={() => this.setState({ isOpenVerifyPhoneNumber: false })}
                                data-test-id="prescription-form__message__close-button"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogActions>

                </Dialog>
            </>
        )
    }

    renderValidationAndAgreement = () => {
        const { dispatch, prescriptionObj } = this.props;
        const validator = new PrescriptionFormValidator();
        validator.form(prescriptionObj);
        if (validator.validate()) {
            this.setState({ isOpenAgreement: true });
        } else {
            dispatch(alertAction.warning(msgConstant.PLEASE_CORRECT_ERRORS_BELOW, validator.errorMsgs));
        }

    }

    renderAgreement = () => {
        const { ckb1, ckb2, ckb3, ckb4, ckb5, ckb6, isOpenAgreement } = this.state;
        const enable = ckb1 && ckb2 && ckb3 && ckb4 && ckb5 && ckb6;
        return (
            <>
                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={isOpenAgreement}

                >
                    <DialogTitle>
                        <span>I acknowledge and agree with the following AskMedication.com policies:</span>
                    </DialogTitle>
                    <DialogContent>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input " id="ckb1" name="ckb1"
                                checked={ckb1 || false} onChange={this.changeCheckbox}

                            />
                            <label className="custom-control-label" htmlFor="ckb1" style={{ fontSize: "18px", marginBottom: "10px", lineHeight: "initial" }}>
                                AskMedication.com is for prescribing medications that I regularly take. This service is not for starting new medications or changing medication dosages. If I request a medication that I do not currently take, this medication may not be prescribed by the AskMedication.com doctor and my request may be cancelled.
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input " id="ckb2" name="ckb2"
                                checked={ckb2 || false} onChange={this.changeCheckbox}

                            />
                            <label className="custom-control-label " htmlFor="ckb2" style={{ fontSize: "18px", marginBottom: "10px", lineHeight: "initial" }}>
                                I confirm that my chronic medical conditions, if I have any, are stable.
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input " id="ckb3" name="ckb3"
                                checked={ckb3 || false} onChange={this.changeCheckbox}

                            />
                            <label className="custom-control-label " htmlFor="ckb3" style={{ fontSize: "18px", marginBottom: "10px", lineHeight: "initial" }}>
                                Should I have any adverse reaction to a medication, I agree to promptly seek medical care at the South Vancouver Medical Clinic or elsewhere.
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input " id="ckb4" name="ckb4"
                                checked={ckb4 || false} onChange={this.changeCheckbox}

                            />
                            <label className="custom-control-label " htmlFor="ckb4" style={{ fontSize: "18px", marginBottom: "10px", lineHeight: "initial" }}>
                                I agree that fees I pay to AskMedication.com portal are non-refundable. If I request a medication that I do not currently take (see point 1 above), the fees I paid to AskMedication.com will not be refunded.
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input " id="ckb5" name="ckb5"
                                checked={ckb5 || false} onChange={this.changeCheckbox}

                            />
                            <label className="custom-control-label " htmlFor="ckb5" style={{ fontSize: "18px", marginBottom: "10px", lineHeight: "initial" }}>
                                I agree that the AskMedication.com physician may modify my request if necessary and that the maximum prescription duration is 3 months.
                            </label>
                        </div>

                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input " id="ckb6" name="ckb6"
                                checked={ckb6 || false} onChange={this.changeCheckbox}

                            />
                            <label className="custom-control-label " htmlFor="ckb6" style={{ fontSize: "18px", marginBottom: "10px", lineHeight: "initial" }}>
                            I am aware that prescription requests need to be reviewed by a healthcare provider, which may take up to 24 hours.
                            </label>
                        </div>


                    </DialogContent>
                    <DialogActions>
                        <div className="row">
                            {enable ?
                                <button
                                    className="btn btn-outline-primary btn-sm mt-2 mr-2"
                                    onClick={this.handlePrescriptionSubmit}

                                >I confirm</button>
                                :
                                <button className="btn btn-outline-primary btn-sm mt-2 mr-2 disabled " disabled>I confirm</button>
                            }
                            <button
                                className="btn btn-outline-primary btn-sm mt-2 mr-2"
                                onClick={() => this.setState({ isOpenAgreement: false })}
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogActions>

                </Dialog>
            </>
        )
    }

    renderDisplayBefore = () => {
        const { isOpenBeforeVerify } = this.state;
        const { person } = this.props;

        return (
            <>
                <Dialog
                    fullWidth={true}
                    maxWidth="sm"
                    open={isOpenBeforeVerify}
                    className="text-center"
                // onClose={this.handleMessageClose}

                >
                    <DialogTitle>
                        <i className="fas fa-exclamation-triangle text-warning mb-3" style={{ fontSize: "50px" }}></i>
                    </DialogTitle>

                    <DialogContent>
                        <p style={{ fontSize: "18px", lineHeight: "initial" }}>
                            Please note that your mobile phone number must be verified prior to proceeding. This is to ensure that our doctors can get a hold of you if required. Your mobile phone number on file is {person.BestPhoneNumber}. If this is not the correct number, please go back to the SouthVanMed.com dashboard and click "My Account". You will then be able to enter the correct phone number. If you are ready to proceed, please click "Ok" and then enter the code you received as a text message
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <div className="row">
                            <button
                                className="btn btn-primary btn-sm mr-2"
                                onClick={this.handleDisplayClose}
                                data-test-id="prescription-form__message__close-button"
                            >
                                OK
                            </button>
                            <button
                                className="btn btn-secondary btn-sm  mr-2"
                                onClick={() => this.setState({ isOpenBeforeVerify: false })}
                                data-test-id="prescription-form__message__close-button"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogActions>

                </Dialog>
            </>
        )
    }


    render() {
        const { medicalInfoObj, prescriptionObj } = this.props;
        let medications;

        if (prescriptionObj) {
            medications = prescriptionObj.Medications;
        }

        return (
            <div className="prescription-form">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Request a Prescription</h1><hr />
                </div>

                <div>
                    <div className="d-flex justify-content-between mb-2">
                        <div className="sub-title">
                            <div>
                                <h1 className="h5 font-weight-normal">
                                    Select the medications to be refilled
                                </h1>
                                {!prescriptionConstant.IS_UNLIMITED && <div className="mb-3">
                                    (At most <span style={{ fontWeight: "bold" }}>5</span> medications are allowed per day)
                                </div>}
                            </div>
                            {
                                medicalInfoObj && medicalInfoObj.MedicationsCurrentlyTaken.length > 0 &&
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" id="is-load-medication" className="custom-control-input"
                                        onChange={this.loadMedication} />
                                    <label className="custom-control-label" htmlFor="is-load-medication">
                                        Do you want to load your currently taken medications?<span style={{ color: "red" }}> (Only medications that can be refilled with AskMedication.com will be loaded)</span>
                                    </label>
                                </div>
                            }
                        </div>
                        {/*<PrescriptionCost medications={medications} />*/}
                    </div>
                    {prescriptionObj.Medications &&
                        <DrugSearch onMedicationsChanged={this.handleMedicationsChanged} allowEdit={true} isCrossOut={true} />
                    }
                    <hr />
                    <h2 className="h5 mb-3 font-weight-normal">
                        Your Selected Pharmacy
                    </h2>
                    {this.renderPreferedPharmacy()}
                    {/* {prescriptionObj.pharmacy !== undefined &&
                            // <PharmacySelect id="pharmacy" name="pharmacy" value={prescriptionObj.pharmacy} onChange={this.handleChange} />
                            <PharmacySelectOscar
                            id="pharmacy"
                            pharmacyList={this.state.pharmacyList}
                            onChange={this.handleChange}
                            name="pharmacy"
                            selectedPharmacy={prescriptionObj.pharmacy}
                
                          />
                        }                         */}
                    <hr className="mb-6-5" />
                    <div className="d-flex justify-content-between">
                        <button className="btn btn-outline-secondary btn-lg" type="button" onClick={() => history.goBack()}>Back</button>
                        <button
                            className="btn btn-primary btn-lg"
                            type="submit"
                            data-test-id="prescription-form__proceed"
                            disabled={this.state.isSubmitting}
                            onClick={this.renderValidationAndAgreement}
                        >{this.state.isSubmitting ? "Confirming Medication Request" : "Confirm Medication Request"}</button>
                    </div>
                </div>

                {this.renderMessage()}
                {this.renderVerifyPhoneNumber()}
                {this.renderDisplayBefore()}
                {this.renderAgreement()}
            </div>
        );
    }
}


PrescriptionForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    prescriptionObj: PropTypes.object,
    medicalInfoObj: PropTypes.object
};


function mapStateToProps(state) {
    const { medicalInfoObj } = state.medicalInfo;
    let { prescriptionObj } = state.prescription;
    const person = (state.authentication.user || {}).Person
    const { user } = state.authentication;
    const { list } = state.pharmacyOscar;


    // init prescriptionObj
    if (prescriptionObj === undefined) {
        prescriptionObj = {
            Person: getPersonId()
        };
    }

    if (prescriptionObj.Person === undefined) {
        prescriptionObj.Person = getPersonId();
    }

    // init pharmacy
    if (prescriptionObj.pharmacy === undefined) {
        prescriptionObj.pharmacy = person ? person.preferredPharmacy : undefined;
    }

    // init Medications
    if (prescriptionObj.Medications === undefined) {
        prescriptionObj.Medications = [];
    }

    return {
        medicalInfoObj,
        prescriptionObj,
        person,
        user,
        list
    };
}

const mapDispatchToProps = dispatch => {
    return {
        createAndPending: async (prescriptionObj = {}) => await dispatch(prescriptionAction.createAndPending(prescriptionObj)),
        dispatch
    }
}

const temp = connect(mapStateToProps, mapDispatchToProps)(withRouter(PrescriptionForm));

export { temp as PrescriptionForm };