import { pharmacyConstant }  from "../_constants";

const initialState = {list:[]};

const pharmacyOscar = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case pharmacyConstant.GET_ALL_PHARMACY:
      return {
        ...state,
        list: payload
      };

    default:
      return state;
  }
};

export {pharmacyOscar} ;