import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatDate, calculatePrescriptionRefund, isDoctor } from '../../_helpers';
import { prescriptionAction, popupAction } from '../../_actions';
import { PrescriptionDialog } from './';

import { Medications, BillDialog } from '../../components';
import { Element, scroller } from 'react-scroll';
import { PendingElement } from "./pendingElement";

const IS_FREE = true;
class PendingPrescription extends Component {
    constructor(props) {
        super(props);
        const state = {};
        if (props.selectPrescriptionId)
            state.selectPrescriptionId = props.selectPrescriptionId;
        this.state = state;
    }

    componentDidMount() {
        const { selectPrescriptionId } = this.state;

        if (selectPrescriptionId) {
            // scroll to specific note
            scroller.scrollTo(selectPrescriptionId, {
                containerId: 'pending-prescription',
                duration: 1,
                smooth: true,
                offset: -300
            });
        }
    }

    openPrescriptionDialog = (prescription) => {
        const { dispatch } = this.props;
        dispatch(prescriptionAction.openPrescriptionDialog(prescription));
    }

    acknowledge = (prescription) => {
        const { dispatch } = this.props;
        dispatch(prescriptionAction.acknowledge(prescription));
    }

    handleRefund = (prescription) => {
        const { dispatch } = this.props;
        const action = prescriptionAction.refund(prescription);
        dispatch(popupAction.openDialog('Refund confirmation', 'You are about to refund one or more prescriptions, are you sure?', action));
    }

    renderAcknowledgeBtn(prescription) {
        if (prescription.statuses[0].text === 'Pending' && isDoctor()) {
            return (
                <button className="btn btn-warning btn-xs"  onClick={() => this.acknowledge(prescription)}>
                    Acknowledge
                </button>
            );
        } else {
            return '';
        }
    }

    renderRefundBtn(prescription) {
        const anyRejected = prescription.medications.find(o => o.status === 'Rejected');
        const anyNonAction = prescription.medications.find(o => o.status == undefined);     // status either null or undefined
        const isRefunded = prescription.statuses.find(o => o.text === 'Refunded');
        const refundAmount = calculatePrescriptionRefund(prescription.medications);

        if (anyRejected && !anyNonAction && !isRefunded && refundAmount > 0) {
            return (
                <button className="btn btn-sm btn-outline-danger mt-2" onClick={() => this.handleRefund(prescription)}>
                    {`Refund ($${refundAmount})`} 
                </button>
            );
        } else {
            return '';
        }
    }

    renderPrescriptionBtn(prescription) {
        const anyApproved = prescription.medications.find(o => o.status === 'Approved');
        const anyNonAction = prescription.medications.find(o => o.status == undefined);     // status either null or undefined
        const isFaxed = prescription.statuses.find(o => o.text === 'Faxed');

        if (anyApproved && !anyNonAction /*&& !isFaxed*/) {
            return (
               isDoctor() && <button className="btn btn-sm btn-outline-primary ml-2 mt-2" onClick={() => this.openPrescriptionDialog(prescription)}>
                    Prescription
                </button>
            );
        } else {
            return '';
        }
    }

    render() {
        const { prescriptions } = this.props;
        const { selectPrescriptionId } = this.state;

        if (prescriptions && prescriptions.length > 0) {
            return (
                <div id="pending-prescription">
                    {prescriptions.map(prescription => {
                        let classes = 'prescription';
                        const selected = prescription._id === selectPrescriptionId;
                        if (selected) classes += ' selected';

                        return (
                            <Element name={prescription._id} className={classes} key={prescription._id}>
                                <PendingElement prescription={prescription}></PendingElement>
                                {/* <div className="left-panel">
                                    <Medications items={prescription.medications}
                                        allowEdit={true} action={true} prescription={prescription}></Medications>
                                </div>
                                <div className="right-panel">
                                    <div className="created-at">
                                        {formatDate(prescription.createdAt)}
                                    </div>
                                    <div className="prescription-id">
                                        {prescription._id}
                                    </div>
                                    <div className="action">
                                        {this.renderAcknowledgeBtn(prescription)}
                                        {!IS_FREE && this.renderRefundBtn(prescription)}
                                        {this.renderPrescriptionBtn(prescription)}
                                    </div>
                                </div> */}
                                
                            </Element>
                        );
                    })}
                    <PrescriptionDialog />
                    <BillDialog />
                </div>
            );
        } else {
            return (
                <div className="mb-4">
                    No pending prescriptions for this patient.
                </div>
            );
        }
    }
}


PendingPrescription.propTypes = {
    dispatch: PropTypes.func.isRequired,
    selectPrescriptionId: PropTypes.string,
    prescriptions: PropTypes.array
};


function mapStateToProps(state) {
    const { prescriptions, selectPrescriptionId } = state.prescription;

    return {
        prescriptions,
        selectPrescriptionId
    };
}


const temp = connect(mapStateToProps)(PendingPrescription);

export { temp as PendingPrescription };

