import { billConstant } from '../_constants';
import { teleplanService, medicationBillService } from '../_services';
import { alertAction } from './';
import { errorHandling } from '../_helpers';

export const billAction = {
    openBillDialog,
    closeBillDialog,
    createBill,
}

function openBillDialog(prescription, billWay) {
    return {
        type: billConstant.OPEN_BILL_DIALOG,
        prescription,
        billWay,
    };
}

function closeBillDialog() {
    return {
        type: billConstant.CLOSE_BILL_DIALOG
    };
}

function createBill(obj){
    return dispatch => {
        teleplanService.createBill(obj)
            .then(
                res => {
                    if (res.data.isExistBill){
                        dispatch(alertAction.success('Bill already exists'));
                    } else{
                    dispatch(alertAction.success('Save bill information successfully'));
                    }
                },
                error => {
                    errorHandling('bill.action.create bill', error, dispatch);

                }
            );
    };
}

