import React, {Component} from 'react';
import PropTypes from 'prop-types';


class ToggleProvince extends Component {
    constructor(props) {
        super(props);

        // init
        this.state = {
            value: this.props.value
        };
    }

    onChange = (event) => {
        this.setState({
            value: event.target.value
        });
    }

    render() {
        return ( <div>
            <select className="custom-select d-block w-100"
                value={this.state.value} onChange={this.onChange} required>
                <option value="">Choose...</option>
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland and Labrador</option>
                <option value="NT">Northwest Territories</option>
                <option value="NS">Nova Scotia</option>
                <option value="NU">Nunavut</option>
                <option value="ON">Ontario</option>
                <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
                <option value="YT">Yukon</option>
                <option value="OTHER">Other</option>
            </select>
        </div>);
    }
}


ToggleProvince.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string
};


const temp = ToggleProvince;

export { temp as ToggleProvince };