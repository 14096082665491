import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';



const startYear = 1900;
const currentYear = new Date().getFullYear();
const years = [...Array(currentYear - startYear + 1).keys()].map(i => (i + startYear).toString());//range(1990, getYear(new Date()) + 1, 1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

function getYear(date) {
    return new Date(date).getFullYear();
}

function getMonth(date) {
    return new Date(date).getMonth();
}


class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value : props.value ? new Date(props.value) : new Date()
        }

    }

    render() {
        return <div data-test-id='calendar'> 
        <DatePicker
            className="form-control"
            renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled
            }) => (
                <div
                style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center"
                }}
                >
                <button onClick={
                    (e) => {
                        e.preventDefault();
                        decreaseMonth(e)
                    }
                    
                    } disabled={prevMonthButtonDisabled}>
                    {"<"}
                </button>
                <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                >
                    {years.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                    ))}
                </select>

                <select
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                    }
                >
                    {months.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                    ))}
                </select>

                <button onClick={(e) => {
                    e.preventDefault();
                    increaseMonth(e)
                }} 
                    disabled={nextMonthButtonDisabled}>
                    {">"}
                </button>
                </div>
            )}
            selected={this.state.value}
            dateFormat="dd-MMM-yyyy"
            onChange={value => {
                this.setState({ value });
                if (value) {
                    value.setMilliseconds(0);
                    value.setHours(0);
                    value.setMinutes(0);
                    value.setSeconds(0);
                }
                
                this.props.onChange(value);
            }}
            />
        </div>
    }
}


Calendar.propTypes = {

};


const temp = connect()(Calendar);

export { temp as Calendar };