import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const problemService = {
    getAll,
    create,
    countUnread,
    acknowledge
};

function getAll() {
    const url = `${config.apiHost}/problem`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    
    return axios.get(url);
}

function create(dataURL, message, personId) {
    const url = `${config.apiHost}/problem`;
    const body = {
        dataURL,
        message,
        personId
    };

    return axios.post(url, body);
}

function countUnread() {
    const url = `${config.apiHost}/problem/count-unread`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function acknowledge(id) {
    const url = `${config.apiHost}/problem/acknowledge`;
    const body = {
        id
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}