import React, {Component} from 'react';
import PropTypes from 'prop-types';


class PharmaNetButton extends Component {
    constructor(props) {
        super(props);

        // init
        this.state = {
        };
    }

    openPharmaNetWindow = (event) => {
        event.stopPropagation();
        const { firstName, lastName, phn } = this.props;
    
        // patient info
        let params = {
            first: firstName,
            last: lastName,
            phn
        };
        // let params = { 'login' : 'rxmd', 'passwd': 'Superstore604'};  
        // let params = {
        //     login: 'rxmd',
        //     first: 'SMITH',
        //     last: 'EDWARD',
        //     phn: 9029816866
        // };
        let windowoption = 'width=600,height=700,toolbar=no,menubar=no,location=no,directories=no,resizable=yes,titlebar=no,scrollbars=yes,status=yes';
        let winName='MyWindow';
        let winURL='https://swan.medinet.ca/cgi-bin/rxmd.cgi';

        let form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', winURL);
        form.setAttribute('target', winName);
        for (let i in params) {
            if (params.hasOwnProperty(i)) {
                let input = document.createElement('input');
                input.type = 'hidden';
                input.name = i;
                input.value = params[i];
                form.appendChild(input);
            }
        }              
        document.body.appendChild(form);                       
        window.open('', winName, windowoption);
        form.target = winName;
        form.submit();        
        document.body.removeChild(form); 
    }

    render() {

        return (
            <div>
                <button className='btn btn-outline-secondary btn-lg my-4' onClick={(event) => this.openPharmaNetWindow(event)}>Check PharmaNet</button>
            </div>
        );
    }
}


PharmaNetButton.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phn: PropTypes.number,
};


const temp = PharmaNetButton;

export { temp as PharmaNetButton };