
const errorMapping = {
    'askmedication.users.$Email_1 dup key': 'email is invalid please choose another email address',
    'askmedication.users index: Email_1 dup key': 'email is invalid please choose another email address'
};

export const getErrorMsg = (sysError) => {
    const keys = Object.keys(errorMapping);
    let errMsg = 'undefined system error';

    for (let key of keys) {
        if (sysError.indexOf(key) > -1) {
            errMsg = errorMapping[key];
            break;
        }
    }

    return errMsg;
};


