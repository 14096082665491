export const messageConstant = {    
    CHANGE_TAB: 'MESSAGE_CHANGE_TAB',
    DISPLAY_INBOX: 'MESSAGE_DISPLAY_INBOX',
    DISPLAY_SENT: 'MESSAGE_DISPLAY_SENT',
    DISPLAY_ARCHIVE: 'MESSAGE_DISPLAY_ARCHIVE',
    
    OPEN: 'MESSAGE_OPEN',

    REPLY: 'MESSAGE_REPLY',
    DISCARD: 'MESSAGE_DISCARD',
    CHANGE: 'MESSAGE_CHANGE',
    GET: 'MESSAGE_GET',

    SEND_REPLY_REQUEST: 'MESSAGE_SEND_REPLY_REQUEST',
    SEND_REPLY_SUCCESS: 'MESSAGE_SEND_REPLY_SUCCESS',

    COMPOSE: 'MESSAGE_COMPOSE',
    COMPOSE_CLOSE: 'MESSAGE_COMPOSE_CLOSE',

    COUNT_UNREAD: 'MESSAGE_COUNT_UNREAD',

    TAB_INBOX: 'tab-inbox',
    TAB_SENT: 'tab-sent',
    TAB_ARCHIVE: 'tab-archive',

    STATUS_REPLYING: 'replying',
    STATUS_SENDING: 'sending',
    STATUS_COMPOSE: 'compose',
    STATUS_DISPLAY_INBOX: 'display_inbox',
    STATUS_DISPLAY_SENT: 'display_sent',
    STATUS_DISPLAY_ARCHIVE: 'display_archive',

    ARCHIVED: 'MESSAGE_ARCHIVED',
    BACKTOINBOX: 'MESSAGE_BACKTOINBOX',
    GET_CUSTOMERSERVICE_SUCCESS:'MESSAGE_GET_CUSTOMERSERVICE_SUCCESS',
    GET_ADMIN_SUCCESS:'MESSAGE_GET_ADMIN_SUCCESS',

    SET_PAGE: 'MESSAGE_SET_PAGE',
    SET_ROWS: 'MESSAGE_SET_ROWS',
    SELECT_MESSAGE: 'MESSAGE_SELECT_MESSAGE',
    REPLIED: 'MESSAGE_REPLIED',
    PRESCRIPTION_REPLIED:'MESSAGE_PRESCRIPTION_REPLIED',
};
