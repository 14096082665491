import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import config from '../../config';
import { ProfileValidator } from '../../_validator';
import { alertAction } from '../../_actions';
import { faxService } from "../../_services/"



// const isNativeDatePicker = false;
class FaxResend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true
        };
    }

    render() {
        return (
            <div className="container">
                <div className="py-5 text-center">
                    <h2>Send Prescription Again to your Pharmacy</h2>
                    <p className="lead">
                        Please note that each prescription can only be sent to the pharmacy 2 more times.
                    </p>
                </div>
                <Dialog
                    fullWidth={true}
                    maxWidth="sm"
                    open={this.state.isOpen}
                >
                    <DialogTitle>
                        Resend Prescription Fax
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ fontSize: "20px" }}>
                            Are you sure you want to resend your prescription to the pharmacy by fax?
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-primary mt-4" onClick={
                            async () => {
                                try {
                                    const result = await faxService.resendFax(this.props.match.params.sfaxDetailsID);
                                    this.props.alertSuccess("Prescription resent successfully!");
                                    this.setState({ isOpen: false });
                                } catch (error) {
                                    this.props.alertError("Error on refaxing", [error.message]);
                                }
                            }
                        }>Yes</button>
                        <button className="btn btn-outline-secondary mr-2 mt-4" onClick={() => this.setState({isOpen: false})}>Cancel</button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


FaxResend.propTypes = {
    history: PropTypes.object
};

function mapDispatchToProps(dispatch) {
    return {
        alertError: (title, content) => dispatch(alertAction.error(title, content)),
        alertSuccess: (title, content) => dispatch(alertAction.success(title, content))
    };
}
const temp = connect(null, mapDispatchToProps)(FaxResend);

export { temp as FaxResend };