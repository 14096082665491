import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StripeProvider, Elements } from 'react-stripe-elements';
import config from '../../config';
import { CheckoutDialog } from './';

class PayButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };
    }

    handleClick = () => {
        this.setState({
            isOpen: true
        });
    }

    handleClose = () => {
        this.setState({
            isOpen: false
        });
    }

    render() {
        const { isOpen } = this.state;
        const { className, onPay, children, clinicObj } = this.props;

        return (
            <>
                <button type="button" className={className} onClick={this.handleClick}>
                    {children}
                </button>                
                {/* <StripeProvider apiKey={config.stripePublicKey}>
                    <Elements>
                        <CheckoutDialog isOpen={isOpen} onPay={onPay} onClose={this.handleClose} />
                    </Elements>
                </StripeProvider> */}
                 <CheckoutDialog isOpen={isOpen} onPay={onPay}  clinicObj={clinicObj} onClose={this.handleClose}  />
            </>
        );
    }
}


PayButton.propTypes = {
    isOpen: PropTypes.bool,
    className: PropTypes.string,
    onPay: PropTypes.func,
    children: PropTypes.string
};


const temp = PayButton;

export { temp as PayButton };