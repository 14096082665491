import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pharmacyService } from '../_services';
import { errorHandling } from '../_helpers';


class PharmacySelect extends Component {
    constructor(props) {
        super(props);

        // init
        this.state = {
            searchKey: '',
            searchList: [],
            item: props.value
        };
    }

    handleChange = (event) => {
        this.setState({
            searchKey: event.target.value
        });
    }

    searchByName = (event) => {
        const searchKey = event.target.value;

        this.setState({
            searchList: []
        });

        if (searchKey !== '' && searchKey.length > 3) {
            pharmacyService.multiTextSearch(searchKey)
                .then(
                    res => {
                        const searchList = res.data;

                        if (searchList.length > 0) {
                            this.setState({
                                searchList: res.data
                            });
                        }
                    },
                    error => {
                        if (!pharmacyService.isCancel(error)) {
                            errorHandling('PharmacySelect.searchByName', error, this.props.dispatch);
                        }
                    }
                );
        }
    }

    selectItem = (selectedItem) => {
        const item = {
            _id: selectedItem._id,
            name: `${selectedItem.name} - ${selectedItem.address}`
        };

        this.setState({
            searchList: [],
            searchKey: '',
            item
        });

        // match the event.target structure
        this.props.onChange({
            target: {
                name: this.props.name,
                value: item         
            }
        });
    }

    removeItem = () => {
        const item = null;

        this.setState({
            item
        });

        // match the event.target structure
        this.props.onChange({
            target: {
                name: this.props.name,
                value: item
            }
        });
    }

    renderInput() {
        if (!this.state.item) {
            return (
                <input type="text" className="form-control" placeholder="Search for a pharmacy"
                    value={this.state.searchKey} onKeyUp={this.searchByName} onChange={this.handleChange} />
            );
        }

        return '';
    }

    renderSearchList() {
        if (this.state.searchList.length > 0) {
            return (
                <ul>
                    {
                        this.state.searchList.map((item) => {
                            return (
                                <li key={item._id} onClick={() => this.selectItem(item)}>
                                    {`${item.name} - ${item.address}`}
                                </li>
                            );
                        })
                    }
                </ul>
            );
        } else {
            return '';
        }
    }

    renderItem() {
        const { item } = this.state;
        const { isDeletable = true } = this.props;

        if (item) {
            return (
                <div className="item">
                    <span className="badge badge-light" >
                        {item.name}
                        {isDeletable && <button onClick={this.removeItem} type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>}
                    </span>
                </div>
            );
        }

        return '';
    }

    render() {
        return (
            <div id="pharmacy-select">
                {this.renderInput()}
                {this.renderSearchList()}
                {this.renderItem()}
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.item != null 
            && this.props.value != null
            && prevState.item.name !== this.props.value.name)
        this.setState ({
            item: this.props.value
        });
    }
}


PharmacySelect.propTypes = {
    dispatch: PropTypes.func,
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.object
};


const temp = connect()(PharmacySelect);

export { temp as PharmacySelect };