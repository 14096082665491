import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatDateTime } from '../../_helpers';
import DatePicker from 'react-datepicker';


class BloodPressure extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleChange = event => {
        const { name, value } = event.target;

        this.props.onChange({
            name, 
            value: value === '' ? value : parseInt(value)
        });
    }

    handleDateChange = date => {
        this.props.onChange({
            name: 'measureDate',
            value: date
        });
    }

    render() {
        const { onChange, bloodPressureObj } = this.props;
        const question = 'What is your blood pressure (systolic/diastolic)?';

        if (onChange) {
            // edit mode
            const measureDate = bloodPressureObj.measureDate ? new Date(bloodPressureObj.measureDate) : null;

            return (
                <div className="edit-blood-pressure col-md-12 my-2">
                    <label htmlFor="systolic" className="mr-5">
                        {question}
                        <span className="text-danger"><b> *</b></span>
                    </label>
                    <input type="number" className="form-control" name="systolic"
                        min="50" max="400"
                        value={bloodPressureObj.systolic} onChange={this.handleChange} required />
                    <span className="mx-2">/</span>
                    <input type="number" className="form-control mr-3" name="diastolic"
                        min="20" max="300"
                        value={bloodPressureObj.diastolic} onChange={this.handleChange} required />
                    <DatePicker
                        name="blood_pressure_date"
                        selected={measureDate}
                        onChange={this.handleDateChange}
                        selectsStart
                        maxDate={new Date()}
                        peekNextMonth
                        showTimeSelect
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        isClearable={true}
                        placeholderText="Measure Date"
                        dateFormat="MM/dd/yyyy h:mm aa"
                    />
                </div>
            );
        } else {
            // display mode
            return (
                <div className="edit-blood-pressure col-md-12 my-2">
                    <strong className="mr-3">
                        {question}
                    </strong>
                    <span className="mr-3">{bloodPressureObj.systolic}/{bloodPressureObj.diastolic}</span>
                    <span>{formatDateTime(bloodPressureObj.measureDate)}</span>
                </div>
            );
        }
    }
}


BloodPressure.propTypes = {
    dispatch: PropTypes.func,
    bloodPressureObj: PropTypes.object,
    onChange: PropTypes.func
};


const temp = BloodPressure;

export { temp as BloodPressure };