import { registrationConstant } from '../_constants';

export function registration(state = {}, action) {
    switch (action.type) {
        case registrationConstant.REGISTER_REQUEST:
            return {
                registering: true
            };
        case registrationConstant.REGISTER_SUCCESS:
        case registrationConstant.REGISTER_FAILURE:
            return {
                registering: false
            };
        case registrationConstant.IS_ACTIVATED:
            return {
                ...state,
                isActivated: action.isActivated
            };
        case registrationConstant.CONFIRM_CHANGE:
            return {
                ...state,
                confirm: { ...action.confirm }
            };
        case registrationConstant.OPEN_PRIVACY_POLICY:
            return {
                ...state,
                openPrivacyPolicy: true
            };
        case registrationConstant.CLOSE_PRIVACY_POLICY:
            return {
                ...state,
                openPrivacyPolicy: false,
                privacyPolicyChecked: action.checked
            };
        case registrationConstant.OPEN_TERMS_OF_USE:
            return {
                ...state,
                openTermsOfUse: true
            };
        case registrationConstant.CLOSE_TERMS_OF_USE:
            return {
                ...state,
                openTermsOfUse: false,
                termsOfUseChecked: action.checked
            };

        case registrationConstant.OPEN_FEE_SCHEDULE:
            return {
                ...state,
                openFeeSchedule:true
        };
        case registrationConstant.CLOSE_FEE_SCHEDULE:
            return {
                ...state,
                openFeeSchedule: false
        }

        case registrationConstant.OPEN_PHARMANET_CONSENT:
            return {
                ...state,
                openPharmanetConsent: true
            };
        case registrationConstant.CLOSE_PHARMANET_CONSENT:
            return {
                ...state,
                openPharmanetConsent: false,
                pharmanetConsentChecked: action.checked
            };
        case registrationConstant.ACTIVATED:
            return {};
        default:
            return state;
    }
}