import { messageService, noteService } from '../_services';
import { messageConstant } from '../_constants';
import { getPersonId, errorHandling } from '../_helpers';
import { loadingAction, patientAction, prescriptionAction, noteAction, sleepAssessmentAction, labtestAction, alertAction  } from './';


export const messageAction = {
    getCustomerService,
    changeTab,
    openMessage,
    doctorOpenMessage,
    compose,
    composeClose,
    create,
    reply,
    discard,
    change,
    sendReply,
    countUnread,
    archive,
    setPage,
    setRows,
    clickMessage,
    goBackToInbox,
    getAdmin,
    sendReplyByLink,
    getMessage,
    getPrescriptionStatus
    
};

function getCustomerService() {
    return dispatch => {
        messageService.getCustomerService()
            .then(
                res => {
                    dispatch({
                        type: messageConstant.GET_CUSTOMERSERVICE_SUCCESS,
                        customerServiceListIds: res.data
                    });
                },
                error => {
                    errorHandling('message.action.getCustomerService', error, dispatch);
                }
            );
    };
}

function getAdmin() {
    return dispatch => {
        messageService.getAdmin()
            .then(
                res => {
                    dispatch({
                        type: messageConstant.GET_ADMIN_SUCCESS,
                        adminListIds: res.data
                    });
                },
                error => {
                    errorHandling('message.action.getAdmin', error, dispatch);
                }
            )
    }
}



function changeTab(tabValue) {
    return dispatch => {
        dispatch(request());

        messageService.getByType(tabValue)
            .then(
                res => {           
                    if (tabValue === messageConstant.TAB_INBOX) {
                        // inbox
                        dispatch(display(messageConstant.DISPLAY_INBOX, res.data));
                    } else if (tabValue === messageConstant.TAB_SENT) {
                        // sent
                        dispatch(display(messageConstant.DISPLAY_SENT, res.data));
                    } else if (tabValue === messageConstant.TAB_ARCHIVE) {
                        // archive
                        dispatch(display(messageConstant.DISPLAY_ARCHIVE, res.data));
                    }
                },
                error => {
                    errorHandling('message.action.changeTab', error, dispatch);
                }
            );
    };

    function request() { return { type: messageConstant.CHANGE_TAB, tabValue: tabValue }; }
    function display(type, messageList) { return { type, messageList }; }
}

function openMessage(messageObj) {
    const personId = getPersonId();

    return dispatch => {
        // message is not read
        if (!messageObj.isRead && isNotMe(messageObj)) {
            messageService.updateIsRead(messageObj._id)
                .then(
                    () => {
                        dispatch(countUnread());
                    },
                    error => {
                        errorHandling('message.action.openMessage', error, dispatch);
                    }
                );
        }

        dispatch({
            type: messageConstant.OPEN,
            messageObj: messageObj
        });
    };

    function isNotMe(messageObj) {
        if (messageObj.repliedBy !== undefined) {
            // not replied by me
            return messageObj.repliedBy._id !== personId;
        } else {
            // not sent by me
            return messageObj.from._id !== personId;
        }
    }
}

function doctorOpenMessage(messageObj) {
    return dispatch => {
        if (messageObj.prescription) {
            // prescription exist
            dispatch(patientAction.selectPatient(messageObj.from._id));     // messageObj.from is patient
            dispatch(prescriptionAction.goToPrescription(messageObj.prescription));
            dispatch(labtestAction.setOpenLabtest(messageObj.labtest));
        } else if (messageObj.sleepAssessment) {
            
            dispatch(patientAction.selectPatient(messageObj.from._id)); 
            dispatch(sleepAssessmentAction.goToSleepAssessment(messageObj.sleepAssessment));
            
        } else if (messageObj.labtest) {
            
            dispatch(patientAction.selectPatient(messageObj.from._id)); 
            dispatch(labtestAction.goToLabtest(messageObj.labtest));
            dispatch(labtestAction.acknowledgeMessage(messageObj.from._id, messageObj._id, messageObj.labtest));
            
        } else if (messageObj.note) {
            // note exist
            dispatch(patientAction.selectPatient(messageObj.to._id));       // messageObj.to is patient
            dispatch(noteAction.goToNote(messageObj.note));
        } else {
            dispatch(messageAction.openMessage(messageObj));
        }
    };
}

function compose() {
    return {
        type: messageConstant.COMPOSE
    };
}

function composeClose() {
    return {
        type: messageConstant.COMPOSE_CLOSE
    };
}

function create(from, to, subject, message, files, reload = false) {
    return dispatch => {
        dispatch(loadingOpen());

        messageService.create(from, to, subject, message, files)
            .then(
                () => {
                    dispatch(composeClose());

                    if (reload) {
                        dispatch(changeTab(messageConstant.TAB_SENT));     // Sent
                    }
                },
                error => {
                    errorHandling('message.action.create', error, dispatch);
                }
            )
            .finally(
                () => { dispatch(loadingClose()); }
            );
    };
}

function reply() {
    return {
        type: messageConstant.REPLY
    };
}

function discard() {
    return {
        type: messageConstant.DISCARD
    };
}

function change(sendMessageObj) {
    return {
        type: messageConstant.CHANGE,
        sendMessageObj: sendMessageObj
    };
}

function sendReply(id, from, to, message, files) {
    return dispatch => {
        dispatch(request());

        messageService.updateReplies(id, from, to, message, files)
            .then(
                res => {
                    dispatch(success(res.data.messageObj));                  
                },
                error => {
                    errorHandling('message.action.sendReply', error, dispatch);
                }
            );
    };

    function request() { return { type: messageConstant.SEND_REPLY_REQUEST }; }
    function success(messageObj) { return { type: messageConstant.SEND_REPLY_SUCCESS, messageObj }; }
}

function sendReplyByLink(id, from, message, files, text) {
    return dispatch => {
     

        messageService.updateRepliesByLink(id, from, message, files)
            .then(
                res => {
                    const  { isReplied } = res.data;
                    if (!isReplied){
                    dispatch(alertAction.success("Your message was successfully sent to the doctor."));
                    const { person } =  res.data;
                    noteService.sendNoteToEmr(text, person); 
                 } else {
                    dispatch({
                        type: messageConstant.REPLIED,
                        isReplied,
                    });
                 }               
                },
                error => {
                    errorHandling('message.action.sendReply', error, dispatch);
                }
            );
    };

}

function getMessage(id, from) {
    return dispatch => {
        messageService.getMessage(id, from)
            .then(
                res =>{
                    const  { isReplied, messageInfo } = res.data;
                    dispatch({
                        type: messageConstant.GET,
                        isReplied,
                        messageInfo
                    });

                },
               error => {
                errorHandling('message.action.sendReply', error, dispatch);
               }  
            )


    }
}

function getPrescriptionStatus(prescriptionId) {
    return dispatch => {
        messageService.getPrescriptionStatus(prescriptionId)
            .then(
                res =>{
                    const  { isReplied, isExpired } = res.data;
                    dispatch({
                        type: messageConstant.PRESCRIPTION_REPLIED,
                        isReplied,
                        isExpired
                    });

                },
               error => {
                errorHandling('message.action.sendReply', error, dispatch);
               }  
            )
    }
}

function countUnread() {
    return dispatch => {
        messageService.countUnread()
            .then(
                res => {
                    dispatch(success(res.data.unreadCount));
                },
                error => {
                    errorHandling('message.action.countUnread', error, dispatch);
                }
            );
    };

    function success(unreadCount) { return { type: messageConstant.COUNT_UNREAD, unreadCount }; }
}

function archive(messageId, personId) {
    return dispatch => {
        messageService.archive(messageId, personId)
            .then(
                res => {
                    dispatch({
                        type: messageConstant.ARCHIVED,
                        messageList: res.data
                    });
                },
                error => {
                    errorHandling('message.action.archive', error, dispatch);
                }
            );
    };
}

function goBackToInbox(messageId, personId) {
    return dispatch => {
        messageService.goBackToInbox(messageId,personId)
            .then(
                res => {
                    dispatch({
                        type: messageConstant.BACKTOINBOX,
                        messageList: res.data
                    })
                },
                error => {
                    errorHandling('message.action.backtoinbox', error, dispatch);
                }
            );
    }
}

function setPage(page = 0 ) {
    return {
        type: messageConstant.SET_PAGE,
        page
    }
}

function setRows(rows = 10 ) {
    return {
        type:messageConstant.SET_ROWS,
        rows
    }
}

function clickMessage(id) {
    return {
        type: messageConstant.SELECT_MESSAGE,
        id
    }
}



function loadingOpen() { return loadingAction.open(); }
function loadingClose() { return loadingAction.close(); }