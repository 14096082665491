import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { popupAction } from '../_actions';
import { DialogActions } from '@material-ui/core';


const PopupDialog = ({ dispatch, isOpen, title, message, action }) => {
    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={isOpen || false}
        >
            <DialogTitle>
                <span>{title}</span>
            </DialogTitle>
            <DialogContent>
                <div style={{fontSize: 16}}>
                    {message}
                </div>
            </DialogContent>
            <DialogActions>
                <button className="btn btn-outline-secondary mr-2 mt-4" onClick={() => dispatch(popupAction.cancel())}>Cancel</button>
                <button className="btn btn-primary mt-4" onClick={() => dispatch(popupAction.confirm(action))}>Confirm</button>
            </DialogActions>
        </Dialog>
    );
};


PopupDialog.propTypes = {
    dispatch: PropTypes.func,
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    action: PropTypes.func,
};


function mapStateToProps(state) {
    const { isOpen, title, message, action } = state.popup;

    return {
        isOpen,
        title,
        message,
        action
    };
}


const temp = connect(mapStateToProps)(PopupDialog);

export { temp as PopupDialog };