import axios from 'axios';
import { getUser, getToken, isSouthVan } from '../_helpers';
import config from '../config';


export const profileService = {
    get,
    updateCredential,
    updatePersonalInfo,
    updateHealthDoc,
    changeEmailGetPhoneCode,
    changeEmailGetEmailCode,
    changeEmailsetNewEmail,
    changeEmailValidateEmailCode,
    updatePersonalInfoAndHealthDoc,
    createAskmedDemographic,
};

async function get() {
    const userId = getUser()._id;
    const personId = getUser().Person._id;
    let url;
    if (isSouthVan()){
        url = `${config.apiHost}/person/profile/${personId}`;
    } else {
        url = `${config.apiHost}/user/${userId}`;
    }
    axios.defaults.headers.common['x-access-token'] = getToken();
    const user = await axios.get(url);
    console.log(user)
    return user
}

function updateCredential(user) {
    const userId = getUser()._id;
    const url = `${config.apiHost}/user/credential/${userId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, user);
}

function updatePersonalInfo(formData) {
    const userId = getUser()._id;
    const url = `${config.apiHost}/person/personalInfo/${userId}`;
    const axiosConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data'
        }
    };
    axios.defaults.headers.common['x-access-token'] = getToken();
    axios.defaults.headers.common['x-access-secret'] = config.southVanSecret;

    return axios.put(url, formData, axiosConfig);
}

function updateHealthDoc(formData) {
    const userId = getUser()._id;
    const url = `${config.apiHost}/person/healthDoc/${userId}`;
    const axiosConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data'
        }
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, formData, axiosConfig);
}

async function changeEmailGetPhoneCode(password, role) {
    try {
        axios.defaults.headers.common['x-access-token'] = getToken();
        return await axios
            .post(`${config.apiHost}/user/change-email/get-phone-code`,
                { password, role }
            );
    } catch (error) {
        throw ({ message: "Error on validating password. " + error.message });
    }
}

async function changeEmailGetEmailCode(phoneCode) {
    try {
        axios.defaults.headers.common['x-access-token'] = getToken();
        return await axios
            .post(
                `${config.apiHost}/user/change-email/get-email-code`,
                { phoneCode }
            );
    } catch (error) {
        throw ({ message: "Error on validating phone code. " + error.message });
    }
}

async function changeEmailsetNewEmail(email = '') {
    try {
        axios.defaults.headers.common['x-access-token'] = getToken();
        return await axios
            .post(`${config.apiHost}/user/change-email/set-new-email`,
                { email }
            );
    } catch (error) {
        throw ({ message: "Error on saving new Email. " + error.message });
    }
}

async function changeEmailValidateEmailCode(emailCode = '') {
    try {
        axios.defaults.headers.common['x-access-token'] = getToken();
        return await axios
            .post(`${config.apiHost}/user/change-email/validate-email-code`,
                { emailCode }
            );
    } catch (error) {
        throw ({ message: "Error on validating Email code. " + error.message });
    }
}

async function updatePersonalInfoAndHealthDoc(
    id, 
    firstName, lastName, dateOfBirth,
    address, phoneNumber, bestNumber,
    docType, docNumber, docProvince,
    city, province, postalCode, email
) {

    const url = `${config.apiHost}/person/personal-info/${id}`;
    const southVanUrl = `${config.southVanApi}/clinic/admin/patient-askmed/info`;

    const formData = createForm(
        id, 
        firstName, lastName, dateOfBirth,
        address, phoneNumber, bestNumber,
        docType, docNumber, docProvince,
        city, province, postalCode, email
    );
    
    const axiosConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data'
        }
    };
   
    const body = {firstName, lastName, dateOfBirth,
                address, phoneNumber, bestNumber,
                docType, docNumber, docProvince,
                city, province, postalCode, email};

    axios.defaults.headers.common['x-access-token'] = getToken();
    axios.defaults.headers.common['x-access-secret'] = config.southVanSecret;

    formData.append("byPerson", getUser().Person._id);
    const southvanRes = await axios.post(southVanUrl, body);
    const res = await axios.put(url, formData, axiosConfig);
    return {res, southvanRes}

}

function createForm(
    id, firstName, lastName, dateOfBirth,
    address, phoneNumber, bestNumber,
    docType, docNumber, docProvince,
    city, province, postalCode,email
) {
    const formData = new FormData();

    formData.append('id', id);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('dateOfBirth', dateOfBirth);
    formData.append('phoneNumber', phoneNumber);
    formData.append('bestNumber', bestNumber);
    formData.append('address', address);
    formData.append('docNumber', docNumber);
    formData.append('docType', docType);
    formData.append('city', city);
    formData.append('province', province);
    formData.append('postalCode', postalCode);
    formData.append('email', email);
    // if (BCCardNumber) {
    //     formData.append('BCCardNumber', user.BCCardNumber);
    // }
    // if (user.HealthCardNumber) {
    //     formData.append('HealthCardNumber', user.HealthCardNumber);
    // }
    // if (user.HealthCardProvince) {
    //     formData.append('HealthCardProvince', user.HealthCardProvince);
    // }
    // if (user.PassportNumber) {
    //     formData.append('PassportNumber', user.PassportNumber);
    // }
    // if (user.PassportCountryId) {
    //     formData.append('PassportCountryId', user.PassportCountryId);
    // }

    return formData;
}

function createAskmedDemographic(person, demographicNo){
    try {
        const personId = person._id;
        const url = `${config.apiHost}/person/demographic/${personId}`;
         const body = {
            demographicNo
                };
        axios.defaults.headers.common['x-access-token'] = getToken();
            
        return axios.post(url, body);
        
    } catch (error) {
        // throw ({ message: "Error on saving new Email. " + error.message });
        console.log(error);

    }
}