import { sleepAssessmentConstant, faxConstant } from '../_constants';


export function sleepAssessment(state = {}, action) {
    switch (action.type) {
        case sleepAssessmentConstant.GET_PENDING_BY_PERSON_SUCCESS:
        case sleepAssessmentConstant.REFUND_SUCCESS:
        case faxConstant.QUEUE_SLEEP_ASSESSMENT_FAX_SUCCESS:
            return {
                ...state,
                pendingList: [...action.pendingList]
            };
        case sleepAssessmentConstant.GO_TO_SLEEPASSESSMENT:
            return {
                ...state,
                selectSleepAssessmentId:action.selectSleepAssessmentId,
                openPendingSleepAssesment:true,
            }
        case sleepAssessmentConstant.SET_OPEN_PENDING_SLEEP:
            return {
                ...state,
                openPendingSleepAssesment: action.isOpen,
            }
        case sleepAssessmentConstant.OPEN_DIALOG:
            return {
                ...state,
                openDialog: true,
                sleepAssessmentObj: { ...action.sleepAssessmentObj }
            };
        case sleepAssessmentConstant.CLOSE_DIALOG:
            return {
                ...state,
                openDialog: false
            };
        default:
            return state;
    }
}