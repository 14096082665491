import { Component } from 'react';


class BaseTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    //----------------------------------------------------------------------------------------------------------
    // Sorting
    desc = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    descPatients = (a, b, orderBy) => {
        if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
            return -1;
        }
        if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
            return 1;
        }
        return 0;
    };

    stableSort = (array, cmp) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    };

    patientStableSort = (array, cmp) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0].Person, b[0].Person);
            if (order !== 0) return order;
            return a[1].Person - b[1].Person;
        });
        return stabilizedThis.map(el => el[0]);
    };

    

    getSorting = (order, orderBy) => {
        return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
    };

    patientsGetSorting = (order, orderBy) => {
        return order === 'desc' ? (a, b) => this.descPatients(a, b, orderBy) : (a, b) => -this.descPatients(a, b, orderBy);
    };

   
    //----------------------------------------------------------------------------------------------------------
}


const temp = BaseTable;

export { temp as BaseTable };