import axios from 'axios';
import { isDoctor, getPersonId, getToken, isCustomerService } from '../_helpers';
import { messageConstant } from '../_constants';
import config from '../config';


export const messageService = {
    getCustomerService,
    getByType,
    updateReplies,
    create,
    updateIsRead,
    countUnread,
    archive,
    goBackToInbox,
    getAdmin,
    sentEmail,
    updateRepliesByLink,
    getMessage,
    getPrescriptionStatus
};

function getCustomerService(){
    const url = `${config.apiHost}/message/customerservice/list`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function getAdmin(){
    const url = `${config.apiHost}/message/admin/list`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function getByType(type) {
    let url = '';
    const personId = getPersonId();

    switch (type) {
        case messageConstant.TAB_INBOX:
            if (isDoctor() || isCustomerService()) {
                url = `${config.apiHost}/message/inbox/doctor/${personId}`;
            } else {
                url = `${config.apiHost}/message/inbox/patient/${personId}`;
            }
            break;
        case messageConstant.TAB_SENT:
            url = `${config.apiHost}/message/sent/${personId}`;
            break;
        case messageConstant.TAB_ARCHIVE:
            url = `${config.apiHost}/message/archive/${personId}`;
            break;
    }

    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}


function create(from, to, subject, message, files) {
    const url = `${config.apiHost}/message`;

    // create formData
    const formData = new FormData();
    formData.append('from', from);
    formData.append('to', to);
    formData.append('subject', subject);
    formData.append('message', message);

    // add files to FormData
    addFilesToFormData(formData, files);

    const axiosConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data'
        }
    };

    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, formData, axiosConfig);
}


function updateReplies(id, from, to, message, files) {
    const url = `${config.apiHost}/message/updateReplies`;

    // create formData
    const formData = new FormData();
    formData.append('id', id);
    formData.append('from', from);
    formData.append('to', to);
    formData.append('message', message);

    // add files to FormData
    addFilesToFormData(formData, files);

    const axiosConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data'
        }
    };

    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, formData, axiosConfig);
}

function updateRepliesByLink(id, from, message, files) {
  
    const url = `${config.apiHost}/message/update-replies`;

    // create formData
    const formData = new FormData();
    formData.append('id', id);
    formData.append('from', from);
    formData.append('message', message);

    // add files to FormData
    addFilesToFormData(formData, files);

    const axiosConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data'
        }
    };

    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, formData, axiosConfig);
}

const addFilesToFormData = (formData, files) => {
    if (files && files.length > 0) {
        for (const file of files) {
            formData.append('files[]', file);
        }
    }
};

function updateIsRead(id) {
    const url = `${config.apiHost}/message/updateIsRead`;
    const body = {
        id
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function countUnread() {
    const personId = getPersonId();
    const url = `${config.apiHost}/message/countUnread/${personId}`;

    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function getMessage(id, personId){
    const url = `${config.apiHost}/message/info/${id}/${personId}`;
    
    return axios.get(url);
}

function archive(messageId, personId) {
    const url = `${config.apiHost}/message/archive`;
    const body = {
        messageId,
        personId
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function goBackToInbox(messageId, personId) {
    const url = `${config.apiHost}/message/back-inbox`;
    const body = {
        messageId,
        personId
    };

    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function sentEmail() {
    const url = `${config.apiHost}/message/sent-email`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    return axios.post(url);
}

function getPrescriptionStatus(prescriptionId){
    const url = `${config.apiHost}/prescription/reply-fax/${prescriptionId}`;
    
    return axios.get(url);
}