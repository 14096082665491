import axios from 'axios';
import config from '../config';
import { getToken, getPersonId } from '../_helpers';


export const labtestService = {
    getLabtestByPerson,
    acknowledge
};


function getLabtestByPerson(personId) {
    const url = `${config.apiHost}/lab-test/${personId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function acknowledge(personId, messageId, labtestId){
    const url = `${config.apiHost}/lab-test/acknowledge`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    const body = {
        personId,
        messageId,
        labtestId
    }

    return axios.put(url, body);
}

