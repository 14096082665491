import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { connect } from 'react-redux';
import { formatDateTime } from '../../_helpers';
import { medicationFilterAction } from '../../_actions';
import Tooltip from '@material-ui/core/Tooltip';
import { BaseTable } from '../../components';


class MedicationFilterTable extends BaseTable {
    constructor(props) {
        super(props);
        this.state = {
            order: 'desc',
            orderBy: 'createdAt',
            page: 0,
            rowsPerPage: 5,
            open: false
        };
    }

    delete = (id) => {
        const { dispatch } = this.props;
        dispatch(medicationFilterAction.remove(id));
    }

    render() {
        const { medicationFilterList } = this.props;
        const { order, orderBy, rowsPerPage, page } = this.state;

        if (medicationFilterList) {
            return (
                <div id="medication-filter-table">
                    <Paper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>tc_ahfs_number</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Active Ingredients</TableCell>
                                    <TableCell>Created</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.stableSort(medicationFilterList, this.getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(obj => {
                                        return (
                                            <TableRow key={obj._id} className="row">
                                                <TableCell>
                                                    {obj.tc_ahfs_number}
                                                </TableCell>
                                                <TableCell>
                                                    {obj.status}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        obj.activeIngredients
                                                            .map((ingredient, ind) => 
                                                                ind == obj.activeIngredients.length - 1 
                                                                    ? ingredient
                                                                    : ingredient + ', '
                                                            )
                                                    }
                                                </TableCell>
                                                <TableCell className="created-at">
                                                    {formatDateTime(obj.createdAt)}
                                                </TableCell>
                                                <TableCell className="delete">
                                                    <Tooltip title="Delete">
                                                        <button className="btn">
                                                            <i className="material-icons" onClick={() => this.delete(obj._id)}>delete</i>
                                                        </button>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 20]}
                            component="div"
                            count={medicationFilterList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            );
        } else {
            return '';
        }
    }
}

function mapStateToProps(state) {
    const { medicationFilterList } = state.medicationFilter;

    return {
        medicationFilterList
    };
}

const temp = connect(mapStateToProps)(MedicationFilterTable);

export { temp as MedicationFilterTable };

