const SECOND_LEVEL_DOMAIN   = "AskMedication";
const TOP_LEVEL_DOMAIN      = "com";
const DOMAIN_NAME           = SECOND_LEVEL_DOMAIN + '.' + TOP_LEVEL_DOMAIN 
const COMPANY_NAME          = DOMAIN_NAME + " Inc."


export const profileConstant = {
    GET: 'PROFILE_GET',
    CHANGE: 'PROFILE_CHANGE',
    UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',

    EDIT_SECTION: 'PROFILE_EDIT_SECTION',
    EDIT_SECTION_V2: 'PROFILE_EDIT_SECTION_V2',
    UPDATE_CREDENTIAL: 'PROFILE_UPDATE_CREDENTIAL',

    SECTION_PERSONAL_INFO: 'PROFILE_SECTION_PERSONAL_INFO',
    SECTION_HEALTH_DOC: 'PROFILE_SECTION_HEALTH_DOC',
    SECTION_CREDENTIAL: 'PROFILE_SECTION_CREDENTIAL',

    UPDATE_DATE_AND_VERSION : 'Last updated: April 08, 2020 - Version 1.1',
    SECOND_LEVEL_DOMAIN,
    DOMAIN_NAME,
    COMPANY_NAME,
    PROVINCE: "B.C.",
    
    IS_OTHER_PROV_ALLOWED: false,
    IS_PROFILE_UPLOAD_ALLOWED: false,
    IS_BC_CARD_UPLOAD_ALLOWED: false
};
