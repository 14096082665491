import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";
import { medicationFilterAction } from '../../_actions';
import { IconButton } from '../../components';
import { drugService } from '../../_services/drug.service';


class AddMedicationFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ingredients: []
        };
    }

    handleChange = (event) => {
        const { dispatch, medicationFilter } = this.props;
        medicationFilter[event.target.name] = event.target.value;
        dispatch(medicationFilterAction.change(medicationFilter));
    }

    handleAdd = (event) => {
        event.preventDefault();

        const { dispatch } = this.props;
        const { medicationFilter } = this.props;

        dispatch(medicationFilterAction.create(medicationFilter.tc_ahfs_number, medicationFilter.status));
    }

    onAdd = async (event) => {
        event.preventDefault();
        const ingredients = await drugService
            .getIngredientsByTcAhfs(this.props.medicationFilter.tc_ahfs_number);
        this.setState({ ingredients });
    }

    getActiveList() {
        return this.state.ingredients.filter((ingredient) => this.refs[ingredient].checked);
    }

    onConfirm = async () => {
        try {
            const { tc_ahfs_number, status } = this.props.medicationFilter;
            await this.props.create(tc_ahfs_number, status, this.getActiveList());
            this.setState({ ingredients: [] });
        } catch (error) {
            console.log(error);
        }
    }

    renderIngredientCheck(name) {
        return <div className="custom-control custom-checkbox mt-1 ml-3">
            <input 
                type="checkbox" name={name} id={name} 
                className="custom-control-input"
                ref={name}
            />
            <label className="custom-control-label" htmlFor={name}>{name}</label>
        </div>
    }

    isRenderCheck() {
        return this.state.ingredients.length > 0;
    }

    renderCheck() {
        return <div>
            <strong>Check-marking an ingredient below makes it an exception to this filter:</strong>
            <div className="row">
                {this.state.ingredients.map((name) => this.renderIngredientCheck(name))}
            </div>
            <div> 
                <button 
                    className="btn btn-outline-secondary mb-3 mt-2"
                    onClick={this.onConfirm}
                >
                    Confirm
                </button> 
            </div>
        </div>
    }

    render() {
        const { medicationFilter, medicationFilterList } = this.props;
        return (
            <div>
                <div className="add-medication-filter row">
                    <form className="add-medication-filter__add-form mb-4" onSubmit={this.onAdd}>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="tc_ahfs_number">tc_ahfs_number</label>
                                <input className="form-control" type="text" id="tc_ahfs_number" name="tc_ahfs_number"
                                    value={medicationFilter.tc_ahfs_number} onChange={this.handleChange} autoFocus required/>
                            </div>
                            <div className="col-4">
                                <label htmlFor="status">Status</label>
                                <select className="form-control" id="status" name="status" 
                                    value={medicationFilter.status} onChange={this.handleChange} required>
                                    <option value="">Choose status</option>
                                    <option value="CROSS_OUT">Cross out</option>
                                    <option value="DO_NOT_SHOW_UP">Do not show up</option>
                                </select>
                            </div>
                            <div className="col-4 d-flex align-self-end">
                                <IconButton type="submit" icon="fas fa-plus" className="btn btn-outline-secondary">Add</IconButton>
                            </div>
                        </div>
                    </form>
                    <div className="add-medication-filter__download mb-4">
                        <CSVLink
                            data={medicationFilterList}
                            filename="medication-filter.csv"
                        >
                            <IconButton
                                className="btn btn-outline-secondary"
                                icon="fas fa-download"
                            >
                                CSV
                            </IconButton>
                        </CSVLink>
                        <a
                            href={"data:" + "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(medicationFilterList))}
                            download="medication-filter.json"
                        >
                            <IconButton
                                className="btn btn-outline-secondary"
                                icon="fas fa-download"
                            >
                                JSON
                            </IconButton>

                        </a>
                    </div>
                </div>
                {this.isRenderCheck() && this.renderCheck()}
            </div>
        );
    }
}


AddMedicationFilter.propTypes = {
    dispatch: PropTypes.func.isRequired,
    medicationFilter: PropTypes.object
};


function mapStateToProps(state) {
    let { medicationFilter, medicationFilterList = [] } = state.medicationFilter;

    if (medicationFilter === undefined) {
        medicationFilter = {
            tc_ahfs_number: '',
            status: '',
            activeList:[],
        };
    }

    if (medicationFilterList.length > 0) {
        medicationFilterList = medicationFilterList.map((medicationFilter) => {
            return {
                tc_ahfs_number: medicationFilter.tc_ahfs_number,
                status: medicationFilter.status,
                activeList: medicationFilter.activeIngredients,
            };
        });
    }

    return {
        medicationFilter,
        medicationFilterList
    };
}
function mapDispatchToProps(dispatch) {
    return {
        create: (tc_ahfs_number, status, activeList) => dispatch(medicationFilterAction.create(tc_ahfs_number, status, activeList)),
        dispatch
    };
}

const temp = connect(mapStateToProps, mapDispatchToProps)(AddMedicationFilter);

export { temp as AddMedicationFilter };