import React from 'react';
import PropTypes from 'prop-types';


const RequiredLabel = (props) => (
    <label {...props} className="title">
        <span>{props.children}</span>
        <span className="text-danger"> *</span>
    </label>
);


RequiredLabel.propTypes = {
    children: PropTypes.node.isRequired
};


const temp = RequiredLabel;

export { temp as RequiredLabel };