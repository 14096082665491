import { loadingAction, alertAction, messageAction} from '../_actions';
import { sleepAssessmentService, paymentService } from '../_services';
import { errorHandling, getFaxNumber } from '../_helpers';
import { sleepAssessmentConstant } from '../_constants';


export const sleepAssessmentAction = {
    getPendingByPerson,
    create,
    updateStatus,
    openDialog,
    closeDialog,
    refund,
    goToSleepAssessment,
    setOpenPendingSleep,
    createFree
};


function getPendingByPerson(personId) {
    return dispatch => {
        sleepAssessmentService.getPendingByPerson(personId)
            .then(
                res => {
                    dispatch({
                        type: sleepAssessmentConstant.GET_PENDING_BY_PERSON_SUCCESS,
                        pendingList: res.data
                    });
                },
                error => {
                    errorHandling('sleep-assessment.action.getPendingByPerson', error, dispatch);
                }
            );
    };
}

function create(sleepInfoObj, sleepClinicObj, orderID, url = '') {
    return async (dispatch) => {
        try {
            dispatch(loadingOpen());
            const res1 = await sleepAssessmentService.create(sleepInfoObj, sleepClinicObj);
            const res2 = await paymentService.charge(orderID, '20.00', 'Sleep assessment charge');
            const sleepAssessment = res1.data;
            const personId = sleepAssessment.person
            const fax = { number: getFaxNumber(sleepClinicObj.faxNumber), url };
            await sleepAssessmentService.paymentCompleted(sleepAssessment._id, personId, res2.data._id, fax);
            dispatch(alertAction.success('Payment successful and Sleep Assessment sent to requested location.'));///
        } catch (error) {
            errorHandling('sleep-assessment.action.create', error, dispatch);
        } finally {
            dispatch(loadingClose());
        }
    };
}

function createFree(sleepInfoObj, sleepClinicObj, url = '') {
    return async (dispatch) => {
        try {
            dispatch(loadingOpen());
            const res1 = await sleepAssessmentService.create(sleepInfoObj, sleepClinicObj);
            const sleepAssessment = res1.data;
            const personId = sleepAssessment.person
            const fax = { number: getFaxNumber(sleepClinicObj.faxNumber), url };
            await sleepAssessmentService.paymentCompleted(sleepAssessment._id, personId, null, fax);
            dispatch(alertAction.success('Sleep Assessment sent to requested location.'));
        } catch (error) {
            errorHandling('sleep-assessment.action.create', error, dispatch);
        } finally {
            dispatch(loadingClose());
        }
    };
}

function updateStatus(id, status) {
    return async (dispatch) => {
        try {
            await sleepAssessmentService.updateStatus(id, status);
        } catch (error) {
            errorHandling('sleep-assessment.action.updateStatus', error, dispatch);
        }
    };
}

function openDialog(sleepAssessmentObj) {
    return {
        type: sleepAssessmentConstant.OPEN_DIALOG,
        sleepAssessmentObj
    };
}

function closeDialog() {
    return {
        type: sleepAssessmentConstant.CLOSE_DIALOG
    };
}

function refund(chargeId, sleepAssessmentId, personId) {
    return async (dispatch) => {
        try {
            dispatch(loadingOpen());
            dispatch(closeDialog());

            await paymentService.refund(chargeId, '20.00');
            const res = await sleepAssessmentService.refundSuccess(sleepAssessmentId, personId);
            
            dispatch({
                type: sleepAssessmentConstant.REFUND_SUCCESS,
                pendingList: res.data
            });
            dispatch(messageAction.countUnread());
            dispatch(alertAction.success('Refund sleep assessment payment successful.'));
        } catch (error) {
            errorHandling('sleep-assessment.action.refund', error, dispatch);
        } finally {
            dispatch(loadingClose());
        }
    };
}

function goToSleepAssessment(selectSleepAssessmentId) {
    if (selectSleepAssessmentId)
        return {
            type: sleepAssessmentConstant.GO_TO_SLEEPASSESSMENT,
            selectSleepAssessmentId
        }
    return {
        type: sleepAssessmentConstant.SET_OPEN_PENDING_SLEEP,
        isOpen: false
    }
}

function setOpenPendingSleep(isOpen = false) {
    return {
        type: sleepAssessmentConstant.SET_OPEN_PENDING_SLEEP,
        isOpen
    }
}


function loadingOpen() { return loadingAction.open(); }
function loadingClose() { return loadingAction.close(); }