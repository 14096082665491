import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { profileAction } from '../../_actions';
import { UserCredential, PersonalInfo, HealthDocument } from './';
import { UserEmail } from './userEmail';
import { env } from '../../_helpers';
import { userService } from '../../_services';
import { userAction } from '../../_actions';


class ProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const { dispatch, user } = this.props;

        if (!user) {
            dispatch(profileAction.get());
        }
    }

    onDeleteMeClick = async () => {
        await userService.deleteUserById(this.props.userId);
        this.props.logOut();
    }

    /**
     * Render the HTML template
     */
    render() {
        return (
            <div className="profile-page">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                    <h1 className="h2">Profile</h1>
                </div>
                <PersonalInfo />
                <hr className="mb-1" />
                <HealthDocument />
                <hr className="mb-1" />
                {/*<UserCredential />
                <hr className="mb-1" />*/}
                <UserEmail />
                <hr className="mb-1" />
                {env() == "auto" && <button 
                    data-test-id="profile-page__delete-me-button"
                    onClick={this.onDeleteMeClick}
                > Delete Me </button>}
            </div>
        );
    }
}


ProfilePage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object
};


function mapStateToProps(state) {
    let { user, status } = state.profile;
    const userId = state.authentication.user._id;

    if (status === 'changed') {
        user = undefined;
    }

    return {
        user,
        userId
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logOut: () => dispatch(userAction.logout()),
        dispatch
    };
}

const temp = connect(mapStateToProps, mapDispatchToProps)(ProfilePage);

export { temp as ProfilePage };
