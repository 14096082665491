import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogActions } from '@material-ui/core';
import { getUser, formatDateTime } from '../../_helpers';
import { noteAction, noteDraftAction, billAction, medicationBillAction} from '../../_actions';
import { PersonIcon } from '../../components';
import { NoteReply } from './';
import { multilineText } from '../../_helpers';
import { Element, scroller, animateScroll } from 'react-scroll';
import { medicationBillService } from '../../_services'


class Note extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSentToPatientPopUp: false,
            isSentToEmrPopUp:false,
            diagnosisCode: '',
            question:'',
            isAutoOpen: false,
        };
    }

    componentDidMount() {
        const { selectedNoteId, prescriptions } = this.props;
        // const tc_ahfs_number = prescriptions[0].medications[0].tc_ahfs_numbers[0];

        if (selectedNoteId) {
            // scroll to specific note
            scroller.scrollTo(selectedNoteId, {
                containerId: 'notes',
                duration: 1,
                smooth: true,
                offset: -300
            });
        } else {
            // scroll to bottom note
            animateScroll.scrollToBottom({
                containerId: 'notes',
                duration: 1,
                smooth: true
            });
        }

    }

    handleChange = event => {
        const { dispatch } = this.props;

        dispatch(noteAction.change(event.target.value));
    }

    handleDialogChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    handleEdit = (noteId) => {
        const { dispatch, editDraft } = this.props;

        dispatch(noteAction.edit(noteId));

        // close edit draft if draft is in edit mode
        if (editDraft) {
            dispatch(noteDraftAction.close());
        }
    }

    handleSave = () => {
        const { dispatch, editNoteId, personId, text } = this.props;
        const fromObj = getUser().Person;

        dispatch(noteAction.save(editNoteId, personId, fromObj, text));
    }

    handleCancel = () => {
        const { dispatch } = this.props;

        dispatch(noteAction.cancelEdit());
    }

    // setQuestion = async () =>{
    //     const { dispatch, note, prescriptions }  = this.props;
    //     const lastVersion = [...note.versions].pop(); 
    //     let text = lastVersion.text.replace(/\n\[Signed.+/,'');
    //     text = text.replace(/\n\[Sent.+/, '');
    //     const tc_ahfs_number = prescriptions[0].medications[0].tc_ahfs_numbers[0];
    //     await dispatch(medicationBillAction.getQuestion(tc_ahfs_number));
    //     this.setState({
    //         question: text,
    //         isAutoOpen: true
    //     }, this.setState({
    //         isSentToPatientPopUp:true
    //     }));
    // }

    setQuestion = async () =>{
        const { dispatch, note, prescriptions }  = this.props;
        const lastVersion = [...note.versions].pop(); 
        let text = lastVersion.text.replace(/\n\[Signed.+/,'');
        text = text.replace(/\n\[Sent.+/, '');
        const tc_ahfs_number = prescriptions[0].medications[0].tc_ahfs_numbers[0];
        await dispatch(medicationBillAction.getQuestion(tc_ahfs_number));
        this.setState({
            question: text,
            isAutoOpen: true
        });
    }

    sendNote = async (note) => {
        const { dispatch, personId, person, prescriptions } = this.props;
        const fromObj = getUser().Person;
        const to = personId;
        const subject = 'Doctor sent you a note';
        const lastVersion = [...note.versions].pop();
        const prescriptionId = prescriptions[0]._id;
        // const billWay = "msg";
        // const billObject = { personId, prescriptionId, diagnosisCode, billWay }
        // const tc_ahfs_number = prescriptions[0].medications[0].tc_ahfs_numbers[0];

        dispatch(noteAction.sendNote(note._id, fromObj, to, subject, lastVersion, prescriptionId));
        dispatch(noteAction.sendNoteCcToEmr(lastVersion, person, note._id, personId, fromObj));
        
        this.setState({ isSentToPatientPopUp: false });
        
        // let isDiagnosisCodeExists, diagnosisCode;
        // const prescription = prescriptions[0]
        // if (prescription.payment == null) {
        //     if (prescription.medications[0].tc_ahfs_numbers) {
        //         const tc_ahfs_number = prescription.medications[0].tc_ahfs_numbers[0];
        //         const res = await medicationBillService.getDiagnosisCode(tc_ahfs_number);
        //         isDiagnosisCodeExists = res.data.isDiagnosisCodeExists;
        //         diagnosisCode = res.data.diagnosisCode;
        //     }
            
        //     if (isDiagnosisCodeExists) {
        //         const { _id: personId } = prescription.person;
        //         const { _id: prescriptionId } = prescription;
        //         const obj = { personId, prescriptionId, diagnosisCode, billWay };

        //         dispatch(billAction.createBill(obj));


        //     } else {
        //         dispatch(billAction.openBillDialog(prescription, billWay));

        //     }
        // }
    }

    sendNoteCancel = () => {
        this.setState({isSentToPatientPopUp: false});
        // this.setQuestion(); // // uncomment if auto note is needed
    }

    sendNoteToEmr = (note) => {
         const { dispatch, person, personId} = this.props;
         const fromObj = getUser().Person;
         const lastVersion = [...note.versions].pop(); 
          dispatch(noteAction.sendNoteToEmr(lastVersion, person, note._id, personId, fromObj));
          this.setState({ isSentToEmrPopUp:false });
    }

    openNoteVersion = (note) => {
        const { dispatch } = this.props;
        dispatch(noteAction.openRevision(note));
    }

    saveBillInfo = ( ) => {
        const { dispatch, prescriptions } = this.props;
        const { question } = this.state;
        const tc_ahfs_number = prescriptions[0].medications[0].tc_ahfs_numbers[0];
        dispatch(medicationBillAction.createOrUpdate('', question, tc_ahfs_number));
        this.setState({ isAutoOpen: false});
    }

    renderQuestionAuto() {

        const { prescriptions, isQuestionExists, medicationBillList } = this.props;
        const { question } = this.state;
        if (isQuestionExists == undefined || medicationBillList == undefined){
            return <div/>
        }
        const medication = prescriptions[0].medications[0];
        const tc_ahfs_number = prescriptions[0].medications[0].tc_ahfs_numbers[0];
        const headerContent =`Save question for the ${medicationBillList.length} medications below : `;
      
        return <Dialog
            fullWidth={true}
            maxWidth="md"
            open={true}
        >
            <DialogContent className="text-center bg-success text-white">
                <div style={{ fontSize: 16 }}>
                    <h3>{headerContent}</h3>
                    <div>
                        <textarea className="form-control " name="question"
                            id="question" value={question}
                            onChange={this.handleDialogChange}
                        >
                        </textarea>
                    </div>
                    <div className = "mt-2"> 
                    {medicationBillList.map(item => {
                        return (
                            <span>{`${item.name} (${item.administrations[0]}/${item.formulations[0] || ''}), `}</span>
                        )
                    })}
                    </div>
                </div>

            </DialogContent>
            <DialogActions className="d-flex justify-content-center m-3">
                <button className="btn btn-success mr-2 mt-4 btn-block"  onClick={ this.saveBillInfo}>Yes</button>
                <button className="btn btn-outline-secondary mr-2 mt-4 btn-block" onClick={() => this.setState({ isAutoOpen: false})}>No</button>
            </DialogActions>
        </Dialog>
    }

    renderSentToPatient() {
        const { isSentToPatientPopUp } = this.state;
        const { note } = this.props;

        return <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={isSentToPatientPopUp}
        >
            <DialogContent className="text-center bg-success text-white">
                <div style={{ fontSize: 16 }}>
                Are you sure you want to send this note to the Patient and EMR at the same time ?
                </div>
            </DialogContent>
            <DialogActions className="d-flex justify-content-center m-3">
                <button className="btn btn-success mr-2 mt-4 btn-block"  onClick={() => this.sendNote(note)}>Yes</button>
                <button className="btn btn-outline-secondary mr-2 mt-4 btn-block" onClick={() => this.sendNoteCancel()}>Cancel</button>
            </DialogActions>
        </Dialog>
    }
    renderSentToEmr() {
         const {isSentToEmrPopUp } = this.state;
         const { note } = this.props;
        return <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={isSentToEmrPopUp}
        >
            {/* <DialogTitle className="text-center bg-danger">
                <i className="fas fa-exclamation-triangle mb-2 text-white" style={{fontSize: "50px"}}></i>
                <div className="text-white">Redirecting</div>
            </DialogTitle> */}
            <DialogContent className="text-center bg-danger text-white">
                <div style={{ fontSize: 16 }}>
                Are you sure you want to send this note to the EMR ?
                </div>
            </DialogContent>
            <DialogActions className="d-flex justify-content-center m-3">
                <button className="btn btn-danger mr-2 mt-4 btn-block" onClick={() => this.sendNoteToEmr(note)}>Yes</button>
                <button className="btn btn-outline-secondary mr-2 mt-4 btn-block" onClick={() => this.setState({ isSentToEmrPopUp: false})}>Cancel</button>
            </DialogActions>
        </Dialog>
    }

    renderHeader() {
        const { editNoteId, note } = this.props;
        const { message, isSentToEmr } = note;
        const isEditNote = editNoteId === note._id;
        let isSent = isSentToEmr ? true: message !== undefined;
        const isSentToEMR = isSentToEmr;
        let action;

        if (!isSent) {
            // not send yet
            if (isEditNote) {
                action = (
                    <div>
                        <a href="#" onClick={() => this.handleSave(editNoteId)}>Save</a>
                        <a href="#" onClick={this.handleCancel}>Cancel</a>
                    </div>
                );
            } else {
                action = (
                    <div>
                        <a href="#" onClick={() => this.handleEdit(note._id)}>Edit</a>
                        {/* <a href="#" onClick={() => this.setState({ isSentToPatientPopUp: true})} data-test-id="note__send-button">To Patient</a>
                        <a href="#" onClick={() => this.setState({ isSentToEmrPopUp: true})}
                        >To EMR</a> */}
                    </div>
                );
            }
        }



        return (
            <>
                <div className="note-id">
                    {note._id}
                </div>
                {action}
            </>
        );
    }

    renderText() {
        const { editNoteId, note } = this.props;
        const isEditNote = editNoteId === note._id;
        const latestNote = [...note.versions].pop();


        if (isEditNote) {
            let text = this.props.text;

            if (text === undefined) {
                text = latestNote.text;
            }

            return (
                <textarea className="form-control" rows="4" placeholder="Your note..."
                    value={text} onChange={this.handleChange}></textarea>
            );
        } else {
            const { text } = latestNote;

            return (
                <div className="multiline-text">
                    {multilineText(text)}
                </div>
            );
        }
    }

    render() {
        const { isAutoOpen } = this.state;
        const { note, selectedNoteId, isQuestionExists } = this.props;
        const { message, isSentToEmr } = note;
        const latestNote = [...note.versions].pop();
        const { from, createdAt } = latestNote;
        const isSent = message !== undefined;
        const hasReply = message && message.replies.length > 0;
        const selected = note._id === selectedNoteId;

        let classes = 'note';

        if (isSent) classes += ' is-sent';
        if (isSentToEmr) classes += ' is-sent-to-emr';
        if (hasReply) classes += ' has-reply';
        if (selected) classes += ' selected';

        return (
            <Element name={note._id} className={classes}>
                <div className="header">
                    {this.renderHeader()}
                </div>
                <div className="text">
                    {this.renderText()}
                </div>
                <div className="footer">
                    <PersonIcon className="mr-2" person={from} />
                    <span>
                        <span className="created-at">
                            {`${formatDateTime(createdAt)}`}
                        </span>
                        <span className="revision">
                            <a href="#" onClick={() => this.openNoteVersion(note)}>Rev {note.versions.length}</a>
                        </span>
                    </span>
                </div>
                {this.renderSentToPatient()}
                {this.renderSentToEmr()}
                {!isQuestionExists && isAutoOpen && this.renderQuestionAuto()}
                <NoteReply note={note} />
            </Element>
        );
    }
}


Note.propTypes = {
    dispatch: PropTypes.func.isRequired,
    selectedNoteId: PropTypes.string,
    editNoteId: PropTypes.string,
    personId: PropTypes.string,
    text: PropTypes.string,
    editDraft: PropTypes.bool,
    note: PropTypes.object,
    person: PropTypes.object,
};


function mapStateToProps(state) {
    const { editNoteId, personId, text, selectedNoteId } = state.note;
    const { edit } = state.noteDraft;
    const { person } = state.patient;
    const { prescriptions } = state.prescription;
    const { medicationBillList, isQuestionExists } = state.medicationBill;


    return {
        selectedNoteId,
        editNoteId,
        personId,
        text,
        editDraft: edit,
        person,
        prescriptions,
        medicationBillList,
        isQuestionExists
    };
}

const temp = connect(mapStateToProps)(Note);


export { temp as Note };