import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { connect } from 'react-redux';
import { formatDateTime } from '../../_helpers';
import { BaseTable } from '../../components';


class AuditTable extends BaseTable {
    constructor(props) {
        super(props);
        this.state = {
            order: 'desc',
            orderBy: 'createdAt',
            page: 0,
            rowsPerPage: 10,
            open: false
        };
    }

    render() {
        const { auditList } = this.props;
        const { order, orderBy, rowsPerPage, page } = this.state;

        if (auditList) {
            return (
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Patient</TableCell>
                                <TableCell>Accessed By</TableCell>
                                <TableCell>Created</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.stableSort(auditList, this.getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(obj => {
                                    return (
                                        <TableRow key={obj._id} className="row">
                                            <TableCell className="message">
                                                <span>{obj.patient.FirstName} {obj.patient.LastName}</span>
                                            </TableCell>
                                            <TableCell className="person">
                                                <span>{obj.accessedBy.FirstName} {obj.accessedBy.LastName}</span>
                                            </TableCell>
                                            <TableCell className="created-at">
                                                {formatDateTime(obj.createdAt)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 20]}
                        component="div"
                        count={auditList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </Paper>
            );
        } else {
            return '';
        }
    }
}

function mapStateToProps(state) {
    const { auditList } = state.audit;

    return {
        auditList
    };
}

const temp = connect(mapStateToProps)(AuditTable);

export { temp as AuditTable };

