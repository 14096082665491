import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { auditAction } from '../../_actions';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

import 'react-datepicker/dist/react-datepicker.css';

class AuditSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const { dispatch, search } = this.props;
        dispatch(auditAction.get(search));
        dispatch(auditAction.getPatients());
        dispatch(auditAction.getAccessedBy());
    }

    changePatient = (option) => {
        const { dispatch, search } = this.props;
        search.patient = option;
        dispatch(auditAction.change(search));
    }

    changeAccessedBy = (option) => {
        const { dispatch, search } = this.props;
        search.accessedBy = option;
        dispatch(auditAction.change(search));
    }

    changeStartDate = (date) => {
        const { dispatch, search } = this.props;
        search.startDate = date;
        dispatch(auditAction.change(search));
    }

    changeEndDate = (date) => {
        const { dispatch, search } = this.props;
        search.endDate = date;
        dispatch(auditAction.change(search));
    }

    reset = () => {
        const { dispatch } = this.props;
        const search = {
            patient: null,
            accessedBy: null,
            startDate: null,
            endDate: null
        };
        dispatch(auditAction.change(search));
        dispatch(auditAction.get(search));
    }

    search = () => {
        const { dispatch, search } = this.props;
        dispatch(auditAction.get(search));
    }

    render() {
        const { search, patientList, accessedByList } = this.props;
        const startDateMax = search.endDate ? new Date(search.endDate - 1) : search.endDate;
        const endDateMin = search.startDate ? new Date(search.startDate + 1) : search.startDate;

        let patientOptions, accessedByOptions = [];

        if (patientList) {
            patientOptions = patientList.map((item) => ({
                value: item._id,
                label: `${item.firstName} ${item.lastName}`,
            }));
        }

        if (accessedByList) {
            accessedByOptions = accessedByList.map((item) => ({
                value: item._id,
                label: `${item.firstName} ${item.lastName}`,
            }));
        }

        return (
            <div id="audit-search" className="row mb-4">
                <div className="col-xl-2 col-md-4 mb-2">
                    <label htmlFor="accessedBy">Patient</label>
                    <Select
                        className="select" classNamePrefix="select"
                        options={patientOptions}
                        value={search.patient}
                        onChange={this.changePatient}
                        placeholder="All"
                        isClearable
                    />
                </div>
                <div className="col-xl-2 col-md-4 mb-2">
                    <label htmlFor="accessedBy">Accessed By</label>
                    <Select
                        className="select" classNamePrefix="select"
                        options={accessedByOptions}
                        value={search.accessedBy}
                        onChange={this.changeAccessedBy}
                        placeholder="All"
                        isClearable
                    />
                </div>
                <div className="d-xl-none col-md-4 mb-2"></div>
                <div className="col-xl-6 col-sm-9 mb-2">
                    <label htmlFor="startDate">Created</label>
                    <div>
                        <DatePicker
                            selected={search.startDate}
                            onChange={this.changeStartDate}
                            selectsStart
                            startDate={search.startDate}
                            endDate={search.endDate}
                            maxDate={startDateMax}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            isClearable={true}
                            placeholderText="Start Date"
                        />
                        <span> to </span>
                        <DatePicker
                            selected={search.endDate}
                            onChange={this.changeEndDate}
                            selectsEnd
                            startDate={search.startDate}
                            endDate={search.endDate}
                            minDate={endDateMin}
                            maxDate={new Date()}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            isClearable={true}
                            placeholderText="End Date"
                        />
                    </div>
                </div>
                <div className="col-md-2 col-sm-3 mb-2 d-flex align-items-end">
                    <button className="btn btn-outline-secondary mr-2" onClick={this.reset}>Reset</button>
                    <button className="btn btn-primary" onClick={this.search}>Search</button>
                </div>
            </div>
        );
    }
}


AuditSearch.propTypes = {
    dispatch: PropTypes.func,
    search: PropTypes.object,
    patientList: PropTypes.array,
    accessedByList: PropTypes.array
};


function mapStateToProps(state) {
    let { search } = state.audit;
    const { patientList, accessedByList, auditList } = state.audit;

    if (search === undefined) {
        search = {
            patient: null,
            accessedBy: null,
            startDate: null,
            endDate: null
        };
    }

    return {
        patientList,
        accessedByList,
        search,
        auditList
    };
}

const temp = connect(mapStateToProps)(AuditSearch);

export { temp as AuditSearch };

