import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { alertAction } from '../../_actions';
import { BadgeItem } from '../../components/BadgeItem';


class OtherSurgery extends Component {
    constructor(props) {
        super(props);

        // init
        this.state = {
            item: '',
            list: props.value
        };
    }

    handleChange = (event) => {
        this.setState({
            item: event.target.value
        });
    }

    addItem = (event) => {
        event.preventDefault();

        const { item, list } = this.state;
        const newList = [...list, item];

        if (item !== '') {
            this.setState({
                item: '',
                list: newList            // es6 spread operator
            });

            // match the event.target structure
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: newList
                }
            });
        } else {
            this.props.dispatch(alertAction.error('Other surgery is required.'));
        }
    }

    removeItem = (index) => {
        const list = this.state.list;

        list.splice(index, 1);

        this.setState({
            list
        });

        // match the event.target structure
        this.props.onChange({
            target: {
                name: this.props.name,
                value: list
            }
        });
    }

    renderInput() {
        return (
            <>
                <input type="text" className="form-control" placeholder="Please specify the surgery"
                    value={this.state.item} onChange={this.handleChange} />
                <button className="btn btn-outline-danger" onClick={this.addItem}>
                    Save
                </button>
            </>
        );
    }

    renderList() {
        const { list } = this.state;

        if (list.length > 0) {
            return (
                <div className="list">
                    {list.map((item, index) => {
                        return (
                            <BadgeItem key={index} item={item} onClick={() => this.removeItem(index)} />
                        );
                    })}
                </div>
            );
        }

        return '';
    }

    render() {
        return (
            <div id="other-surgery">
                {this.renderInput()}
                {this.renderList()}
            </div>
        );
    }
}


OtherSurgery.propTypes = {
    dispatch: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.array
};


const temp = connect()(OtherSurgery);

export { temp as OtherSurgery };