import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'

import { teleplanService } from '../../_services/tele-plan.service';
import { history } from '../../_helpers';


class EditInvoicePage extends Component {
    constructor() {
        super();
        this.state = {
            list: []
        }
    }

    async componentDidMount() {
        const list = await teleplanService.getProblematicInvoiceList();
        console.log(list)
        this.setState({ list });
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Edit Invoice</h1>
                </div>
                <div style={{fontSize: "20px"}}>
                    <div className="row">
                        <div className="col-md-1 text-center">Status</div> 
                        <div className="col-md-2 text-center">Invoice Date</div> 
                        <div className="col-md-4 text-center">Descriptions</div>
                        <div className="col-md-1 text-center">Charges</div>
                        <div className="col-md-1 text-center">Credit</div>
                        <div className="col-md-1 text-center">Tax</div>
                        <div className="col-md-1 text-center">Total</div>
                        <div className="col-md-1 text-center">Invoice</div>
                    </div>
                    <hr/>
                    {this.state.list.map(element => {
                        return <div className="row">
                            <div className="col-md-1 text-center">Rejected</div>
                            {/* <div className="col-md-4 text-center">{element.sequence}</div>  */}
                            <div className="col-md-2 text-center">{moment(element.time).format('YYYY-MM-DD @ HH:mm a')}</div>
                            <div className="col-md-4 text-left">{element.description}</div>
                            <div className="col-md-1 text-center">0</div>
                            <div className="col-md-1 text-center">0</div>
                            <div className="col-md-1 text-center">0</div>
                            <div className="col-md-1 text-center">0</div>
                            {element.bill && <div className="col-md-1 text-center">
                                <button type="button" className="btn btn-outline-primary"
                                    onClick={() => {
                                        console.log(element.bill)
                                        history.push(`/edit-invoice/${element.bill}`);
                                    }}
                                >
                                    View
                                </button>
                                {/*element.bill*/}
                            </div>}
                        </div>
                    })}
                </div>
            </div>
        );
    }
}


EditInvoicePage.propTypes = {
    dispatch: PropTypes.func.isRequired
};


const temp = connect()(EditInvoicePage);

export { temp as EditInvoicePage };

