import { medicationBillConstant, userConstant } from '../_constants';

export function medicationBill(state = {}, action) {
    switch (action.type) {
        case medicationBillConstant.GET_LISTS_SUCCESS_ALL:
        case medicationBillConstant.DELETED:
        case medicationBillConstant.ADDED:
            return {
                ...state,
                medicationBillListAll: action.medicationBillListAll,
            }
        case medicationBillConstant.GET_LISTS_SUCCESS_DIAGNOSIS:
        // case medicationBillConstant.CREATED:
       
            return {
                ...state,
                medicationBillList: [...(action.medicationBillList || [])],
                isDiagnosisCodeExists: action.isDiagnosisCodeExists

            };
        case medicationBillConstant.GET_LISTS_SUCCESS_QUESTION:

            return {
                ...state,
                medicationBillList: [...(action.medicationBillList || [])],
                isQuestionExists: action.isQuestionExists

            };
        // case medicationFilterConstant.CHANGE:
        //     return {
        //         ...state,
        //         medicationFilter: { ...action.medicationFilter }
        //     };
        case medicationBillConstant.DIAGNOSIS_CODE_EXIST:
            return {
                ...state,
                isDiagnosisCodeExists: action.isDiagnosisCodeExists,   
                diagnosisCode: action.diagnosisCode
            }
        case medicationBillConstant.QUESTION_EXIST:
            return {
                ...state,
                isQuestionExists: action.isQuestionExists,
                question: action.question
            }
        case userConstant.LOGOUT:
        case userConstant.BACK_TO_DASHBOARD:
            return {};
        default:
            return state;
    }
}