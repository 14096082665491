export const problemConstant = {
    GET_ALL: 'PROBLEM_GET_ALL',
    CAPTURE_REQUEST: 'PROBLEM_CAPTURE_REQUEST',
    OPEN_DIALOG: 'PROBLEM_OPEN_DIALOG',
    CHANGE: 'PROBLEM_CHANGE',
    SUBMIT_SUCCESS: 'PROBLEM_SUBMIT_SUCCESS',
    CLOSE_DIALOG: 'PROBLEM_CLOSE_DIALOG',
    COUNT_UNREAD: 'PROBLEM_COUNT_UNREAD',
    ACKNOWLEDGE_SUCCESS: 'PROBLEM_ACKNOWLEDGE_SUCCESS'
};
