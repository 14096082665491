import { prescriptionConstant, faxConstant, labtestConstant } from '../_constants';

export function labtest(state = {}, action) {
    switch (action.type) {
        case labtestConstant.GO_TO_LABTEST:
            return {
                ...state,
                selectLabtestId: action.selectLabtestId,
                openLabtest: true,       
            };
        case labtestConstant.GET_LAB_TEST_SUCCESS:
            return {
                ...state,
                pendingList: action.pendingList
            }

        case labtestConstant.SET_OPEN_LAB_TEST:
            return {
                ...state,
                openLabtest: action.isOpen
            }
        
        default:
            return state;
    }
}