import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { DialogActions } from '@material-ui/core';

import { alertAction } from '../../_actions';
import { sleepAssessmentAction } from '../../_actions/sleep-assessment.action';
import { getPersonName } from '../../_helpers';
import { CheckoutFormSleepment } from '../RequestPrescription/CheckoutFormSleepment';
import { paymentConstant } from '../../_constants/payment.constant';
import getCompUrl from '../../_helpers/getCompUrl';
import { default as SleepContent } from '../Patient/SleepAssessmentDialogFirst/content';
import getCanvasFromComp from '../../_helpers/getCanvasFromComp';
import getUrlFromMultiCanvas from '../../_helpers/getUrlFromMultiCanvas';



class CheckoutDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    // handlePay = async () => {
    //     const { dispatch, onPay, onClose } = this.props;
    //    // const { token } = await this.props.stripe.createToken({ name: getPersonName() });

    //     if (token) {
    //         onPay(token);
    //         onClose();
    //     } else {
    //         dispatch(alertAction.error('Please enter valid card information!'));
    //     }
    // }

    render() {
        const { isOpen, onClose, onPay, clinicObj, sleepInfoObj, medicalInfoObj, createFree, person } = this.props;

        return (
            <Dialog id="checkout-dialog"
                fullWidth={true}
                maxWidth="sm"
                open={isOpen}
            >
                <DialogTitle className="title">
                    <span>Confirm the selection?{/*Please enter your credit card information*/}</span>
                </DialogTitle>
                <DialogContent className="content">
                    {/* <CardElement /> */}
                    {paymentConstant.IS_FREE 
                        ? <div>
                            <div>Name: {clinicObj.name}</div>
                            <div>Address: {clinicObj.address}</div>
                            <div className="mb-2">Phone number: {clinicObj.phoneNumber}</div>
                            
                            <button 
                                className="btn btn-sm btn-outline-secondary mr-2" 
                                onClick={async() => {
                                    // const url = await getCompUrl(<SleepContent
                                    //     sleepAssessmentObj={{
                                    //         sleepInfo: sleepInfoObj,
                                    //         sleepClinic: clinicObj,
                                    //         person
                                    //     }}
                                    //     medicalInfoObj={medicalInfoObj}
                                    //     options={{
                                    //         isPreliminary:true,
                                    //         isAnonymous:true
                                    //     }}
                                    // />);
                                    const convas = await getCanvasFromComp(<SleepContent
                                        sleepAssessmentObj={{
                                            sleepInfo: sleepInfoObj,
                                            sleepClinic: clinicObj,
                                            person
                                        }}
                                        medicalInfoObj={medicalInfoObj}
                                        options={{
                                            isPreliminary:true,
                                            isAnonymous:true
                                        }}
                                    />);
                                    const url = await getUrlFromMultiCanvas([convas]);
                                    await createFree(sleepInfoObj, clinicObj, url);
                                    onClose();
                                }}
                            > 
                                Yes 
                            </button>
                            <button className="btn btn-sm btn-outline-secondary" onClick={onClose}> No </button>
                        </div>
                        : 
                        <CheckoutFormSleepment onPay={onPay} clinicObj={clinicObj} onClose={onClose} />
                    }
                    
                </DialogContent>
                <DialogActions className="action">
                    <button className="btn btn-sm btn-outline-secondary" onClick={onClose}>Close</button>
                    {/* <button className="btn btn-sm btn-primary" onClick={this.handlePay}>Pay</button> */}
                </DialogActions>
            </Dialog>
        );
    }
}

CheckoutDialog.propTypes = {
    dispatch: PropTypes.func,
    stripe: PropTypes.object,
    clinicObj: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onPay: PropTypes.func
};

function mapStateToProps(state) {
    const { sleepInfoObj } = state.sleepInfo;
    const { medicalInfoObj } = state.medicalInfo;
    const { Person: person } = state.authentication.user;
    return {
        sleepInfoObj,
        medicalInfoObj,
        person
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createFree: (sleepInfoObj, sleepClinicObj, url = '') => 
            dispatch(sleepAssessmentAction.createFree(sleepInfoObj, sleepClinicObj, url))
    }
}

const temp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    // injectStripe,
)(CheckoutDialog);

export { temp as CheckoutDialog };
