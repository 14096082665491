import { userConstant, profileConstant } from '../_constants';

export function profile(state = {}, action) {
    switch (action.type) {
        case profileConstant.GET:
            return {
                ...state,
                user: { ...action.user }
            };        
        case profileConstant.CHANGE:
            return {
                ...state,
                status: 'changed',
                editSection: state.editSection,
                originalUser: state.originalUser ? { ...state.originalUser } : { ...state.user },
                user: { ...action.user }
            };
        case userConstant.UPDATE_REQUEST:
            return {
                ...state,
                status: 'updating'
            };
        case profileConstant.UPDATE_SUCCESS:
        case userConstant.UPDATE_SUCCESS:
            return {
                ...state,
                user: { ...action.user },
                originalUser:{...state.user},
            };
        case profileConstant.EDIT_SECTION:
            return {
                ...state,
                editSection: action.editSection,
                user: state.originalUser ? { ...state.originalUser, Password:''} : { ...state.user, Password:'' }
              
            };
        case profileConstant.EDIT_SECTION_V2:
            return {
                ...state,
                editSection: action.editSection,
                // originalUser: state.user ? { ...state.user, Password: '' } : { ...state.originalUser, Password: '' },
                user: { ...state.user, Password: '' }
            };
        case userConstant.REGISTER_SUCCESS:
        case userConstant.LOGOUT:
        case userConstant.BACK_TO_DASHBOARD:
            return {};
        default:
            return state;
    }
}
