import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


class FormatPhoneNumber extends Component {
    constructor(props) {
        super(props);

        let firstDigits = '';
        let secondDigits = '' ;
        let thirdDigists = '';

        if(this.props.value ){
            const  { value } = this.props;
            firstDigits = value.slice(0,3);
            secondDigits = value.slice(3,6);
            thirdDigists = value.slice(6);
        }
        this.state = {
            firstDigits,
            secondDigits,
            thirdDigists
        }
        this.blurs = [false, false, false];
    }

    componentDidUpdate(prevProps){

        const thisValues = this.props.values || [];
        const preValues = prevProps.values || [];
        if ( 
            
            thisValues[0] !== preValues[0] 
            || thisValues[1] !== preValues[1] 
            || thisValues[2] !== preValues[2] 
        ) {
            this.setState({
                firstDigits: thisValues[0],
                secondDigits: thisValues[1],
                thirdDigists: thisValues[2]
            })
        }
    }

    changeValue = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        this.setState({
            [e.target.name]: value
        }, () => {
            const {firstDigits, secondDigits, thirdDigists } = this.state;
            let phoneNumber = firstDigits + secondDigits + thirdDigists;
            this.props.onChange(phoneNumber, [firstDigits, secondDigits, thirdDigists]);
            
            if (firstDigits.length > 2 && secondDigits.length < 3) 
                this.refs.secondDigits.focus();
            
            if (firstDigits.length > 2 && secondDigits.length > 2) 
                this.refs.thirdDigists.focus();
        })
    }


    onBlur = (e, ind) => {
        this.blurs[ind] = true;
        if (
            this.blurs[0] 
            && this.blurs[1]
            && this.blurs[2]
        )
        this.props.onBlur(e);
    }

    render() {
           
        const { firstDigits, secondDigits, thirdDigists } = this.state;
        const { isDisable } = this.props;
        return (
            <div className="d-flex format-phone-number">
                <div className="format-bracket">(</div>
                <input type="text" className="d-block form-control phone-number"
                    value={firstDigits} onChange={this.changeValue} required
                    name="firstDigits" maxlength="3" placeholder = "XXX"
                    disabled = {isDisable} onBlur = {(e) => this.onBlur(e, 0)}
                    ref="firstDigits"
                    data-test-id="format-phone-number__first-digits"
                />
                <div className="format-bracket">)</div>
                <div className="dash-line">-</div>
                <input type="text" className="d-block form-control phone-number"
                    value={secondDigits} onChange={this.changeValue} required
                    name="secondDigits" maxLength="3" placeholder = "XXX"
                    disabled = {isDisable} onBlur = {(e) => this.onBlur(e, 1)}
                    ref="secondDigits"
                    data-test-id="format-phone-number__second-digits"
                />
                <div className="dash-line">-</div>
                <input type="text" className="d-block form-control phone-number"
                    value={thirdDigists} onChange={this.changeValue} required
                    name="thirdDigists" maxLength="4" placeholder = "XXXX"
                    disabled = {isDisable} onBlur = {(e) => this.onBlur(e, 2)}
                    ref="thirdDigists"
                    data-test-id="format-phone-number__third-digits"
                />
            </div>
        );
    }
}


FormatPhoneNumber.propTypes = {
    dispatch: PropTypes.func,
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func
};


const temp = connect()(FormatPhoneNumber);

export { temp as FormatPhoneNumber};