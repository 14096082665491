import axios from 'axios';
import config from '../config';
import { getToken, getGuestToken } from '../_helpers';



export const userService = {
    login,
    updateDontShowLastLogin, 
    getUserEmailByToken,
    getUserByEmail,
    sendEmailInvitation,
    deleteUserById,
    createSouthVanPerson,
    updateIsFirstLogInShowUp,
    verifyPhoneNumber,
    submitPhoneCode,
    updatePreferredPharmacy
};


function login(email, password, role, ipObj, code, storedCode) {
    const url = `${config.apiHost}/user/authenticate`;
    const body = {
        Email: email,
        Password: password,
        Role: role,
        ipObj,
        code,
        storedCode
    };

    return axios.post(url, body);
}

function updateDontShowLastLogin(id, dontShowLastLogin) {
    const url = `${config.apiHost}/user/dont-show-last-login`;
    const body = {
        id,
        dontShowLastLogin
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

async function getUserEmailByToken() {
    try {
        const token = getToken();
        if (!token) throw({code: "NO_TOKEN"});

        axios.defaults.headers.common['x-access-token'] = token;
        const url = `${config.apiHost}/user/token`;
        const res = await axios.post(url);
        return res.data.Email;
    } catch (error) {
        if ((((error || {}).response || {}).data || {}).code || '' === "INVALID_TOKEN")
            throw({code: "INVALID_TOKEN"});
        throw ({code: "ERROR_GET_EMAIL_BY_TOKEN"});
    }
}

async function getUserByEmail(email) {
    try {
        const url = `${config.apiHost}/user/email/${email}`
        axios.defaults.headers.common['x-access-token'] = getToken();
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw (error);
    }
}

function sendEmailInvitation(email) {
        const url = `${config.apiHost}/user/recovery/send-email`;
        const body = {
            email: email,
        };
    return axios.post(url, body);
}

async function deleteUserById(
    id = null
) {
    try {
        if (id == null) return;

        const url = `${config.apiHost}/user/${id}`;
        axios.defaults.headers.common['x-access-token'] = getToken();
        
        return await axios.delete(url);
    } catch (error) {
        console.log(error);
    }
}

async function createSouthVanPerson(user){
    try {
        const url = `${config.apiHost}/user/create-person`;
        const body = {
            user
        }
       
        return await axios.post(url, body);
    } catch (error) {
        throw(error);
    }
}

function updateIsFirstLogInShowUp(id){

    axios.defaults.headers.common['x-access-token'] = getToken();
    const url = `${config.apiHost}/person/first-login/${id}`;

    return axios.put(url);
}

function updatePreferredPharmacy(personId, preferredPharmacy, southVanMedToken) {
    const url = `${config.apiHost}/person/prefer-pharmacy`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    const body = {
        personId,
        preferredPharmacy,
        southVanMedToken
    }

    return axios.post(url, body);
}

function verifyPhoneNumber(personId) {
    const url = `${config.apiHost}/person/verify-number/${personId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    return axios.get(url);
}

function submitPhoneCode(personId, verificationCode) {
    const url = `${config.apiHost}/person/verify-code/activate`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    const body = {
        personId,
        verificationCode
    }
    return axios.post(url, body);

}