import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { appType } from  '../../_helpers'
import queryString from 'query-string';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogActions } from '@material-ui/core';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            isDialogOneOpen:false,
            isDialogTwoOpen:false,
            isDialogThreeOpen:false,
            showFeesModal: false,
        }
    }

    handleFeesModalOpen = () => {
        this.setState({ showFeesModal: true });
    };

    handleFeesModalClose = () => {
        this.setState({ showFeesModal: false });
    };
    
    componentDidMount() {
        this.redirect(this.props);
    }

    redirect(props) {
        const { location } = props;
        let params = queryString.parse(location.search);
        if (typeof params !== 'undefined' && params.redirect) {
            if (params.redirect == "recovery/password")
                props.history.push(`/recovery/password?redirect=${params.redirect}&token=${params.token}`);
            else
                props.history.push(`/registration?redirect=${params.redirect}&token=${params.token}`);
        }
    }

    redirectOfficeRegister(props) {
        const { location }  = props;
        let params = queryString.parse(location.search);
        if(typeof params !== 'undefined' && params.redirect) {
            props.history.push(`/registration?redirect=${params.redirect}&token=${params.token}`);
        }
    }

    handleClickOneOpen = () => {
        this.setState({
            isDialogOneOpen:true,
        })
    }

    handleClickTwoOpen = () => {
        this.setState({
            isDialogTwoOpen:true,
        })
    }

    handleClickThreeOpen = () => {
        this.setState({
            isDialogThreeOpen:true,
        })
    }

    handleCloseOne = () => {
        this.setState({
            isDialogOneOpen:false,
        })

    }

    handleCloseTwo = () => {
        this.setState({
            isDialogTwoOpen:false,
        })

    }

    handleCloseThree = () => {
        this.setState({
            isDialogThreeOpen:false,
        })

    }
    handleStyle = () => {
        return {
         fontSize:"20px",
        }
    }

    
    renderDialogOne = () => {
        const { isDialogOneOpen } = this.state;
        return (
            <>
            <Dialog 
                fullWidth={true}
                maxWidth="md"
                open={isDialogOneOpen}
                onClose={this.handleCloseOne}
            >
                <DialogContent>
                    <div style={{height:"400px"}}>
                            
                           {/* <p style={this.handleStyle()}>AskMedication.com allows you to connect with a doctor who will carefully review your prescription requests. A private fee is incurred. Please see our <Link to="#" onClick={this.handleFeesModalOpen}>Patient Fee Schedule</Link> for details. 
                            Please note that some medications, such as controlled substances, cannot be obtained using AskMedication.com. If we cannot complete your prescription request, we will refund 100% of your fees. Once approved, we will submit your prescription(s) to the pharmacy of your choice within British Columbia.
                            </p>
                            <p style={this.handleStyle()}>Our aim is to increase the efficiency of healthcare. Please note that the services provided by AskMedication.com are only available to individuals aged 19 years or older who are physically located in British Columbia, Canada.</p> */}

                            {/* <p style={this.handleStyle()}>AskMedication.com allows you to connect with a doctor who will carefully review your prescription requests. Given the COVID-19 pandemic ALL our services are free if you have a valid B.C. Services Card (CareCard) or equivalent provincial health card (Alberta, Ontario, etc.). Please note that some medications, such as controlled substances, cannot be obtained using AskMedication.com. Once approved, we will submit your prescription(s) to the pharmacy of your choice within British Columbia.
                            </p> */}
                            <p style={this.handleStyle()}>AskMedication.com allows you to connect with a doctor who will carefully review your prescription requests.
                            </p>
                            <p style={this.handleStyle()}>Please note that some medications, such as controlled substances, cannot be obtained using AskMedication.com.</p>
                            <p style={this.handleStyle()}>Once approved, we will submit your prescription(s) to the pharmacy of your choice within British Columbia.</p>
                            {this.renderSignButtons()}
                            {this.renderFeesDialog()}
                    </div>
                </DialogContent>
                <DialogActions>
                <button className="btn btn-outline-primary btn-sm mt-2 mr-2" onClick={this.handleCloseOne}>
                     Close
                </button>
                </DialogActions>
            </Dialog>
            </>

        )
    }

    renderDialogTwo = () => {

        const { isDialogTwoOpen } = this.state;
        const homePage = <a href={window.location.origin}>AskMedication.com</a>;
        return (
            
            <Dialog 
                fullWidth={true}
                maxWidth="md"
                open={isDialogTwoOpen}
                onClose={this.handleCloseTwo}
            >
                <DialogContent>
                    <div style={{ height: "400px" }}>
                        <p style={this.handleStyle()}>Do you snore? Is your sleep not restful? Are you fatigued during the day? {homePage} allows you to request a sleep study for from your preferred sleep clinic. The sleep study takes place at your home. The sleep clinic will provide you a sleep apnea testing machine that you will use for one night. </p>
                        <p style={this.handleStyle()}>In order to obtain a sleep study, the {homePage} doctor will ask you questions about your health. You will also be instructed to obtain blood pressure readings at home or at a location such as a pharmacy. There is no charge for this service if you have a valid B.C. Services Card (CareCard).</p>
                        <p style={this.handleStyle()}>Please note that the services provided by {homePage} are only available to individuals aged 19 years or older. You must also be physically located in British Columbia, Canada when you request our services.</p>
                        {this.renderSignButtons()}
                        {this.renderFeesDialog()}
                    </div>
                </DialogContent>
                <DialogActions>
                <button className="btn btn-outline-primary btn-sm mt-2 mr-2" onClick={this.handleCloseTwo}>
                     Close
                </button>
                </DialogActions>
            </Dialog>
            

        )
    }
    renderDialogThree = () => {

        const { isDialogThreeOpen } = this.state;
        return (
            
            <Dialog 
                fullWidth={true}
                maxWidth="md"
                open={isDialogThreeOpen}
                onClose={this.handleCloseThree}
            >
                <DialogContent>
                    <div style={{ height: "400px" }}>
                        <p style={this.handleStyle()}>
                            Patients will be able to obtain a referral to a medical specialist in British Columbia using <Link to="#" style={{ textDecoration: "underline" }}>AskMedication.com</Link>. Some restriction will apply.
                        </p>
                        {this.renderSignButtons()}
                    </div>
                </DialogContent>
                <DialogActions>
                <button className="btn btn-outline-primary btn-sm mt-2 mr-2" onClick={this.handleCloseThree}>
                     Close
                </button>
                </DialogActions>
            </Dialog>
            

        )
    }
    renderSignButtons = () => {
        return <div style = {{
            fontSize        : "30px",
            display         : "flex",
            justifyContent  : "space-evenly", 
            marginTop       : "80px"
        }}>
            <Link to="/login">Sign in</Link>
            <Link to="/registration">Create Account</Link>
        </div>
    }
    renderFeesDialog = () => {
        return (
            <>
                <Dialog
                    open={this.state.showFeesModal}
                    onClose={this.handleFeesModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Fees"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">                       
                        Our services are not covered by the B.C. Services Card (CareCard). Private charges will apply.
                        </DialogContentText>
                        {/* <hr />
                        <DialogContentText>
                            <span className="m-3">1st prescription: <strong>$20.00</strong></span>
                        </DialogContentText>
                        <DialogContentText>
                            <span className="m-3">Subsequent prescription(s): <strong>$10.00</strong></span>
                        </DialogContentText>
                        <DialogContentText>
                            <span className="m-3">Sleep apnea assessment request: <strong>$20.00</strong></span>
                        </DialogContentText> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleFeesModalClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    renderLearnMoreDialog = () => {
        return (
            <div>
                <Dialog
                    open={this.state.isModalOpen}
                    onClose={() => this.setState({isModalOpen: false})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="sm"
                    className="mbr-popup cid-s9MPQnQRip learn-more-dialog"
                    style={{height: "100%"}}
                >
                    <div style={{width: "500px"}}>
                    <div
                        className="modal-header pb-0" id="alert-dialog-title"
                    >
                        <h5 className="modal-title mbr-fonts-style">
                            AskMedication.com
                        </h5>
                        <button type="button" 
                            class="close" 
                            data-dismiss="modal" 
                            aria-label="Close"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({isModalOpen: false})
                            }}
                        >
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="currentColor">
                                <path d="M31.797 0.191c-0.264-0.256-0.686-0.25-0.942 0.015l-13.328 13.326c-0.598 0.581 0.342 1.543 0.942 0.942l13.328-13.326c0.27-0.262 0.27-0.695 0-0.957zM14.472 17.515c-0.264-0.256-0.686-0.25-0.942 0.015l-13.328 13.326c-0.613 0.595 0.34 1.562 0.942 0.942l13.328-13.326c0.27-0.262 0.27-0.695 0-0.957zM1.144 0.205c-0.584-0.587-1.544 0.336-0.942 0.942l30.654 30.651c0.613 0.625 1.563-0.325 0.942-0.942z"></path>
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p 
                            id="alert-dialog-description"
                            className="mbr-text mbr-fonts-style"
                        >                       
                            AskMedication.com allows you to connect with a doctor who will carefully review your prescription requests.
                            <br/>
                            <br/>
                            Please note that some medications, such as controlled substances, cannot be obtained using AskMedication.com.
                            <br/>
                            <br/>
                            Once approved, we will submit your prescription(s) to the pharmacy of your choice within British Columbia.
                        </p>
                        {/* <hr />
                        <DialogContentText>
                            <span className="m-3">1st prescription: <strong>$20.00</strong></span>
                        </DialogContentText>
                        <DialogContentText>
                            <span className="m-3">Subsequent prescription(s): <strong>$10.00</strong></span>
                        </DialogContentText>
                        <DialogContentText>
                            <span className="m-3">Sleep apnea assessment request: <strong>$20.00</strong></span>
                        </DialogContentText> */}
                        <div>
                        <div class="card-img mbr-figure">
                            <img alt="Mobirise" title="" data-alt="Mobirise" 
                                data-src={require('../../assets/images/mbr-936x597.jpg')}
                                class="" 
                                src={require('../../assets/images/mbr-936x597.jpg')}
                            />
                        </div>
                            

                            
                        </div>
                    </div>
                    </div>
                </Dialog>
            </div>
        )
    }

    renderWhatWeOffer = () => {
        return(
            <section class="features1 cid-s8mZZpGuuw home-container__offer" id="features03-c">
                <div class="container">
                    <div class="row align-center justify-content-center">

                        <div class="col-12 col-md-12 col-lg-6 pb-5">

                            <h3 class="main-title align-center pb-3 mbr-bold mbr-black mbr-fonts-style">What We Offer for You</h3>
                            <h4 class="main-subtitle align-center mbr-regular mbr-black mbr-fonts-style">Please allow us to demonstrate our excellent customer care by signing up for our services.</h4>

                        </div>

                    </div>
                    <div class="row align-center justify-content-center">


                        <div class="card col-12 col-md-6 col-lg-4">
                            <div class="card-wrapper">

                                <div class="card-box">
                                    {/* <span class="mbr-iconfont icon54-v1-medicine"></span> */}
                                    <div className="img-wrapper">
                                        <img alt="Mobirise" title="" data-alt="Mobirise" class="" 
                                            src={require('../../assets/img/prescription-bottle.png')}
                                        />
                                    </div>
                                    <h5 class="card-title mbr-semibold mbr-black mbr-fonts-style">Prescription on demand&nbsp;</h5>
                                    <p class="card-text mbr-regular pb-2 mbr-black mbr-fonts-style">Refill most of your regularly taken medications.</p>
                                    <h6 class="link mbr-regular pt-1 mbr-black mbr-fonts-style"><a href="#" class="text-primary" data-toggle="modal" data-target="#mbr-popup-20" onClick={() => this.setState({isModalOpen: true})}>LEARN MORE</a>
                                    </h6>

                                </div>
                            </div>
                        </div>
                        <div class="card col-12 col-md-6 col-lg-4">
                            <div class="card-wrapper">

                                <div class="card-box">
                                    <div className="img-wrapper">
                                        <img alt="Mobirise" title="" data-alt="Mobirise" class="" 
                                            src={require('../../assets/img/waiting.png')}
                                        />
                                    </div>
                                    <h5 class="card-title mbr-semibold mbr-black mbr-fonts-style">No more waiting in line</h5>
                                    <p class="card-text mbr-regular pb-2 mbr-black mbr-fonts-style">We message you back ASAP to process your request.</p>
                                    <h6 class="link mbr-regular pt-1 mbr-black mbr-fonts-style"><a href="#" class="text-primary" data-toggle="modal" data-target="#mbr-popup-20"
                                    onClick={() => this.setState({isModalOpen: true})}
                                    >LEARN MORE</a>
                                    </h6>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )}

    renderJumbotron = () => {
        return (
            <section class="features2 cid-s8e6SevhPb jumbotron" id="features2-5">
                <div class="container">
                    <div class="contents">
                        <h3 class="mbr-subtitle mbr-fonts-style mbr-bold">OUR NEWS</h3>
                        <Link to="/blog" class="mbr-section-title mbr-fonts-style mbr-bold displ btn btn-black-outline">Read our Latest Posts</Link>
                        <div className="mt-4">
                            <Link
                                to="/blog/post-2"
                                class="mbr-section-text mbr-fonts-style btn btn-black-outline mbr-light"
                                style={{ fontWeight: "300" }}
                            >My doctor told me that they would fax my prescription to the pharmacy. Really? Do people still use fax machines?</Link>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="card p-3 col-12">
                            <div class="card-wrapper">
                                <div class="card-img">
                                    <img alt="Mobirise" title="" data-alt="Mobirise"
                                        data-src={require('../../assets/images/pexels-polina-tankilevitch-3873171-1076x717.jpeg')}
                                        src={require('../../assets/images/pexels-polina-tankilevitch-3873171-1076x717.jpeg')}
                                        class=""
                                    />
                                    <div class="mbr-link-btn meta-category">
                                        <Link to="/registration"
                                            class="btn btn-md btn-white-outline"

                                        >Prescription

                                        </Link>
                                    </div>
                                </div>
                                <div class="card-box align-left">

                                    <div className="mb-2">
                                        <Link
                                            to="/registration"
                                            class="card-title align-left mbr-fonts-style"
                                        >Obtain a Doctor's Prescription Online</Link>
                                    </div>
                                    <p class="mbr-text align-left mbr-fonts-style">Skip the wait at the medical clinic and obtain your prescription online. Pick up your medication at any pharmacy in British Columbia.
                                     <br />
                                     <br />
                                    </p>

                                    <div class="mbr-link-btn">
                                        <a className="btn btn-md btn-black-outline"
                                            //href="page4.html"
                                            // to='/blog'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ isModalOpen: true })
                                            }
                                            }
                                        >
                                            <span class="mbri-arrow-next mbr-iconfont mbr-iconfont-btn">
                                            </span>
                                            {/* <font face="MobiriseIcons">
                                                <br />
                                            </font> */}
                                LEARN MORE
                            </a>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* <div class="card p-3 col-12 col-md-6">
                            <div class="card-wrapper">
                                <div class="card-img">
                                    <img
                                        alt="Mobirise"
                                        title=""
                                        data-alt="Mobirise"
                                        data-src={require("../../assets/images/sleep-apnea-color-paper-2-pen.png")}
                                        class=""
                                        src={require("../../assets/images/sleep-apnea-color-paper-2-pen.png")}
                                    />
                                <div class="mbr-link-btn meta-category">
                                    <Link to="/registration"
                                        class="btn btn-md btn-white-outline"

                                    >ASSESSMENT
                                    </Link>
                                </div>
                                </div>

                                <div class="card-box align-left">

                                    <div className="mb-2">
                                        <Link
                                            to="/registration"
                                            class="card-title align-left mbr-fonts-style"
                                        > Sleep Apnea Assessments </Link>
                                    </div>
                                    <p class="mbr-text align-left mbr-fonts-style">Let your preferred sleep clinic in British Columbia know that you require a home assessment for sleep apnea.
                                    <br />
                                    <br />
                                    </p>

                                    <div class="mbr-link-btn">
                                        <a
                                            class="btn btn-md btn-black-outline"
                                            //href="page4.html"
                                            // to='/blog'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ isModalOpen: true })
                                            }
                                            }
                                        >
                                            <span class="mbri-arrow-next mbr-iconfont mbr-iconfont-btn"></span>
                                LEARN MORE
                            </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        );
    }
 
    renderAdvantage = () => {
        return (
            <section class="counter02 cid-rSbev8cAt7" id="counters2-6">
                <div class="container-fluid">
                    <div class="row justify-content-center align-center">
                        <div class="col-md-3 col-sm-6 stats-item">

                            <div class="fld-wrap">
                                <div class="number d-flex justify-content-center">
                                    <h3 class="count counter mbr-fonts-style mbr-bold">7000</h3>
                                    <h3 class="counter mbr-fonts-style mbr-bold ml-2">+</h3>
                                </div>
                                <h2 class="heading mbr-card-title mbr-fonts-style">MEDICATIONS</h2>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-6 stats-item col-lg-3">

                            <div class="fld-wrap">
                                <div class="number d-flex justify-content-center">
                                    <h3 class="count counter mbr-fonts-style mbr-bold">1000</h3>
                                    <h3 class="counter mbr-fonts-style mbr-bold ml-2">+</h3>
                                </div>
                                <h2 class="heading mbr-card-title mbr-fonts-style">PHARMACIES</h2>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-6 stats-item col-lg-3">

                            <div class="fld-wrap">
                                <div class="number d-flex justify-content-center">
                                    <h3 class="count counter mbr-fonts-style mbr-bold">02</h3>
                                    <h3 class="counter mbr-fonts-style mbr-bold ml-2">+</h3>
                                </div>
                                <h2 class="heading mbr-card-title mbr-fonts-style">PHYSICAL &nbsp;LOCATIONS</h2>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-6 stats-item col-lg-3">

                            <div class="fld-wrap">
                                <div class="number d-flex justify-content-center">
                                    <h3 class="count counter mbr-fonts-style mbr-bold">20</h3>
                                    <h3 class="counter mbr-fonts-style mbr-bold ml-2">+</h3>
                                </div>
                                <h2 class="heading mbr-card-title mbr-fonts-style">
                                    YEARS OF EXPERIENCE
                    </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    renderStats() {
        return (
            <div className="stats-info container-fluid">
                <div className="row ">
                    <div className="accolade col-sm-3 mx-auto">
                        <div class="icon-header">
                            <i className="fas fa-prescription-bottle-alt"></i>
                            <p className="accolade-header">Prescription on demand</p>
                            <p>Refill most of your regularly taken medications.</p>
                        </div>
                    </div>
                    <div className="accolade col-sm-3 mx-auto">
                        <div class="icon-header">
                            <i className="far fa-clock"></i>
                            <p className="accolade-header">No more waiting in line</p>
                            <p>We message you back ASAP to process your request.</p>
                        </div>
                    </div>
                    <div className="accolade col-sm-3 mx-auto">
                        <div class="icon-header">
                            <i className="fas fa-bed"></i>
                            <p className="accolade-header">Sleep Assessments</p>
                            <p>Provide a sleep apnea assessment request to your favorite sleep provider.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderClickInfo = () => {
        return (
            <section class="testimonials1 cid-s8HgOFvBHp" id="testimonials1-1y">
                <div class="container justify-content-center">
                    <div class="row justify-content-center">
                        <div class="col-sm-10 col-lg-8  align-center">
                            <div class="contents">
                                <h3 class="mbr-subtitle mbr-fonts-style mbr-bold">OUR LOCATIONS</h3>
                                <h1 class="mbr-section-title mbr-fonts-style mbr-bold">Physical Locations</h1>
                                <h2 class="mbr-section-text mbr-fonts-style mbr-light">We have 2 physical locations if more than a prescription refill is required. The 2 locations are the South Vancouver Medical Clinic and Richmond in-Store Medical Clinic.</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-xs-12 col-lg-4">
                            <div class="mbr-testimonial card mbr-testimonial-lg">
                                <div class="card-block mbr-fonts-style">
                                    <p><a href="http://www.southvanmed.com" target="_blank" class="text-white">South Vancouver Medical Clinic&nbsp;</a></p>
                                </div>
                                <div class="mbr-author card-footer">
                                    <div class="name-wrap">
                                        <p class="mbr-text mbr-author-desc mbr-normal mbr-fonts-style"><a href="http://www.southvanmed.com" target="_blank">Visit Website</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-lg-4">
                            <div class="mbr-testimonial card mbr-testimonial-lg">
                                <div class="card-block mbr-fonts-style">
                                    <p><a href="http://www.instoremed.com" target="_blank" class="text-white">Richmond in-Store Medical Clinic&nbsp;</a></p>
                                </div>
                                <div class="mbr-author card-footer">
                                    <div class="name-wrap">
                                        <p class="mbr-text mbr-author-desc mbr-normal mbr-fonts-style">
                                            <a href="http://www.instoremed.com" target="_blank">Visit Website</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )}

    renderInfo () {
        return (
            <div className="jumbotron info jumbotron-fluid">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col with-logo"></div>
                        <div className="col">
                            <h1 id="section-2" className="hero-header">Our Mission.</h1>
                            <p className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSteps () {
        return (
            <div className="jumbotron steps jumbotron-fluid">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <h1 id="section-2" className="hero-header">See what is available.</h1>
                            <p className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderText () {
    if (appType() === undefined || appType() === 'client') {
        return(
             <>
            <h1 className="jumbotron-heading mb-3">
                Medication Renewal
           </h1>
            <div className="lead text-muted">
                Skip the wait at the medical clinic and renew your medication online
          </div>
            <div className="lead text-muted">
                Pick up your medication at any pharmacy in British Columbia
         </div>
         </>)
   
    }  else if (appType() === 'office') {
        return (
            <h1 className="jumbotron-heading mb-3">
            Staff Login
            </h1>
        )   
    }
 }

    render() {
        return (
            <section className={"home-container " + (appType() === 'office' && ' home-container--office')} >
                {this.renderLearnMoreDialog()}
                {this.renderJumbotron()}
                {this.renderWhatWeOffer()}
                {this.renderClickInfo()}
                {this.renderAdvantage()}
                {/* <div className="container">
                    <div className="col-12">
                        <div className="btn-bar mt-4 text-center lead text-muted">
                            If you require help logging in, please email us at <a href="mailto:info@southvanmed.com?subject=Login Support for AskMedication.com">info@southvanmed.com</a>.
                        </div>
                    </div>
                </div> */}
            </section>
        );
    }
}


const temp = connect()(Home);

export { temp as Home };
