import html2canvas from 'html2canvas';
import { loadingAction } from '.';
import { problemService } from '../_services';
import { errorHandling } from '../_helpers';
import { problemConstant } from '../_constants';
import { alertAction } from './alert.action';

export const problemAction = {
    getAll,
    capture,
    change,
    submit,
    closeDialog,
    countUnread,
    acknowledge
};

function getAll() {
    return dispatch => {
        problemService.getAll()
            .then(
                res => {
                    dispatch({
                        type: problemConstant.GET_ALL,
                        problems: res.data
                    });
                },
                error => {
                    errorHandling('problem.action.getAll', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function capture(document) {
    return dispatch => {
        dispatch({
            type: problemConstant.CAPTURE_REQUEST
        });

        html2canvas(document.body, { 
            allowTaint : true,
            useCORS: true
        }).then(function (canvas) {
            // Export the canvas to its data URI representation
            const dataURL = canvas.toDataURL('image/png');

            dispatch({
                type: problemConstant.OPEN_DIALOG,
                dataURL
            });
        });
    };
}

function change(message) {
    return {
        type: problemConstant.CHANGE,
        message
    };
}

function submit(dataURL, message, personId) {
    return dispatch => {
        dispatch(loadingOpen());

        problemService.create(dataURL, message, personId)
            .then(
                res => {
                    dispatch({
                        type: problemConstant.SUBMIT_SUCCESS,
                        problems: res.data
                    });
                    dispatch({
                        type: problemConstant.CLOSE_DIALOG
                    });
                    dispatch(alertAction.success('Problem reported successfully!'));
                },
                error => {
                    delete error.response.config.data;
                    delete error.response.request.__sentry_xhr__;
                    errorHandling('problem.action.submit', error, dispatch);
                }
            )
            .finally(
                () => { dispatch(loadingClose()); }
            );
    };
}

function closeDialog() {
    return {
        type: problemConstant.CLOSE_DIALOG
    };
}

function countUnread() {
    return dispatch => {
        problemService.countUnread()
            .then(
                res => {
                    dispatch({
                        type: problemConstant.COUNT_UNREAD, 
                        unreadCount: res.data.unreadCount
                    });
                },
                error => {
                    errorHandling('problem.action.countUnread', error, dispatch);
                }
            );
    };
}

function acknowledge(id) {
    return dispatch => {
        problemService.acknowledge(id)
            .then(
                res => {
                    dispatch({
                        type: problemConstant.ACKNOWLEDGE_SUCCESS,
                        problems: res.data
                    });
                },
                error => {
                    errorHandling('problem.action.acknowledge', error, dispatch);
                }
            );
    };
}


function loadingOpen() { return loadingAction.open(); }
function loadingClose() { return loadingAction.close(); }