import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const noteService = {
    getByPerson,
    save,
    sendNote,
    acknowledgeReply,
    sendNoteToEmr,
    sentToEmrSave
};

function getByPerson(personId) {
    const url = `${config.apiHost}/note/person/${personId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function save(noteId, personId, from, text) {
    const url = `${config.apiHost}/note`;
    const body = {
        noteId,
        personId,
        from,
        text
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function sentToEmrSave(noteId, personId, from, text) {
    const url = `${config.apiHost}/note/emr`;
    const body = {
        noteId,
        personId,
        from,
        text
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function sendNote(noteId, from, to, subject, lastVersion, prescriptionId) {
    const url = `${config.apiHost}/note/sendNote`;
    const body = {
        noteId,
        from,
        to,
        subject,
        lastVersion,
        prescriptionId
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function sendNoteToEmr(text, person) {

    const url = `${config.southVanApi}/services/askmed/encounternote/add`;
    let demographicNo;
    if (person.oscarDemographicId){
        demographicNo = person.oscarDemographicId;

        const body = {
            note: text,
            demographicNo
        };

        axios.defaults.headers.common['x-access-secret'] = config.southVanSecret;
        
        return axios.post(url, body);

    }
        return; 
}

function acknowledgeReply(messageId, personId, replyId, replyText) {
    const url = `${config.apiHost}/note/reply/acknowledge`;
    const body = {
        messageId,
        personId,
        replyId,
        replyText
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

