import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isAuth } from '../_helpers/authorized';


// if not Auth, these route redirect to /
export const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            isAuth()
                ? (
                    <Component {...props} />
                )
                : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
        )} />
    );
};


PrivateRoute.propTypes = {
    component: PropTypes.func,
    location: PropTypes.object
};


// if Auth, these route redirect to /dashboard
export const PublicRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        !isAuth()
            ? (
                <Component {...props} />
            )
            : (
                <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
            )
    )} />
);


PublicRoute.propTypes = {
    component: PropTypes.func,
    location: PropTypes.object
};