import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { prescriptionAction, alertAction, profileAction, loadingAction, drugSearchAction } from '../../_actions';
import { history, getPersonName, getFaxNumber, calculatePrescriptionCharge } from '../../_helpers';
import { PayPalButton } from "react-paypal-button-v2";
import config from '../../config';
import PaypalButton from "../../components/paypalButton";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogActions } from '@material-ui/core';

import RequestedPopup from "./requested-popup"; 

class CheckoutForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenMessage: false,
        };
        this.handlePay = this.handlePay.bind(this);
        this.handleMessageClose = this.handleMessageClose.bind(this);
    }

    componentDidMount() {
        const { dispatch, user } = this.props;

        if (!user) {
            dispatch(profileAction.get());
        }
    }
    handleMessageClose() {
        this.setState({
            isOpenMessage: false
        }, history.push(`/prescription/${this.props.prescription._id}`));
    }
    
    async handlePay() {
        const { dispatch, prescription } = this.props;
        const { token } = await this.props.stripe.createToken({ name: getPersonName() });

        if (token) {
            dispatch(prescriptionAction.charge(token.id, prescription));
        } else {
            dispatch(alertAction.error('Please enter valid card information!'));
        }
    }

    renderMessage = () => {
        const { isOpenMessage } = this.state;
        return (
            <>
            <Dialog 
                fullWidth={true}
                maxWidth="md"
                open={isOpenMessage}
                // onClose={this.handleMessageClose}
                
            >
                <RequestedPopup/>
                <DialogActions>
                <button className="btn btn-outline-primary btn-sm mt-2 mr-2" onClick={this.handleMessageClose}>
                     Close
                </button>
                </DialogActions>
            </Dialog>
            </>
        )
    }

    renderPayPalSelf() {
        const { user, prescription,isEligible } = this.props;
        const medications = (prescription || {}).medications || [];
        const value = calculatePrescriptionCharge(medications, isEligible);
        if (typeof user == 'undefined') 
            return <div/>
        if (this.state.isPaying) return<div/>

        return  <div className="mb-5">
            <p>Please pay using one of the options below:</p>
    
             <PaypalButton
                order={{
                    payer: {
                        name: {
                            given_name: user.FirstName, // Paypal
                            surname: user.LastName // Customer
                        },
                        address: {
                            address_line_1: user.Address, // '123 ABC Street',
                            // address_line_2: 'Apt 2',
                            admin_area_2: user.City, // 'Vancouver',
                            admin_area_1: user.Province, // 'BC',
                            // postal_code: user.PostalCode, // 'V5H 3Z7',
                            country_code: 'CA'
                        },
                        email_address: user.Email, // 'a@a.com',
                        phone: {
                            phone_number: {
                                national_number: getFaxNumber(user.PhoneNumber) // '7789691234'
                            }
                        }
                    },
                    purchase_units:[{
                        amount: {
                            value,
                            currency_code: 'CAD'
                        },
                        /*
                        shipping: {
                            address: {
                                address_line_1: '123 ABC Street',
                                address_line_2: 'Apt 2',
                                admin_area_2: 'Vancouver',
                                admin_area_1: 'BC',
                                postal_code: 'V5H 3Z7',
                                country_code: 'CA'
                            }
                        }*/
                    }]
                }}
                onApprove={async (data, details) => {
                    this.setState({isPaying: true});
                    const { dispatch} = this.props;
                    dispatch(loadingAction.open());
                    const isPaid = await this.props.dispatch(prescriptionAction.charge(data.orderID, prescription, isEligible));
                    console.log("onApprove");
                    isPaid && this.setState({isOpenMessage: true});
                    dispatch(loadingAction.close());
                }}
                onError={(err) => {
                    this.props.dispatch(alertAction.error('Please enter valid card information!'));
                }}
            />
        </div>
    }

    renderPayPal() {
        return <div className="mb-5">
            <p>Please enter your credit card information.</p>
            
            <PayPalButton
                amount="0.01"
                // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                onSuccess={(details, data) => {
                    // alert("Transaction completed by " + details.payer.name.given_name);

                    this.props.dispatch(prescriptionAction.charge(data.orderID, this.props.prescription))

                    this.props.dispatch(drugSearchAction.init());
                    // OPTIONAL: Call your server to save the transaction
                    // return fetch("/paypal-transaction-complete", {
                    //     method: "post",
                    //     body: JSON.stringify({
                    //     orderID: data.orderID
                    //     })
                    // });
                }}
                onError={ (error) => {
                    this.props.dispatch(alertAction.error('Please enter valid card information!'));
                }}
                options={{
                    debug: true,
                    clientId: config.payPalClientId,
                    currency: 'CAD',
                    buyerCountry: 'CA'
                }}
            />
        </div>
    }

    // renderStripe() {
    //     return <div className="mb-5">
    //         <p>Please enter your credit card information.</p>
    //         <CardElement />
    //         <div className="text-right">
    //             <button className="btn btn-outline-secondary mr-2" onClick={() => history.goBack()}>Back</button>
    //             <button className="btn btn-primary" onClick={this.handlePay}>Pay Now</button>
    //         </div>
    //     </div>
    // }

    render() {
        return (
            <div className="checkout">
                {this.renderPayPalSelf()}
                {this.renderMessage()}
            </div>
        );
    }
}


CheckoutForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    prescription: PropTypes.object,
    stripe: PropTypes.object
};


function mapStateToProps(state) {
    const { prescription, isEligible } = state.payment;
    const { user } = state.profile;

    return {
        prescription,
        user,
        isEligible
    };
}

const temp = compose(
    connect(mapStateToProps),
    injectStripe,
    withRouter
)(CheckoutForm);

export { temp as CheckoutForm };