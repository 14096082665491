import axios from 'axios';
import config from '../config';
import { getToken, getSouthVanToken } from '../_helpers';


export const southVanService = {
    getUserInfo,
    getDemographicByHin,
    updatePreferredPharmacy,
    updateOscarInfoForPrivate
    
};

function getUserInfo(token) {
    // const url = `http://localhost:3000/me`;
    const url = `${config.southVanApi}/me`;
    axios.defaults.headers.common['x-auth-token'] = token;

    return axios.get(url);
}

function updatePreferredPharmacy( token, preferredPharmacy ){
    const url = `${config.southVanApi}/me/prefer-pharmacy`;
    axios.defaults.headers.common['x-auth-token'] = token;
    const body = {
        preferredPharmacy
    }
    return axios.post(url, body);
}

function updateOscarInfoForPrivate(isPrivatePay){
    try{
    let token = getSouthVanToken();
    const url = `${config.southVanApi}/me/update-oscar-profile`;
    axios.defaults.headers.common['x-auth-token'] = token;
    const body = {
        isPrivatePay
    }
    return axios.post(url, body);
  } catch(error){
      console.log(error);
  }
}



function access() {
    const url = `${config.apiHost}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

async function getIpObj() {
    try {
        // throw ("something is wrong")
        const url = `${config.ipApi}`;
        delete axios.defaults.headers.common['x-access-token'];     // remove x-access-token property
        const res = await axios.get(url);
        const ipObj = res.data;
        return ipObj;
    } catch (error) {
        throw (error);
    }
}

async function getDemographicByHin(hin){
    try{
        //get demographicNo by hin;
        const url = `${config.southVanApi}/demographics/number?hin=${hin}`;
        let res = await axios.get(url);
        return res.data;
    }catch (error) {
        throw error;
    }

}
