import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const teleplanService = {
    createBill,
    eligibility,
    getClaimNumbers,
    getRetrieveList,
    getProblematicInvoiceList,
    sendUnsentBills,
    pullLatestRemittance,
    getBillById,
    getRemittance,
};

function createBill(billObj) {
    const url = `${config.apiHost}/tele-plan/bill`;
    const body = {
          billObj,
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, body);

}


function eligibility (hic, DateOfBirth, personId) {
    const url = `${config.apiHost}/tele-plan/eligibility`;
    const body = {
        hic,
        DateOfBirth,
        personId
    };
    return axios.post(url, body);
}

async function getClaimNumbers() {
    const url = `${config.apiHost}/tele-plan/claim-numbers`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    
    return (await axios.get(url)).data;
}

async function getRetrieveList() {
    const url = `${config.apiHost}/tele-plan/status-retrieved`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    const res = await axios.get(url);
    const list = res.data;
    return list;
}

async function getProblematicInvoiceList() {
    const url = `${config.apiHost}/tele-plan/problematic-invoice-list`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return (await axios.get(url)).data;
}

async function sendUnsentBills() {
    const url = `${config.apiHost}/tele-plan/send-unsent-bills`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return (await axios.post(url)).data;
}

async function pullLatestRemittance() {
    const url = `${config.apiHost}/tele-plan/pull-latest-remittance`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return (await axios.post(url)).data;
}

async function getBillById(id) {
    const url = `${config.apiHost}/tele-plan/bill/${id}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return (await axios.get(url)).data;
}


function getRemittance (limit, skip) {
    const url = `${config.apiHost}/tele-plan/remittance?limit=${limit}&skip=${skip}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    
}