import React, { Component } from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";

export class PdfDocument extends Component {
      constructor(props){
          super(props);
      }

      renderText(){
        const { data } = this.props;
        let textArray = [];
        for(let i= 0 ; i < Math.floor((data.length/5000)+1); i++){
           let text = data.slice(i*5000,(i+1)*5000);
           textArray.push(text);
        };

        return  textArray.map(text => {
             return (
                 <View>
                     <Text>{text}</Text>
                 </View>
             )
         })

      }

     render(){
         const { data } = this.props;
         console.log('**data-length**', data.length);
         
        return (
            <Document>
                <Page >
                    {data ? this.renderText(): "pdf"}
                </Page>
            </Document>
        )};
    }
