import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { formatDate, getPersonName, getS3ImageUrl, uploadBucket } from '../../_helpers';
import { sleepAssessmentAction, faxAction, popupAction, profileAction } from '../../_actions';
import { SleepInfo } from '../SleepReferral';
import SignatureCanvas from 'react-signature-canvas'
import { paymentConstant } from '../../_constants/payment.constant';

class SleepAssessmentDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        
    }

    componentDidMount(){
         const { dispatch, user } = this.props;
         if (!user){
             dispatch(profileAction.get());
         }

    }

    handleFax = () => {
        const { dispatch, sleepAssessmentObj } = this.props;
        dispatch(faxAction.queueSleepAssessmentFax(sleepAssessmentObj));
        this.handleClose();
    }

    handleRefund = () => {
        const { dispatch, sleepAssessmentObj: obj } = this.props;
        const action = sleepAssessmentAction.refund(obj.payment.chargeId, obj._id, obj.person._id);
        dispatch(popupAction.openDialog('Refund confirmation', 'You are about to refund the sleep assessment, are you sure?', action));
    }

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(sleepAssessmentAction.closeDialog());
    };

    displayContent = () => {
        const { sleepAssessmentObj, medicalInfoObj, user } = this.props;

        if (sleepAssessmentObj && medicalInfoObj) {
            const person = sleepAssessmentObj.person;
            const sleepInfoObj = sleepAssessmentObj.sleepInfo;
            const sleepClinicObj = sleepAssessmentObj.sleepClinic;
            const signatureKey = `${uploadBucket.signatureImage}/${user.signatureImageKey}`;
            const signatureUrl = getS3ImageUrl(signatureKey);

            return (
                <div id="sleep-assessment-form">
                    <div className="header">
                        <div>Sleep/Respiratory Assessment Form</div>
                        <div>Company: {sleepClinicObj.name}</div>
                        <div>Location requested: {sleepClinicObj.address}</div>
                    </div>
                    <div className="personal-info">
                        <div className="col">
                            <div>
                                <span className="field-name">Last Name:</span>
                                <span>{person.LastName}</span>
                            </div>
                            <div>
                                <span className="field-name">First Name:</span>
                                <span>{person.FirstName}</span>
                            </div>
                            <div>
                                <span className="field-name">Address:</span>
                                <span>{person.Address}</span>
                            </div>
                            <div>
                                <span className="field-name">City:</span>
                                <span>{person.City}</span>
                            </div>
                            <div>
                                <span className="field-name">Province:</span>
                                <span>{person.Province}</span>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                                <span className="field-name">Postal Code:</span>
                                <span>{person.PostalCode}</span>
                            </div>
                            <div>
                                <span className="field-name">Phone:</span>
                                <span>{person.PhoneNumber}</span>
                            </div>
                            <div>
                                <span className="field-name">Health Card:</span>
                                {(person.HealthDocuments || []).length > 0 && <span>
                                    {person.HealthDocuments[person.HealthDocuments.length - 1].DocumentNumber}
                                </span>}
                            </div>
                            <div>
                                <span className="field-name">Date of Birth:</span>
                                <span>{formatDate(person.DateOfBirth)}</span>
                            </div>
                            <div>
                                <span className="field-name">Gender:</span>
                                <span>{medicalInfoObj && medicalInfoObj.Sex}</span>
                            </div>
                        </div>
                    </div>
                    <div className="sleep-info">
                        <div className="title">
                            Please see above patient for Sleep Apnea Level III testing. The patient reports the following:
                        </div>
                        <SleepInfo sleepInfoObj={sleepInfoObj} />
                    </div>
                    <div className="footer">
                        <div className="title">
                            <span>Thank you very much. Best regards</span>
                            <span>{formatDate(new Date())}</span>
                        </div>
                        <div>
                            <div>
                                <div>
                                    <span className="field-name">Practitioner Name:</span>
                                    <span>{getPersonName()}</span>
                                </div>
                                <div>
                                    <span className="field-name">Signature:</span>
                                    {/* <span><img className="signature" src={signatureUrl} crossOrigin="anonymous" /></span> */}
                                    <span><SignatureCanvas 
                                                 canvasProps={{ className: 'sigCanvasSleepment'}} /></span>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span className="field-name">Clinic Name:</span>
                                    <span>AskMedication.com Medical Clinic</span>
                                </div>
                                <div>
                                    <span className="field-name">Fax number:</span>
                                    <span>(604) 398-5999</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return '';
        }
    }

    render() {
        const { openDialog } = this.props;

        return (
            openDialog ?
                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={true}
                    onClose={this.handleClose}
                >
                    <DialogTitle className="no-print">
                        Sleep Assessment
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                            <i className="fa fa-close mx-2"></i>
                        </button>
                    </DialogTitle>
                    <DialogContent>
                        {this.displayContent()}
                    </DialogContent>
                    <DialogActions className="no-print">
                        <button className="btn btn-outline-primary" onClick={this.handleFax}>Fax</button>
                        {!paymentConstant.IS_FREE && <button className="btn btn-outline-danger" onClick={this.handleRefund}>Refund</button>}
                    </DialogActions>
                </Dialog>
                :
                ''
        );
    }
}

SleepAssessmentDialog.propTypes = {
    dispatch: PropTypes.func,
    onClose: PropTypes.func,
    sleepAssessmentObj: PropTypes.object,
    medicalInfoObj: PropTypes.object,
    openDialog: PropTypes.bool
};


function mapStateToProps(state) {
    const { medicalInfoObj } = state.medicalInfo;
    const { sleepAssessmentObj } = state.sleepAssessment;
    const { user } = state.profile;
    let { openDialog } = state.sleepAssessment;

    if (openDialog === undefined) {
        openDialog = false;
    }

    return {
        sleepAssessmentObj,
        medicalInfoObj,
        openDialog,
        user
    };
}

const temp = connect(mapStateToProps)(SleepAssessmentDialog);

export { temp as SleepAssessmentDialog };