import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { DialogActions } from '@material-ui/core';
import { pharmanetService } from '../_services';
import { errorHandling } from '../_helpers';
import { connect } from 'react-redux';

class PharmaNetLoginDialog extends Component {
    constructor(props) {
        super(props);

        // init
        this.state = {
            isOpen: false,
            login: '',
            password: '',
            lastLogin: false
        };
    }
    componentDidMount = () => {
        let credentialStr = sessionStorage.getItem('medinetUser');
        if (credentialStr) {
            pharmanetService.decryptCredential(credentialStr).then(
                res => {
                    if (res.data.credential) {
                        let {login, passwd} = res.data.credential;
                        this.setState({
                            login,
                            password: passwd,
                            lastLogin: true
                        });
                    } else {
                        sessionStorage.removeItem('medinetUser');
                    }
                },
                error => {
                    errorHandling('PharmaNetLoginDialog.decryptCredential', error, this.props.dispatch);
                }
            );
        }
    }
    handleClickOpen = () => {
        let {lastLogin} = this.state;
        
        if (lastLogin) {
            this.openPharmaNetWindow();
        } else {
            this.setState({
                isOpen: true
            });
        }
    }
    handleClickClose = () => {
        this.setState({
            isOpen: false
        });
    }
    handleClickConfim = () => {
        this.openPharmaNetWindow();
    }
    openPharmaNetWindow = () => {
        const { phn } = this.props;
        const { password: passwd, login, lastLogin } = this.state;

        // patient info
        let params = {
            phn
        };

        if (login && passwd) {
            params.passwd = passwd;
            params.login = login;
        } else {
            return;
        }

        let windowoption = 'width=980,height=700,toolbar=no,menubar=no,location=no,directories=no,resizable=yes,titlebar=no,scrollbars=yes,status=yes';
        let winName = 'MyWindow';
        let winURL = 'https://swan.medinet.ca/cgi-bin/rxmd.cgi';


        let form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', winURL);
        form.setAttribute('target', winName);
        for (let i in params) {
            if (params.hasOwnProperty(i)) {
                let input = document.createElement('input');
                input.type = 'hidden';
                input.name = i;
                input.value = params[i];
                form.appendChild(input);
            }
        }
        document.body.appendChild(form);
        window.open('', winName, windowoption);
        form.target = winName;
        form.submit();
        document.body.removeChild(form);

        if (!lastLogin) {
            let date = new Date();
            pharmanetService.encryptCredential({
                login,
                passwd,
                timestamp: date.getTime()
            }).then(
                res => {
                    const encryptedStr = res.data.encryptedStr;

                    if (encryptedStr) {
                        sessionStorage.setItem('medinetUser', encryptedStr);
                    }
                },
                error => {
                    errorHandling('PharmaNetLoginDialog.encryptCredential', error, this.props.dispatch);
                }
            );
        }

        this.setState({
            isOpen: false,
            lastLogin: true
        });
    }

    changeValue = (event) => {
        let value = {};
        value[event.target.name] = event.target.value;
        this.setState(value);
    }

    changeUser = () => {
        this.setState({
            lastLogin: false
        });
    }

    renderDialogContent = () => {
        let { lastLogin, login, password } = this.state;
        if (lastLogin) {
            return '';
        } else {
            return (
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="normal"
                        id="login"
                        label="User ID"
                        type="text"
                        name="login"
                        autoComplete="off"
                        variant="outlined"
                        value={login}
                        onChange={this.changeValue}
                        fullWidth
                        required
                    />
                    <TextField
                        margin="normal"
                        id="password"
                        label="Password"
                        type="password"
                        name="password"
                        autoComplete="off"
                        variant="outlined"
                        value={password}
                        onChange={this.changeValue}
                        fullWidth
                        required
                    />
                </DialogContent>
            );
        }
    }

    renderDialogActions = () => {
        return (
            <DialogActions>
                <button className="btn btn-outline-secondary mr-2 mt-4" onClick={() => this.handleClickClose()}>Cancel</button>
                <button className="btn btn-primary mt-4" onClick={() => this.handleClickConfim()}>Confirm</button>
            </DialogActions>
        );
    }

    renderButton = () => {
        let {type} = this.props;
        if ( type == 'sm') {
            return <button className="btn btn-sm btn-outline-primary ml-2 mt-2" onClick={() => this.handleClickOpen()}>Check PharmaNet</button>;
        } else {
            return <button className='btn btn-outline-secondary btn-lg my-4' onClick={() => this.handleClickOpen()}>Check PharmaNet</button>;
        }
    }

    render() {
        const { isOpen } = this.state;
        return (
            <>
                {this.renderButton()}
                <Dialog
                    maxWidth="md"
                    open={isOpen || false}
                >
                    <DialogTitle>
                        <span>PharmaNet Login</span>
                    </DialogTitle>
                    {this.renderDialogContent()}
                    {this.renderDialogActions()}
                </Dialog>
            </>
        );
    }
}


PharmaNetLoginDialog.propTypes = {
    dispatch: PropTypes.func,
    phn: PropTypes.string.isRequired,
    type: PropTypes.string
};

const temp = connect()(PharmaNetLoginDialog);
export { temp as PharmaNetLoginDialog };