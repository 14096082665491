import { pharmacyConstant } from '../_constants';

export function pharmacy(state = {}, action) {
    switch (action.type) {
        case pharmacyConstant.GET:
            return {
                pharmacyList: action.pharmacyList
            };
        default:
            return state;
    }
}