import React, { Component } from 'react';
import { connect } from 'react-redux';


class Fees extends Component {
    render() {
        return (
            <div className="container">
                <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                    <h1 className="display-4">Fees</h1>
                    <p className="lead">Please note that prices are subject to change without notice.</p>
                </div>
                <div className="card-deck mb-3 text-center">
                    <div className="col-sm-6 col-xs-12">
                        <div className="card mb-4 box-shadow">
                            <div className="card-header">
                                <h4 className="my-0 font-weight-normal">1st prescription</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">$20</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="card mb-4 box-shadow">
                            <div className="card-header">
                                <h4 className="my-0 font-weight-normal">Subsequent prescription(s)</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">$10</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const temp = connect()(Fees);

export { temp as Fees };
