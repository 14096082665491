import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { profileAction, alertAction, userAction } from '../../_actions';
import { RequiredLabel, ErrorMsgField, FormatPhoneNumber } from '../../components';
import { appType } from '../../_helpers';
import { ProfileValidator } from '../../_validator';
import { profileConstant, msgConstant } from '../../_constants';

class UserCredential extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            isChecked:false,
        };

        this.changeValue = this.changeValue.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    section = profileConstant.SECTION_CREDENTIAL;

    /**
     * Get the value from the inputs form
     */
    changeValue = (event) => {
        const { dispatch, user } = this.props;
        const { isChecked } = this.state;
        let value = event.target.value;
        if (event.target.name == "PhoneNumber" || event.target.name == "confirmPhoneNumber" || event.target.name == "BestPhoneNumber"){
        value = value.replace(/-/g, '');
        };
        user[event.target.name] = value;
        
        if(isChecked){
            user.BestPhoneNumber = user.PhoneNumber;
        }
        
        dispatch(profileAction.change(user));
    }

    changePhoneNumber = ( number, numbers ) => {
        const { dispatch, user } = this.props;
        user.PhoneNumber = number;
        user.PhoneNumbers = numbers;
        if (this.state.isChecked) {
            user.BestPhoneNumber = user.PhoneNumber;
            user.BestPhoneNumbers = user.PhoneNumbers;
        }
        dispatch(profileAction.change(user));
    }

    changeConfirmPhoneNumber = ( number, numbers ) => {
        const { dispatch, user } = this.props;
        user.confirmPhoneNumber = number;
        user.confirmPhoneNumbers = numbers;
        dispatch(profileAction.change(user));
    }

    changeBestPhoneNumber = ( number, numbers ) => {
        const { dispatch, user } = this.props;
        user.BestPhoneNumber = number;
        user.BestPhoneNumbers = numbers;
        dispatch(profileAction.change(user));  
    }


    handleBestPhoneNumber = () => {
        const { isChecked } = this.state;
        const { dispatch, user } = this.props;
        this.setState({
            isChecked: !isChecked,
        }, () => {
            if (this.state.isChecked){
                user.BestPhoneNumber = user.PhoneNumber;
                user.BestPhoneNumbers = user.PhoneNumbers;
            } else {
                user.BestPhoneNumber = '';
                user.BestPhoneNumbers = ['','',''];
            }
            dispatch(profileAction.change(user));
        })    
    }

    /**
     * 
     * format the phone number
     */
    getFormattedPhoneNumber() {
        let { PhoneNumber = '' } = (this.props || {}).user || {};

        const len = PhoneNumber.length;
        if (len > 6)
            return PhoneNumber.slice(0,3) + '-' + PhoneNumber.slice(3, 6) + '-' + PhoneNumber.slice(6);

        if (len > 3)
            return PhoneNumber.slice(0,3) + '-' + PhoneNumber.slice(3);
        
        return PhoneNumber;
    }

    /**
     * validate the confirm field
     */
    validateConfirmField(field) {
        const { dispatch, user } = this.props;
        if (user[field] && user[`confirm${field}`] &&
            user[field] !== user[`confirm${field}`]) {
            user[`confirm${field}Error`] = true;
        } else {
            user[`confirm${field}Error`] = false;
        }

        dispatch(profileAction.change(user));
    }

    renderConfirmFieldError(field) {
        if (this.props.user[`confirm${field}Error`]) {
            return (
                <div className="confirm-error-div">
                    <label>{`Confirm ${field} doesn't match ${field}`}</label>
                </div>
            );
        }

        return '';
    }

    /**
     * Submit the form sending a post request to the API
     */
    handleSave = (event) => {
        event.preventDefault();
        const { dispatch, user } = this.props;

        const validator = new ProfileValidator();
        validator.userCredential(user);

        if (validator.validate()) {
            // dispatch(profileAction.updateCredential(user));
            // dispatch(userAction.updateEmail(user.Email));
            this.props.saveUser(user);
        } else {
            dispatch(alertAction.error(msgConstant.PLEASE_CORRECT_ERRORS_BELOW, validator.errorMsgs));
        }
    }

    handleEdit() {
        const { dispatch } = this.props;
        dispatch(profileAction.editSection(this.section));
    }

    handleCancel() {
        const { dispatch } = this.props;
        dispatch(profileAction.editSection(undefined));
    }

    renderEdit() {
        const { user, edit } = this.props;
        const { isChecked } = this.state;
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="my-3">
                        User Credentials
                    </h4>
                    {!edit &&
                        <div>
                            <button className="btn btn-outline-secondary btn-sm mr-2" type="button" onClick={this.handleCancel}>Cancel</button>
                            <button className="btn btn-outline-success btn-sm" type="submit" onClick={this.handleSave}>Save</button>
                        </div>
                    }
                </div>
                <div className="row">
                    <div 
                        className="col-lg-6 col-md-12 col-sm-12 form-group"
                        data-test-id="user-credential__phone-number"
                    >
                        <RequiredLabel htmlFor="PhoneNumber">
                            Cell Phone
                        </RequiredLabel>
                        {/* <input type="text" className="form-control" id="PhoneNumber" name="PhoneNumber"
                            value={user.PhoneNumber} onChange={this.changeValue} onBlur={() => this.validateConfirmField('PhoneNumber')} required disabled={!edit} /> */}

                        <FormatPhoneNumber value={user.PhoneNumber}
                                            values = {user.PhoneNumbers}
                                            onChange = {this.changePhoneNumber}  
                                            onBlur={() => this.validateConfirmField('PhoneNumber')} 
                                            required disabled={!edit}
                                            />
                        <div className="invalid-feedback">
                            Cell Phone is required.
                        </div>
                    </div>
                    <div 
                        className="col-lg-6 col-md-12 col-sm-12 form-group"
                        data-test-id="user-credential__confirm-phone-number"
                    >
                        <RequiredLabel htmlFor="confirmPhoneNumber">
                            Confirm Cell Phone
                        </RequiredLabel>
                        {/* <input type="text" className="form-control" 
                                id="confirmPhoneNumber" name="confirmPhoneNumber"
                            value={user.confirmPhoneNumber} onChange={this.changeValue}
                             onBlur={() => this.validateConfirmField('PhoneNumber')} 
                             required /> */}
                        <FormatPhoneNumber value={user.confirmPhoneNumber} 
                            values={user.confirmPhoneNumbers}
                                            onChange = {this.changeConfirmPhoneNumber}  
                                            onBlur={() => this.validateConfirmField('PhoneNumber')} 
                                            required disabled={!edit}/>
                        <ErrorMsgField isShow={user['confirmPhoneNumberError']}>
                            Confirm Cell Phone Number doesn&apos;t match Cell Phone Number
                        </ErrorMsgField>
                        <div className="invalid-feedback">
                            Confirm Cell Phone is required.
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                        {/* <RequiredLabel htmlFor="Email">
                            Email
                        </RequiredLabel> */}
                        <input type="email" className="form-control" id="Email" name="Email"
                            value={user.Email || ''} onChange={this.changeValue} onBlur={() => this.validateConfirmField('Email')} required 
                            data-test-id="user-credential__email"
                            placeholder="Email *"
                        />
                        <div className="invalid-feedback">
                            Email is required.
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                        {/* <RequiredLabel htmlFor="confirmEmail">
                            Confirm Email
                        </RequiredLabel> */}
                        <input type="email" className="form-control" id="confirmEmail" name="confirmEmail"
                            value={user.confirmEmail || ''} onChange={this.changeValue} onBlur={() => this.validateConfirmField('Email')} required 
                            data-test-id="user-credential__confirm-email"
                            placeholder="Confirm Email *"
                        />
                        <ErrorMsgField isShow={user['confirmEmailError']}>
                            Confirm Email doesn&apos;t match Email
                        </ErrorMsgField>
                        <div className="invalid-feedback">
                            Confirm Email is required.
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                        {/* <RequiredLabel htmlFor="Password">Password</RequiredLabel> */}
                        <input type="password" className="form-control" id="Password" name="Password"
                            value={user.Password || ''} onChange={this.changeValue} onBlur={() => this.validateConfirmField('Password')} required 
                            data-test-id="user-credential__password"
                            placeholder="Password *"
                        />
                        <div className="invalid-feedback">
                            Password is required.
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                        {/* <RequiredLabel htmlFor="confirmPassword">Confirm Password</RequiredLabel> */}
                        <input type="password" className="form-control" id="confirmPassword" name="confirmPassword"
                            value={user.confirmPassword || ''} onChange={this.changeValue} onBlur={() => this.validateConfirmField('Password')} required 
                            data-test-id="user-credential__confirm-password"
                            placeholder="Confirm Password *"
                        />
                        <div className="invalid-feedback">
                            Confirm Password is required.
                        </div>
                        <ErrorMsgField isShow={user['confirmPasswordError']}>
                            Confirm Password doesn&apos;t match Password
                        </ErrorMsgField>
                    </div>
                </div>
               {(appType() === 'client' || (appType() === undefined && window.location.pathname != '/office-registration')) && (<div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 form-group"
                        data-test-id="user-credential__best-phone-number"
                    >
                        <div>
                            <RequiredLabel htmlFor="BestPhoneNumber">
                                Best phone number if a doctor needs to reach you:
                            </RequiredLabel>
                        </div>   
                        {/* <input type="text" className="form-control" id="BestPhoneNumber" name="BestPhoneNumber"
                            value={user.BestPhoneNumber} onChange={this.changeValue} onBlur={() => this.validateConfirmField('PhoneNumber')} required disabled={!edit || isChecked} /> */}

                        <div className="custom-control custom-checkbox mb-1">
                            <input type="checkbox" className="custom-control-input" id="confirmBestPhoneNumber"
                                checked={isChecked || false} onChange={this.handleBestPhoneNumber} 
                            />
                            <label className="custom-control-label confirm-best-phone-label" htmlFor="confirmBestPhoneNumber">
                                Same as cell phone
                            </label>
                        </div>
                        <FormatPhoneNumber value={user.BestPhoneNumber} 
                            values={user.BestPhoneNumbers} 
                                            onChange = {this.changeBestPhoneNumber}  
                                            onBlur={() => this.validateConfirmField('PhoneNumber')} 
                                            required disabled={!edit} isDisable={isChecked}/>
                        
                         
                        <div className="invalid-feedback">
                            Best reach phone number is required.
                        </div>
                    </div>
                   
                    
                </div>)}
                
            </div>
        );
    }

    renderDisplay() {
        const { user } = this.props;
        
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="my-3">
                        User Credentials
                    </h4>
                    <div>
                        <button className="btn btn-outline-primary btn-sm" type="button" onClick={this.handleEdit}>Edit</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 mb-3">
                        <label className="title">Email</label>
                        <div>{user.Email}</div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { user, edit, editSection } = this.props;

        if (user == undefined) return '';

        if (edit) {
            return this.renderEdit();
        } else {
            if (editSection === this.section) {
                return (
                    <form className="w-100" onSubmit={this.handleSave} noValidate>
                        {this.renderEdit()}
                    </form>
                );
            } else {
                return this.renderDisplay();
            }
        }
    }
}


function mapStateToProps(state) {
    const { editSection, user, status } = state.profile;

    // pre-filled
    if (status !== 'changed' && user && user.confirmEmail === undefined) {
        user.confirmEmail = user.Email;
    }

    // pre-filled, set the password to empty because the database password is a hash value
    if (status !== 'changed' && user && user.Password !== '') {
        user.Password = '';
    }

    return {
        editSection,
        user: { ...user }
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        // dispatching plain actions
        saveUser: (user) => {
            dispatch(profileAction.updateCredential(user));
            dispatch(userAction.updateEmail(user.Email));
        }
    }
}

UserCredential.propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object,
    edit: PropTypes.bool,
    editSection: PropTypes.string
};

const temp = connect(mapStateToProps, mapDispatchToProps)(UserCredential);

export { temp as UserCredential };
