import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUser } from '../../_helpers';
import { noteAction, noteDraftAction } from '../../_actions';
import Tooltip from '@material-ui/core/Tooltip';
import IdleTimer from 'react-idle-timer';
import { noteDraftService } from '../../_services';
import { draftText } from '../../_helpers/draftText';

import debounce from 'debounce-promise';



class NoteDraft extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSignOff: false,
            isUpdating: false,
            isFocused: false
        };

        this.idleTimer = null;
        this.onIdle = this.onIdle.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    handleEdit = () => {
        const { dispatch, editNoteId } = this.props;

        dispatch(noteDraftAction.edit());

        // cancel the note edit if one of the note is in edit mode
        if (editNoteId) {
            dispatch(noteAction.cancelEdit());
        }        
    }

    handleChange = async (event) => {
        const { dispatch, id, text } = this.props;
        dispatch(noteDraftAction.change(event.target.value));

        // await this.props.update(id, event.target.value);

    }

    handleSave = async () => {
        const { dispatch, id, text } = this.props;
        dispatch(noteDraftAction.updateV3(id, text));
    }

    handleSign = (e) => {
        e.preventDefault();
        const { dispatch, id, personId, text } = this.props;
        const from = getUser().Person;

        // update noteDraft to empty text
        dispatch(noteDraftAction.sign(id, personId, from, text));
        // noteDraftService.updateOrSignDebounce(false, id, personId, from, text);
        this.handleClose();
    }

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(noteDraftAction.close());
    }

    onIdle() {
        const { changed } = this.props;

        if (changed) {
            // this.handleSave();
        }
    }

    onFocus() {
        this.setState({
            isFocused: true
        });
    }

    onBlur() {
        this.handleSave();
        // this.setState({
        //     isFocused: false
        // });
        // this.setState({
        //     text: draftText(this.state.text)
        // });
    }

    renderInput() {
        const { edit } = this.props;
        let { text } = this.props;

        if (text === undefined) {
            text = '';
        }

        const disabled = text === '' // || this.state.isSaving || this.state.isFocused;

        // const hint = disabled ? "Draft is not saved, sign disabled" : "Draft Saved, sign enabled";

        if (edit) {
            return (
                <form>
                    <div className="text-right">
                        <Tooltip title="Close Note">
                            <i className="material-icons close-note" onClick={this.handleClose}>close</i>
                        </Tooltip>
                    </div>
                    <textarea className="form-control mb-2" rows="4" placeholder="Your note..."
                        value={text} onChange={this.handleChange} 
                        onBlur={this.onBlur} /*onFocus={this.onFocus}*/
                        ref={"textarea"}
                        data-test-id={"note-draft__text-area"}
                    ></textarea>
                    {/*<div className="text-left"><span>{hint}</span> </div>*/}
                       
                    <div className="text-right">
                        <button type="submit" className="btn btn-outline-primary btn-sm ml-2 mb-2" onClick={this.handleSign} disabled={disabled}
                            data-test-id="note-draft__sign-button"
                        >Sign</button>
                    </div>
                </form>
            );
        } else {
            return (
                <Tooltip title="Edit Note">
                    <i className="material-icons add-note" onClick={this.handleEdit}>create</i>
                </Tooltip>
            );
        }
    }

    render() {
        return (
            <div className="note-draft">
                <IdleTimer
                    ref={ref => { this.idleTimer = ref; }}
                    element={document}
                    onIdle={this.onIdle}
                    timeout={1000 * 10} /> 
                {this.renderInput()}
            </div>
        );
    }
}


NoteDraft.propTypes = {
    dispatch: PropTypes.func.isRequired,
    editNoteId: PropTypes.string,
    id: PropTypes.string,
    text: PropTypes.string,
    personId: PropTypes.string,
    changed: PropTypes.bool,
    edit: PropTypes.bool
};


function mapStateToProps(state) {
    const { id, personId, text, changed, edit } = state.noteDraft;
    const { editNoteId } = state.note;

    return {
        id,
        personId,
        text,
        changed,
        edit,
        editNoteId
    };
}

function mapDispatchToProps(dispatch) {
    return {
        update: (id, text) => dispatch(noteDraftAction.updateV2(id, text)),
        dispatch
    };
}

const temp = connect(mapStateToProps, mapDispatchToProps)(NoteDraft);

export { temp as NoteDraft };