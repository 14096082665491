import { officeConstant } from '../_constants';

//let user = JSON.parse(sessionStorage.getItem('user'));
//const initialState = user ? { loggedIn: true, user } : {};

export function office(state = {}, action) {
    switch (action.type) {
        case officeConstant.OFFICE_INVITE_REQUEST:
            return {
                sendingInvited: true
            };
        case officeConstant.OFFICE_INVITE_SUCCESS:
            return {
                sendingInvited: true,
                message: action.msg
            };
        case officeConstant.OFFICE_INVITE_FAILURE:
            return {
                sendingInvited: false
            };
        default:
            return state;
    }
}