import '../node_modules/jquery/dist/jquery';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap';
import '../node_modules/bootstrap/js/dist/util';
import './assets/css/main.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
import { Provider } from 'react-redux';
import { store } from './_helpers';
import { version } from '../package.json';


// if (process.env.REACT_APP_ENV !== 'dev') {
    Sentry.init({
        environment: process.env.REACT_APP_ENV,
        dsn: "https://da0d8945c3c74d72949aba7b05993c4a@o618912.ingest.sentry.io/5751295",
        release: version,
        // beforeSend(event, hint) {
        //     const error = hint.originalException;
        //     if (
        //       error &&
        //       error.message &&
        //       error.message.includes("Err: ")
        //     ) return event;
        // },
        maxValueLength: 100000,
        integrations: [new Sentry.Integrations.Breadcrumbs({
            console: false
        })]
    });
// }

// disable react-dev-tools for prod
if (process.env.REACT_APP_ENV != 'dev' 
    && process.env.REACT_APP_ENV != 'auto' 
    && process.env.REACT_APP_ENV != 'test' 
    &&  typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object"
) {
    for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? () => { } : null;
    }
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);