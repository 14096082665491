import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { DialogActions} from '@material-ui/core';
import TimePicker from 'react-time-picker';
import DatePicker from 'react-datepicker';

import { billAction,  medicationBillAction } from '../_actions';


class BillDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            diagnosisCode : '',
            isAutoOpen: false,    
        }
    }

    saveBill = () => {
        const { dispatch, prescription, billWay } = this.props;
        const { diagnosisCode} = this.state;
        const { _id: personId } = prescription.person;
        const { _id: prescriptionId } = prescription;
        const obj = { personId, prescriptionId, diagnosisCode, billWay }
        const tc_ahfs_number = prescription.medications[0].tc_ahfs_numbers[0];
        
        dispatch(billAction.createBill(obj));
        dispatch(medicationBillAction.getDiagnosisCode(tc_ahfs_number));
        dispatch(billAction.closeBillDialog());
        this.setState({ isAutoOpen: true});
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    handleStartChange = value => {
        this.setState({
            serviceStartTime: value,
        })
    }

    handleEndChange = value => {
        this.setState({
            serviceEndTime: value,
        })
    }

    saveBillInfo = ( ) => {
        const { dispatch, prescription } = this.props;
        const { diagnosisCode} = this.state;
        const tc_ahfs_number = prescription.medications[0].tc_ahfs_numbers[0];
        dispatch(medicationBillAction.createOrUpdate(diagnosisCode, '', tc_ahfs_number));
        this.setState({ isAutoOpen: false});
    }

    renderAuto() {
        if (this.props.prescription == undefined || this.props.medicationBillList == undefined)
        return <div/>

        const {diagnosisCode} = this.state;
        const { prescription, medicationBillList } = this.props;
        
        const medication = prescription.medications[0];
        const headerContent =`The Diagnosic code is ${diagnosisCode} for the ${medicationBillList.length} medications below :`;
        
        return <Dialog
            fullWidth={true}
            maxWidth="md"
            open={true}
        >
            {/* <DialogTitle className="text-center bg-danger">
                <i className="fas fa-exclamation-triangle mb-2 text-white" style={{fontSize: "50px"}}></i>
                <div className="text-white">Redirecting</div>
            </DialogTitle> */}
            <DialogContent className="text-center bg-success text-white">
                <div style={{ fontSize: 16 }}>
                    <h3>{headerContent}</h3>
                    <div >
                        {medicationBillList.map(item => {
                            return (
                                <span>{`${item.name} (${item.administrations[0]}/${item.formulations[0] || ''}), `}</span>
                            )
                        })}
                    </div>
                </div>
                {/* <div>
                    <label htmlFor="diagnosis_code">Diagnosis code :</label>
                    <input className="form-control" type="text" id="diagnosis_code" name="diagnosisCode"
                        value={this.state.diagnosisCode} onChange={this.handleDialogChange} />
                </div>
                <div>
                    <label htmlFor="question">Question :</label>
                    <input className="form-control" type="text" id="question" name="question"
                        value={this.state.question} onChange={this.handleDialogChange} />
                </div> */}
            </DialogContent>
            <DialogActions className="d-flex justify-content-center m-3">
                <button className="btn btn-success mr-2 mt-4 btn-block"  onClick={ this.saveBillInfo}>Yes</button>
                <button className="btn btn-outline-secondary mr-2 mt-4 btn-block" onClick={() => this.setState({ isAutoOpen: false})}>No</button>
            </DialogActions>
        </Dialog>
    }


    render() {
        if (this.props.prescription == undefined)
            return <div/>
        const { prescription, isOpen, dispatch, isDiagnosisCodeExists} = this.props;
        const { person, _id: prescriptionId } = prescription || {};
        const { FirstName = '', LastName = '' } = person || {};
        const { diagnosisCode, isAutoOpen } = this.state;
        return (
            <>
            isOpen ? 
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={isOpen || false}
                aria-labelledby="bill-dialog-title"
                aria-describedby="bill-dialog-description"
            >
                <DialogTitle>
                    <span>Bill</span>
                </DialogTitle>
                <DialogContent>
                    <div style={{ fontSize: 16 }}>

                        <div>Patient:{` ${FirstName} ${LastName}`}</div>
                        <p>BC card number: { ((person.HealthDocuments || [])[0] || {}).DocumentNumber || '' }</p>
                        {/* <div>
                            <span>Service starting time:</span>
                            <DatePicker
                                selected={this.state.serviceStartTime}
                                onChange={this.handleStartChange}
                                showTimeSelect
                                dateFormat="MM/dd/yyyy h:mm aa"
                            />
                         </div> */}
                         {/* <div>
                            <span>Service ending time:</span>
                            <DatePicker
                                selected={this.state.serviceEndTime}
                                onChange={this.handleEndChange}
                                showTimeSelect
                                dateFormat="MM/dd/yyyy h:mm aa"
                            />
                         </div> */}

                        {/* <div>
                            <label htmlFor="service_code">
                                Service code:
                            </label>
                            <select className="custom-select d-block w-100"
                                    id="service_code" 
                                    name="serviceCode" 
                                    onChange={this.handleChange} 
                                    value={ serviceCode } required>
                                <option value="">Choose...</option>
                                <option value="13237">T13237-Telehealth GP Visit (age 0-1)</option>
                                <option value="13437">T13437-Telehealth GP Visit (age 2-49)</option>
                                <option value="13537">T13537-Telehealth GP Visit (age 50-59)</option>
                                <option value="13637">T13637-Telehealth GP Visit (age 60-69)</option>
                                <option value="13737">T13737-Telehealth GP Visit (age 70-79)</option>
                                <option value="13837">T13837-Telehealth GP Visit (age 80+)</option> 
                            </select>
                        </div>  */}
                        <div>
                                <label htmlFor="diagnosis_code">Diagnosis code :</label>
                                <input className="form-control" type="text" id="diagnosis_code" name="diagnosisCode"
                                    value={diagnosisCode} onChange={this.handleChange} />
                        </div>  
                        {/* <div>Facility number: OA325</div>  */}
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="btn btn-outline-secondary mr-2 mt-4" onClick={() => dispatch(billAction.closeBillDialog())}>Cancel</button>
                    <button className="btn btn-primary mt-4" onClick={() => this.saveBill()}>Save</button>
                </DialogActions>
            </Dialog>
            : <div/>
            {!isDiagnosisCodeExists && isAutoOpen && this.renderAuto()}
            </>
        )
    };
};


BillDialog.propTypes = {
    dispatch: PropTypes.func,
    isOpen: PropTypes.bool,
    prescription: PropTypes.object,
};


function mapStateToProps(state) {
    const { isOpen, prescription, billWay } = state.bill;
    const { medicationBillList, isDiagnosisCodeExists } = state.medicationBill;

    return {
        isOpen,
        prescription,
        billWay,
        medicationBillList,
        isDiagnosisCodeExists

    };
}


const temp = connect(mapStateToProps)(BillDialog);

export { temp as BillDialog };