import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const pharmacyService = {
    get,
    multiTextSearch,
    isCancel
};

const CancelToken = axios.CancelToken;
let cancel;

function get() {
    const url = `${config.apiHost}/pharmacy`;

    return axios.get(url);
}

function multiTextSearch(searchKey) {
    const url = `${config.apiHost}/pharmacy/multi-text-search/${encodeURIComponent(searchKey)}`;    
    axios.defaults.headers.common['x-access-token'] = getToken();

    cancel && cancel();

    return axios.get(url, {
        cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
        })
    });
}

function isCancel(error) {
    return axios.isCancel(error);
}