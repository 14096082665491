import React from 'react';
import PropTypes from 'prop-types';


const IconButton = (props) => {
    return (
        <button {...props} className={`${props.className}`}>
            <i className={`${props.icon}`}></i>
            <span className="ml-2">{props.children}</span>
        </button>
    );
};


IconButton.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.node.isRequired
};


const temp = IconButton;

export { temp as IconButton };