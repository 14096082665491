import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { history, formatDateTime, isPrivatePay, isDoctor } from '../../_helpers';
import { prescriptionAction } from '../../_actions';
import { StatusBadges, BaseTable } from '../../components';
import { InvoiceDialog } from './';


class PrescriptionTable extends BaseTable {
    constructor(props) {
        super(props);
        const { order, orderBy, rowsPerPage, page } = props.tableObj;
        this.state = {
            order,
            orderBy,
            rowsPerPage,
            page,
        };
    }

    selectPrescription = (prescriptionId) => {
        history.push(`/prescription/${prescriptionId}`);
    }

    openInvoice = (event, obj) => {
        event.stopPropagation();   
        this.props.dispatch(prescriptionAction.openInvoiceDialog(obj));
    }

    renderInvoice(obj) {
        if (obj.statuses[0].text !== 'Created' && obj.statuses[0].text !== 'Pending'&& obj.statuses[0].text !== 'Acknowledged') {
            return (
                <button className="btn btn-sm btn-outline-info" onClick={(event) => this.openInvoice(event, obj)}>Invoice</button>
            );
        } else {
            return '';
        }
    }



    render() {
        const { prescriptions } = this.props;
        const { order, orderBy, rowsPerPage, page } = this.state;

        if (prescriptions) {
            return (
                <div className='prescription-table'>
                    <Paper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Patient Name (Prescription Id)</TableCell>
                                    <TableCell>Created</TableCell>
                                    {/* <TableCell>Status</TableCell>                                     */}
                                    {/* { (isPrivatePay() || isDoctor()) && <TableCell>Invoice</TableCell> } */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.stableSort(prescriptions, this.getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(obj => {
                                        const { person } = obj;
                                        
                                        return (
                                            <TableRow key={obj._id} onClick={() => this.selectPrescription(obj._id)}>
                                                <TableCell className="name">
                                                    {`${person.FirstName} ${person.LastName} (${obj._id})`}
                                                </TableCell>
                                                <TableCell className="created-at">
                                                    {formatDateTime(obj.createdAt)}
                                                </TableCell>
                                                {/* <TableCell className="status">
                                                    <StatusBadges statusList={obj.statuses} prescription={obj} />
                                                </TableCell>                                                 */}
                                               {/* { obj.payment && <TableCell className="invoice">
                                                    {this.renderInvoice(obj)}
                                                </TableCell>} */}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 15, 20, 25, 30]}
                            component="div"
                            count={prescriptions.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                    <InvoiceDialog />
                </div >
            );
        } else {
            return '';
        }
    }
}


PrescriptionTable.propTypes = {
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.object,
    tableObj: PropTypes.object,
    prescriptions: PropTypes.array
};


function mapStateToProps(state) {
    const { prescriptions } = state.prescription;
    let { tableObj } = state.prescription;
    const { medicalInfoObj } = state.medicalInfo;

    if (tableObj === undefined) {
        tableObj = {
            order: 'desc',
            orderBy: 'createdAt',
            page: 0,
            rowsPerPage: 10
        };
    }

    return {
        prescriptions,
        tableObj,
        medicalInfoObj
    };
}

const temp = compose(
    connect(mapStateToProps),
    withRouter
)(PrescriptionTable);

export { temp as PrescriptionTable };