import { auditConstant, userConstant } from '../_constants';

export function audit(state = {}, action) {
    switch (action.type) {
        case auditConstant.GET_PATIENTS_SUCCESS:
            return {
                ...state,
                patientList: [...action.patientList]
            };
        case auditConstant.GET_ACCESSED_BY_SUCCESS:
            return {
                ...state,
                accessedByList: [...action.accessedByList]
            };
        case auditConstant.GET_SUCCESS:
            return {
                ...state,
                auditList: [...action.auditList]
            };
        case auditConstant.CHANGE:
            return {
                ...state,
                search: { ...action.search }
            };
        case userConstant.LOGOUT:
        case userConstant.BACK_TO_DASHBOARD:
            return {};
        default:
            return state;
    }
}