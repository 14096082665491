import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { connect } from 'react-redux';
import { formatDateTime } from '../../_helpers';
import { medicationBillAction } from '../../_actions';
import Tooltip from '@material-ui/core/Tooltip';
import { BaseTable } from '../../components';


class MedicationBillTable extends BaseTable {
    constructor(props) {
        super(props);
        this.state = {
            order: 'desc',
            orderBy: 'createdAt',
            page: 0,
            rowsPerPage: 10,
            open: false,
            inputKey:''
        };
    }

    handleInputKey = (event) => {
        this.setState({
            inputKey: event.target.value,
        })
    }

    delete = (id) => {
        const { dispatch } = this.props;
        dispatch(medicationBillAction.remove(id));
    }

    render() {
        const { medicationBillListAll } = this.props;
        const { order, orderBy, rowsPerPage, page, inputKey } = this.state;

        if (medicationBillListAll) {
        let newmedicationBillList =  medicationBillListAll.filter (item => item.tc_ahfs_number.includes(inputKey));
            return (
                <>
                <div className="">
                            <input className="form-control" type="text" placeHolder="Search by tc_ahfs_number" value={this.state.inputKey} name="inputKey"
                                onChange={this.handleInputKey} onFocus={this.handleInputKey} />
                        </div>
                <div id="medication-filter-table">
                    <Paper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>tc_ahfs_number</TableCell>
                                    <TableCell>ICD</TableCell>
                                    {/* <TableCell>Question</TableCell> */}
                                    <TableCell>Created</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.stableSort(newmedicationBillList, this.getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(obj => {
                                        return (
                                            <TableRow key={obj._id} className="row">
                                                <TableCell>
                                                    {obj.tc_ahfs_number}
                                                </TableCell>
                                                <TableCell>
                                                    {obj.diagnosisCode || ""}
                                                </TableCell>
                                                {/* <TableCell>
                                                    { obj.question || ""}
                                                </TableCell> */}
                                                <TableCell className="created-at">
                                                    {formatDateTime(obj.createdAt)}
                                                </TableCell>
                                                <TableCell className="delete">
                                                    <Tooltip title="Delete">
                                                        <button className="btn">
                                                            <i className="material-icons" onClick={() => this.delete(obj._id)}>delete</i>
                                                        </button>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 20]}
                            component="div"
                            count={medicationBillListAll.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
                </>
            );
        } else {
            return '';
        }
    }
}

function mapStateToProps(state) {
    const { medicationBillListAll } = state.medicationBill;

    return {
        medicationBillListAll,

    };
}

const temp = connect(mapStateToProps)(MedicationBillTable);

export { temp as MedicationBillTable };

