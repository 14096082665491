export const drugSearchConstant = {
    INIT: 'DRUG_SEARCH_INIT',
    PRELOAD: 'DRUG_SEARCH_PRELOAD',
    CHANGE: 'DRUG_SEARCH_CHANGE',
    SEARCH_SUCCESS: 'DRUG_SEARCH_SEARCH_SUCCESS',
    MULTIPLE_DRUG: 'DRUG_SEARCH_MULTIPLE_DRUG',
    SHOW_USAGE: 'DRUG_SEARCH_SHOW_USAGE',
    CLEAR_SEARCH: 'DRUG_SEARCH_CLEAR_SEARCH',
    CLEAR_SEARCH_KEY: 'DRUG_SEARCH_CLEAR_SEARCH_KEY',
    ADD_DRUG: 'DRUG_SEARCH_ADD_DRUG',
    REMOVE_DRUG: 'DRUG_SEARCH_REMOVE_DRUG',
    UPDATE_DRUG_USAGE: 'DRUG_SEARCH_UPDATE_DRUG_USAGE'
};
