import { userConstant, noteDraftConstant } from '../_constants';

export function noteDraft(state = {}, action) {
    switch (action.type) {
        case noteDraftConstant.GET_BY_PERSON:
            return {
                id: action.draft._id,
                personId: action.draft.person,
                text: action.draft.text,
                edit: true
            };
        case noteDraftConstant.EDIT:
            return {
                ...state,
                edit: true
            };
        case noteDraftConstant.CLOSE:
            return {
                ...state,
                edit: false
            };
        case noteDraftConstant.SAVED:
            return {
                ...state,
                text: action.text,
                changed: false
            };
        case noteDraftConstant.CHANGE:
            return {
                ...state,
                text: action.text,
                changed: true
            };
        case noteDraftConstant.SIGNED:
            return {
                ...state,
                text: ''
            };
        case userConstant.LOGOUT:
        case userConstant.BACK_TO_DASHBOARD:
            return {};
        default:
            return state;
    }
}