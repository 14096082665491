import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Note, NoteRevisionDialog, NoteDraft } from './';
import { noteAction, noteDraftAction } from '../../_actions';


class NotePanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    // componentDidMount() {
    //     const { dispatch, personId } = this.props;
    //     dispatch(noteAction.getByPerson(personId));
    //     dispatch(noteDraftAction.getByPerson(personId));
    // }

    renderNotes() {
        const { notes } = this.props;

        return (
            <div id="notes" className="notes">
                {notes &&
                    notes.map((note) => {
                        return <Note key={note._id} note={note} />;
                    })
                }
            </div>
        );
    }

    render() {
        return (
            <div className="note-panel">
                {this.renderNotes()}
                <NoteDraft />
                <NoteRevisionDialog />
            </div>
        );
    }
}


NotePanel.propTypes = {
    dispatch: PropTypes.func.isRequired,
    personId: PropTypes.string,
    notes: PropTypes.array
};


function mapStateToProps(state) {
    const { notes } = state.note;
    const { person } = state.patient;

    const personId = person._id;

    return {
        notes,
        personId
    };
}

const temp = connect(mapStateToProps)(NotePanel);

export { temp as NotePanel };