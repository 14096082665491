import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';

const CancelToken = axios.CancelToken;
let cancel;

export const patientService = {
    getPatients,
    getPatient,
    getPatientsBySearchKey,
    isCancel
};

function getPatients() {
    const url = `${config.apiHost}/user/patients`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function getPatient(personId) {
    const url = `${config.apiHost}/person/${personId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function getPatientsBySearchKey(key) {
    const url = `${config.apiHost}/person/search-key/${encodeURIComponent(key)}`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    cancel && cancel();

    return axios.get(url, {
        cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
        })
    });
}

function isCancel(error) {
    return axios.isCancel(error);
}