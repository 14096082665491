import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MailIcon from '@material-ui/icons/Mail';
import Badge from '@material-ui/core/Badge';
import grey from '@material-ui/core/colors/grey';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogActions } from '@material-ui/core';
import { scroller } from 'react-scroll';

import prescriptionIcon from '../assets/img/prescription.png';
import { isDoctor, getUser, appType, isAdmin, isCustomerService, env } from '../_helpers';
import { appAction, userAction } from '../_actions';
import { PersonIcon } from '.';
import { userConstant } from '../_constants';
import { featureToggle } from '../_helpers/featureToggle';
import { history } from '../_helpers';
import config from '../config';


const styles = () => ({
    mailIcon: {
        color: grey[100]
    }
});

const isPricing = false;


export default class NavbarDashboardLogout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFeesModal: false,
        };
    }

    scrollToMain() {
        scroller.scrollTo("main", {
            duration: 800,
            smooth: true,
            offset: -50
        });
    }

    componentDidMount() {
        const { dispatch, loggedIn } = this.props;
        if (loggedIn) {
            dispatch(appAction.init());
        }
    }

    componentDidUpdate(prevProps) {
        const { dispatch, loggedIn } = this.props;
        if (!prevProps.loggedIn && loggedIn) {
            dispatch(appAction.init());
        }
    }

    handleFeesModalOpen = () => {
        this.setState({ showFeesModal: true });
    };

    handleFeesModalClose = () => {
        this.setState({ showFeesModal: false });
    };

    logout = () => {
        const { dispatch } = this.props;
        dispatch(userAction.logout());
    }

    renderFeesDialog = () => {
        return (
            <>
                <Dialog
                    fullWidth={true}
                    maxWidth="sm"
                    open={this.state.showFeesModal}
                    onClose={this.handleFeesModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Fees"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        Our services are not covered by the B.C. Services Card (CareCard). Private charges will apply.
                        </DialogContentText>
                        {/* <hr />
                        <DialogContentText>
                            <span className="m-3">1st prescription: <strong>$20.00</strong></span>
                        </DialogContentText>
                        <DialogContentText>
                            <span className="m-3">Subsequent prescription(s): <strong>$10.00</strong></span>
                        </DialogContentText>
                        <DialogContentText>
                            <span className="m-3">Sleep apnea assessment request: <strong>$20.00</strong></span>
                        </DialogContentText> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleFeesModalClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    renderMenuItem(text, to, icon = undefined, dataTestId = "") {
        return (
            <li className="nav-item">
                <Link className="nav-link" to={to}
                    data-test-id={dataTestId}
                >
                    <div className="menu-icon">
                        {icon && <i className={icon}></i>}
                    </div>
                    <span className="menu-text" data-test="left-menu-item">{text}</span>
                </Link>
            </li>
        );
    }

    renderMessageMenuItem() {
        const { unreadCount, isPatient } = this.props;

        return (
            <li className="nav-item">
                <Link 
                    className="nav-link" 
                    to="/messaging"
                    data-test-id="messaging-link"
                >
                    <div className="menu-icon">
                        <i className="fas fa-envelope"></i>
                    </div>
                    {!isPatient ?
                        <span className="menu-text">Inbox</span>
                        :
                        <span className="menu-text">Message</span>
                    }
                    {unreadCount > 0 &&
                        <span className="message-count">
                            <span className="badge badge-pill badge-light">{unreadCount}</span>
                        </span>
                    }
                </Link>
            </li>
        );
    }

    renderProblemMenuItem() {
        const { problemUnreadCount } = this.props;

        return (
            <li className="nav-item">
                <Link className="nav-link" to="/problems">
                    <div className="menu-icon">
                        <i className="fa fa-exclamation-circle"></i>
                    </div>
                    <span className="menu-text">Suggestions</span>
                    {problemUnreadCount > 0 &&
                        <span className="message-count">
                            <span className="badge badge-pill badge-light">{problemUnreadCount}</span>
                        </span>
                    }
                </Link>
            </li>
        );
    }

    renderMedicalInfoMenuItem(text, to) {
        const { isPatient } = this.props;
        if (!isPatient) return;

        if (this.props.noMedicalInfo) {
            return (
                <li className="nav-item">
                    <Link className="nav-link link-disabled" onClick={(e) => e.preventDefault()} to={to}>
                        <div className="menu-icon">
                            <i className="fas fa-prescription"></i>
                        </div>
                        <span className="menu-text">{text}</span>
                    </Link>
                </li>
            );
        } else {
            return (
                <li className="nav-item" >
                    <Link className="nav-link" to={to}
                        data-test-id="nav-bar-dashboard__prescription-request"
                    >
                        <div 
                            className="menu-icon"
                        >
                            <i className="fas fa-prescription"></i>
                        </div>
                        <span className="menu-text"
                        >{text}</span>
                    </Link>
                </li>
            );
        }
    }

    renderMessageBtn() {
        const { classes, unreadCount } = this.props;
        const invisible = unreadCount === undefined || unreadCount == 0;
        const badgeContent = unreadCount || 0;

        return (
            <IconButton className="mx-2 message-icon" color="inherit" component={Link} to="/messaging">
                <Badge invisible={invisible} badgeContent={badgeContent} color="secondary">
                    <MailIcon className={classes.mailIcon} />
                </Badge>
            </IconButton>
        );
    }

    renderCollapseMenu() {
        const { isPatient } = this.props;
        return (
            <div className="d-md-none collapse-menu" data-test="collapse-menu">
                {this.renderMedicalInfoMenuItem('Request Prescription', '/request-prescription/medicalinfo')}
                {this.renderMenuItem('Prescription History', '/prescriptions', 'fas fa-file-prescription')}
                {isPatient && this.renderMenuItem('Medical Information', '/medicalInfo', 'fas fa-notes-medical')}
                {/* {isPatient && this.renderMenuItem('Sleep Referral','/sleep-info','fas fa-notes-medical')} */}
                {(isDoctor() || isCustomerService()) && this.renderMenuItem('Patients', '/patients', 'fas fa-notes-medical')}
                {isAdmin() && this.renderMenuItem('Audit', '/audit', 'fas fa-clipboard-check')}
                {isAdmin() && this.renderProblemMenuItem()}
                {isAdmin() && this.renderMenuItem('Manage Data', '/manage-data', 'fas fa-database')}
                {isAdmin() && this.renderMenuItem('Medication Filter', '/medication-filter', 'fas fa-filter')}
                {isAdmin() && this.renderMenuItem('Medication ICD', '/medication-icd', 'fas fa-filter')}
            </div>
        );
    }

    renderTitle() {
        let title = 'AskMedication'; //  V1 for test

        switch (env()) {
            case 'dev':
                title += ` (${env()})`;
                break;
            case 'test':
                title += ` (${appType()} ${env()})`;
                break;
            case 'prod':
                if (appType() === 'office') {
                    title += ` (${appType()})`;
                }                 
                break;
        }
        
        return title;
    }

    renderNavBar() {
        const { loggedIn, user } = this.props;
        let navbarContent;

        if (loggedIn) {
            navbarContent = (
                <div id="navbarContent" className="collapse navbar-collapse">
                    <ul className="navbar-nav ml-auto">
                        {this.renderCollapseMenu()}
                    </ul>
                    <div className="d-flex justify-content-between justify-content-sm-end">
                        <Link className="user-data" to='/profile'>
                            <div className="username">
                                <PersonIcon person={getUser().Person} theme="light" />
                            </div>
                            <div className="d-none d-sm-block">{user.Email}</div>
                        </Link>
                        {this.renderMessageBtn()}
                        <button 
                            data-test-id="navbar-dashboard-sign-out"
                            className="btn btn-outline-info my-2 ml-2" 
                            onClick={this.logout}
                        >
                            Sign out
                        </button>
                    </div>
                </div>
            );
        } else {
            navbarContent = (
                <div id="navbarContent" className="collapse navbar-collapse">
                    
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                        {(env() === 'dev' || env() === 'auto') && <a 
                            className="btn-nav my-2 nav-link" 
                            to="/office-login" 
                            data-test-id="office-log-in-link"
                            onClick={() => {
                                history.push('/office-login');
                                this.scrollToMain();
                            }}
                        ><span>Office Login</span></a>}
                        </li>
                        <li className="nav-item">
                        <Link className="btn-nav my-2 nav-link" to="/login" data-test-id="sign-in-link"><span>Sign In</span></Link>
                        </li>
                        <li className="nav-item">
                        <a 
                            className="btn-nav my-2 nav-link" 
                            data-test-id="registration-link" 
                            // to="/registration"
                            onClick={() => {
                                history.push('/registration');
                                this.scrollToMain();
                            }}
                        ><span>Create Account</span></a>
                        </li>
                        <li className="nav-item">
                        <Link className="btn-nav my-2 nav-link" to="/faq"><span>FAQ</span></Link>
                        </li>
                        <li className="nav-item">
                        <Link className="btn-nav my-2 nav-link" to="/blog"><span>Blog</span></Link>
                        </li>
                        <li className="nav-item">
                        
                        <Link className="btn-nav my-2 nav-link" to="/about-us"><span>About Us</span></Link>
                        </li>
                        <li className="nav-item">
                        <Link className="btn-nav my-2 nav-link" to="#" onClick={this.handleFeesModalOpen}><span>Pricing</span></Link>
                        </li>

                        
                        
                       
                        
                        
                       
                        {this.renderFeesDialog()}
                    
                    </ul>
                    </div>
                
            );
        }

        return (
            <header className="no-print">
                <nav className={"navbar navbar-expand-md justify-content-between navbar-fixed-top  " + (loggedIn ? "logged-in" : "logged-out sticky-top")} >
                    <Link className="navbar-brand" to="/">
                        <i className="fas fa-prescription"></i> 
                        <span>{this.renderTitle()}</span>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"><i className="fas fa-bars"></i></span>
                    </button>
                    {navbarContent}
                </nav>
            </header>
        );
    }

    renderLeftMenu() {
        const { loggedIn, isPatient } = this.props;

        if (loggedIn) {
            // login
            return (
                <div className="container-fluid">
                    <div className="row">
                        <nav className="col-md-2 d-none d-md-block bg-light sidebar" >
                            <div className="sidebar-sticky">
                                <ul className="nav flex-column">
                                    {this.renderMenuItem('Dashboard', '/dashboard', 'fa fa-dashboard')}
                                    {this.renderMedicalInfoMenuItem('Request Prescription', '/request-prescription/medicalinfo', prescriptionIcon)}
                                    {this.renderMenuItem('Prescription History', '/prescriptions', 'fas fa-file-prescription')}
                                    {(isDoctor() || isCustomerService() )&& this.renderMenuItem('Patients', '/patients', 'fas fa-notes-medical')}
                                </ul>
                                <hr />
                                <ul className="nav flex-column mb-2">
                                    {this.renderMenuItem('Profile', '/profile', 'fas fa-user-circle', "nav-bar-dash-board__profile")}
                                    {isPatient && this.renderMenuItem('Medical Information', '/medicalInfo', 'fas fa-notes-medical')}
                                    {/* {isPatient && this.renderMenuItem('Sleep Referral', '/sleep-info', 'fas fa-notes-medical')} */}
                                </ul>
                                <hr />
                                <ul className="nav flex-column mb-2">
                                    {this.renderMessageMenuItem()}
                                    {isAdmin() && this.renderMenuItem('Audit', '/audit', 'fas fa-clipboard-check')}
                                    {isAdmin() && this.renderMenuItem('Office Invite', '/office-invite', 'far fa-envelope', "nav-bar-dash-board__office-invite")}
                                    {isAdmin() && this.renderProblemMenuItem()}
                                </ul>
                                <hr />
                                <ul className="nav flex-column mb-2">
                                    {isAdmin() && this.renderMenuItem('Manage Data', '/manage-data', 'fas fa-database')}
                                    {isAdmin() && this.renderMenuItem('Medication Filter', '/medication-filter', 'fas fa-filter')}
                                    {isAdmin() && this.renderMenuItem('Medication ICD', '/medication-icd', 'fas fa-filter')}
                                </ul>
                                <hr />
                                <ul className="nav flex-column mb-2">
                                    {featureToggle.bill && isAdmin() && this.renderMenuItem('Teleplan File', '/tele-file', 'fa fa-paper-plane')}
                                    {featureToggle.bill && isAdmin() && this.renderMenuItem('Manage Teleplan', '/manage-tele', 'fa fa-get-pocket')}
                                    {featureToggle.bill && isAdmin() && this.renderMenuItem('Edit Invoice', '/edit-invoice', 'fa fa-pencil-square-o')}
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            );
        } else {
            // logout
            return '';
        }
    }

    render() {
        const { location } = this.props;
        return (
            <section class="menu cid-s8n53aE9JO nav-bar-dashboard-logout" once="menu" id="menu1-d">



                <nav class="navbar navbar-dropdown align-items-center navbar-fixed-top navbar-toggleable-sm">

                    <div class="menu-bottom">


                        <div class="menu-logo nav-bar-dashboard-logout__menu-logo">
                            <div class="navbar-brand">

                                <span class="navbar-caption-wrap">
                                    <Link to="/" class="brand-name mbr-black mbr-bold text-primary display-1">AskMedication</Link>
                                </span>
                            </div>
                        </div>



                        <div class="show nav-bar-dashboard-logout__menu collapse navbar-collapse">
                     
                            <ul class="navbar-nav nav-dropdown js-float-line nav-right" data-app-modern-menu="true">
                                <li class="nav-item nav-bar-dashboard-logout__menu__item ">
                                    <Link
                                        className={"nav-link link mbr-black display-7 " + (location.pathname == "/registration" ? "nav-bar-dashboard-logout__link--active" : "text-black")}
                                        // to="/registration"
                                        data-test-id="registration-link"
                                        onClick={() => window.location.href = config.southVanHost + "/login" }
                                        to='#'
                                    >Create Account</Link>
                                </li>
                                <li class="nav-item nav-bar-dashboard-logout__menu__item nav-hide">
                                    <Link 
                                        className={"nav-link link mbr-black display-7 " + (location.pathname == "/about-us" ? "nav-bar-dashboard-logout__link--active" : "text-black")}
                                        to="/about-us"
                                    >About Us</Link>
                                </li>
                                <li class="nav-item nav-bar-dashboard-logout__menu__item nav-hide">
                                    <Link 
                                        className={"nav-link link mbr-black display-7 " + ((location.pathname == "/blog" || location.pathname.includes('/blog/')) ? "nav-bar-dashboard-logout__link--active" : "text-black")}
                                        to="/blog"
                                    >Blog</Link>
                                </li>
                                <li class="nav-item nav-bar-dashboard-logout__menu__item nav-hide">
                                    <Link 
                                        className={"nav-link link mbr-black display-7 " + (location.pathname == "/price" ? "nav-bar-dashboard-logout__link--active" : "text-black")}
                                        to="/price">Pricing</Link>
                                </li>
                                <li class="nav-item nav-bar-dashboard-logout__menu__item nav-hide">
                                    <Link 
                                        className={"nav-link link mbr-black display-7 " + (location.pathname == "/faq" ? "nav-bar-dashboard-logout__link--active" : "text-black")}
                                        to="/faq"
                                    >FAQ</Link>
                                </li>
                                <li class="nav-item nav-bar-dashboard-logout__menu__item">
                                    <Link
                                        className={"nav-link link mbr-black display-7 " + (location.pathname == "/login" ? "nav-bar-dashboard-logout__link--active" : "text-black")}
                                        // to="/login"
                                        data-test-id="sign-in-link"
                                        onClick={() => {
                                           if (
                                               appType() =='client' ||
                                               typeof(appType()) == "undefined"
                                           ) {
                                               window.location.href = config.southVanHost + "/login";
                                           } else {
                                            history.push('/login');
                                            this.scrollToMain();
                                           }
                                        }}
                                        to='#'
                                    >Log In</Link>
                                </li>
                                <li class="nav-item nav-bar-dashboard-logout__menu__item nav-hide">
                                    {(env() === 'dev' || env() === 'auto') && <Link
                                        className={"nav-link link mbr-black display-7 " + (location.pathname == "/office-login" ? "nav-bar-dashboard-logout__link--active" : "text-black")}
                                        // to="/office-login"
                                        data-test-id="office-log-in-link"
                                        onClick={() => {
                                            history.push('/office-login');
                                            this.scrollToMain();
                                        }}
                                        to='#'
                                    >Office Login</Link>}
                                </li>
                            </ul>



                        </div>
                        {/* <button class="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i class="fas fa-bars"></i>

                        </button> */}

                    </div>
                </nav>
            </section>
        );
    }
}


NavbarDashboardLogout.propTypes = {
    dispatch: PropTypes.func,
    loggedIn: PropTypes.bool,
    noMedicalInfo: PropTypes.bool,
    unreadCount: PropTypes.number,
    problemUnreadCount: PropTypes.number,
    classes: PropTypes.object
};


function mapStateToProps(state) {
    const { unreadCount } = state.message;
    const { loggedIn, user} = state.authentication;
    const { noMedicalInfo } = state.medicalInfo;

    const isPatient = (user || {}).Role === userConstant.ROLE_PATIENT;

    return {
        isPatient,
        loggedIn,
        unreadCount,
        noMedicalInfo,
        problemUnreadCount: state.problem.unreadCount,
        user,
    };
}


const temp = compose(
    connect(mapStateToProps),
    withStyles(styles),
    withRouter
)(NavbarDashboardLogout);

export { temp as NavbarDashboardLogout };