
import axios from 'axios';
import debounce from 'debounce-promise';
import moment from "moment";
import * as Sentry from "@sentry/react";

import config from '../config';
import { getPersonId, getToken, isPrivatePay } from '../_helpers';


export const prescriptionService = {
    getById,
    getFaxedOrRefunded,
    getByPerson,
    getPendingByPerson,
    create,
    updateMedicationStatus,
    updateMedicationUsage,    
    acknowledge,
    print,
    paymentCompleted,
    refundSuccess,
    cancel,
    setPay,
    setPayV2,
    createAndPending,
    saveText,
    updateMedicationAndUsage,
    updateDrPharmacy,
    CheckPrescriptionDuplicted,
    checkMedicationNumbers,
    createAndPendingDebounce: debounce(createAndPending, 2000),
    createOrFindOscarDemographic,
    postMedicationToOscar,
    updatePrvLastName,
    updateEmail
    
  
};

function getById(prescriptionId) {
    const url = `${config.apiHost}/prescription/${prescriptionId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function getFaxedOrRefunded() {
    const url = `${config.apiHost}/prescription/faxed-or-refunded`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}


function getByPerson(personId) {
    const url = `${config.apiHost}/prescription/person/${personId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function CheckPrescriptionDuplicted(personId,drug) {
    const url = `${config.apiHost}/prescription/person/duplicate/${personId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    const body = {
        drug
    }

    return axios.post(url, body);
}

function checkMedicationNumbers(personId, prescriptionObj) {
    const url = `${config.apiHost}/prescription/medication/number/${personId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    const body = {
        prescriptionObj: prescriptionObj,
    }

    return axios.post(url,body);
}

function getPendingByPerson(personId) {
    const url = `${config.apiHost}/prescription/pending/person/${personId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function create(prescriptionObj) {
    const url = `${config.apiHost}/prescription`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, prescriptionObj);
}

async function createAndPending(prescriptionObj) {
    const url = `${config.apiHost}/prescription/create-and-pending`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return await axios.post(url, prescriptionObj);
}

function updateMedicationStatus(prescription, medication, status) {
    const url = `${config.apiHost}/prescription/medication/updateStatus`;
    const body = {
        prescription,
        medication,
        status
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function updateMedicationUsage(prescription, medication, usage) {
    const url = `${config.apiHost}/prescription/medication/usage`;
    const body = {
        prescription,
        medication,
        usage
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function updateMedicationAndUsage(prescription, medication, usage) {
    const url = `${config.apiHost}/prescription/medication/medication-usage`;
    const body = {
        prescription,
        medication,
        usage
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function updateDrPharmacy(prescription){
    const url =`${config.apiHost}/prescription/dr-pharmacy`;
    const body = {
        prescription,
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function acknowledge(prescriptionId, messageId, personId) {
    const url = `${config.apiHost}/prescription/acknowledge`;
    const body = {
        prescriptionId,
        messageId,
        personId,
        acknowledgedBy: getPersonId()
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function cancel(prescriptionId, personId) {
    const url = `${config.apiHost}/prescription/cancel`;
    const body = {
        prescriptionId,
        personId
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function print(prescriptionId, personId) {
    const url = `${config.apiHost}/prescription/print`;
    const body = {
        prescriptionId,        
        personId,
        faxedBy: getPersonId()
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function paymentCompleted(id, personId, paymentId) {
    const url = `${config.apiHost}/prescription/payment-completed`;
    const body = {
        id,
        personId,
        paymentId
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function refundSuccess(id, personId) {
    const url = `${config.apiHost}/prescription/refund-success`;
    const body = {
        id,
        personId,
        refundedBy: getPersonId()
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}


function setPay(prescriptionId, stripeTokenId, amount) {
    const url = `${config.apiHost}/prescription/set-pay`;
    const body = {
        prescriptionId,
        stripeTokenId,
        amount
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function setPayV2(prescriptionId, orderID, amount) {
    const url = `${config.apiHost}/prescription/set-pay`;
    const body = {
        prescriptionId,
        orderID,
        amount
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function saveText(prescriptionId, text = '') {
    const url = `${config.apiHost}/prescription/set-text`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    return axios.post(url, {data: {prescriptionId, text}});
}

function updatePrvLastName(person){
    const user = {};
    user.isPrivatePay = true;
    user.id = person.oscarUserId;
    user.email = person.email;
    let secret = 'cXpMnLhQ*7#qX1i';
    const url = `${config.southVanApi}/me/update-oscar-profile`;
    axios.defaults.headers.common['x-access-secret'] = secret;
    const body = {
        user
    };

    return axios.post(url,body);
}

function updateEmail(person){
    const user = {};
    user.isEmail = true;
    user.id = person.oscarUserId;
    user.email = person.email;
    let secret = 'cXpMnLhQ*7#qX1i';
    const url = `${config.southVanApi}/me/update-oscar-profile`;
    axios.defaults.headers.common['x-access-secret'] = secret;
    const body = {
        user
    };

    return axios.post(url,body);
}


function createOrFindOscarDemographic(person,email, sex){
    const user = {};
    user.province = person.Province;
    user.postal = person.PostalCode;
    user.city = person.City;
    user.address = person.Address;
    user.firstname = person.FirstName;
    user.sex = person.sex ? person.sex : sex;
    user.email = person.email ? person.email : email;
    user.healthcardProvince = "OTHER";
    user.lastname = person.LastName;
    if (person.eligibility == "R" || person.eligibility == "NE") {
        // let lastNameArr = person.LastName.split(' ');
        //     if(lastNameArr.length>1){
        //         let tempStr = ''
        //         lastNameArr.forEach((e,i)=>{
        //             if(i==1){

        //                 tempStr = tempStr + ' -PRV- ' + e;
        //             }
        //             tempStr += ` ${e}`;
        //         })
        //         user.lastname = tempStr;
        //     }else{
        //         user.lastname = lastNameArr[0] + ' -PRV-'
        //     }
     user.isPrivatePay = true;
    }

    if (!isPrivatePay()) {
        user.healthcardProvince = "BRITISH COLUMBIA";
        user.hin = person.HealthDocuments[0].DocumentNumber;
    } 
    user.phone = person.BestPhoneNumber;
    user.year = moment(person.DateOfBirth).year().toString()
    let month = (moment(person.DateOfBirth).month() + 1).toString();
    if (month.length == 1)
        month = '0' + month;
    user.month = month;
    let day = moment(person.DateOfBirth).date().toString() ;
    if(day.length ==1)
    day = '0' + day;
    user.day = day;

    let secret = 'cXpMnLhQ*7#qX1i';
    const url = `${config.southVanApi}/demographics/askmed/create`;
    axios.defaults.headers.common['x-access-secret'] = secret;
    const body = {
        user
    };

    return axios.post(url,body);

}






async function postMedicationToOscar(person, m) {
    let body;

    try {
        const url = `${config.southVanApi}/services/askmed/encounternote/add`;
        let demographicNo;
        if (person.oscarDemographicId) {
            demographicNo = person.oscarDemographicId;
            let medsToFormat = '';
            let separator = ', ';
            let usagesLength = m.usages.length;
            let usage = m.usages[usagesLength-1];

            medsToFormat += `${m.name} (${m.administrations[0]}/)${separator}`;
            medsToFormat += arrayToString(m.ingredients.map(ing => ing.ingredient_name + ' ' + ing.strength + ' ' + ing.strength_unit)) + separator;
            medsToFormat += 'Use: ' + usage.quantity + separator;
            medsToFormat += 'Frequency: ' + usage.frequency + separator;
            medsToFormat += 'Duration: ' + usage.frequency + separator;
            if (usage.asNeeded)
                medsToFormat += "As needed" + separator;
            const { repeat } = usage;
            if ((repeat == '0' || typeof (repeat) == "undefined")){
                medsToFormat += "No Repeats." ;
            } else if( repeat == '1'){
                medsToFormat += "1 Repeat." ;
            } else {
                medsToFormat += repeat + 'Repeats.'  ;
            }
            
            body = {
                note: medsToFormat,
                demographicNo
            };

            axios.defaults.headers.common['x-access-secret'] = config.southVanSecret;

            return axios.post(url, body);

        }
        return;
    } catch (error) {
        Sentry.captureException(new Error("Err: ERROR_POST_MED_TO_OSCAR " + error.message + ". Body: " + JSON.stringify(body)));
    }
}

// function sendPrescriptionToEmr( prescription ){

//     const { person, medications } = prescription;

//     const url = `${config.southVanApi}/services/askmed/encounternote/add`;
//     let demographicNo;
//     if (person.oscarDemographicId){
//         demographicNo = person.oscarDemographicId;

//         let medsToFormat = '';
//         let separator = `\n`;

//         medications.forEach((m, ind) =>{

//             medsToFormat += `${m.name} (${m.administrations[0]}/)${separator}`;
//             medsToFormat += arrayToString(m.ingredients.map(ing => ing.ingredient_name + ' ' + ing.strength + ' ' + ing.strength_unit)) + separator;
//             medsToFormat += 'Use: ' + m.usages[0].quantity + separator;
//             medsToFormat += 'Frequency: ' + m.usages[0].frequency + separator;
//             medsToFormat += 'Duration: ' + m.usages[0].frequency + separator;
//             if (m.usages[0].asNeeded)
//                 medsToFormat += "As needed" + separator;
//             if (ind < medications.length - 1)
//                 medsToFormat += separator;

//         })

//         if( prescription.pharmacy ){
//             medsToFormat += "Pharmacy" + prescription.pharmacy.name + "-" + prescription.pharmacy.address;
//         }
//         const body = {
//             note:medsToFormat,
//             demographicNo
//         };

//         axios.defaults.headers.common['x-access-secret'] = config.southVanSecret;
        
//         return axios.post(url, body);

//     }
//         return; 
// }


function arrayToString(arr = []) {
    if (arr.length == 0)
        return '';
    
    let str = '';
    let sep = ', '
    for (let i = 0; i < arr.length; i++) {
        if (i < arr.length - 1) {
            str += arr[i].toString() + sep;
            continue;
        }
        str += arr[i].toString();
    }
    return str;
} 



