import { popupConstant } from '../_constants';

export const popupAction = {
    openDialog,
    confirm,
    cancel
};

function openDialog(title, message, action) {
    return {
        type: popupConstant.OPEN_DIALOG,
        title,
        message,
        action
    };
}

function confirm(action) {
    return dispatch => {
        dispatch(action);

        dispatch({
            type: popupConstant.CONFIRM_SUCCESS,
        });
    };
}

function cancel() {
    return { 
        type: popupConstant.CANCEL
    };
}

