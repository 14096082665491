import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


const ApiNotAvailable = ({ apiNotAvailable }) => {
    if (apiNotAvailable !== true) return '';

    return (
        <div id="api-not-available">
            <span className="text">
                Api is currently not available, please contact IT Support!
            </span>
        </div>
    );
};


ApiNotAvailable.propTypes = {
    apiNotAvailable: PropTypes.bool
};


function mapStateToProps(state) {
    const { apiNotAvailable } = state.app;

    return {
        apiNotAvailable
    };
}

const temp = connect(mapStateToProps)(ApiNotAvailable);

export { temp as ApiNotAvailable };