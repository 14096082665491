import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PersonalInfo, UserCredential } from '.';
import { HealthDocument } from './HealthDocument';
import { registrationAction, profileAction, alertAction } from '../../_actions';
import { ProfileValidator } from '../../_validator';
import { appType, env } from '../../_helpers';
import { Loading } from '../../components';
import { PrivacyPolicyDialog, TermsOfUseDialog, PharmanetConsentDialog } from '.';
import queryString from 'query-string';
import { msgConstant } from '../../_constants';


class RegistrationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    addOfficeRegisterTokenToFormData = (formData) => {
        const { location } = this.props;
        let params = queryString.parse(location.search);
        if(params.token) {
            formData.append('officeRegistrationToken', params.token);
        }
        return formData;
    }

    changeCheckbox = (event) => {
        const { dispatch, user } = this.props;

        user[event.target.name] = event.target.checked;
        dispatch(profileAction.change(user));

        switch (event.target.name) {
            case 'ckbPolicy': 
                dispatch(registrationAction.openPrivacyPolicy());
                break;
            case 'ckbTerms': 
                dispatch(registrationAction.openTermsOfUse());
                break;
            case 'ckbPharmanet':
                dispatch(registrationAction.openPharmanetConsent());
                break;
        }
    }

    createFormData() {
        const { user } = this.props;
        const formData = new FormData();

        formData.append('FirstName', user.FirstName);
        formData.append('LastName', user.LastName);
        formData.append('DateOfBirth', user.DateOfBirth);
        formData.append('PhoneNumber', user.PhoneNumber);
        formData.append('BestPhoneNumber', user.BestPhoneNumber);
        formData.append('Email', user.Email);
        formData.append('Password', user.Password);

        if (user.Address) {
            formData.append('Address', user.Address);
        }
        if (user.Province) {
            formData.append('Province', user.Province);
        }
        if (user.PostalCode) {
            formData.append('PostalCode', user.PostalCode);
        }
        if (user.City) {
            formData.append('City', user.City);
        }
        if (user.BCCardNumber) {
            formData.append('BCCardNumber', user.BCCardNumber);
        }
        if (user.HealthCardNumber) {
            formData.append('HealthCardNumber', user.HealthCardNumber);
        }
        if (user.HealthCardProvince) {
            formData.append('HealthCardProvince', user.HealthCardProvince);
        }
        if (user.PassportNumber) {
            formData.append('PassportNumber', user.PassportNumber);
        }
        if (user.PassportCountryId) {
            formData.append('PassportCountryId', user.PassportCountryId);
        }

        if (user.isPrivatePay) {
            formData.append('isPrivatePay', user.isPrivatePay);
        }

        formData.append('files', user.profileImageFile);
        formData.append('files', user.healthDocFile);
        if (user.healthDocFileBack) formData.append('files', user.healthDocFileBack);
        return formData;
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const { user, dispatch } = this.props;

        // profile validator
        const validator = new ProfileValidator();
        validator.personalInfo(user);
        (appType() === 'client' || (appType() === undefined && this.props.history.location.pathname != '/office-registration')) && validator.healthDoc(user);
        validator.userCredential(user);

        if (validator.validate()) {
            let formData = this.createFormData();

            //Add the Office Register Token 
            if(appType() === 'office' || (appType() === undefined && this.props.history.location.pathname == '/office-registration')) {
                formData = this.addOfficeRegisterTokenToFormData(formData);
            }

            dispatch(registrationAction.register(formData));
        } else {
            dispatch(alertAction.error(msgConstant.PLEASE_CORRECT_ERRORS_BELOW, validator.errorMsgs));
        }
    }

    renderForm() {
        const { user } = this.props;

        if ((appType() === undefined && this.props.history.location.pathname != '/office-registration') || appType() === 'client') {
            return (
                < >
                
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 mx-auto mbr-form">
                                <form className="mbr-form form-with-styler pb-5" 
                                    onSubmit={this.handleSubmit} 
                                    noValidate
                                >
                                    <div className="dragArea form-row">    
                                        {/* <hr className="my-1" /> */}
                                        <PersonalInfo edit={true} />
                                        {/* <hr className="mb-1" /> */}
                                        <HealthDocument edit={true} />
                                        <div>
                                            {/* <hr className="mb-1" /> */}
                                            <UserCredential edit={true} />
                                            {this.renderAgreement()}
                                            {this.renderButtons()}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <PrivacyPolicyDialog />
                    <TermsOfUseDialog />
                    <PharmanetConsentDialog />
                </>
            )
        } else if (appType() === 'office' || (appType() === undefined && this.props.history.location.pathname == '/office-registration')) {
            return (
                < >
                    <div className="profile-page container-fluid">
                        <div className="row justify-content-center">
                            <form className="px-3" onSubmit={this.handleSubmit} noValidate>
                                <hr className="my-1" />
                                <PersonalInfo edit={true} />
                                <hr className="mb-1" />
                                    <UserCredential edit={true} />
                                    {this.renderButtons()}
                            </form>
                        </div>
                    </div>
                </>
            )
        } 
    }

    /**
     * Render the form agreement section
     */
    renderAgreement() {
        const { user, privacyPolicyChecked, termsOfUseChecked, pharmanetConsentChecked } = this.props;

        return (
            <>
                <hr className="mb-4" />
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input " id="ckbCommunication" name="ckbCommunication"
                        checked={user.ckbCommunication || false} onChange={this.changeCheckbox} 
                        data-test-id="registration-page__ckbCommunication"
                    />
                    <label className="custom-control-label " htmlFor="ckbCommunication">
                       I give AskMedication.com consent to send me communication by email and cell phone text messages
                    </label>
                </div>
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="ckbPharmanet" name="ckbPharmanet"
                        checked={pharmanetConsentChecked || false} onChange={this.changeCheckbox} 
                        data-test-id="registration-page__ckbPharmanet"
                    />
                    <label className="custom-control-label" htmlFor="ckbPharmanet">
                        I have read and accept the AskMedication.com <span style={{
                            textDecoration: "underline",
                            color: "#4f87fb",
                            fontWeight: "bold"
                        }}>PharmaNet Policy</span>
                    </label>
                </div>
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="ckbPolicy" name="ckbPolicy"
                        checked={privacyPolicyChecked || false} onChange={this.changeCheckbox} 
                        data-test-id="registration-page__ckbPolicy"
                    />
                    <label className="custom-control-label" htmlFor="ckbPolicy">
                        I have read and accept the AskMedication.com <span style={{
                            textDecoration: "underline",
                            color: "#4f87fb",
                            fontWeight: "bold"
                        }}>Privacy Policy</span>
                    </label>
                </div>
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="ckbTerms" name="ckbTerms"
                        checked={termsOfUseChecked || false} onChange={this.changeCheckbox} 
                        data-test-id="registration-page__ckbTerms"
                    />
                    <label className="custom-control-label" htmlFor="ckbTerms">
                        I have read and accept the AskMedication.com <span style={{
                            textDecoration: "underline",
                            color: "#4f87fb",
                            fontWeight: "bold"
                        }}>Terms of Use</span>
                    </label>
                </div>
            </>
        );
    }

    renderButtons() {
        const { user, registering, privacyPolicyChecked, termsOfUseChecked, pharmanetConsentChecked } = this.props;
        const enable = (privacyPolicyChecked &&
                       termsOfUseChecked &&
                       user.ckbCommunication &&
                       pharmanetConsentChecked &&
                       !registering) || env() == "auto"

        if(appType() === 'office' || (appType() === undefined && this.props.history.location.pathname == '/office-registration')){
            return (
                <>
                <hr className="mb-4" />
                <div className="mb-4 text-right">
                        <button className="btn btn-success btn-lg submit-button" type="submit"
                            data-test-id="registration-page__submit--office"
                        >Sign up</button>                       
                </div>
               </>
            )
        } else if (appType() === 'client' || (appType() === undefined && this.props.history.location.pathname != '/office-registration')){

        return (
            <>
                <hr className="mb-4" />
                <div className="col-auto">
                    {enable ?
                        <button 
                            className="btn btn-primary display-7 submit-button" 
                            type="submit"
                            data-test-id="registration-page__submit"
                        >Submit</button>
                        :
                        <button className="btn display-7 disabled submit-button" disabled>Submit</button>                        
                    }
                </div>
            </>
        )};
    }

    renderLoading() {
        if (this.props.registering) {
            return <Loading />;
        }

        return '';
    }

    /**
     * Render the HTML template
     */
    render() {
        const { user } = this.props;

        return (
            <div className="register">
                <div className="container-fluid pt-3 my-3 text-center ">
                    <h2>Sign Up form</h2>
                     <div style={{marginTop:"20px", fontSize:"22px"}}>Already have an account? Log in <Link to="/login" style={{fontWeight: "bold"}}>here</Link>.</div>
                </div>
                {this.renderForm()}
            </div>
        );
    }
}


RegistrationPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object,
    registering: PropTypes.bool,
    openPrivacyPolicy: PropTypes.bool,
    privacyPolicyChecked: PropTypes.bool,
    termsOfUseChecked: PropTypes.bool, 
    pharmanetConsentChecked: PropTypes.bool
};


function mapStateToProps(state) {
    let { user } = state.profile;
    const { privacyPolicyChecked, termsOfUseChecked, pharmanetConsentChecked } = state.registration;

    if (user === undefined) {
        user = {};
    }

    return {
        user,
        privacyPolicyChecked,
        termsOfUseChecked,
        pharmanetConsentChecked
    };
}

const temp = connect(mapStateToProps)(RegistrationPage);

export { temp as RegistrationPage };
