import { patientConstant } from '../_constants';

export function patient(state = {}, action) {
    switch (action.type) {
        case patientConstant.GET_PATIENTS_SUCCESS:
            return {
                ...state,
                users: [...action.users]
            };
        case patientConstant.GET_PATIENT_SUCCESS:
            return {
                ...state,
                person: { ...action.person }
            };
        case patientConstant.SET_PAGE:
            return {
                ...state,
                page: action.page
            };
        case patientConstant.SET_ROWS:
            return {
                ...state,
                rows: action.rows
            };
        case patientConstant.SELECT_PATIENT:
            return {
                ...state,
                selectId: action.id
            };
        case patientConstant.UPDATE_ELIGIBILITY:
            if (state.person._id != action.personId) return { ...state };

            return {
                ...state,
                person: {
                    ...state.person,
                    HealthDocuments: state.person.HealthDocuments.map( doc => {
                        if (doc.Type == "BC Card") 
                            return {
                                ...doc,
                                status: action.status
                            };
                        return doc;
                    })
                }
            };
        default:
            return state;
    }
}