import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


export default async function getUrlFromMultiCanvas(canvases) {
    
    
    // create pdf by jsPDF
    const doc = new jsPDF();
    const width = doc.internal.pageSize.getWidth() - 40;
    
    for (let index = 0; index < canvases.length; index++) {
        const canvas = canvases[index];
        const ratio = canvas.height / canvas.width;
        const height = ratio * width;

        if (index > 0)
            doc.addPage();
        
        doc.addImage(canvas, 'JPEG', 20, 20, width, height);
    }
    

    // convert back to dataURL
    const dataURL = doc.output('datauristring');

    return dataURL;
}
