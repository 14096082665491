import React, {Component} from 'react';
import PropTypes from 'prop-types';


class ProvinceSelect extends Component {
    constructor(props) {
        super(props);

        // init
        this.state = {
            value: this.props.value
        };
    }

    onChange = (event) => {
        this.setState({
            value: event.target.value
        });

        // call outside props method
        this.props.onChange(event);
    }

    render() {
        const { id, name } = this.props;

        return (
            <select className="form-control display-7" id={id} name={name}
                value={this.state.value} onChange={this.onChange} required
                data-test-id="province-select"    
            >
                <option value="">Choose...</option>
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland and Labrador</option>
                <option value="NT">Northwest Territories</option>
                <option value="NS">Nova Scotia</option>
                <option value="NU">Nunavut</option>
                <option value="ON">Ontario</option>
                <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
                <option value="YT">Yukon</option>
                <option value="OTHER">Other</option>
            </select>
        );
    }
}


ProvinceSelect.propTypes = {
    onChange: PropTypes.func,
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string
};


const temp = ProvinceSelect;

export { temp as ProvinceSelect };