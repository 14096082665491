import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Routes from './Routes';
import { NavbarDashboard, PopupDialog, ApiNotAvailable, Alert, Loading, NavbarDashboardLogout } from './components';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { userAction,loadingAction } from './_actions';
import { history, env, getToken, isSouthVan } from './_helpers';
import { Screenshot } from './pages/Problem';
import { Footer, LastLoginDialog } from './pages/App';
import { Footer as LogoutFooter} from './pages/Public/footer';
import { Banner } from './pages/Public/banner';
import { PublicRoute } from './components/CustomRoute';
import { southVanService } from './_services';
import { saveSouthVanUser } from './_actions'
import config from './config';

// import "./assets/web/assets/mobirise-icons2/mobirise2.css";
// import "./assets/bootstrap-material-design-font/css/material.css";
// import "./assets/tether/tether.min.css";
// import "./assets/bootstrap/css/bootstrap.min.css";
// import "./assets/bootstrap/css/bootstrap-grid.min.css";
// import "./assets/bootstrap/css/bootstrap-reboot.min.css";
// import "./assets/formstyler/jquery.formstyler.css";
// import "./assets/formstyler/jquery.formstyler.theme.css";
// import "./assets/datepicker/jquery.datetimepicker.min.css";
// import "./assets/dropdown/css/style.css";
// import "./assets/theme/css/style.css";
// import "./assets/recaptcha.css";

// import "./assets/mobirise/css/mbr-additional.css";



class App extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.onIdle = this.onIdle.bind(this);
    }

    async componentDidMount() {
        const { dispatch } = this.props;
        if (!isSouthVan() && getToken()){
            dispatch(userAction.refreshPage());
  
        } 
        try {
            let southVanToken = sessionStorage.getItem('svm_dashboard_token');
            if (!southVanToken) return;
            if (southVanToken)  {
                dispatch(loadingAction.openWithStyle('loading-whiteoverlay'))
                dispatch(userAction.authenticateWithToken(southVanToken));
                return;
            }
        }  catch (err){
            dispatch(loadingAction.close());
            console.log('error',err)
        }




            // try{
            //     dispatch(loadingAction.openWithStyle('loading-whiteoverlay'))
            //     const value = await getValue2(config.southVanHost, "accessToken");

            //     if(!value){
            //         dispatch(loadingAction.close());
            //         return ;
            //     };
            //     dispatch(userAction.authenticateWithToken(value));
               
            // }catch(err){
            //     dispatch(loadingAction.close());
            //     console.log('error',err)
            // }

    }

    render() {
        let { loggedIn, isloggingIn, isTried, user } = this.props;
        const token = getToken();
        /*
        if (
            getToken() && (!(user || {})._id)
            // old logic getToken() && (!loggedIn || isloggingIn). but we want to remove condition getToken() && !loggedIn && !isloggingIn
        )
            return null;*/
        if (
            !token
            || (token && isTried)
        ) {

        
            const classes = loggedIn ? 'col-md-10' : '';

            // 5 minutes timeout
            return (
                <>
                    <LastLoginDialog />
                    <ApiNotAvailable />
                    <Loading />
                    {loggedIn && <Screenshot />}
                    <Alert />
                    <PopupDialog />
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref; }}
                        element={document}
                        onIdle={this.onIdle}
                        timeout={1000 * 60 * 5} 
                    />
                    <Router history={history}>
                        <>
                            {loggedIn 
                                ? <NavbarDashboard className="no-print" /> 
                                : <PublicRoute path='/' component={NavbarDashboardLogout} />
                            }
                            {!loggedIn && 
                                <PublicRoute path='/' component={Banner} />}
                            <main id="main" role="main" className={classes + ' ' + (loggedIn ? 'logged-in' : 'logged-out' + " no-print")}>
                                <Routes />                            
                            </main>
                            {loggedIn ? <Footer /> : <LogoutFooter />}
                        </>
                    </Router>
                </>
            );
        } else {
            return null;
        }
    }

    onIdle() {
        const { dispatch, loggedIn } = this.props;

        // logout when it is login and production environment
        if (env() !== 'dev' && env() != 'auto' && loggedIn) {
                if(isSouthVan()){
                    dispatch(userAction.logout());
                    window.location.assign(config.southVanHost);
                } else{
                    dispatch(userAction.logout());
                }
         }
    }
}


App.propTypes = {
    dispatch: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool
};


function mapStateToProps(state) {
    const { loggedIn, isloggingIn, isTried, user, southVanUser } = state.authentication;
    

    return {
        loggedIn,
        isloggingIn,
        isTried,
        user,
        southVanUser
    };
}


export default connect(mapStateToProps)(App);
