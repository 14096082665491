import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { manageDataAction, alertAction, medicationFilterAction } from '../../_actions';
import { FileUploader } from '../../components';


class ManageDataPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            importCollection: '',
            displayCollection: ''
        };

        this.importData = this.importData.bind(this);
    }

    /**
     * Get the file on the input file
     */
    changeFile = (files) => {
        this.setState({
            file: files && files[0]
        });
    }

    changeImportSelect = (event) => {
        const { target } = event;

        this.setState({
            importResource: target[target.selectedIndex].text,
            importCollection: target.value
        });
    }

    changeDisplaySelect = (event) => {
        const { target } = event;

        this.setState({
            displayResource: target[target.selectedIndex].text,
            displayCollection: target.value
        });
    }


    importData = () => {
        const { dispatch } = this.props;
        const { importCollection, importResource, file } = this.state;

        const formData = new FormData();
        formData.append('collection', importCollection);
        formData.append('file', file);

        if (importCollection !== '' && file !== undefined) {
            if (importCollection === 'medicationfilters') {
                dispatch(medicationFilterAction.importData(formData));
            } else {
                dispatch(manageDataAction.importData(importResource, formData));
            }
        } else {
            dispatch(alertAction.warning('Please choose the file and correct resource name before import!'));
        }
    }

    displayData = () => {
        const { dispatch } = this.props;
        const { displayCollection, displayResource } = this.state;

        if (displayCollection !== '') {
            dispatch(manageDataAction.displayData(displayResource, displayCollection));
        } else {
            dispatch(alertAction.warning('Please select a resource to display!'));
        }
    }

    buildDrugs = () => {
        const { dispatch } = this.props;
        dispatch(manageDataAction.buildDrugs());
    }

    renderJson() {
        const { json } = this.props;

        if (json) {
            return (
                <article className="display-json">
                    <pre className="json">
                        {JSON.stringify(json, null, 4)}
                    </pre>
                </article>
            );
        } else {
            return '';
        }
    }

    render() {

        return (
            <div id="manage-data-page">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Manage Data</h1>
                </div>
                <Paper className="p-3 my-4">
                    <div className="title">
                        Import data
                    </div>
                    <div className="import-data">
                        <select className="custom-select d-block" onChange={this.changeImportSelect} value={this.state.importCollection}>
                            <option value="">Choose resource</option>
                            <option value="_activeIngredient">Active Ingredient</option>
                            <option value="_dosageForm">Dosage Form</option>
                            <option value="_drugProduct">Drug Product</option>
                            <option value="_productStatus">Product Status</option>
                            <option value="_routeOfAdministration">Route of Administration</option>
                            <option value="_therapeuticClass">Therapeutic Class</option>
                            <option value="medicationfilters">Medication Filter</option>
                        </select>
                        <FileUploader id="raw" onChange={this.changeFile} accept="application/json, .csv" />
                        <button className="btn btn-primary" onClick={this.importData}>Import</button>
                    </div>
                </Paper>
                <Paper className="p-3 my-4">
                    <div className="title">
                        Build drugs collection
                    </div>
                    <button className="btn btn-primary" onClick={this.buildDrugs}>Build Drugs</button>
                </Paper>
                <Paper className="p-3 my-4">
                    <div className="title">
                        Display recourse structure
                    </div>
                    <div className="display-data">
                        <select className="custom-select d-block" onChange={this.changeDisplaySelect} value={this.state.displayCollection}>
                            <option value="">Choose resource</option>
                            <option value="_activeIngredient">Active Ingredient</option>
                            <option value="_dosageForm">Dosage Form</option>
                            <option value="_drugProduct">Drug Product</option>
                            <option value="_productStatus">Product Status</option>
                            <option value="_routeOfAdministration">Route of Administration</option>                                                        
                            <option value="_therapeuticClass">Therapeutic Class</option>
                            <option value="drugs">Drugs</option>
                            <option value="medicationfilters">Medication Filter</option>                            
                        </select>
                        <button className="btn btn-outline-primary" onClick={this.displayData}>Display</button>
                        {this.renderJson()}
                    </div>
                </Paper>
            </div>
        );
    }
}


ManageDataPage.propTypes = {
    dispatch: PropTypes.func,
    json: PropTypes.object
};


function mapStateToProps(state) {
    const { json } = state.manageData;

    return {
        json
    };
}

const temp = connect(mapStateToProps)(ManageDataPage);

export { temp as ManageDataPage };