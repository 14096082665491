import React from 'react';
import ReactDomServer from 'react-dom/server';
import { Provider } from 'react-redux';

import { store } from './';
import getCanvasFromEle from './getCanvasFromEle';


export default async function getCanvasFromComp(comp) {
    
    // const innerDiv = document.createElement('div');
    const stringContainingHTMLSource = ReactDomServer.renderToStaticMarkup(
        <Provider store={store}>
            {comp}
        </Provider>
    );
    // innerDiv.innerHTML = html;
    const parser = new DOMParser();
    const innerDiv = parser.parseFromString(stringContainingHTMLSource, "text/html").activeElement.childNodes[0];
    // innerDiv.width = 'fit-content';
    innerDiv.width = "650px";
    // elem.style.position = 'relative';
    // elem.style.top = window.innerHeight + 'px';

    const elem = document.createElement('div');
    elem.style.width = "100%";
    elem.style.maxWidth = "960px";
    elem.style.position = "fixed";
    elem.style.top = "0";
    elem.style.left = "0";
    elem.style.right = "0";
    elem.style.bottom = "0";
    elem.style.zIndex = "-1300";
    elem.style.maxHeight = "calc(100% - 96px)";
    elem.style.overflowY = "auto";

    elem.appendChild(innerDiv);
    document.body.appendChild(elem);
    const canvas = await getCanvasFromEle(innerDiv);
    elem.removeChild(innerDiv);
    document.body.removeChild(elem);
    // document.body.appendChild(canvas);
    return canvas;
}
