import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import * as Sentry from "@sentry/react";

import { alertAction }  from '../_actions/alert.action';
import rootReducer from '../_reducers';


const loggerMiddleware = createLogger();

let composeEnhancers = compose;

const crashReport = store => next => action => {
    try {
        return next(action);
    } catch (error) {
        Sentry.captureException(error);
        store.dispatch(alertAction.error("Some error happens. Please try again or contact us."));
    }
}
const middleWares = [crashReport, thunkMiddleware];

if (process.env.REACT_APP_ENV == "test" 
    || process.env.REACT_APP_ENV == "dev"
    || process.env.REACT_APP_ENV == "auto"
) {
    middleWares.push(loggerMiddleware);
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}
    

export const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(
        ...middleWares
    )
));