import React from 'react';



class PharmacySelectOscar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchKey:'',
            searchList:[],
            pharmacyList:this.props.pharmacyList
        }
        
    }


    componentDidMount() {
        if(this.state.pharmacyList.length > 0) {
            let pharmacyToBeSelected = this.props.selectedPharmacy ? this.props.selectedPharmacy : this.props.pharmacyList[0]
            this.selectItem(pharmacyToBeSelected)
        }
    }



    componentDidUpdate(prevProps,prevState){
        if(this.props.pharmacyList !== prevProps.pharmacyList){
            this.setState({
                pharmacyList:this.props.pharmacyList
            })
            
            if(this.props.pharmacyList.length > 0){
                let pharmacyToBeSelected = this.props.selectedPharmacy ? this.props.selectedPharmacy : this.props.pharmacyList[0]
                this.selectItem(pharmacyToBeSelected)
            }
        }
    }
    
    handleChange = (event) => {
        this.setState({
            searchKey: event.target.value
        },()=>{
            if(this.state.searchKey.length >= 3){
                this.filterPharmacyList();
            }
        });

        
    }

    filterPharmacyList() {
        let searchKey = this.state.searchKey.split('').join('-*');
        searchKey = '.*' + searchKey.replace(/ /g, ".*").toLowerCase() + '.*';  

        let filtered = this.state.pharmacyList.filter(ph=>{
            let mapString = ph.name.toLowerCase() + ph.address.address.toLowerCase();
            return mapString.match(searchKey)
        })
        filtered.sort((pha,phb)=>{
            let a = pha.name;
            let b = phb.name;

            if (a > b) {
                return 1;
            }
            if (b > a) {
                return -1;
            }
            return 0;
        });

        this.setState({
            searchList: filtered
        })
    }

    selectItem = (selectedItem) => {

        this.setState({
            searchList: [],
            searchKey: '',
            item:selectedItem
        });

        // match the event.target structure
        this.props.onChange({
            target: {
                name: this.props.name,
                value: selectedItem         
            }
        });
    }

    removeItem = () => {
        const item = '';

        this.setState({
            item
        });

        // match the event.target structure
        this.props.onChange({
            target: {
                name: this.props.name,
                value: item
            }
        });
    }

    renderInput() {
        if (!this.state.item) {
            return (
                <input type="text" className={this.props.className} placeholder="Search for a pharmacy"
                    value={this.state.searchKey} onChange={this.handleChange} disabled={this.props.disabled}/>
            );
        }

        return '';
    }

    renderSearchList() {

        if (this.state.searchList.length > 0) {
            return (
                <ul>
                    {
                        this.state.searchList.map((item,index) => {
                            return (
                                <li key={index} onClick={() => this.selectItem(item)}>
                                    {`${item.name} - ${item.address.address}`}
                                </li>
                            );
                        })
                    }
                </ul>
            );
        } else {
            return '';
        }
    }

    renderItem() {
        const { item } = this.state;
        // const { isDeletable = true } = this.props;

        if (item) {
            return (
                <div className="item">
                    <span className="badge badge-light" >
                        {item.name} <br/>
                        Address: {item.address.address}<br/>
                        Fax: {item.fax}<br/>
                        Phone: {item.phone1}
                         <button onClick={this.removeItem} type="button" className="btn btn-secondary btn-sm" data-dismiss="alert" aria-label="Close">
                            {/* <span aria-hidden="true">&times;</span> */}
                            <span aria-hidden="true">Modify my preferred pharmacy</span>
                        </button>
                    </span>
                </div>
            );
        }

        return '';
    }



    render(){
        return <div id="pharmacy-select">
            {this.renderInput()}
            {this.renderSearchList()}
            {this.renderItem()}
        </div>
    }

}


export { PharmacySelectOscar };