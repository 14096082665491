import axios from 'axios';
import config from '../config';
import { getPersonId, getToken } from '../_helpers';


export const faxService = {
    queuePrescriptionFax,
    queueSleepAssessmentFax,
    resendFax
};


function queuePrescriptionFax(faxNumber, dataURL, prescriptionId, personId) {
    const url = `${config.apiHost}/fax/queue-prescription-fax`;
    const body = {
        faxNumber,
        dataURL,
        prescriptionId,
        personId,
        faxedBy: getPersonId()        
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, body);
}

function queueSleepAssessmentFax(faxNumber, dataURL, sleepAssessmentId, personId) {
    const url = `${config.apiHost}/fax/queue-sleep-assessment-fax`;
    const body = {
        faxNumber,
        dataURL,
        sleepAssessmentId,
        personId,
        faxedBy: getPersonId()        
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, body);
}

async function resendFax(sfaxDetailsID) {
    try {
        const url = `${config.apiHost}/fax/${sfaxDetailsID}/resend`;
        const body = {       
        };
        // axios.defaults.headers.common['x-access-token'] = getToken();
        const result = await axios.post(url, body);
        return result;
    } catch (error) {
        throw ({message : error.response.data.message});
    }
    
}
     
// currently not used
// function checkStatus() {
//     const url = `${config.apiHost}/fax/check-status`;
//     axios.defaults.headers.common['x-access-token'] = getToken();

//     return axios.get(url);
// }